import React, {useState} from 'react'
import CategoryDetails from '../component/CategoryDetails';

const ViewCategory = props => {
  let { category } = props.location.state.category;

  const [values] = useState({
    id: category.id,
    name: category.name
  });

  const onHandleSubmit = (values) => {
    return true;
  }

  return(
    <CategoryDetails
      initialCategory = {values}
      onHandleSubmit = {onHandleSubmit}
      submitButton = "View"
      title = "View Category"
    />

  )
}

export default ViewCategory;
