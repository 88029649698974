import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Bar, getElementAtEvent} from 'react-chartjs-2';

const InteractiveBarChart = props => {
  const {options, data, onHandleBarClick} = props;

  const onBarClick = (event) => {
    const elem = getElementAtEvent(chartRef.current, event);
    if (elem.length === 0) return;
    onHandleBarClick(elem[0].index, elem[0].datasetIndex)
  }

  const chartRef = useRef();
  return (
    <Bar
      options={options}
      data={data}
      type="bar"
      onClick={onBarClick}
      ref={chartRef}
    />
  );
}

InteractiveBarChart.propTypes = {
  className: PropTypes.string
};

export default InteractiveBarChart;
