import React from 'react'

import Card from "../../../components/Card/Card";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {CardContent, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ViewConnectedAppInfo from "../components/ConnectedAppsList/components/ViewConnectedAppInfo";
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {useDispatch} from "react-redux";
import {companyActions} from "../../../store/actions";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(0)
  },
  createButton: {
    margin: theme.spacing(1),
    color: '#FFF',
    backgroundColor: theme.palette.success.main
  },
  colorPicker: {
    fontSize: 12,
    size: 10,
    maxLength: 30
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  resetButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  cancelButton: {
    margin: theme.spacing(1),
    color: '#FFF',
    backgroundColor: theme.palette.error.main
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  fab: {
    margin: theme.spacing(1), // You might not need this now
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
    aspectRatio: "3/1",
    width: "max-content",
  },
  button: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    position: "relative",
    height: `calc(100% - 2 * ${theme.spacing(2)})`,
    width: `calc(100% / 3 - 2 * ${theme.spacing(2)})`,
  },
  cardRoot: {
    padding: theme.spacing(4),
    minHeight: 150,
  },
  connectedAppImage: {
    maxWidth: 70,
    width: 50,
    height: 'auto'
  },
  image: {
    position: 'relative',
    minWidth: 100,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 'auto',
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.5,
        filter: `saturate(var(--value, 2))`,
        webkitFilter: `saturate(var(--value, 2))`
      },
      '& $connectedAppImage': {
        width: 60
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        padding: 5,
        border: '5px solid currentColor',
        borderRadius: 10
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  captionText: {
    padding: 10
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  iconImageDiv: {
    width: '100%',
    alignContent: 'center'
  },
  iconImageDivReverse: {
    width: '100%',
    alignContent: 'center',
    filter: `invert(1)`,
    webkitFilter: `invert(1)`
  },
  iconAvatarList: {
    alignContent: 'center',
    color: "#FFF",
    marginTop: 0,
    backgroundColor: 'gray',
    borderRadius: 5,
    fontSize: 32,
    marginRight: 20
  },
  iconAvatar: {
    alignContent: 'center',
    backgroundColor: "gray"
  },
  imageTitle: {
    maxWidth: 90,
    position: 'relative',
    padding: 5,
    border: '2px solid white',
    borderRadius: 10
  },
  imageMarked: {
    height: 1,
    width: 40,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 20px)',
    transition: theme.transitions.create('opacity'),
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: "100%",
  },
}));


const ViewConnectedApps = props => {
  const {
    initialConnectedApp,
    action
  } = props.location.state;

  const classes = useStyles();
  const dispatch = useDispatch();

  const onHandleSubmitUpdate = (values) => {
    dispatch(companyActions.updateConnectedApp(values));
  }

  const onHandleSubmitNew = (values) => {
    dispatch(companyActions.addNewConnectedApp(values));
  }

  let title = action + " Orchestrated Apps";
  let subtitle = action + " Orchestrated Apps Information";
  return (
    <div className={classes.root}>
      <PageHeader title={title} subTitle={subtitle}/>
      <Card className={classes.content}>
        {(!initialConnectedApp) ? (<LoadingSpinner/>) : (
          <CardContent>
            <div>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                >
                  <ViewConnectedAppInfo
                    classes={classes}
                    appsInfo={initialConnectedApp}
                    edit={action === "Edit"}
                    onHandleSubmitNew={onHandleSubmitNew}
                    onHandleSubmitUpdate={onHandleSubmitUpdate}
                  />

                </Grid>

              </Grid>
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  )
}

export default ViewConnectedApps;
