import {categoryConstants} from '../../constants/index';

const initialState = {};

export function categories(state = initialState, action) {
  switch (action.type) {
    case categoryConstants.ADDNEW_REQUEST:
      return {...state,
        requestPending: true
      };
    case categoryConstants.ADDNEW_SUCCESS:
      return {
        ...state,
        id: action.category.data,
        requestPending: false
      };
    case categoryConstants.ADDNEW_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case categoryConstants.GETALL_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case categoryConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.categories.data,
        requestPending: false
      };
    case categoryConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case categoryConstants.GET_CATEGORY_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case categoryConstants.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case categoryConstants.GET_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case categoryConstants.UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case categoryConstants.UPDATE_SUCCESS:
      return {
        ...state,
        item: action.category.data,
        requestPending: false
      };
    case categoryConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
