import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.white
  },
  avatarIcon: {
    height: 60,
    width: 60,
    color: theme.palette.tertiary.main,
  },
  name: {
    marginTop: theme.spacing(1)
  },
  roleText: {
    color: '#ff6300',
    fontWeight: 800
  },
}));
