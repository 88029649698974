import React, {useState} from 'react'
import {useDispatch} from 'react-redux';

import {companyActions} from '../../../store/actions';
import CompanyInfo from '../component/CompanyInfo';
import Card from "../../../components/Card/Card";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {CardContent, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Button from "../../../components/CustomButtons/Button";
import {Create, Refresh} from "@mui/icons-material";
import ContactInfo from "../component/ContactInfo";
import {history} from "../../../helpers/history";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  createButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  resetButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3)
  }
}));


const ViewCompany = props => {
  const { company, companies, submit }  = props.location.state;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [companyInfo, setCompanyInfo] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [hasCompanyErrors, setHasCompanyErrors] = useState(false);
  const [hasContactErrors, setHasContactErrors] = useState(false);
  const [updtSwitch, setUpdtSwitch] = useState(false);
  const [updtCompany, setUpdtCompany] = useState(false);
  const [updtContact, setUpdtContact] = useState(false);

  let edit = false;
  if (submit !== "View"){
    edit = true;
  }

  if (initialRender){
      if (company) {
        let parentguid = company.parentguid;
        if (company.parentguid === null){
          parentguid = 'owner';
        }
        setCompanyInfo({
          id: company.guid,
          name: company.name,
          guid: company.guid,
          parentguid: parentguid,
          street: company.street,
          city: company.city,
          state: company.state,
          zipcode: company.zipcode,
          registercode: company.registercode,
          logoname: company.logoname,
          deactivatedate: company.deactivatedate,
          datecreated: company.datecreated,
          active: company.active
        });

        setContactInfo({
          firstname: company.firstname,
          lastname: company.lastname,
          cStreet: company.cStreet,
          cCity: company.cCity,
          cState: company.cState,
          cZipcode: company.cZipcode,
          cPhone: company.cPhone,
          cEmail: company.cEmail
        });
      }
      setInitialRender(false);
  }

  const onHandleSwitchChange = (event) => {
    if (edit){
      setCompanyInfo(companyInfo => ({...companyInfo, [event.target.name]: (event.target.checked ? 1 : 0)}));
      setUpdtSwitch(true);
    }
  };

  const onHandleCompanyChange = (event) => {
    if (edit) {
      setCompanyInfo(companyInfo => ({...companyInfo, [event.target.name]: event.target.value}));
      setUpdtCompany(true);
    }
  };

  const onHandleParentCompanyChange = (event) => {
    if (edit) {
      let parentguid = event.target.value
      if (parentguid === "owner"){
        parentguid = "";
      }
      setCompanyInfo(companyInfo => ({...companyInfo, [event.target.name]: parentguid}));
      setUpdtCompany(true);
    }
  };

  const onHandleContactChange = (event) => {
    if (edit){
      setContactInfo(contactInfo => ({...contactInfo, [event.target.name]: event.target.value}));
      setUpdtContact(true);
    }
  };

  const onHandleReset = () => {
    setCompanyInfo(company);
    setUpdtSwitch(false);
    setUpdtCompany(false);
    setUpdtContact(false);
  }

  const onHandleSubmit = (values) => {
    if (updtCompany || updtContact || updtSwitch){
      let companyValues = {
        updtCompany: updtCompany,
        updtContact: updtContact,
        updtSwitch: updtSwitch,
        companyInfo: companyInfo,
        contactInfo: contactInfo
      }

      if (submit === "Edit"){
        dispatch(companyActions.update(companyValues));
      } else {
        dispatch(companyActions.add(companyValues))
      }

      history.goBack();
    }
  }

  let title = submit + " Company";
  let subtitle = submit + " Company Information";

  return(
    <div className={classes.root}>
      <PageHeader title={ title } subTitle={ subtitle }/>
      <Card className={classes.content}>
        <CardContent>
          <div>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
               <CompanyInfo
                  classes = { classes }
                  companyInfo = { companyInfo }
                  companies = { companies }
                  onHandleSwitchChange = { onHandleSwitchChange }
                  onHandleParentCompanyChange = {onHandleParentCompanyChange}
                  onHandleCompanyChange = {onHandleCompanyChange}
                  setHasCompanyErrors = {setHasCompanyErrors}
                  submitButton ={ submit }
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <ContactInfo
                  classes = { classes }
                  contactInfo = { contactInfo }
                  onHandleContactChange = {onHandleContactChange}
                  setHasContactErrors={setHasContactErrors}
                  submit ={ submit }
                />
              </Grid>
              {!edit ? "": (
                <div className={classes.fab} style={{position: 'fixed'}}>
                  {(updtCompany || updtContact || updtSwitch) &&
                    <Button aria-label="Create" className={classes.createButton}
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={(hasCompanyErrors || hasContactErrors)}
                      onClick={onHandleSubmit}
                    >
                      <Create/> {submit === "Edit" ? ("Update") : (submit)}
                    </Button>
                  }
                  <Button aria-label="Reset" className={classes.resetButton}
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={onHandleReset}
                  >
                    <Refresh/> Reset
                  </Button>

                </div>
              ) }
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default ViewCompany;
