import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Card, Grid} from '@mui/material';
import {SECURITY_ROLES} from '../../../constants/SECURITY_ROLES';
import {Redirect} from 'react-router-dom';

import {DocumentMetrics, LatestDocuments} from '../components';
import {useDispatch, useSelector} from 'react-redux';
import {
  AdminUserRoleMetrics,
  CompanyMetrics,
  PendingDocuments,
  UsersMetrics
} from './components/';
import CloudAdminDocumentsHistory from './components/DocumentsHistory';
import CloudAdminTopDocumentsViewedMetrics from './components/TopViewedDocuments';
import CloudAdminIpMetrics from './components/IpMetrics/CloudAdminIpMetrics';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {dashboardActions} from "../../../store/actions";

const useStyles = makeStyles(theme => ({
  root: {}
}));

const AdminDashboard = () => {
  const classes = useStyles();
  const user = useSelector(state => state.authentication.user);
  const {metrics, requestPending, nextRequest} = useSelector(state => state.dashboard);
  const [initialLoad, setInitialLoad] = useState(true);
  const [awaitingDispatch, setAwaitingDispatch] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && !requestPending) {
      const waitTime = initialLoad ? 0 : nextRequest - Date.now();
      let thisDispatch = dispatch(dashboardActions.getCloudAdminDashboardMetrics(waitTime));
      setAwaitingDispatch(thisDispatch);
      setInitialLoad(false);
    }
  }, [user, dispatch, initialLoad, metrics, requestPending, nextRequest]);

  useEffect(() => {
    return function cleanup() {
      if (awaitingDispatch && awaitingDispatch.cancel) awaitingDispatch.cancel();
    }
  }, [awaitingDispatch])

  return (
    <React.Fragment>
      {user && (user.role) && (user.role === SECURITY_ROLES.CLOUD_ADMIN) ?
        (
          <div className={classes.root}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                { metrics ? (
                  <PendingDocuments metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Pending Documents"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                { metrics ? (
                  <CompanyMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Company Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                { metrics ? (
                  <UsersMetrics userData={metrics}/>) : (  <Card><LoadingSpinner title={"Loading User Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <AdminUserRoleMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner  title={"Loading Role Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={4}
                xs={12}
              >
                { metrics ? (
                  <DocumentMetrics metrics={metrics} view={SECURITY_ROLES.CLOUD_ADMIN}/>) : (  <Card><LoadingSpinner title={"Loading Admin Document Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                md={12}
                xl={4}
                xs={12}
              >
                { metrics ? (
                  <CloudAdminTopDocumentsViewedMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Top Documents Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                { metrics ? (
                  <CloudAdminDocumentsHistory metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Document History"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                { metrics ? (
                  <LatestDocuments metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Latest Documents"}/></Card>)
                }
              </Grid><
                Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
                     >
                { metrics ? (
                  <CloudAdminIpMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Access Metrics"}/></Card>)
                }
              </Grid>
            </Grid>
          </div>)
        :
        (<Redirect to="/"/>)
      }
    </React.Fragment>
  );
}

export default AdminDashboard;
