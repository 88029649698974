import {toast} from 'react-toastify';
import {alertConstants} from '../store/constants';

export const toastHelper = {
  showToast
}

function showToast(type, message) {
  switch(type) {
    case alertConstants.ERROR: {
      toast.error(message);
      break;
    }
    case alertConstants.SUCCESS : {
      toast.success(message);
      break;
    }
    case alertConstants.WARNING : {
      toast.warning(message);
      break;
    }
    default: {
      toast(message);
      break;
    }
  }
}
