import axiosInstance from "../../axios.instance";
import {requestHelper} from "../helpers";
import {maestroenv} from '../../helpers/maestroenv';

export const vodService = {
  uploadToVoDApi,
  getVoDUploadProgress,
  getVoDFile,
  getVoDFileDirectUrl
}

function uploadToVoDApi(visibility, uploadFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_VOD_URL}/storage?visibility=${visibility}`, uploadFields)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error))
    });
}

function getVoDUploadProgress(providedUrl) {
  return axiosInstance.get(`${maestroenv.REACT_APP_VOD_URL}/${providedUrl}`)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error))
    });
}

function getVoDFile(providedUrl) {
  return axiosInstance.get(`${maestroenv.REACT_APP_VOD_URL}/${providedUrl}`, {
    responseType: 'blob'
  })
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error))
    });
}

function getVoDFileDirectUrl(providedUrl) {
  return axiosInstance.get(`${maestroenv.REACT_APP_VOD_URL}/download/${providedUrl}`, {
    responseType: 'text'
  })
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error))
    });
}
