import 'date-fns';
import React from 'react';
import MaterialTable from '@material-table/core';
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";

const UploadListPanel = props => {
  const {
    fileList,
  } = props

  const WithoutPin = [
    { title: 'File Name', field: 'name', cellStyle: { width: '60%', maxWidth: '60%' } },
    { title: 'Size', field: 'size', type: 'numeric' },
    { title: 'Type', field: 'type' },
  ];

  let selectedFiles = [];
  for (let i = 0; i < fileList.length; i++) {
    let record = {
      'name': fileList[i].name,
      'size': fileList[i].size,
      'type': fileList[i].type
    };
    selectedFiles.push(record);
  }

  return(
    <div>
      {!selectedFiles ? (<LoadingSpinner/>) :(
        <MaterialTable
          style={{width: "100%", height: "100%", padding: 20, paddingBottom: 0}}
          columns={WithoutPin}
          data={selectedFiles}
          title=""
        />
      )}
    </div>
  )
}

export default UploadListPanel;
