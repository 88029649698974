class AudioVideoDocTypeReference {
  typeMap = {
    AUDIO: "audio",
    VIDEO: "video"
  }

  typeList = Object.values(this.typeMap).map(type => `${type}/*`);

  videoTypeList = [`${this.typeMap.VIDEO}/*`];
  audioTypeList = [`${this.typeMap.AUDIO}/*`];

  /**
   *
   * @param {File} file
   * @return {string | null} the document's content type
   */
  isDocType(file){
    const fileTypeFragments = file.type.split("/");
    const type = fileTypeFragments[0];
    return this.typeList.includes(`${type}/*`) ? file.type : null;
  }
}

export const AudioVideoDocTypes = new AudioVideoDocTypeReference();
