import {companyConstants} from '../../constants/index';
import {companyService} from '../../services/index';
import {alertActions} from '../index';
import {history} from '../../../helpers/history';

export const companyActions = {
  add,
  update,
  activate,
  deactivate,
  getCompanyByName,
  getCompanyById,
  getParentCompanyList,
  getAllCompanies,
  getAllActiveCompanies,
  addNewConnectedApp,
  updateConnectedApp,
  updateConnectedAppLink,
  uploadAppImages
};

function add(company) {
  return dispatch => {
    dispatch(request(company));

    companyService.add(company)
      .then(
        company => {
          dispatch(success(company));
          dispatch(alertActions.success("Company " + company.name + " added."));
          getAllCompanies();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(company) {
    return {type: companyConstants.ADDNEW_REQUEST, company}
  }

  function success(company) {
    return {type: companyConstants.ADDNEW_SUCCESS, company}
  }

  function failure(error) {
    return {type: companyConstants.ADDNEW_FAILURE, error}
  }
}

function getAllCompanies() {
  return dispatch => {
    dispatch(request());
    companyService.getAllCompanies()
      .then(
        companies => dispatch(success(companies)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: companyConstants.GETALL_REQUEST}
  }

  function success(companies) {
    return {type: companyConstants.GETALL_SUCCESS, companies}
  }

  function failure(error) {
    return {type: companyConstants.GETALL_FAILURE, error}
  }
}

function getAllActiveCompanies() {
  return dispatch => {
    dispatch(request());
    companyService.getAllActiveCompanies()
      .then(
        companies => dispatch(success(companies)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: companyConstants.GETALL_ACTIVE_REQUEST}
  }

  function success(companies) {
    return {type: companyConstants.GETALL_ACTIVE_SUCCESS, companies}
  }

  function failure(error) {
    return {type: companyConstants.GETALL_ACTIVE_FAILURE, error}
  }
}

function getParentCompanyList() {
  return dispatch => {
    dispatch(request());
    companyService.getParentCompanyList()
      .then(
        companies => {
          dispatch(success(companies))
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: companyConstants.GET_PARENT_COMPANY_REQUEST}
  }

  function success(companies) {
    return {type: companyConstants.GET_PARENT_COMPANY_SUCCESS, companies}
  }

  function failure(error) {
    return {type: companyConstants.GET_PARENT_COMPANY_FAILURE, error}
  }
}

function getCompanyByName(name) {
  return dispatch => {
    dispatch(request(name));

    companyService.getCompanyByName(name)
      .then(
        company => dispatch(success(company)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request(name) {
    return {type: companyConstants.GET_COMPANY_REQUEST}
  }

  function success(name) {
    return {type: companyConstants.GET_COMPANY_SUCCESS, name}
  }

  function failure(error) {
    return {type: companyConstants.GET_COMPANY_FAILURE, error}
  }
}

function getCompanyById(id) {
  return dispatch => {
    dispatch(request(id));

    companyService.getCompanyById(id)
      .then(
        company => dispatch(success(company)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request(name) {
    return {type: companyConstants.GET_COMPANY_REQUEST}
  }

  function success(name) {
    return {type: companyConstants.GET_COMPANY_SUCCESS, name}
  }

  function failure(error) {
    return {type: companyConstants.GET_COMPANY_FAILURE, error}
  }
}

function activate(id) {
  return dispatch => {
    dispatch(request(id));

    companyService.activate(id)
      .then(
        id => {
          dispatch(success(id));
          getAllCompanies();
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(id) {
    return {type: companyConstants.ACTIVATE_REQUEST}
  }

  function success(id) {
    return {type: companyConstants.ACTIVATE_SUCCESS, id}
  }

  function failure(error) {
    return {type: companyConstants.ACTIVATE_FAILURE, error}
  }
}

function deactivate(id) {
  return dispatch => {
    dispatch(request(id));

    companyService.deactivate(id)
      .then(
        id => {
          dispatch(success(id));
          getAllCompanies();
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(id) {
    return {type: companyConstants.DEACTIVATE_REQUEST}
  }

  function success(id) {
    return {type: companyConstants.DEACTIVATE_SUCCESS, id}
  }

  function failure(error) {
    return {type: companyConstants.DEACTIVATE_FAILURE, error}
  }
}

function update(company) {
  return dispatch => {
    dispatch(request(company));
    companyService.update(company)
      .then(
        company => {
          dispatch(success(company));
          dispatch(alertActions.success("Company " + company.name + " updated."));
          getAllCompanies();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(company) {
    return {type: companyConstants.UPDATE_REQUEST, company}
  }

  function success(company) {
    return {type: companyConstants.UPDATE_SUCCESS, company}
  }

  function failure(error) {
    return {type: companyConstants.UPDATE_FAILURE, error}
  }
}

function updateConnectedAppLink(data) {
  return dispatch => {
    dispatch(request(data));
    companyService.updateConnectedAppLink(data)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success("Connected App Link to " + data.name + " updated."));
          history.goBack();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) {
    return {type: companyConstants.APP_LINK_UPDATE_REQUEST, data}
  }

  function success(data) {
    return {type: companyConstants.APP_LINK_UPDATE_SUCCESS, data}
  }

  function failure(error) {
    return {type: companyConstants.APP_LINK_UPDATE_FAILURE, error}
  }
}

function updateConnectedApp(connectedData) {
  return dispatch => {
    dispatch(request(connectedData));
    companyService.updateConnectedApp(connectedData)
      .then(
        connectedData => {
          dispatch(success(connectedData));
          dispatch(alertActions.success("Connected App " + connectedData.name + " updated."));
          history.goBack();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(connectedData) {
    return {type: companyConstants.APP_UPDATE_REQUEST, connectedData}
  }

  function success(connectedData) {
    return {type: companyConstants.APP_UPDATE_SUCCESS, connectedData}
  }

  function failure(error) {
    return {type: companyConstants.APP_UPDATE_FAILURE, error}
  }
}

function addNewConnectedApp(connectedData) {
  return dispatch => {
    dispatch(request(connectedData));
    companyService.addNewConnectedApp(connectedData)
      .then(
        connectedData => {
          dispatch(success(connectedData));
          dispatch(alertActions.success("New Connected App " + connectedData.name + " added."));
          companyService.getConnectedApps(connectedData.companyId);
          history.goBack();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(connectedData) {
    return {type: companyConstants.APP_NEW_REQUEST, connectedData}
  }

  function success(connectedData) {
    return {type: companyConstants.APP_NEW_SUCCESS, connectedData}
  }

  function failure(error) {
    return {type: companyConstants.APP_NEW_FAILURE, error}
  }
}


function uploadAppImages(uploadFields, companyId) {
  return dispatch => {
    dispatch(request(uploadFields, companyId));

    companyService.uploadAppImages(uploadFields, companyId)
      .then(
        fileListUploaded => {
          dispatch(success(fileListUploaded));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(uploadFields, companyId) {
    return {type: companyConstants.UPLOAD_FILES_REQUEST, uploadFields}
  }

  function success(fileListUpload) {
    return {type: companyConstants.UPLOAD_FILES_SUCCESS, fileListUpload}
  }

  function failure(error) {
    return {type: companyConstants.UPLOAD_FILES_FAILURE, error}
  }
}

