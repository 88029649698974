import * as React from 'react';
import Card from "../../../../../components/Card/Card";
import {
  Grid, Modal, Typography, CardHeader, TextField
} from "@mui/material";
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import CardBody from "../../../../../components/Card/CardBody";
import {makeStyles} from "@mui/styles";
import {GridItem} from "../../../../../components/Grid";

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.white,
//     display: 'flex',
//     flexDirection: 'column',
//     position: "relative",
//     padding: theme.spacing(2),
//   },
//   content: {
//     width: '100%',
//     height: '100%',
//     padding: 0
//   },
//   documentContent: {
//     maxWidth: '100%',
//     maxHeight: '100%',
//   },
//   pageContent: {
//     overflowX: 'scroll',
//   },
//   modalContent: {
//     position: 'absolute',
//     border: '2px solid white',
//     backgroundColor: 'white',
//     boxShadow: '2px solid white',
//     height: '60%',
//     width: '60%',
//     overflowY: 'scroll',
//     margin: 'auto'
//   },
//   headerContent: {
//     backgroundColor: theme.palette.primary.main,
//     color: "white"
//   }
// }));


const DocumentAttributes = (props) => {

  // const classes = useStyles();

  const {
    classes,
    showModal,
    handleCloseModal,
    displayName,
    pageTitle,
    metaData
  } = props;

  const AttributeElements = Object.entries(JSON.parse(metaData))
    .sort((entryLeft, entryRight) => entryLeft[0] - entryRight[0])
    .map((entry, i) =>
      gridItemFromKeyAndIndex(entry[0], entry[1], i)
    );

  /**
   *
   * @param key
   * @param value
   * @param index
   * @return {JSX.Element}
   */
  function gridItemFromKeyAndIndex(key, value, index) {
    return (<GridItem
      key={index}
      item
      zeroMinWidth
      textAlign="center"
      lg={6}
      xs={6}
    >
      <TextField
        fullWidth
        multiline
        label={key}
        margin="dense"
        name={key}
        disabled={true}
        value={value || ''}
        variant="filled"
      />
    </GridItem>);
  }

  return (
    <Modal
      onClose={handleCloseModal}
      open={showModal}
      className={classes.modalContent}
    >
      {(!metaData || !classes) ? (<LoadingSpinner/>) : (
        <div className={classes.root}>
          <Card className={classes.content}>
            <Typography variant={"h4"} className={classes.modalHeader}>
              Attributes - {displayName}
            </Typography>
            <CardBody
              style={{padding: 10}}
            >
              <Grid
                className={classes.content}
                container
              >
                {AttributeElements}
              </Grid>
            </CardBody>
          </Card>
        </div>
      )}
    </Modal>

  );
}

export default DocumentAttributes;
