import React, {useCallback, useEffect, useState} from 'react';
import {Pie} from 'react-chartjs-2';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@mui/styles';
import {Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {useSelector} from 'react-redux';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {GridItem} from "../../../../components/Grid";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    width: "100%"
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1),
    flexShrink: 1
  },
  deviceIcon: {
    color: theme.palette.icon,
    width: "100%"
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  }
}));

const UserRoleMetrics = props => {
  const {
    className,
    metrics,
    displayedRoleList,
    getRoleMetricsMethod,
    ...rest
  } = props;
  const [roleMetrics, setRoleMetrics] = useState(metrics);
  const user = useSelector(state => state.authentication.user);
  const [dataIsBuilding, setDataIsBuilding] = useState(false);
  const [dataBuilt, setDataBuilt] = useState(false);
  const [data, setData] = useState(null);
  const [roles, setRoles] = useState(null);
  const [roleElemWidth, setRoleElemWidth] = useState("16%")

  const classes = useStyles();
  const theme = useTheme();
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'point',
        intersect: false,
        padding: 8,
        usePointStyle: true,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.primary.dark,
        titleFontColor: theme.palette.white,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 0,
    layout: {padding: 0},

  };

  const buildData = useCallback((roleMetrics) => {
    setDataIsBuilding(true);
    const newRoles = displayedRoleList.map(role => ({
      title: role.title,
      value: role.getValue(roleMetrics),
      icon: React.createElement(role.iconElement, {style: {color: role.color}}),
      color: role.color
    }));
    const newRolesWithTotal = Array.prototype.concat(newRoles, [{
        title: 'Total Active Roles',
        value: roleMetrics.activeUsersCount,
        icon: <VerifiedUserIcon style={{color: theme.palette.green}}/>,
        color: theme.palette.green
      }]
    )
    setData({
      datasets: [
        {
          data: newRoles.map(role => role.value),
          backgroundColor: newRoles.map(role => role.color),
          borderWidth: 4,
          borderColor: theme.palette.white,
          borderAlign: 'center',
          hoverBorderColor: theme.palette.white,
          hoverOffset: 8
        }
      ],
      labels: newRoles.map(role => role.title)
    });
    setRoles(newRolesWithTotal);
    setRoleElemWidth(`max(${100 / newRolesWithTotal.length}%, calc(${theme.typography.h6.fontSize} * ${getLengthOfLongestWordInTitles(newRolesWithTotal)})`)
    setDataBuilt(true);
    setDataIsBuilding(false);
  }, [theme, displayedRoleList, setData, setDataBuilt, setDataIsBuilding])

  function getLengthOfLongestWordInTitles(rolesList){
    let lengthOfLongestInTitle = 0;

    for (const roleIndex in rolesList) {
      const words = rolesList[roleIndex].title.split(' ');
      for (const wordIndex in words)
        lengthOfLongestInTitle = Math.max(words[wordIndex].length, lengthOfLongestInTitle);
    }
    return lengthOfLongestInTitle
  }

  async function resetData() {
    setData(null);
    setDataBuilt(false);
  }

  const handleRefresh = event => {
    getData().then(metrics => {
      setRoleMetrics(metrics);
      resetData().then(r => {
        buildData(roleMetrics)
      })
    });
  };

  async function getData() {
    return await getRoleMetricsMethod(user.companyId).then(r => {
      return r.data
    });
  }

  useEffect(() => {
    if (!dataBuilt && !dataIsBuilding) {
      buildData(roleMetrics)
    }
  }, [dataBuilt, dataIsBuilding, buildData, roleMetrics]);

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.titleHeader}
          action={
            <IconButton
              onClick={handleRefresh}
              size="small"
            >
              <RefreshIcon/>
            </IconButton>
          }
          title="User Role Metrics"
        />
        <Divider/>
        <CardContent>
          {roleMetrics && data && !dataIsBuilding ? (
            <React.Fragment>
              <div className={classes.chartContainer}>
                <Pie
                  data={data}
                  options={options}
                />
              </div>
              <Grid container className={classes.stats}>
                {roles.map(role => (
                  <GridItem
                    className={classes.device}
                    key={role.title}
                    style={{width: roleElemWidth}}
                  >
                    <span className={classes.deviceIcon}>{role.icon}</span>

                    <Typography
                      style={{color: role.color, width: "100%"}}
                      variant="h2"
                    >
                      {role.value}
                    </Typography>

                    <Typography
                      style={{color: role.color, width: "100%", justifyContent: "center"}}
                      variant="h6"
                    >{role.title}</Typography>
                  </GridItem>
                ))}
              </Grid>
            </React.Fragment>
          ) : (<LoadingSpinner/>)}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

UserRoleMetrics.propTypes = {
  className: PropTypes.string
};

export default UserRoleMetrics;
