import React from 'react';

import FileListWindow from "../components/FileListWindow";
import {documentActions} from "../../../store/actions";
import {documentService} from "../../../store/services";
import {DOCUMENT_VIEWER_MODES} from "../../../constants/DOCUMENT_VIEWER_MODES";
import {isNull, isSet} from "underscore";
import {DOCUMENT_STATUS} from "../../../constants/DOCUMENT_STATUS";

const StagedDocument = (props) => {
  const {
    initialStatus,
    initialSection,
  } = props.location;

  return (<FileListWindow
    title={'Staged Documents'}
    subtitle={'View/Approve Staged Documents'}
    initialStatus={(typeof initialStatus !== 'undefined') ?
      (isNull(initialStatus) ? DOCUMENT_STATUS.WAITING : initialStatus) :
      (DOCUMENT_STATUS.WAITING) }
    initialSection={isSet(initialSection) ? 'All Sections' : initialSection }
    includeSectionFilter
    includeCompanyFilter
    includeStatusFilter
    viewer={DOCUMENT_VIEWER_MODES.STAGED}
    getDocumentFromDocumentServiceMethod={id => documentService.getDocumentById(id)}
    searchDocumentFromDocumentServiceMethod={(searchFields => documentActions.getStagedDocuments(searchFields))}
  />);
}

export default StagedDocument;
