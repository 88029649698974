import DashboardIcon from '@mui/icons-material/Dashboard';
import PageviewIcon from '@mui/icons-material/Pageview';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import MovieIcon from '@mui/icons-material/Movie';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import {Apps} from "@mui/icons-material";

export const memberPages = [
  {
    collapse: true,
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Documents',
    href: '/documents',
    icon: <PageviewIcon />
  },
  {
    title: 'Staged Documents',
    href: '/documents/staged',
    icon: <PageviewIcon />
  },
  {
    title: 'Upload',
    icon: <CloudUploadIcon />,
    menu: [
      {
        title: 'Documents',
        href: '/documents/upload/documents',
        view: 'documents',
        icon: <DescriptionIcon />
      },
      {
        title: 'Image/Drawing/CAD',
        href: '/documents/upload/image',
        view: 'documents',
        icon: <ImageIcon />
      },
      {
        title: 'Video/Audio',
        href: '/documents/upload/video',
        view: 'documents',
        icon: <MovieIcon />
      }
    ]
  },
  {
    title: 'Settings',
    icon: <SettingsIcon />,
    menu: [
      {
        title: 'Notifications',
        href: '/settings/notification',
        icon: <NotificationsIcon />
      },
      {
        title: 'Application Setting',
        href: '/settings/application',
        admin: true,
        icon: <SettingsApplicationsIcon />
      },
      {
        title: 'Connected Apps',
        href: '/settings/connectedapp',
        admin: true,
        icon: <Apps/>
      },
    ]
  }
];
