import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useSelector} from 'react-redux';
import {Card, Grid} from '@mui/material';
import {companyService} from "../../store/services";
import {ConnectedApp} from "./components";
import PageHeader from "../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../components/Spinner/loading.spinner";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));
const ConnectedApps = () => {
  const classes = useStyles();
  const user = useSelector(state => state.authentication.user);
  const [connectedApps, setConnectedApps] = useState(null);
  const [requestPending, setRequestPending] = useState(false);

  useEffect(() => {
    if (!connectedApps && !requestPending) {
      companyService.getActiveConnectedApps(user.companyId)
        .then((results) => {
          setConnectedApps(results.data.connectedApps);
          setRequestPending(false);
        });
    }
  }, [user, connectedApps, requestPending]);

  return (
    <div className={classes.root}>
      <Card className={classes.content}>
        <PageHeader
          subTitle={'Orchestrated Apps Portal'}
          title={'Orchestrated Apps'}
        />
        {connectedApps && !requestPending ? (
          <Grid container justifyContent={"flex-start"}>
            {connectedApps.map((app, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={index}>
                <ConnectedApp
                  key={index}
                  connectedApp={app}
                />
              </Grid>
            ))}
          </Grid>)

          :
          (<LoadingSpinner/>)

        }
      </Card>
    </div>
  );
};

export default ConnectedApps;
