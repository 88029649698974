import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  gridItem: {
    maxWidth: 500
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  }
}));
