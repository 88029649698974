import React from 'react';
import PropTypes from 'prop-types';
import FooterLogo from 'assets/images/footerLogo.png';
import {makeStyles} from '@mui/styles';
import { maestroenv } from '../../helpers/maestroenv';

const useStyles = makeStyles((theme) => ({
  captionBox: {
    marginRight: 20,
    marginBottom: 20,
    width: '100%',
    textAlign: 'right',
    alignItems: 'right'
  },
  captionText: {
    color: theme.palette.grey
  },
  footerLogo: {
    height: 15,
  },
  style: {
    backgroundColor: '#FFF',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '10px',
    position: 'fixed',
    display: 'inline-block',
    left: '0',
    bottom: '0',
    height: theme.overlayProportions.footerHeight,
    width: '100%',
  },
  phantom: {
    display: 'block',
    padding: '10px',
    height: '20px',
    width: '100%',
  }

}));


export default function PageFooter(props) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.style}>
        {maestroenv.REACT_APP_FOOTER_TEXT}
        <img alt='NDP ORE'
             className={classes.footerLogo}
             src={FooterLogo}/>
      </div>
    </div>
  )
}
PageFooter.propTypes = {
  caption: PropTypes.string
};
