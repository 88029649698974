import {requestHelper} from '../helpers/index';
import axiosInstance from '../../axios.instance';
import {SECURITY_ROLES} from "../../constants/SECURITY_ROLES";
import { maestroenv } from '../../helpers/maestroenv';

export const settingService = {
  add,
  update,
  getAllSettings,
  getSettingByName,
  getConnectedAppsLocation
};

function add(setting) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/settings/add`, JSON.stringify(setting))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getAllSettings() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/settings/`)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getSettingByName(name, companyId) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/settings/${name}/getsetting`, JSON.stringify({companyId: companyId}))
    .then(response => {
      return response.data
    })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


function getConnectedAppsLocation(companyId) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/settings/appsimagedirectory`, JSON.stringify({companyId: companyId}))
    .then(response => {
      return response.data
    })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function update(setting) {
  if (setting.role === SECURITY_ROLES.COMPANY_ADMIN){
    return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/settings/${setting.companyId}/addorupdate`, JSON.stringify(setting))
      .then(response => { return response.data })
      .catch(function(error) {
        return Promise.reject(requestHelper.handleErrorResponse(error));
      });
  } else {
    return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/settings/update`, JSON.stringify(setting))
      .then(response => { return response.data })
      .catch(function(error) {
        return Promise.reject(requestHelper.handleErrorResponse(error));
      });
  }

}
