export const settingConstants = {
  ADDNEW_REQUEST: 'SETTINGS_ADDNEW_REQUEST',
  ADDNEW_SUCCESS: 'SETTINGS_ADDNEW_SUCCESS',
  ADDNEW_FAILURE: 'SETTINGS_ADDNEW_FAILURE',

  DELETE_REQUEST: 'SETTINGS_DELETE_REQUEST',
  DELETE_SUCCESS: 'SETTINGS_DELETE_SUCCESS',
  DELETE_FAILURE: 'SETTINGS_DELETE_FAILURE',

  GETALL_REQUEST: 'SETTINGS_GETALL_REQUEST',
  GETALL_SUCCESS: 'SETTINGS_GETALL_SUCCESS',
  GETALL_FAILURE: 'SETTINGS_GETALL_FAILURE',

  GET_SETTING_REQUEST: 'GET_SETTING_REQUEST',
  GET_SETTING_SUCCESS: 'GET_SETTING_SUCCESS',
  GET_SETTING_FAILURE: 'GET_SETTING_FAILURE',

  UPDATE_REQUEST: 'SETTINGS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'SETTINGS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'SETTINGS_UPDATE_FAILURE',

  GET_CONNECTED_APPS_LOCATION_REQUEST: 'GET_CONNECTED_APPS_LOCATION_REQUEST',
  GET_CONNECTED_APPS_LOCATION_SUCCESS: 'GET_CONNECTED_APPS_LOCATION_SUCCESS',
  GET_CONNECTED_APPS_LOCATION_FAILURE: 'GET_CONNECTED_APPS_LOCATION_FAILURE',



};
