import {userConstants} from '../../constants/index';
import jwtDecode from 'jwt-decode';

const initialState = {
  requestPending: false,
  token: null,
  user: null
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.OAUTH_LOGIN_REQUEST:
      return {
        requestPending: true,
        user: null
      }
    case userConstants.OAUTH_LOGIN_SUCCESS:
      try {
        let decoded = jwtDecode(action.user);
        localStorage.setItem('user', action.user);
        return {
          user: decoded,
          token: action.user,
          requestPending: false,
        };
      } catch (e) {
        return {requestPending: false, user: null};
      }
    case userConstants.OAUTH_LOGIN_FAILURE:
      return {
        requestPending: false,
        user: null
      }
    case userConstants.LOGIN_REQUEST:
    case userConstants.LOGIN_ROLE_REQUEST:
      return {
        requestPending: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
    case userConstants.LOGIN_ROLE_SUCCESS:
      try {
        let decoded = jwtDecode(action.user);
        return {
          user: decoded,
          token: action.user,
          requestPending: false,
        };
      } catch (e) {
        return {requestPending: false, user: null};
      }
    case userConstants.LOGOUT_REQUEST:
      return {
        requestPending: true,
      };
    case userConstants.LOGOUT:
    case userConstants.LOGOUT_FAILURE:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {
          user: null,
          token: null,
          requestPending: false,
      }
    case userConstants.LOGIN_FAILURE:
    case userConstants.LOGIN_ROLE_FAILURE:
      return {requestPending: false};
    default:
      return state
  }
}
