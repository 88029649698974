import React from 'react';
import {Card, CardContent, Grid,} from '@mui/material';
import useStyles from './styles';
import MaestroLogo from '../../assets/images/logo.png';
import LoginBackground from '../../assets/images/login-background-2.jpg';
import RoleSelectionForm from './components/RoleSelectionForm';
import {useSelector} from 'react-redux';
import LoadingSpinner from "../../components/Spinner/loading.spinner";

function RoleSelect(props) {
  const classes = useStyles();
  const switchRole = props && props.location.state ? props.location.state.switchRole : false;
  const authenticatedUser = useSelector(state => state.authentication.user);

  return (
    <Grid
      className={classes.container}
      container
      style={{backgroundImage: `url(${LoginBackground})`}}
    >
      <div className={classes.formContainer}>
        <Card
          className={classes.cardContainer}
          raised
        >
          <CardContent>
            <div className={classes.form}>
              <Grid
                className={classes.loginLogoContainer}
                container
              >
                <img
                  alt="Maestro"
                  className={classes.loginLogoImage}
                  src={MaestroLogo}
                />
              </Grid>
              <React.Fragment>
                {authenticatedUser && authenticatedUser.role ? (
                  <React.Fragment>
                    <div className={classes.formDivider}/>
                    <RoleSelectionForm switchRole={switchRole}/>
                  </React.Fragment>
                ) : (
                  <LoadingSpinner title={"Checking Roles"}/>
                )
                }
              </React.Fragment>
            </div>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
}

export default RoleSelect;
