import React from 'react';
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import Button from "../../../../components/CustomButtons/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Grid,} from "@mui/material";
import {isEmpty} from "underscore";
import {history} from "../../../../helpers/history";
import {useSelector} from "react-redux";
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";

const LinkDialog = (props) =>  {
  const {
    open,
    classes,
    linkInfo,
    hasLinkErrors,
    setHasLinkErrors,
    onHandleSubmit,
  } = props;

  const requestPending = useSelector(state => state.companies.requestPending);
  const validationSchema = Yup.object().shape({
    link: Yup.string().trim().required('Please Enter a link')
  });

  const initialTouched = {
    link: true
  };

  return (
    <Formik
      enableReinitialize
      initialValues={linkInfo}
      innerRef={self=>setHasLinkErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
      onReset= {(values) => {
        history.goBack();
      }}
      initialTouched={initialTouched}
      onSubmit={(values, {setSubmitting, setValues, setErrors, setFieldError}) => {
        setSubmitting(true);
        if (onHandleSubmit(values)) {
          setSubmitting(false);
        } else {
          setErrors("Connected app link update not successful");
          setFieldError('name', 'Connected app link failed');
        }
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps) => {
        const {
          values,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit
        } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Dialog
              fullWidth="true"
              maxWidth="sm"
              open={open}
              onClose={handleReset}
              aria-labelledby="link-dialog"
            >
              <DialogTitle id="link-dialog-title">Connected App Link Change</DialogTitle>
              <DialogContent>
                {requestPending ? ( <LoadingSpinner title={"Processing Request...."}/>): (
                  <DialogContentText>
                    <Grid
                      container
                      spacing={3}
                      className={classes.content}
                    >
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="link"
                          margin="dense"
                          name="link"
                          type="url"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.link}
                          variant="outlined"
                        />
                        <ErrorMessage className={classes.errorMessage} component="div" name="link"/>
                      </Grid>
                    </Grid>
                  </DialogContentText>
                )}

              </DialogContent>
              <DialogActions>
                <Button
                  aria-label="update"
                  type='submit'
                  onClick={handleSubmit}
                  color="success"
                  disabled={hasLinkErrors || requestPending}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  aria-label="cancel"
                  onClick={handleReset}
                  color="github"
                  disabled={requestPending}
                  variant="contained">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        )
      }}
    </Formik>
  );
}

export default LinkDialog;
