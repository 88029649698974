import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
} from '@mui/material';

import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {yupSchemaCreator} from '../../../../helpers'
import {userService} from '../../../../store/services';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import Switch from "../../../../components/Switch";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

const UserPrivileges = (props) => {
  const {
    title,
    company_guid,
    user_guid,
    roleSelect,
    privileges,
    userRoleList,
    onHandlePrivilegesChange
  } = props

  const classes = useStyles();
  const [roleSelected, setRoleSelected] = useState('');
  const [initialRender, setInitialRender] = useState(true);
  const [privValues, setPrivValues] = useState({
    Approval: 0,
    Download: 0,
    // Edit: 0,
    Email: 0,
    // Manage: 0,
    // Password: 0,
    Print: 0,
    Upload: 0,
    View: 0});

  let privItems =[];
  if (privileges) {
    privileges.forEach(function (p) {
      privItems.push({
        'name': p.priv,
        'type': 'boolean',
        'value': p.priv,
        'validationType': 'boolean'
      });
    });
  }

  const getPrivilegesForRole = async (role_id) => {
    userService.getUserRolePrivilegesOfCompany({
      company_guid: company_guid,
      user_guid: user_guid,
      role_id: parseInt(role_id)
    }).then(dtls => {
      setPrivValues(dtls.data);
    });
  }

  const onHandleRoleSelectChange = (event) => {
    getPrivilegesAndSetRole(event.target.value)
  };

  function getPrivilegesAndSetRole(newRole){
    getPrivilegesForRole(newRole).then(()=>{});
    setRoleSelected(newRole);
  }

  if (roleSelect && initialRender){
    getPrivilegesAndSetRole(roleSelect);
    setInitialRender(false);
  }

  const yupSchema = privItems.reduce(yupSchemaCreator, {});
  const validateSchema = Yup.object().shape(yupSchema);

  return (
    <Formik
      enableReinitialize
      initialValues={privValues}
      onSubmit={(values, {setSubmitting}) => {
        alert('Privileges Update not available at this time.');
      }}
      validationSchema={validateSchema}
    >
      {() => {
        return (
          <form>
            {(!privValues || !privItems || !userRoleList) ? (<LoadingSpinner/>) :
              (
                <div>
                  <Card>
                    <CardHeader
                      title={title}
                    />
                    <Divider/>
                    <CardContent>
                      <Grid
                        className={classes.content}
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="selectRole">Select Role</InputLabel>
                            <Select
                              inputProps={{
                                name: 'selectRole',
                                id: 'selectRole',
                              }}
                              label="Select Role"
                              native
                              onChange={onHandleRoleSelectChange}
                              value={roleSelected}
                            >
                              <option
                                aria-label="None"
                                value=""
                              />
                              {userRoleList.map((r, idx) => (
                                    <option
                                      key={idx} value={r.id}>{r.role}
                                    </option>
                              ))}
                            </Select>
                          </FormControl>
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="selectRole"
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        className={classes.content}
                        container
                        spacing={3}
                      >
                        {privItems.map((items, idx) => (
                          <Grid
                            item
                            key={idx}
                            xs={12}
                          >
                            <FormControlLabel
                              className={classes.switch}
                              control={<Switch
                                checked={Boolean(privValues[items.name])}
                                name={items.name}
                                disabled={Boolean(!onHandlePrivilegesChange)}
                                onHandleToggle={onHandlePrivilegesChange}
                              />}
                              label={items.name}
                              labelPlacement="start"
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              )}
          </form>
        )
      }}
    </Formik>
  )
}
export default UserPrivileges;

