import {userConstants} from '../../constants/index';
import {userService} from '../../services/index';
import {alertActions} from '../index';
import {history} from '../../../helpers/history';
import jwtDecode from 'jwt-decode';

export const userActions = {
  checkSharedUserByEmail,
  login,
  refreshUserToken,
  loginWithRole,
  loginAsSharedUser,
  logout,
  changePassword,
  register,
  getUserProfile,
  getSharedUserProfile,
  updateProfile,
  oauthLogin,
  getOAuthUser,
  getAll,
  getAllUsersOfCompany,
  getRolePrivilegesOfUser,
  getSecurityQuestionsOfUser,
  updateUserDetails,
  addUser,
  addSharedUser,
  updateSharedUserDetails,
  updateSharedUserKeyInfo,
  updateSharedUserPasswordInfo
};

function login(username, password) {
  return dispatch => {
    dispatch(request({username}));

    userService.login(username, password)
      .then(
        user => {
          dispatch(success(user.data));
          let decodedData = jwtDecode(user.data);
          if (decodedData && decodedData.availableRoles && decodedData.availableRoles.length > 1) {
            history.push('/role')
          } else {
            history.push('/');
          }
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(user) {
    return {type: userConstants.LOGIN_REQUEST, user}
  }

  function success(user) {
    return {type: userConstants.LOGIN_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.LOGIN_FAILURE, error}
  }
}
function loginWithRole(role) {
  return dispatch => {
    dispatch(request({role}));
    userService.loginWithRole(role)
      .then(
        user => {
          dispatch(success(user.data));
          history.push('/');
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(user) {
    return {type: userConstants.LOGIN_ROLE_REQUEST, user}
  }

  function success(user) {
    return {type: userConstants.LOGIN_ROLE_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.LOGIN_ROLE_FAILURE, error}
  }
}

function loginAsSharedUser(username, accessKey) {
  return dispatch => {
    dispatch(request({username}));

    userService.loginAsSharedUser(username, accessKey)
      .then(
        user => {
          dispatch(success(user.data));
          history.push('/');
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request(user) {
    return {type: userConstants.LOGIN_REQUEST, user}
  }

  function success(user) {
    return {type: userConstants.LOGIN_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.LOGIN_FAILURE, error}
  }
}
function refreshUserToken() {
  return dispatch => {
    dispatch(request());

    userService.refreshToken()
      .then(
        user => {
          dispatch(success(user.data));
          history.push('/dashboard');
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error))
          userService.logout();
        }
      );
  };
  function request(user) {
    return {type: userConstants.LOGIN_REQUEST, user}
  }

  function success(user) {
    return {type: userConstants.LOGIN_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.LOGIN_FAILURE, error}
  }
}

/*function verifyToken(token) {
  return dispatch => {
    dispatch(request({token}));

    userService.verifyToken(token)
      .then(
        user => {
          dispatch(success(user.data));
          history.push('/dashboard');
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(user) {
    return {type: userConstants.LOGIN_REQUEST, user}
  }

  function success(user) {
    return {type: userConstants.LOGIN_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.LOGIN_FAILURE, error}
  }
}*/

function changePassword(currentPassword, newPassword, userId) {
  return dispatch => {
    dispatch(request());

    userService.changePassword(currentPassword, newPassword, userId)
      .then(
        user => {
          dispatch(success(user.data));
          history.push('/account');
          dispatch(alertActions.success('Successfully Changed Password'));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.CHANGE_USER_PASSWORD_REQUEST}
  }

  function success(user) {
    return {type: userConstants.CHANGE_USER_PASSWORD_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.CHANGE_USER_PASSWORD_FAILURE, error}
  }
}

function logout(user) {
  return dispatch => {
    userService.oauthLogout(user)
      .then(
      response => {
        dispatch(request());
        history.push('/loading', {title: 'Logging Out'});
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return {type: userConstants.LOGOUT}
  }
  function failure(error) {
    return {type: userConstants.LOGOUT_FAILURE, error}
  }
}

function register(user) {
  return dispatch => {
    dispatch(request(user));

    userService.register(user)
      .then(
        user => {
          dispatch(success());
          history.push('/login');
          dispatch(alertActions.success('Registration successful'));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(user) {
    return {type: userConstants.REGISTER_REQUEST, user}
  }

  function success(user) {
    return {type: userConstants.REGISTER_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.REGISTER_FAILURE, error}
  }
}

function getUserProfile(userId) {
  return dispatch => {
    dispatch(request(userId));
    userService.getUserProfile(userId)
      .then(user => {
          dispatch(success(user.data))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(userId) {
    return {type: userConstants.GET_USER_PROFILE_REQUEST, userId}
  }

  function success(user) {
    return {type: userConstants.GET_USER_PROFILE_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.GET_USER_PROFILE_FAILURE, error}
  }
}

function getSharedUserProfile(userId) {
  return dispatch => {
    dispatch(request(userId));
    userService.getSharedUserProfile(userId)
      .then(user => {
          dispatch(success(user.data))
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(userId) {
    return {type: userConstants.GET_USER_PROFILE_REQUEST, userId}
  }

  function success(user) {
    return {type: userConstants.GET_USER_PROFILE_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.GET_USER_PROFILE_FAILURE, error}
  }
}

function updateProfile(profile) {
  return dispatch => {
    dispatch(request(profile));

    userService.update(profile)
      .then(
        user => {
          dispatch(success(user.data));
          dispatch(alertActions.success('Successfully Updated Profile'));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request(userId) {
    return {type: userConstants.UPDATE_USER_PROFILE_REQUEST, userId}
  }

  function success(user) {
    return {type: userConstants.UPDATE_USER_PROFILE_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.UPDATE_USER_PROFILE_FAILURE, error}
  }
}


function oauthLogin(url) {
  return dispatch => {
    dispatch(request());
    userService.oauthLogin(url)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          dispatch(failure(error));
          if (!error.oauth) {
            dispatch(alertActions.error(error));
          }
        }
      );
  };
  function request() {
    return {type: userConstants.OAUTH_LOGIN_REQUEST}
  }

  function success(user) {
    return {type: userConstants.OAUTH_LOGIN_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.OAUTH_LOGIN_FAILURE, error}
  }
}

/*function oauthLogout(url) {
  return dispatch => {
    dispatch(request());
    userService.oauthLogout(url)
      .then(
        user => {
          dispatch(success(user));
          history.push('/login')
          dispatch(alertActions.success('Successfully Logged Out!'))
        },
        error => {
          dispatch(failure(error));
          if (!error.oauth) {
            dispatch(alertActions.error(error));
          }
        }
      );
  };
  function request() {
    return {type: userConstants.LOGOUT_REQUEST}
  }

  function success(user) {
    return {type: userConstants.LOGOUT, user}
  }

  function failure(error) {
    return {type: userConstants.LOGOUT_FAILURE, error}
  }
}*/

function getOAuthUser() {
  return dispatch => {
    dispatch(request());

    userService.getOAuthUser()
      .then(
        user => {
          dispatch(success(user.data));
          let decodedData = jwtDecode(user.data);
          if (decodedData && decodedData.availableRoles && decodedData.availableRoles.length > 1) {
            history.push('/role')
          } else {
            history.push('/');
          }
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };
  function request() {
    return {type: userConstants.OAUTH_LOGIN_REQUEST}
  }

  function success(user) {
    return {type: userConstants.OAUTH_LOGIN_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.OAUTH_LOGIN_FAILURE, error}
  }
}

function getAll() {
  return dispatch => {
    dispatch(request());

    userService.getAll()
      .then(
        users => dispatch(success(users)),
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.GETALL_REQUEST}
  }

  function success(users) {
    return {type: userConstants.GETALL_SUCCESS, users}
  }

  function failure(error) {
    return {type: userConstants.GETALL_FAILURE, error}
  }
}

function getAllUsersOfCompany(companyId) {
  return dispatch => {
    dispatch(request(companyId));

    userService.getAllUsersOfCompany(companyId)
      .then(
        users => dispatch(success(users)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request(userId) {
    return {type: userConstants.GETALLBYCOMPANY_REQUEST, userId}
  }

  function success(user) {
    return {type: userConstants.GETALLBYCOMPANY_SUCCESS, user}
  }

  function failure(error) {
    return {type: userConstants.GETALLBYCOMPANY_FAILURE, error}
  }
}

function addUser(userData, companyId) {
  return dispatch => {
    dispatch(request());

    userService.addUser(userData)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success('Successfully Added User: ' + data.data.firstName + ' ' + data.data.lastName));
          getAllUsersOfCompany(companyId);
          history.goBack();
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.ADD_USER_REQUEST}
  }

  function success() {
    return {type: userConstants.ADD_USER_REQUEST_SUCCESS}
  }

  function failure(error) {
    return {type: userConstants.ADD_USER_REQUEST_FAILURE, error}
  }
}

function addSharedUser(userData) {
  return dispatch => {
    dispatch(request());

    userService.addSharedUser(userData)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success("Successfully Added Shared User: " + data.firstName + " " + data.lastName));
          history.goBack();
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.ADD_SHARED_USER_REQUEST}
  }

  function success() {
    return {type: userConstants.ADD_SHARED_USER_SUCCESS}
  }

  function failure(error) {
    return {type: userConstants.ADD_SHARED_USER_FAILURE, error}
  }
}

function updateSharedUserDetails(userData) {
  return dispatch => {
    dispatch(request());

    userService.updateSharedUserDetails(userData)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success("Successfully Updated SharedUser Details: " + data.data.firstName+ " " + data.data.lastName));
          history.goBack();
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.UPDATE_SHAREDUSER_DETAILS_REQUEST}
  }

  function success() {
    return {type: userConstants.UPDATE_SHAREDUSER_DETAILS_SUCCESS}
  }

  function failure(error) {
    return {type: userConstants.UPDATE_SHAREDUSER_DETAILS_FAILURE, error}
  }
}

function updateSharedUserKeyInfo(userData) {
  return dispatch => {
    dispatch(request());

    userService.updateSharedUserKeyInfo(userData)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success("Successfully Updated Access Key for SharedUser  : " + data.data.firstName+ " " + data.data.lastName));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.UPDATE_SHAREDUSER_KEYINFO_REQUEST}
  }

  function success() {
    return {type: userConstants.UPDATE_SHAREDUSER_KEYINFO_SUCCESS}
  }

  function failure(error) {
    return {type: userConstants.UPDATE_SHAREDUSER_KEYINFO_FAILURE, error}
  }
}

function updateSharedUserPasswordInfo(userData) {
  return dispatch => {
    dispatch(request());

    userService.updateSharedUserPasswordInfo(userData)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success("Successfully Updated Expiration for SharedUser  : " + data.data.firstName+ " " + data.data.lastName));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.UPDATE_SHAREDUSER_PASSWORD_REQUEST}
  }

  function success() {
    return {type: userConstants.UPDATE_SHAREDUSER_PASSWORD_SUCCESS}
  }

  function failure(error) {
    return {type: userConstants.UPDATE_SHAREDUSER_PASSWORD_FAILURE, error}
  }
};

function updateUserDetails(userData) {
  return dispatch => {
    dispatch(request());

    userService.updateUserDetails(userData)
      .then(
        data => {
          dispatch(success(data));
          dispatch(alertActions.success("Successfully Updated User Details: " + data.data.firstName + " " + data.data.lastName));
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error));
        }
      );
  };

  function request() {
    return {type: userConstants.UPDATE_USER_DETAILS_REQUEST}
  }

  function success() {
    return {type: userConstants.UPDATE_USER_DETAILS_SUCCESS}
  }

  function failure(error) {
    return {type: userConstants.UPDATE_USER_DETAILS_FAILURE, error}
  }
}

/*function getAdditionalUserDetails(userSearch) {
  return dispatch => {
    dispatch(request(userSearch));

    userService.getAdditionalUserDetails(userSearch)
      .then(
        users =>{
          dispatch(success(users));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(userSearch) {
    return {type: userConstants.GET_ADDITIONAL_USER_DETAILS_REQUEST}
  }

  function success(users) {
    return {type: userConstants.GET_ADDITIONAL_USER_DETAILS_SUCCESS, users}
  }

  function failure(error) {
    return {type: userConstants.GET_ADDITIONAL_USER_DETAILS_FAILURE, error}
  }
}*/

/*function getPrivilegesOfUser(userSearch) {
  return dispatch => {
    dispatch(request(userSearch));

    userService.getUserPrivilegesOfCompany(userSearch)
      .then(
        users =>{
          dispatch(success(users));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(userSearch) {
    return {type: userConstants.GET_USER_PRIVILEGES_REQUEST}
  }

  function success(users) {
    return {type: userConstants.GET_USER_PRIVILEGES_SUCCESS, users}
  }

  function failure(error) {
    return {type: userConstants.GET_USER_PRIVILEGES_FAILURE, error}
  }
}*/


function getRolePrivilegesOfUser(user) {
  return dispatch => {
    dispatch(request(user));

    userService.getUserRolePrivilegesOfCompany(user)
      .then(
        users =>{
          dispatch(success(users));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(userSearch) {
    return {type: userConstants.GET_USER_ROLE_PRIVILEGES_REQUEST}
  }

  function success(users) {
    return {type: userConstants.GET_USER_ROLE_PRIVILEGES_SUCCESS, users}
  }

  function failure(error) {
    return {type: userConstants.GET_USER_ROLE_PRIVILEGES_FAILURE, error}
  }
}

function getSecurityQuestionsOfUser(userId) {
  return dispatch => {
    dispatch(request(userId));

    userService.getUserQuestionsOfId(userId)
      .then(
        questions =>{
          dispatch(success(questions));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(userId) {
    return {type: userConstants.GET_USER_SECURITY_QUESTIONS_REQUEST}
  }

  function success(questions) {
    return {type: userConstants.GET_USER_SECURITY_QUESTIONS_SUCCESS, questions}
  }

  function failure(error) {
    return {type: userConstants.GET_USER_SECURITY_QUESTIONS_FAILURE, error}
  }
}

function checkSharedUserByEmail(email) {
  return dispatch => {
    dispatch(request(email));

    userService.getUserByEmail(email)
      .then(
        shareduser =>{
          dispatch(success(shareduser));
        },
        error => {
          dispatch(failure(error.toString()));
        }
      );
  };

  function request(email) {
    return {type: userConstants.GET_SHARED_USER_REQUEST}
  }

  function success(shareduser) {
    return {type: userConstants.GET_SHARED_USER_SUCCESS, shareduser}
  }

  function failure(error) {
    return {type: userConstants.GET_SHARED_USER_FAILURE, error}
  }
}
