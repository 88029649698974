class OfficeDocTypeReference {
  typeMap = {
    WORD: "msword",
    WORD_OPENXML: "vnd.openxmlformats-officedocument.wordprocessingml.document",
    POWERPOINT: "vnd.ms-powerpoint",
    POWERPOINT_OPENXML: "vnd.openxmlformats-officedocument.presentationml.presentation",
    EXCEL: "vnd.ms-excel",
    EXCEL_OPENXML: "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  }

  typeList = Object.values(this.typeMap).map(type => `application/${type}`);

  /**
   *
   * @param {File} file
   * @return {string | null} the document's content type
   */
  isDocType(file){
    return this.typeList.includes(file.type) ? file.type : null;
  }
}

export const OfficeDocTypes = new OfficeDocTypeReference();
