import {companyConstants} from '../../constants/index';

const initialState = {
  requestPending: false
};

export function companies(state = initialState, action) {
  switch (action.type) {
    case companyConstants.ACTIVATE_REQUEST:
      return {...state,
        requestPending: true
      };
    case companyConstants.ACTIVATE_SUCCESS:
      return {
        ...state,
        returncode: action.data,
        requestPending: false
      };
    case companyConstants.ACTIVATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case companyConstants.ADDNEW_REQUEST:
      return {...state,
        requestPending: true
      };
    case companyConstants.ADDNEW_SUCCESS:
      return {
        ...state,
        id: action.company.data,
        requestPending: false
      };
    case companyConstants.ADDNEW_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case companyConstants.DEACTIVATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.DEACTIVATE_SUCCESS:
      return {
        ...state,
        returncode: action.data,
        requestPending: false
      };
    case companyConstants.DEACTIVATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case companyConstants.GETALL_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.companies.data,
        requestPending: false
      };
    case companyConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };

    case companyConstants.GETALL_ACTIVE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.GETALL_ACTIVE_SUCCESS:
      return {
        ...state,
        items: action.companies.data,
        requestPending: false
      };
    case companyConstants.GETALL_ACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };

    case companyConstants.GET_PARENT_COMPANY_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.GET_PARENT_COMPANY_SUCCESS:
      return {
        ...state,
        parents: action.companies.data,
        requestPending: false
      };
    case companyConstants.GET_PARENT_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };


    case companyConstants.GET_COMPANY_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.GET_COMPANY_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case companyConstants.GET_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case companyConstants.APP_UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.APP_UPDATE_FAILURE:
      return {
        ...state,
        requestPending: false
      };
    case companyConstants.APP_UPDATE_SUCCESS:
      return {
        ...state,
        requestPending: false
      };
      case companyConstants.APP_LINK_UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.APP_LINK_UPDATE_FAILURE:
      return {
        ...state,
        requestPending: false
      };
    case companyConstants.APP_LINK_UPDATE_SUCCESS:
      return {
        ...state,
        requestPending: false
      };
    case companyConstants.UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case companyConstants.UPDATE_SUCCESS:
      return {
        ...state,
        item: action.company.data,
        requestPending: false
      };
    case companyConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
