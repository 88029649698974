import {ErrorMessage, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Card, CardContent, CardHeader, Divider, Grid, TextField, Typography} from '@mui/material';
import {states as States} from '../../../../constants/states';
import React from 'react';
import InputMask from 'react-input-mask'
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {isEmpty} from 'underscore';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  subHeader: {
    color: '#ff0000',
    fontSize: '1em',
    fontWeight: 800
  }
}));

const UserInfo = (props) => {
  const {
    className,
    infoValues,
    password,
    setInfoValues,
    onHandleInfoChange,
    setHasInfoErrors,
    edit,
    ...rest
  } = props

  const classes = useStyles();
  let required = edit;
  let initialTouched = {};
  if (edit){
    Object.keys(infoValues).map(function(key, index) {
      return initialTouched[key] = true;
    });
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Invalid Email')
      .required('Email is Required'),
    firstName: Yup.string()
      .trim()
      .required('First Name is Required'),
    lastName: Yup.string()
      .trim()
      .required('Last Name is Required'),
    street: Yup.string()
      .trim(),
    city: Yup.string()
      .trim(),
    phone: Yup.string()
      .trim()
      .required('Phone is Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    zipcode: Yup.string()
      .trim()
      .max(5,'Zip is Invalid')
  });

  return (
    <Formik
      enableReinitialize
      innerRef={self=>setHasInfoErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
      initialTouched={initialTouched}
      initialValues={infoValues}
      onSubmit={(values) => {
      }}
      validateOnMount
      validationSchema={validationSchema}
    >

      {(formikProps) => {
        const {
          handleBlur,
          handleSubmit
        } = formikProps;

        return (

          <Form onSubmit={handleSubmit}>
            {(!infoValues) ? (<LoadingSpinner/>) :
              (
                <Card
                  {...rest}
                  className={clsx(classes.root, className)}
                >
                  {password.length === 0 ? (
                    <CardHeader
                      classes={{
                        subheader: classes.subHeader,
                      }}
                      title="Account Information"
                      subheader="SHARED USER ACCOUNT ONLY"
                    />) : (
                    <CardHeader
                      title="Account Information"
                    />)
                  }
                  <Divider/>
                  <CardContent>
                    <Grid
                      className={classes.content}
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="First Name"
                          margin="dense"
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          value={infoValues.firstName || ''}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="firstName"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Last Name"
                          margin="dense"
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          value={infoValues.lastName || ''}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="lastName"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Email Address"
                          margin="dense"
                          name="email"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          type="email"
                          value={infoValues.email}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="email"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Phone Number"
                          margin="dense"
                          name="phone"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          type="text"
                          value={infoValues.phone || ''}
                          variant="outlined"
                        >
                          <InputMask
                            mask="999-999-9999"
                            maskChar=" "
                          />
                        </TextField>
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="phone"
                        />
                      </Grid>
                    </Grid>

                    <div className={classes.contentHeader}>
                      <Typography variant={'h5'}>Address</Typography>
                      <Divider/>
                    </div>

                    <Grid
                      className={classes.content}
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Street Address"
                          margin="dense"
                          name="street"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          type="text"
                          value={infoValues.street || ''}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="street"
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="City"
                          margin="dense"
                          name="city"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          type="text"
                          value={infoValues.city || ''}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="city"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          SelectProps={{native: true}}
                          fullWidth
                          label="Select State"
                          margin="dense"
                          name="state"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          // eslint-disable-next-line react/jsx-sort-props
                          select
                          value={infoValues.state}
                          variant="outlined"
                        >
                          {States.map(option => (
                            <option
                              key={option.abbreviation}
                              value={option.abbreviation}
                            >
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="state"
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="zip"
                          margin="dense"
                          name="zipcode"
                          onBlur={handleBlur}
                          onChange={onHandleInfoChange}
                          required={required}
                          value={infoValues.zipcode}
                          variant="outlined"
                        />

                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="zipcode"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              )}
          </Form>
        )
      }}
    </Formik>
  )
}
export default UserInfo;

