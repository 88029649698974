import React from 'react';

import {useSelector} from 'react-redux';
import {documentService} from "../../../store/services";
import {SECURITY_ROLES} from "../../../constants/SECURITY_ROLES";
import FileListWindow from "../components/FileListWindow";
import moment from "moment";
import {documentActions} from "../../../store/actions";
import {DOCUMENT_VIEWER_MODES} from "../../../constants/DOCUMENT_VIEWER_MODES";

const ListDocument = (props) => {
  const {
    location
  } = props;

  const {role, id} = useSelector(state => state.authentication.user);

  const isSharedUserVarSet = location.isSharedUser;
  const isSharedUserRole = role === SECURITY_ROLES.SHARED_USER;
  const sharedUser = isSharedUserRole || isSharedUserVarSet;

  const title = sharedUser ?
    'Shared Documents' :
    'Documents';

  const subtitle  = isSharedUserVarSet ?
    `Available documents shared with ${(location.sharedUserName).toUpperCase()}` :
    isSharedUserRole ?
      "Available shared documents" :
      'Available documents';

  const getMethod = isSharedUserRole ?
    id => documentService.getDocumentForSharedUserById(id) :
    id => documentService.getDocumentById(id);

  const sharedUserGuid = sharedUser ?
    (isSharedUserRole ? id : location.sharedUserGuid) : '';

  const memberGuid = sharedUser ? location.memberGuid : id;

  const searchMethod = sharedUser  ?
    searchFields => documentActions.searchSharedUserDocuments(searchFields) :
    searchFields => documentActions.searchDocuments(searchFields);

  return (<FileListWindow
    title={title}
    subtitle={subtitle}
    initialSection={typeof location.initialSection === 'undefined' ? 'All Sections' : location.initialSection}
    includeSectionFilter
    includeCompanyFilter={!isSharedUserVarSet}
    includeClassificationFilter
    includeCategoryFilter
    includeBeginDate
    initialBeginDate={isSharedUserVarSet ?
      location.startDate : (
        (typeof location.startDate !== 'undefined') ? location.startDate : new moment().subtract(90, 'days')
      )}
    includeEndDate
    initialEndDate={new moment()}
    includeFilename
    includeAttribute
    viewer={DOCUMENT_VIEWER_MODES.LIST}
    userGuid={memberGuid}
    sharedUser={sharedUser}
    sharedUserGuid={sharedUserGuid}
    sharedUserRole={isSharedUserRole}
    getDocumentFromDocumentServiceMethod={getMethod}
    searchDocumentFromDocumentServiceMethod={searchMethod}
  />);
}

export default ListDocument;
