import {sectionConstants} from '../../constants/index';
import {sectionService} from '../../services/index';

export const sectionActions = {
  getAllSections
};

function getAllSections() {
  return dispatch => {
    dispatch(request());

    sectionService.getAllSections()
      .then(
        sections => dispatch(success(sections)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: sectionConstants.GETALL_REQUEST}
  }

  function success(sections) {
    return {type: sectionConstants.GETALL_SUCCESS, sections}
  }

  function failure(error) {
    return {type: sectionConstants.GETALL_FAILURE, error}
  }

}
