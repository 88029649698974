import {layoutConstants} from '../../constants/index';

const initialState = {
  isSidebarOpened: true
};

export function layout(state = initialState, action) {
  switch (action.type) {
    case layoutConstants.TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    default:
      return state
  }
}
