import {createTheme as createMuiTheme} from '@mui/material';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1300
  },
  overlayProportions: {
    topbarHeight: "10vh",
    sidebarWidth: 240,
    footerHeight: "40px"
  }
});

export default theme;
