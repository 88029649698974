import React from 'react'
import PropTypes from 'prop-types'
import {PuffLoader} from "react-spinners";
import theme from "../../theme";
import {Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

export default function LoadingSpinner({align, title, component: Component, style}) {

  return (
    <Grid container
          direction={"row"}
          spacing={0}
          alignContent={align}
          alignItems={align}
          justifyContent={'center'}
          style={{...style}}>

      {Component}
      <Typography variant={"caption"}
                  style={{fontWeight: 400, fontStyle: 'italic', padding: 0, marginLeft: 10}}
                  color={"textSecondary"}>{title.toUpperCase()}</Typography>

    </Grid>
  )
}

LoadingSpinner.propTypes = {
  align: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.object,
  style: PropTypes.object
}

LoadingSpinner.defaultProps = {
  component: <PuffLoader color={theme.palette.spinner} size={30} />,
  title: 'Loading',
  align: 'center',
  style: {
    padding: 20,
  }
}
