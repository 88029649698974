import MenuItemContainer from "../MenuItemContainer";

const ArrayBasedMenuItem = props => {
  const {
    classes,
    options
  } = props;

  if (options == null) return null;
  let mappedItems = options.map((string, index) => MenuItemContainer({
    classes: classes,
    optionKey: index,
    optionValue: string,
    optionDisplay: string
  }))
  return (mappedItems);
}

export default ArrayBasedMenuItem;
