import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import SharedUserInfoForm from '../components/SharedUserInfoForm';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import SharedUserAdditionalInfo from '../components/SharedUserAdditionalInfo';
import {useDispatch, useSelector} from 'react-redux';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Button from '../../../components/CustomButtons/Button';
import {Create, Refresh} from '@mui/icons-material';
import {userActions} from '../../../store/actions';
import {history} from '../../../helpers/history';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {userService} from '../../../store/services';
import {CheckEmailPanel} from '../components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  fab: {
    margin: theme.spacing(1), // You might not need this now
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3)
  }
}));

const AddSharedUser = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const requestPending = useSelector(state => state.users.requestPending);
  const {id, companyId} = useSelector(state => state.authentication.user);
  const initialUserInfo =  {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    street: '',
    city: '',
    state: '',
    zip: ''
  }
  const initialAdditionalInfo =  {
    member_guid: id,
    company_guid: companyId,
    shared_user_guid: '',
    print: 0,
    download: 0,
    view: 1,
    date_created: null,
    deactivate_date: null,
    date_start: null,
    date_expires: null,
    expires_days: 1,
    does_not_expire: 0,
    sharedKey: '',
    confirmKey: '',
    key_expiry: 'expires_on_days',
    active: 0,
    daysEnabled: false
  }

  const [expanded, setExpanded] = useState(true);
  const [userFound, setUserFound] = useState(false);
  const [sharedUserFound, setSharedUserFound] = useState(false);
  const [emailSelect, setEmailSelect] = useState({email: ''});
  const [userFoundMessage, setUserFoundMessage] = useState('');
  const [filterPressed, setFilterPressed] = useState(false);
  const [updateAdditional, setUpdateAdditional] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [hasInfoErrors, setHasInfoErrors] = useState( false);
  const [hasAdditionalErrors, setHasAdditionalErrors] = useState( false);
  const [sharedUserInfo, setSharedUserInfo] = useState(initialUserInfo);
  const [additionalInfo, setAdditionalInfo] = useState(initialAdditionalInfo);

  async function checkEmail() {
    return (await (
      userService.checkSharedUserByEmail(emailSelect.email).then(r => {
        setSharedUserInfo(sharedUserInfo => ({...sharedUserInfo,
          'email': emailSelect.email,
          'firstName': '',
          'lastName': '',
          'street': '',
          'city': '',
          'state': '',
          'zip': '',
          'phone': ''}));

        setUserFound(r.userFound);
        setSharedUserFound(r.sharedUserFound);

        if (r.sharedUserFound){
          setUserFoundMessage('Shared User has already been setup to view documents shared by you.');
        } else {
          setUserFoundMessage('');
          setExpanded(false);
          setSharedUserInfo(sharedUserInfo => ({...sharedUserInfo,
            'email': emailSelect.email}));
          if (r.userFound){
            setHasInfoErrors(false);
            setSharedUserInfo(sharedUserInfo => ({...sharedUserInfo,
              'firstName': r.userInfo[0].firstName,
              'lastName': r.userInfo[0].lastName,
              'street': r.userInfo[0].street,
              'city': r.userInfo[0].city,
              'state': r.userInfo[0].state,
              'zip': r.userInfo[0].zipCode,
              'phone': r.userInfo[0].phone
            }));
          }

        }
        return r;
      })));
  }

  /////////////////////
  // Shared User Info
  /////////////////////
  const onHandleSharedInfoChange = (event) => {
    if (!userFound){
      setSharedUserInfo(sharedUserInfo => ({ ...sharedUserInfo, [event.target.name]: event.target.value}));
      setUpdateInfo(true);
    }
  };

  const handleEmailSelect = event => {
    setFilterPressed(false);
    setEmailSelect({ ...emailSelect, [event.target.name]: event.target.value});
    // setEmailSelect(event.target.value);
  };

  /////////////////////
  // Additional Info
  /////////////////////
  const onHandleAdditionalInfoChange = (event) => {
    setAdditionalInfo(additionalInfo => ({...additionalInfo, [event.target.name]: event.target.value}));
    setUpdateAdditional(true);
  };

  const onHandleSwitchChange = (event) => {
    setAdditionalInfo(additionalInfo => ({...additionalInfo, [event.target.name]: (event.target.checked ? 1 : 0)}));
    setUpdateAdditional(true);
  };

  const onHandleRadioChange = (event) => {
    const radio_value = event.target.value;
    if (event.target.value === 'does_not_expire'){
      setAdditionalInfo(additionalInfo => ({
        ...additionalInfo,
        does_not_expire: 1,
        expires_days: 0,
        key_expiry: radio_value,
        daysEnabled: true
      }));
    } else {
      setAdditionalInfo(additionalInfo => ({
        ...additionalInfo,
        does_not_expire: 0,
        expires_days: 0,
        key_expiry: radio_value,
        daysEnabled: false
      }));
    }
    setUpdateAdditional(true);
  };

  const onExpireDaysChange = (event) => {
    setAdditionalInfo(additionalInfo => ({ ...additionalInfo, key_expiry: 'expires_on_days', expires_days: event.target.value}));
    setUpdateAdditional(true);
  };

  const onHandleReset = () => {
    if (!userFound){

      setSharedUserInfo(initialUserInfo);
    }

    setAdditionalInfo(initialAdditionalInfo);
  }

  const handleFilterClick = event => {
    setFilterPressed(true);
    checkEmail().then(()=>{});
  };

  const onHandleSubmit = (rowData) => {
    let updateList = '';

    if (updateAdditional) {
      updateList += '\nAdditionalInfo';
    }

    if (updateInfo) {
      updateList += '\nSharedUserInfo';
    }

    if (updateList){
      setUpdateAdditional(false);
      setUpdateInfo(false);

      let sharedUserValues = {
        additionalInfo: additionalInfo,
        sharedUserInfo: sharedUserInfo
      }

      dispatch(userActions.addSharedUser(sharedUserValues));
      history.push('/dashboard');
    }
  };

  return (
    <div className={classes.root}>
      <PageHeader
        subTitle={'Create New Shared User'}
        title={'Shared User'}
      />
      <Card className={classes.content}>
        <CardContent>
          <Accordion
            expanded={expanded}
            onChange={() => {
              setExpanded(!expanded);
            }}
            style={{width: '100%', margin: 0, padding: 10, backgroundColor: '#3b5998'}}
          >
            <AccordionSummary
              aria-controls="search-details"
              expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}}/>}
              id="search-details"
            >
              <Typography
                style={{color: '#FFFFFF'}}
                variant={'h3'}
              >Shared User Email</Typography>)
            </AccordionSummary>
            <AccordionDetails style={{backgroundColor: '#FFFFFF'}}>
              <CheckEmailPanel
                classes={classes}
                emailSelect={emailSelect}
                handleEmailSelect={handleEmailSelect}
                handleFilterClick={handleFilterClick}
                userFoundMessage={userFoundMessage}
              />
            </AccordionDetails>
          </Accordion>
          {(!filterPressed) ? ('') : (
            <div>
              <Grid
                container
                spacing={4}
              >
                {(!userFound) ? (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <SharedUserInfoForm
                      onHandleSharedInfoChange={onHandleSharedInfoChange}
                      sharedUserInfo = {sharedUserInfo}
                      setHasInfoErrors={setHasInfoErrors}
                      userFound={userFound}
                      view="add"
                    />
                  </Grid>
                ) : (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    {(!sharedUserFound) &&

                      <Chip
                        className={classes.blankSection}
                        label={emailSelect.email}
                        size="medium"
                        style={{backgroundColor: '#3399ff', width: '100%', margin: '20px'}}
                      />
                    }
                  </Grid>
                )}

                {(sharedUserFound) ? ('') : (
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <SharedUserAdditionalInfo
                      additionalInfo={additionalInfo}
                      onExpireDaysChange={onExpireDaysChange}
                      onHandleAdditionalInfoChange={onHandleAdditionalInfoChange}
                      onHandleRadioChange={onHandleRadioChange}
                      onHandleSwitchChange={onHandleSwitchChange}
                      setAdditionalInfo={setAdditionalInfo}
                      setHasAdditionalErrors={setHasAdditionalErrors}
                      view="Add"
                    />
                  </Grid>
                )}
              </Grid>
              {requestPending && <LinearProgress/>}

              {(expanded) ? ('') : (
                <div
                  className={classes.fab}
                  style={{position: 'fixed'}}
                >
                  {(hasInfoErrors || hasAdditionalErrors) ? (
                    <Button
                      aria-label="Create"
                      className={classes.createButton}
                      color="primary"
                      disabled
                      onClick={onHandleSubmit}
                      type="submit"
                      variant="contained"
                    >
                      <Create/> Create
                    </Button>
                  ) : (
                    <Button
                      aria-label="Create"
                      className={classes.createButton}
                      color="primary"
                      onClick={onHandleSubmit}
                      type="submit"
                      variant="contained"
                    >
                      <Create/> Create
                    </Button>
                  )}
                  <Button
                    aria-label="Reset"
                    className={classes.resetButton}
                    color="primary"
                    disabled={requestPending}
                    onClick={onHandleReset}
                    type="submit"
                    variant="contained"
                  >
                    <Refresh/> Reset
                  </Button>

                </div>
              )}
            </div>
          )}
        </CardContent>
        {/*<CardActions className={classes.content}>*/}

        {/*</CardActions>*/}
      </Card>
    </div>
  )
}

export default AddSharedUser;
