import React from 'react';
import {makeStyles} from '@mui/styles';

import {UsersTable} from './components';
import PageHeader from '../../components/PageHeader/PageHeader';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    height: '100%',
    marginTop: theme.spacing(2)
  }
}));



const UserList = () => {
  const classes = useStyles();

  return (
    <div>

      <div className={classes.root}>
        <div className={classes.content}>
          <PageHeader
            subTitle={'List of All Users'}
            title={'users'}
          />
          <UsersTable />
        </div>
      </div>
    </div>
  )
};

export default UserList;
