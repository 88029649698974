import React from 'react';
import BusinessIcon from '@mui/icons-material/Business';
import FolderIcon from '@mui/icons-material/Folder';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import PeopleIcon from '@mui/icons-material/People';

export const adminPages = [
  {
    title: 'Companies',
    href: '/company',
    icon: <BusinessIcon/>
  },
  {
    title: 'Media Types',
    href: '/classification',
    icon: <GroupWorkIcon/>
  },
  {
    title: 'Sections',
    href: '/section',
    icon: <FolderIcon/>
  },
  {
    title: 'File Types',
    href: '/filetype',
    icon: <GroupWorkIcon/>
  },
  {
    title: 'Users',
    href: '/users',
    icon: <PeopleIcon/>
  }
]
