import {Box} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

function TabPanel(props) {
  const {children, value, index, plain, ...other} = props;

  return (
    <div
      aria-labelledby={`wrapped-tab-${index}`}
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={plain ? {padding: 0, margin: 0} : {}}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  plain: PropTypes.bool
};

export default TabPanel;
