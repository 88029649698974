import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  cardContainer: {
    width: 400,
    minWidth: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginLogoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  oauthContainer: {
    minHeight: 500
  },
  oauthLoginLogoContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  oauthIframeContainer: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  oauthLoginPageContainer: {
    minHeight: 250,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginLogoImage: {
    width: 350,
    justifyContent: 'center',
    alignItems: 'center'
  },
  formContainer: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
  form: {
    width: '100%'
  },
  loginButton: {
    marginTop: 10,
    width: '100%',
  },
  cancelButton: {
    borderRadius: 1,
    marginLeft: 20,
    marginRight: 20,
    width: '100%',
    backgroundColor: theme.palette.error.light
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  tabs: {
    paddingTop: 10
  },

  greeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  createAccountButton: {
    height: 46,
    textTransform: 'none',
  },
  formDividerContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + '40',
  },
  errorMessage: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  forgetButton: {
    textTransform: 'none',
    fontWeight: 400,
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
    flex: 1
  },
  copyright: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: theme.spacing(2),
    },
  },
  footerWrap: {
    clear: 'both',
    width: '100%',
    position: 'absolute',
    textAlign: 'left',
  },
  footer: {
    clear: 'both',
    color: '#666',
    margin: '0 auto 10px auto',
    width: '100%',
    padding: '5px 0',
    textAlign: 'center',
    backgroundColor: '#F8F7F7',
    borderTop: '1px solid #F2F2F2'
  }

}));
