import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    actions: {
      justifyContent: 'flex-end'
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    minHeight: '100%',
    padding: 0,
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  },
  title: {
    fontSize: "large"
  },
  subheader: {
    color: theme.palette.white
  },
  button: {
    color: theme.palette.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light

    }
  },
  icon: {
    height: 32,
    width: 32,
    color: theme.palette.primary.main
  },
  action: {
    margin: 0
  }
}));

const Notifications = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={classes.root}
      // className={classes.content}
    >
      <form>
        <CardHeader
          className={classes.titleHeader}
          classes={{
            action: classes.action,
            subheader: classes.subheader,
            title: classes.title
          }}
          subheader="Manage the notifications"
          title="Notifications"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                Notifications
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Push Notifications"
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Text Messages"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Phone calls"
              />
            </Grid>
            <Grid
              className={classes.item}
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
              >
                Messages
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Email"
              />
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Push Notifications"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    defaultChecked //
                  />
                }
                label="Phone calls"
              />
            </Grid>
          </Grid>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
            >
              Save
            </Button>
          </CardActions>
        </CardContent>
      </form>
    </Card>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
