import {vodConstants} from "../../constants";

const initialProgress = {
  uploading: {progress: 0, out_of: 100},
  processing: {progress: 0, out_of: 100},
  saving: {progress: 0, out_of: 100},
  location: null,
  timestamp: Date(),
  status: 100,
  finished: false
}

const initialState = {
  requestPendingCount: 0,
  requestPending: false,
  items: null,
  progress: initialProgress
};

export function vod(state = initialState, action) {
  switch (action.type){
    case vodConstants.UPLOAD_VOD_REQUEST:
      return {
        ...state,
        progress: initialProgress,
        requestPending: true,
        requestPendingCount: state.requestPendingCount + 1
      };
    case vodConstants.UPLOAD_VOD_SUCCESS:
      return {
        ...state,
        items: action.videoListUpload,
        requestPending: state.requestPendingCount > 1,
        requestPendingCount: state.requestPendingCount - 1
      };
    case vodConstants.UPLOAD_VOD_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: state.requestPendingCount > 1,
        requestPendingCount: state.requestPendingCount - 1
      }
    case vodConstants.RETRIEVE_VOD_PROGRESS_REQUEST:
      return {
        ...state,
        progressPending: true
      };
    case vodConstants.RETRIEVE_VOD_PROGRESS_SUCCESS:
      return {
        ...state,
        progress: action.progressReport,
        progressPending: false
      };
    case vodConstants.RETRIEVE_VOD_PROGRESS_FAILURE:
      return {
        ...state,
        items: null,
        error: action.error,
        progressPending: false
      }
    case vodConstants.RETRIEVE_VOD_FILE_REQUEST:
      return {
        ...state,
        requestPending: true
      }
    case vodConstants.RETRIEVE_VOD_FILE_SUCCESS:
      return {
        ...state,
        file: action.file,
        requestPending: false,
      }
    case vodConstants.RETRIEVE_VOD_FILE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false,
      }
    default:
      return state;
  }
}

export {initialProgress};
