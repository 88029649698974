import React, {useEffect, useState} from 'react';
import dashjs from "dashjs";
import {getAuthHeader} from "../../../../axios.instance";
import {maestroenv} from '../../../../helpers/maestroenv';
import {useSelector} from "react-redux";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";

const VoDFileViewer = props => {
  const {
    videoUUID,
    videoLink
  } = props;

  const user = useSelector(state => state.authentication.user);
  const isSharedUser = user && user.role === SECURITY_ROLES.SHARED_USER && user.memberGuid;
  const [playerID] = useState(`video_${videoUUID}`);

  const modifyHdr = request => {
    request.setRequestHeader("Authorization", getAuthHeader());
    return request;
  }

  let dashjsScript = function init() {
    let url = `${maestroenv.REACT_APP_VOD_URL}${isSharedUser ? "/shareduser" : ""}/${videoLink}`;
    let player = dashjs.MediaPlayer().create();
    let video = document.querySelector(`#${playerID}`);

    player.extend("RequestModifier", function () {
      return {
        modifyRequestHeader: modifyHdr,
      }
    }, true);
    if (video instanceof HTMLElement)
      player.initialize(video, url, true);
    else
      console.error("No view somehow");
  }

  const videoPlayer =
    (<video
      id={`${playerID}`}
      controls
      style={{
        flex: true,
        maxWidth: "100%",
        maxHeight: "100%",
        alignContent: "center"
      }}
    />);

  useEffect(() => {
    dashjsScript();
  })

  return (
    <div>
      {videoPlayer}
    </div>
  );
}

export default VoDFileViewer;
