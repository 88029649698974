import React from 'react';
import PropTypes from 'prop-types';
import {dashboardService} from '../../../../../store/services';
import UserRoleMetrics from "../../../components/UserRoleMetrics/UserRoleMetrics";
import UserRoleMetricDisplayInfo from "../../../components/UserRoleMetrics/UserRoleMetricDisplayInfo";

const AdminUserRoleMetrics = props => {
  const { className, metrics, ...rest } = props;

  const displayedRoleList = [
    UserRoleMetricDisplayInfo.cloudAdmin,
    UserRoleMetricDisplayInfo.companyAdmin,
    UserRoleMetricDisplayInfo.user,
    UserRoleMetricDisplayInfo.viewOnly,
    UserRoleMetricDisplayInfo.inactive
  ];

  return (
    <UserRoleMetrics
      className={className}
      metrics={metrics}
      displayedRoleList={displayedRoleList}
      getRoleMetricsMethod={dashboardService.getCloudAdminUserRoleMetrics}
      {...rest}
      />
  );
};

AdminUserRoleMetrics.propTypes = {
  className: PropTypes.string
};

export default AdminUserRoleMetrics;
