class DrawingDocTypesReference {
  typeMap = {
    DWG: "dwg",
    DXF: "dxf",
    DWF: "dwf",
    DXB: "dxb"
  }

  typeList = Object.values(this.typeMap).map(value => `.${value}`);

  /**
   *
   * @param {File} file
   * @return {string | null} the document's content type
   */
  isDocType(file){
    const nameSplitFromExtension = file.name.split(".");
    const extension = nameSplitFromExtension[nameSplitFromExtension.length - 1];
    return this.typeList.includes(`.${extension}`) ?
      `image/vnd.${extension}` :
      null;
  }
}

export const DrawingDocTypes = new DrawingDocTypesReference();
