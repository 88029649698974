import {OfficeDocTypes} from "./OfficeDocTypes";
import {DrawingDocTypes} from "./DrawingDocTypes";
import {AudioVideoDocTypes} from "./AudioVideoDocTypes";

const UploadDocTypeList = {
  document: [
    'application/pdf'
  ].concat(OfficeDocTypes.typeList),
  image: [],
  drawing: DrawingDocTypes.typeList,
  audio: AudioVideoDocTypes.audioTypeList,
  video: AudioVideoDocTypes.videoTypeList
}

export default UploadDocTypeList;
