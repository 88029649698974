import React from 'react'
import {states as States} from 'constants/states';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';

import {CardContent, CardHeader, Divider, FormControlLabel, Grid, TextField} from '@mui/material'
import Card from "../../../../components/Card/Card";
import {isEmpty} from "underscore";
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {useSelector} from "react-redux";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";
import Switch from "../../../../components/Switch";

const CompanyInfo = props => {
  const {
    classes,
    companyInfo,
    companies,
    onHandleParentCompanyChange,
    onHandleCompanyChange,
    onHandleSwitchChange,
    setHasCompanyErrors,
    submitButton
  } = props


  const user = useSelector(state => state.authentication.user);

  let required = false;
  if (submitButton !== 'View'){
    required = true;
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .min(3)
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('Company Name is Required'),
    registercode: Yup.string()
      .trim()
      .min(5)
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('Register Code is Required'),
    street: Yup.string()
      .trim(),
    city: Yup.string()
      .trim(),
    state: Yup.string()
      .trim(),
    zipcode: Yup.number()
      .min(5, 'Company\'s Zip Code is Invalid')
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('Company\'s Zip Code is Required'),
    logoname: Yup.string()
      .trim(),
  });

  const initialTouched = {
    "name": true,
    "zipcode": true,
    "registercode": true
  };

  return(
    <Formik
      enableReinitialize
      initialTouched={initialTouched}
      initialValues={companyInfo}
      innerRef={self=>setHasCompanyErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
      onSubmit={(values) => {
      }}
      validateOnMount
      validateOnChange
      validateOnBlur
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          handleBlur
        } = formikProps;

        return (
          <form>
            {(!companyInfo) ? (<LoadingSpinner/>) :  (
              <div className={classes.root}>
                <Card className={classes.content}>
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Company Details"
                    />
                    <Divider/>
                    <CardContent>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md ={4}
                        xs={12}
                      >
                        <FormControlLabel
                          className={classes.switch}
                          control={<Switch
                            checked={Boolean(companyInfo.active)}
                            name="active"
                            disabled = {companyInfo.guid === user.companyId}
                            onHandleToggle={onHandleSwitchChange}
                          />}
                          label="Active"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid
                        item
                        md={8}
                        xs={12}
                      >
                        {(companyInfo.guid !== user.companyId) &&
                        <TextField
                          SelectProps={{native: true}}
                          fullWidth
                          label="Parent Company Name"
                          margin="dense"
                          name="parentguid"
                          onBlur={handleBlur}
                          onChange={onHandleParentCompanyChange}
                          required={required}
                          select
                          // eslint-disable-next-line react/jsx-sort-props
                          type="text"
                          value={companyInfo.parentguid}
                          variant="outlined"
                        >
                          {user.role === SECURITY_ROLES.CLOUD_ADMIN &&
                          <option
                            key="owner"
                            value="owner"
                          >
                            Maestro Owner
                          </option>
                          }
                          {companies.filter(c => c.name !== companyInfo.name).map(option => (
                            <option
                              key={option.guid}
                              value={option.guid}
                            >
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                        }
                        <ErrorMessage
                        className={classes.errorMessage}
                        component="div"
                        name="parentguid"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          autoFocus
                          fullWidth
                          label="Company Name"
                          margin="dense"
                          name="name"
                          onBlur={handleBlur}
                          onChange={onHandleCompanyChange}
                          required = {required}
                          type="text"
                          value={companyInfo.name}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="name"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Street Address"
                          margin="dense"
                          name="street"
                          onBlur={handleBlur}
                          onChange={ onHandleCompanyChange }
                          type="text"
                          value={companyInfo.street}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="street"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="City"
                          margin="dense"
                          name="city"
                          onBlur={handleBlur}
                          onChange={ onHandleCompanyChange }
                          type="text"
                          value={companyInfo.city}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="city"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          SelectProps={{native: true}}
                          fullWidth
                          label="State"
                          margin="dense"
                          name="state"
                          onBlur={handleBlur}
                          onChange={ onHandleCompanyChange }
                          required = {required}
                          select
                          // eslint-disable-next-line react/jsx-sort-props
                          type="text"
                          value={companyInfo.state}
                          variant="outlined"
                        >
                          <option
                            key=""
                            value=""
                          />
                          {States.map(option => (
                            <option
                              key={option.abbreviation}
                              value={option.abbreviation}
                            >
                              {option.name}
                            </option>
                          ))}
                        </TextField>
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="state"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Zip"
                          margin="dense"
                          name="zipcode"
                          onBlur={handleBlur}
                          onChange={ onHandleCompanyChange }
                          required = {required}
                          type="text"
                          value={companyInfo.zipcode}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="zipcode"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Register Code"
                          margin="dense"
                          name="registercode"
                          onBlur={handleBlur}
                          onChange={ onHandleCompanyChange }
                          required = {required}
                          type="text"
                          value={companyInfo.registercode}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="registercode"
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Logo Filename"
                          margin="dense"
                          name="logoname"
                          onBlur={handleBlur}
                          onChange={ onHandleCompanyChange }
                          type="text"
                          value={companyInfo.logoname}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                    {/*<DisplayFormikState {...formikProps} />*/}
                  </CardContent>
                  </Grid>
                </Card>
              </div>
            )}
          </form>
        )}
      }

    </Formik>
  )
}

export default CompanyInfo;
