import React from 'react';
import {makeStyles} from '@mui/styles';
import AddUserForm from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AddUser = () => {
    const classes = useStyles();

  return (
    <div className={classes.root}>
      <AddUserForm/>
    </div>
  )
}
;

export default AddUser;
