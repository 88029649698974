import {Formik} from 'formik';
import * as Yup from 'yup';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
} from '@mui/material';
import React from 'react';
import {makeStyles} from '@mui/styles';
import {yupSchemaCreator} from '../../../../helpers';
import _ from 'underscore';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";
import Switch from "../../../../components/Switch";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));


const UserRoles = (props) => {
  const {
    title,
    userRoles,
    roleItems,
    onHandleRoleChange
  } = props

  const classes = useStyles();
  const {accountRole} = useSelector(state => {
    const roleTemp = state.authentication.user.role;
    return {
      accountRole: roleTemp
    }
  });
  const yupSchema = roleItems.reduce(yupSchemaCreator, {});
  const validateSchema = Yup.object().shape(yupSchema);

  return (
    <React.Fragment>
      {(!_.isEmpty(userRoles) && roleItems) ?
        (

          <Formik
            enableReinitialize
            initialValues={userRoles}
            onSubmit={() => {
              alert('Role Update not available at this time.');
            }}
            validationSchema={validateSchema}
          >
            {() => {
              return (
                <form>
                  <Card>
                    {(!userRoles || !roleItems) ? (<LoadingSpinner/>) :
                      (
                        <div>
                          <CardHeader
                            title={title}
                          />
                          <Divider/>
                          <CardContent>
                            <Grid
                              className={classes.content}
                              container
                              spacing={3}
                            >
                              {roleItems.map((items, idx) => (
                                <Grid
                                  item
                                  xs={12}
                                  key={idx}
                                >
                                  <FormControlLabel
                                    className={classes.switch}
                                    control={<Switch
                                      checked={Boolean(userRoles[items.name])}
                                      name={items.name}
                                      disabled={(items.name === SECURITY_ROLES.SHARED_USER) || (accountRole !== SECURITY_ROLES.CLOUD_ADMIN && items.name === SECURITY_ROLES.CLOUD_ADMIN)}
                                      onHandleToggle={onHandleRoleChange}
                                    />}
                                    label={items.name}
                                    labelPlacement="start"
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </CardContent>
                        </div>
                      )}
                  </Card>
                </form>
              )
            }}
          </Formik>
        ) : (<LoadingSpinner/>)}
    </React.Fragment>
  )
}
export default UserRoles;
