export const companyConstants = {
  ACTIVATE_REQUEST: 'COMPANIES_ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'COMPANIES_ACTIVATE_SUCCESS',
  ACTIVATE_FAILURE: 'COMPANIES_ACTIVATE_FAILURE',

  ADDNEW_REQUEST: 'COMPANIES_ADDNEW_REQUEST',
  ADDNEW_SUCCESS: 'COMPANIES_ADDNEW_SUCCESS',
  ADDNEW_FAILURE: 'COMPANIES_ADDNEW_FAILURE',

  DEACTIVATE_REQUEST: 'COMPANIES_DEACTIVATE_REQUEST',
  DEACTIVATE_SUCCESS: 'COMPANIES_DEACTIVATE_SUCCESS',
  DEACTIVATE_FAILURE: 'COMPANIES_DEACTIVATE_FAILURE',

  DELETE_REQUEST: 'COMPANIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'COMPANIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'COMPANIES_DELETE_FAILURE',

  GETALL_REQUEST: 'COMPANIES_GETALL_REQUEST',
  GETALL_SUCCESS: 'COMPANIES_GETALL_SUCCESS',
  GETALL_FAILURE: 'COMPANIES_GETALL_FAILURE',

  GETALL_ACTIVE_REQUEST: 'COMPANIES_GETALL_ACTIVE_REQUEST',
  GETALL_ACTIVE_SUCCESS: 'COMPANIES_GETALL_ACTIVE_SUCCESS',
  GETALL_ACTIVE_FAILURE: 'COMPANIES_GETALL_ACTIVE_FAILURE',

  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  GET_PARENT_COMPANY_REQUEST: 'GET_PARENT_COMPANY_REQUEST',
  GET_PARENT_COMPANY_SUCCESS: 'GET_PARENT_COMPANY_SUCCESS',
  GET_PARENT_COMPANY_FAILURE: 'GET_PARENT_COMPANY_FAILURE',


  UPDATE_REQUEST: 'COMPANIES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COMPANIES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COMPANIES_UPDATE_FAILURE',

  APP_NEW_REQUEST: 'APP_NEW_REQUEST',
  APP_NEW_SUCCESS: 'APP_NEW_SUCCESS',
  APP_NEW_FAILURE: 'APP_NEW_FAILURE',

  APP_UPDATE_REQUEST: 'APP_UPDATE_REQUEST',
  APP_UPDATE_SUCCESS: 'APP_UPDATE_SUCCESS',
  APP_UPDATE_FAILURE: 'APP_UPDATE_FAILURE',

  APP_LINK_UPDATE_REQUEST: 'APP_LINK_UPDATE_REQUEST',
  APP_LINK_UPDATE_SUCCESS: 'APP_LINK_UPDATE_SUCCESS',
  APP_LINK_UPDATE_FAILURE: 'APP_LINK_UPDATE_FAILURE',

  APP_UPLOAD_IMAGES_REQUEST: 'APP_UPLOAD_IMAGES_REQUEST',
  APP_UPLOAD_IMAGES_SUCCESS: 'APP_UPLOAD_IMAGES_SUCCESS',
  APP_UPLOAD_IMAGES_FAILURE: 'APP_UPLOAD_IMAGES_FAILURE',

};
