import cancellableTimeoutPromise from "./cancellableTimeoutPromise";
import {alertActions} from "../store/actions";

/**
 *
 * @param operation operation to perform after the waiting period
 * @param waitTime duration in milliseconds to wait for operation to finish (automatically set to 0 if negative, null, or undefined)
 * @param request method to dispatch (for reducer) stating that the request started
 * @param success method to dispatch (for reducer) stating that the request succeeded
 * @param failure method to dispatch (for reducer) stating that the request failed
 * @returns {function(Dispatch<any>): {cancel: function(): void, promise: Promise<void>}}
 */
function storeActionWithDelayAndCancel(operation, waitTime, request, success, failure) {
  return dispatch => {
    dispatch(request())

    let cancelled = false;
    const timeoutPromise = cancellableTimeoutPromise(waitTime, true);
    const thenOperation = () => !cancelled ? operation() : null;
    const thenReportResults = results => {
      if (!cancelled) dispatch(success(results.data));
      else dispatch(failure("Operation Cancelled"));
    };
    const thenHandleError = error => {
      dispatch(failure(error.toString()));
      dispatch(alertActions.error(error.toString()));
    };
    const cancelMethod = () => {
      cancelled = true;
      timeoutPromise.cancel();
    };

    return {
      promise: timeoutPromise.promise
        .then(thenOperation)
        .then(thenReportResults, thenHandleError),
      cancel: cancelMethod
    };
  }
}

export default storeActionWithDelayAndCancel;
