import React from 'react';
import clsx from 'clsx';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import useStyles from './styles';
import {Link} from 'react-router-dom';
import BusinessIcon from '@mui/icons-material/Business';

const CompanyMetrics = props => {
  const {className, metrics, ...rest} = props;
  const classes = useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {metrics &&
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
              variant="body2"
            >
              CHILD COMPANIES
            </Typography>
            <Typography
              className={classes.valueText}
              variant="h3"
            >{metrics.subCompaniesCount}</Typography>
          </Grid>
          <Grid item>
            <Link to={'/company'}>
              <Avatar className={classes.avatar}>
                <BusinessIcon className={classes.icon}/>
              </Avatar>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
      }
    </Card>
  );
};

export default CompanyMetrics;
