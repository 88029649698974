import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import {Card, CardContent, Divider, Grid, TextField, Typography} from '@mui/material';
import {states as States} from '../../../../constants/states';
import React from 'react';
import {makeStyles} from '@mui/styles';
import {isEmpty} from 'underscore';

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  createButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  resetButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  fab: {
    position: 'fixed',
    margin: theme.spacing(1),
    bottom: theme.spacing(4),
    right: theme.spacing(3)
  },
}));

const SharedUserInfoForm = (props) => {
  const {
    sharedUserInfo,
    userFound,
    onHandleSharedInfoChange,
    setHasInfoErrors
  } = props;

  const classes = useStyles();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required('First Name is Required'),
    lastName: Yup.string()
      .trim()
      .required('Last Name is Required'),
    email: Yup.string()
      .trim()
      .email('Invalid Email')
      .required('Email is Required'),
    phone: Yup.string()
      .trim()
      .matches(phoneRegExp, 'Phone number is not valid'),
    street: Yup.string()
      .trim(),
    city: Yup.string()
      .trim(),
    zip: Yup.number()
      .max(5, 'Zip Code cannot be more than 5 bytes')
  })

  const initialTouched = {};
  if (!userFound){
    Object.keys(sharedUserInfo).map(function (key, index) {
      return initialTouched[key] = true;
    });
  }

  return (
    <Formik
      enableReinitialize
      innerRef={form => setHasInfoErrors(form && !isEmpty(form.touched) && !isEmpty(form.errors))}
      initialTouched={initialTouched}
      initialValues={sharedUserInfo}
      onSubmit={(values) => {
      }}
      validateOnMount
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          handleBlur,
          handleSubmit,
        } = formikProps;

        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <div className={classes.contentHeader}>
                  <Typography variant={'h5'}>User Details</Typography>
                  <Divider/>
                </div>
                <Grid
                  className={classes.content}
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="First Name"
                      margin="dense"
                      name="firstName"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      value={sharedUserInfo.firstName}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="firstName"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Last Name"
                      margin="dense"
                      name="lastName"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      value={sharedUserInfo.lastName}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="lastName"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Email Address"
                      margin="dense"
                      name="email"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      value={sharedUserInfo.email}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="email"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      name="phone"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      type="text"
                      value={sharedUserInfo.phone}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="phone"
                    />
                  </Grid>
                </Grid>

                <div className={classes.contentHeader}>
                  <Typography variant={"h5"}>Address Information</Typography>
                  <Divider/>
                </div>

                <Grid
                  className={classes.content}
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Street Address"
                      margin="dense"
                      name="street"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      type="text"
                      value={sharedUserInfo.street}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="street"
                    />
                  </Grid>
                  <Grid
                    item
                    md={5}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="City"
                      margin="dense"
                      name="city"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      type="text"
                      value={sharedUserInfo.city}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="city"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Select State"
                      margin="dense"
                      name="state"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{native: true}}
                      value={sharedUserInfo.state}
                      variant="outlined"
                    >
                      <option
                        key=""
                        value=""
                      />
                      {States.map(option => (
                        <option
                          key={option.abbreviation}
                          value={option.abbreviation}
                        >
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="state"
                    />
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="zip"
                      margin="dense"
                      name="zip"
                      onChange={onHandleSharedInfoChange}
                      onBlur={handleBlur}
                      value={sharedUserInfo.zip}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="zip"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {/*<DisplayFormikState {...formikProps}/>*/}
          </form>
        )
      }}
    </Formik>
  )
}
export default SharedUserInfoForm;

