import axiosInstance from '../../axios.instance';
import {requestHelper} from '../helpers';
import { maestroenv } from '../../helpers/maestroenv';
export const classificationService = {
  add,
  update,
  getAllClassifications
};

function add(classification) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/classifications/add`, JSON.stringify(classification))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });

}

function getAllClassifications() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/classifications/`)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function update(classification) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/classifications/update`, JSON.stringify(classification))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
