import React from 'react';
import {createRoot} from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import App from './App';

const container = document.getElementById('root');
const reactRoot = createRoot(container);
reactRoot.render(<App />);
serviceWorker.unregister();
