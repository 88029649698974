import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import MaterialTable from '@material-table/core';

// @mui/icons-material

import PageHeader from '../../../components/PageHeader/PageHeader';
import {makeStyles} from '@mui/styles';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {sectionService} from "../../../store/services";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ListSection = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialRender, setInitialRender] = useState(true);
  const [sectionData, setSectionData] = useState(null);


  useEffect(() => {
    if (initialRender){
      sectionService.getAllSections().then((results) => {
        if (initialRender) {
          setSectionData(results.data)
        }
      })
      setInitialRender(false);
    }

  }, [dispatch, sectionData, initialRender]);

  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <PageHeader
          subTitle={'List of All Sections'}
          title={'Section'}
        />
        {!sectionData ? (<LoadingSpinner/>) :
          (
            <MaterialTable
              columns={[
                {
                  title: 'Name',
                  field: 'name',
                  validate: rowData => Boolean(rowData.name),
                  cellStyle: {
                    width: '40%',
                    maxWidth: '40%'
                  },
                  headerStyle: {
                    width: '40%',
                    maxWidth: '40%'
                  }
                },
                {
                  cellStyle: {
                    width: '55%',
                    maxWidth: '55%'
                  },
                  headerStyle: {
                    width:'55%',
                    maxWidth: '55%'
                  }
                }
              ]}
              data={sectionData}
              options={{
                search: true,
                searchFieldAlignment: 'left',
                actionsColumnIndex: -1,
                addRowPosition: "first"

              }}
              title=""
            />
          )}
      </div>
    </div>
  )
}

export default ListSection;
