import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import palette from '../../../../../theme/palette';
import {Link} from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import {dashboardService} from '../../../../../store/services';
import {EmptyList} from "../../../../../components/EmptyList";
import CardBody from "../../../../../components/Card/CardBody";

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text,
    fontSize: 14
  },
  section: {
    color: theme.palette.white
  },
  content: {
    padding: 0
  },
  emptyAvatarStyle: {
    margin: 10,
    width: '100%',
    height: 50
  },
  avatarStyle:  {
    marginRight: 10,
    width: 100,
    height: 20
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  }
}));

const TopUserDocumentsViewedMetrics = props => {
  const {className, metrics: {topViewedDocuments: {documents}}, ...rest} = props;
  const [documentData, setDocumentData] = useState(documents);

  const classes = useStyles();
  const statusColors = {
    Public: palette.publicSection,
    Company: palette.companySection,
    Private: palette.privateSection
  };

  const handleRefresh = event => {
    getData().then(metrics => {
      setDocumentData(metrics.documents);
    });
  };

  async function getData() {
    return await dashboardService.getTopUserViewedDocumentsMetrics().then(r => {
      return r.data
    });
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.titleHeader}
        action={
          <IconButton
            onClick={handleRefresh}
            size="small"
          >
            <RefreshIcon/>
          </IconButton>
        }
        subtitle={documentData.length > 0 ? `${documentData.length}  in total` : 'You Have No Recent Viewed Documents!'}
        title={documentData.length > 0 ? `Your Top ${documentData.length} Viewed Documents` : 'You Have No Recent Viewed Documents!'}
      />
      <Divider/>
      <CardBody>
        <div className={classes.content}>
          <List>
            {documentData.length <= 0 ? (
              <EmptyList
                label="No Recent Documents Viewed to Display!"
              />

            )
              :
              (<React.Fragment>{documentData.map((document, i) => (
                <ListItem
                  divider={i < documents.length - 1}
                  key={document.documentName}
                >
                  <ListItemAvatar>
                    <Avatar
                      className={classes.avatarStyle}
                      style={{backgroundColor: statusColors[document.section]}}
                      variant="rounded"
                    >
                      <Typography
                        align="center"
                        className={classes.section}
                        color="textPrimary"
                        component="span"
                        style={{backgroundColor: statusColors[document.section]}}
                        variant="body2"
                      >
                        {document.section}
                      </Typography>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          className={classes.title}
                          color="textPrimary"
                          component="span"
                          variant="body2"
                        >
                          {document.documentName}
                        </Typography>
                      </React.Fragment>}
                  />
                  <ListItemSecondaryAction>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            className={classes.title}
                            color="textPrimary"
                            component="span"
                            variant="body2"
                          >
                            {document.numberOfViews}
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={'Views'}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </React.Fragment>)}
          </List>
        </div>
      </CardBody>
      <Divider/>
      {documentData.length > 0 &&
      <CardActions className={classes.actions}>
        <Link to="/documents">
          <Button
            color="primary"
            size="small"
            variant="text"
          >
            View all <ArrowRightIcon/>
          </Button>
        </Link>
      </CardActions>
      }
    </Card>
  );
};

TopUserDocumentsViewedMetrics.propTypes = {
  className: PropTypes.string
};

export default TopUserDocumentsViewedMetrics;
