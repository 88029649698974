import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ErrorMessage, Formik} from 'formik';
import {history} from "../../../../../../helpers/history";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider, Fab,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  Popover,
  TextField
} from '@mui/material'
import Button from "../../../../../../components/CustomButtons/Button";
import {ColorPicker} from 'react-mui-color';
import LoadingSpinner from "../../../../../../components/Spinner/loading.spinner";
import {Add, Cancel, Refresh, Update} from "@mui/icons-material";
import {isEmpty} from "underscore";
import Switch from "../../../../../../components/Switch";

const ViewConnectedAppInfo = props => {
  const {
    classes,
    appsInfo,
    edit,
    onHandleSubmitNew,
    onHandleSubmitUpdate,
  } = props

  const [icons, setIcons] = useState(null)
  const [cancelDisabled, setCancelDisabled] = useState(false)
  const [appValues] = useState({
    name: appsInfo.name,
    description: appsInfo.description,
    iconFile: appsInfo.iconFile,
    link: appsInfo.link,
    appId: appsInfo.id,
    enabled: Boolean(appsInfo.enabled),
    companyId: appsInfo.companyId,
    bgColor: appsInfo.bgColor
  });
  const [touchedValues] = useState(Object.keys(appValues).reduce(function (result, key) {
    result[key] = true;
    return result;
  }, {}));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onHandleFormSubmit = (values) => {
    setCancelDisabled(true);
    if (edit) {
      onHandleSubmitUpdate(values);
    } else {
      onHandleSubmitNew(values);
    }
  }

  const onHandleFormReset = (values) => {
    console.log("E");
    setCancelDisabled(false)
    values.iconFile = appValues.iconFile
    values.bgColor = appValues.bgColor
    values.iconFile = appValues.iconFile
    values.bgFile = appValues.bgFile
    values.decription = appValues.decription
    values.enabled = appValues.enabled
    values.link = appValues.link
    values.name = appValues.name
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!icons) {
      let tempIcons = [];
      Object.keys(Icons).forEach((icon, index) => {
        if (Icons[icon].icon) {
          tempIcons.push(icon);
        }
      });
      setIcons(tempIcons);
    }
  }, [appsInfo,
    icons]);

  const stringValidation = ({fieldName, minLength, required}) => {
    let validationYup = Yup.string().trim();
    if (minLength) validationYup = validationYup.min(minLength, `${fieldName} must be at least ${minLength} characters`);
    if (required) validationYup = validationYup.required(`${fieldName} is Required`);
    return validationYup;
  }

  const validationSchema = Yup.object().shape({
    name: stringValidation({fieldName: "App Name", minLength: 3, required: true}),
    iconFile: stringValidation({fieldName: "Icon", required: true}),
    bgColor: stringValidation({fieldName: "Background color", required: true}),
    link: stringValidation({fieldName: "App Link", required: true}),
    description: stringValidation({fieldName: "App Description", minLength: 10, required: true}),
  });

  return (
    <React.Fragment>
      {(!appValues || !icons /*|| !iconView*/) ? (<LoadingSpinner/>) :
        (
          <Formik
            enableReinitialize={true}
            initialValues={appValues}
            initialTouched={touchedValues}
            onSubmit={onHandleFormSubmit}
            onReset={onHandleFormReset}
            validateOnChange
            validateOnBlur
            validateOnMount
            validationSchema={validationSchema}
          >

            {(props) => {
              const {
                values,
                errors,
                handleBlur,
                handleChange,
                handleReset,
                handleSubmit,
                setFieldValue
              } = props;
              const hasConnectedAppsErrors = !isEmpty(errors);
              const isUnchanged = values === appValues;

              const iconListElement = icon =>
                (<Grid item key={icon} xs
                       onClick={() => {
                         values.iconFile = icon;
                         if (values.iconFile !== "") {
                           errors.iconFile = "";
                         }
                         setFieldValue('iconFile', icon);
                         handleClose()
                       }} value={icon}>
                  <Avatar variant={"square"} className={classes.iconAvatarList}
                          style={{backgroundColor: values.bgColor}}>
                    <FontAwesomeIcon icon={Icons[icon]}/>
                  </Avatar>
                </Grid>);

              const iconSelectForm =
                (<Grid
                  item
                  xs={12}
                >
                  <Grid item md={4} xs={12}>
                    <List>
                      <ListItem>
                        <Avatar variant={"square"} className={classes.iconAvatarList}
                                style={{backgroundColor: values.bgColor}}>
                          <FontAwesomeIcon name="iconFile"
                                           icon={values.iconFile ? Icons[values.iconFile] : Icons.faExclamation}/>
                        </Avatar>
                        <Button aria-describedby={id} variant="contained" color="primary"
                                onClick={handleClick}>
                          Change Icon
                        </Button>
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          onClose={handleClose}
                        >
                          <Grid container spacing={3}
                                style={{margin: 20, maxHeight: '50vh', maxWidth: '50vh'}}>
                            {icons.map((icon) => iconListElement(icon))}
                          </Grid>
                        </Popover>
                      </ListItem>
                    </List>
                  </Grid>
                  <ErrorMessage
                    className={classes.errorMessage}
                    component="div"
                    name="iconFile"
                  />
                </Grid>);

              const colorSelectForm =
                (<Grid
                  item
                  md={4}
                  xs={12}
                >
                  <InputLabel
                    className={classes.selectLabel}
                    htmlFor="bgColor"
                  >
                    Background Color
                  </InputLabel>
                  <ColorPicker
                    fullWidth
                    margin="dense"
                    id="bgColor"
                    name="bgColor"
                    value={values.bgColor}
                    className={classes.colorPicker}
                    style={{backgroundColor: values.bgColor}}
                    onChange={(color) => setFieldValue('bgColor', color)}
                    variant="free"
                    sx={{}}
                    colors={[]}/>
                  <ErrorMessage
                    className={classes.errorMessage}
                    component="div"
                    name="bgColor"
                  />
                </Grid>);

              const enabledToggle =
                (<Grid
                  item
                  md={12}
                  xs={12}
                >
                  <FormControlLabel
                    className={classes.switch}
                    name="enabled"
                    control={<Switch
                      checked={Boolean(values.enabled)}
                      name="enabled"
                      onHandleToggle={handleChange}
                    />}
                    label="Enabled"
                    labelPlacement="start"
                  />
                </Grid>);

              const nameSelectForm =
                (<Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    autoFocus
                    fullWidth
                    label="Orchestrated App Name"
                    margin="dense"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.name}
                    variant="outlined"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    component="div"
                    name="name"
                  />
                </Grid>);

              const linkSelectForm =
                (<Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Link"
                    margin="dense"
                    name="link"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.link}
                    variant="outlined"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    component="div"
                    name="link"
                  />
                </Grid>);

              const descriptionSelectForm =
                (<Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Description"
                    margin="dense"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    multiline
                    rows={12}
                    value={values.description}
                    variant="outlined"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    component="div"
                    name="description"
                  />
                </Grid>);

              return (
                <div>
                  <div className={classes.root}>
                    <Card className={classes.content}>
                      <form onReset={handleReset} onSubmit={handleSubmit}>
                        <Grid
                          item
                          xs={12}
                        >
                          <CardHeader
                            title="Orchestrated App Details"
                          />
                          <Divider/>
                          <CardContent>
                            <Grid
                              container
                              spacing={3}
                            >
                              {iconSelectForm}
                              {colorSelectForm}
                              {enabledToggle}
                              {nameSelectForm}
                              {linkSelectForm}
                              {descriptionSelectForm}
                            </Grid>
                            {/*<DisplayFormikState {...props} />*/}
                          </CardContent>

                        </Grid>
                        <Grid container className={classes.fab}>
                          <Fab color="primary"
                               aria-label="add"
                               disabled={hasConnectedAppsErrors || isUnchanged}
                               type="submit"
                               variant="text"
                               className={classes.button}
                          >
                            {edit ? <><Update/> Update</> : <><Add/> Create</>}
                          </Fab>
                          <Fab color="primary"
                               aria-label="reset"
                               disabled={isUnchanged}
                               variant="text"
                               onClick={handleReset}
                               className={classes.button}
                          >
                            <><Refresh/> Reset</>
                          </Fab>
                          <Fab color="primary"
                               aria-label="cancel"
                               disabled={cancelDisabled}
                               onClick={() => history.goBack()}
                               variant="text"
                               className={classes.button}
                          >
                            <><Cancel/> Cancel</>
                          </Fab>
                        </Grid>
                      </form>
                    </Card>
                  </div>
                </div>
              )
            }}
          < /Formik>
        )
      }
    </React.Fragment>
  )
}

export default ViewConnectedAppInfo;
