import {filetypeConstants} from '../../constants/index';
import {filetypeService} from '../../services/index';
import {alertActions} from '../index';

export const filetypeActions = {
  add,
  update,
  getAllFiletypes,
  getMimeTypesByClassification
};

function add(filetype) {
  return dispatch => {
    dispatch(request(filetype));

    filetypeService.add(filetype)
      .then(
        filetype => {
          dispatch(success(filetype));
          dispatch(alertActions.success('Filetype ' + filetype.data.extension + ' added.'));
          getAllFiletypes();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(filetype) {
    return {type: filetypeConstants.ADDNEW_REQUEST, filetype}
  }

  function success(filetype) {
    return {type: filetypeConstants.ADDNEW_SUCCESS, filetype}
  }

  function failure(error) {
    return {type: filetypeConstants.ADDNEW_FAILURE, error}
  }
}

function getAllFiletypes() {
  return dispatch => {
    dispatch(request());

    filetypeService.getAllFiletypes()
      .then(
        filetypes => {
          dispatch(success(filetypes))
        },
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: filetypeConstants.GETALL_REQUEST}
  }

  function success(filetypes) {
    return {type: filetypeConstants.GETALL_SUCCESS, filetypes}
  }

  function failure(error) {
    return {type: filetypeConstants.GETALL_FAILURE, error}
  }
}

function update(filetype) {
  return dispatch => {
    dispatch(request());

    filetypeService.update(filetype)
      .then(
        filetype => {
          dispatch(success(filetype));
          dispatch(alertActions.success('Filetype ' + filetype.data.extension + ' updated'));
          getAllFiletypes();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(filetype) {
    return {type: filetypeConstants.UPDATE_REQUEST, filetype}
  }

  function success(filetype) {
    return {type: filetypeConstants.UPDATE_SUCCESS, filetype}
  }

  function failure(error) {
    return {type: filetypeConstants.UPDATE_FAILURE, error}
  }
}


function getMimeTypesByClassification(name) {
    return dispatch => {
      dispatch(request());

      filetypeService.getMimeTypeByClassification(name)
        .then(
          mimetypes => dispatch(success(mimetypes)),
          error => dispatch(failure(error.toString()))
        );
    };

    function request(name) {
      return {type: filetypeConstants.GET_MIMETYPES_BY_ID_REQUEST}
    }

    function success(name) {
      return {type: filetypeConstants.GET_MIMETYPES_BY_ID_SUCCESS, name}
    }

    function failure(error) {
      return {type: filetypeConstants.GET_MIMETYPES_BY_ID_FAILURE, error}
    }
}
