import {ErrorMessage, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Card, CardContent, CardHeader, Divider, Grid, TextField,} from '@mui/material';
import React from 'react';
import {makeStyles} from '@mui/styles';
import {isEmpty} from 'underscore';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  Button: {
    padding: theme.spacing(1)
  }

}));

const UserSecurityQuestions = (props) => {
  const {
    answerValues,
    allQuestions,
    onHandleTextChange,
    setHasAnswerErrors,
    edit
  } = props

  const classes = useStyles();
  const qCode1 = allQuestions.filter(question => question.group_code === 1);
  const qCode2 = allQuestions.filter(question => question.group_code === 2);
  const qCode3 = allQuestions.filter(question => question.group_code === 3);


  let initialTouched = {};
  if (edit) {
    Object.keys(answerValues).map(function(key, index) {
      return initialTouched[key] = true;
    });
  }


  let required = edit;

  const validationSchema = Yup.object().shape({
    question_one: Yup.number()
      .notRequired(),
    answer_one: Yup.string()
      .trim()
      .notRequired()
      .when('question_one', {
        is: true, // alternatively: (val) => val == true
        then: Yup.string()
          .required('Answer is Required')
          .min(3, 'Must be at least 3 characters'),
        otherwise: Yup.string()
          .notRequired()
          .min(0),
      }),
    question_two: Yup.number()
      .notRequired(),
    answer_two: Yup.string()
      .trim()
      .notRequired()
      .when('question_two', {
        is: true, // alternatively: (val) => val == true
        then: Yup.string()
          .required('Answer is Required')
          .min(3, 'Must be at least 3 characters'),
        otherwise: Yup.string()
          .notRequired()
          .min(0),
      }),
    question_three: Yup.number()
      .notRequired(),
    answer_three: Yup.string()
      .trim()
      .notRequired()
      .when('question_three', {
        is: true, // alternatively: (val) => val == true
        then: Yup.string()
          .required('Answer is Required')
          .min(3, 'Must be at least 3 characters'),
        otherwise: Yup.string()
          .notRequired()
          .min(0),
      }),
  });

  return (
    <React.Fragment>
      {(!answerValues|| !qCode1 || !qCode2 || !qCode3) ? (<LoadingSpinner/>):
        (
          <Formik
            enableReinitialize
            innerRef={self=>setHasAnswerErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
            initialTouched={initialTouched}
            initialValues={answerValues}
            onSubmit={(values, {setSubmitting}) => {
              alert('Security Questions Update not available at this time.');
            }}
            validateOnMount
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              const {
                handleBlur,
                handleSubmit,
              } = formikProps;

              return (
                <Form onSubmit={handleSubmit}>
                  <div>
                    <Card>
                      <CardHeader
                        title="Security Questions"
                      />
                      <Divider/>
                      <CardContent>
                        <Grid
                          className={classes.content}
                          container
                          spacing={3}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              SelectProps={{native: true}}
                              fullWidth
                              label="Question One"
                              margin="dense"
                              name="question_one"
                              onBlur={handleBlur}
                              onChange={onHandleTextChange}
                              required = {required}
                              // eslint-disable-next-line react/jsx-sort-props
                              select
                              value={answerValues.question_one}
                              variant="outlined"
                            >
                              <option
                                key={0}
                                value={0}
                              >
                          Select One
                              </option>
                              {qCode1.map(option => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.question}
                                </option>
                              ))}
                            </TextField>
                            <ErrorMessage
                              className={classes.errorMessage}
                              component="div"
                              name="question_one"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Answer One"
                              margin="dense"
                              name="answer_one"
                              onBlur={handleBlur}
                              onChange={onHandleTextChange}
                              required = {required}
                              type="text"
                              value={answerValues.answer_one || ''}
                              variant="outlined"
                            />
                            <ErrorMessage
                              className={classes.errorMessage}
                              component="div"
                              name="answer_one"
                            />
                          </Grid>


                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              SelectProps={{native: true}}
                              fullWidth
                              label="Question Two"
                              margin="dense"
                              name="question_two"
                              onBlur={handleBlur}
                              onChange={onHandleTextChange}
                              required = {required}
                              // eslint-disable-next-line react/jsx-sort-props
                              select
                              value={answerValues.question_two || 0}
                              variant="outlined"
                            >
                              <option
                                key={0}
                                value={0}
                              >
                        Select One
                              </option>
                              {qCode2.map(option => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.question}
                                </option>
                              ))}
                            </TextField>
                            <ErrorMessage
                              className={classes.errorMessage}
                              component="div"
                              name="question_two"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Answer Two"
                              margin="dense"
                              name="answer_two"
                              onBlur={handleBlur}
                              onChange={onHandleTextChange}
                              required = {required}
                              type="text"
                              value={answerValues.answer_two || ''}
                              variant="outlined"
                            />
                            <ErrorMessage
                              className={classes.errorMessage}
                              component="div"
                              name="answer_two"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              SelectProps={{native: true}}
                              fullWidth
                              label="Question Three"
                              margin="dense"
                              name="question_three"
                              onBlur={handleBlur}
                              onChange={onHandleTextChange}
                              required = {required}
                              // eslint-disable-next-line react/jsx-sort-props
                              select
                              value={answerValues.question_three || 0}
                              variant="outlined"
                            >
                              <option
                                key={0}
                                value={0}
                              >
                          Select One
                              </option>
                              {qCode3.map(option => (
                                <option
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.question}
                                </option>
                              ))}
                            </TextField>
                            <ErrorMessage
                              className={classes.errorMessage}
                              component="div"
                              name="question_three"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              fullWidth
                              label="Answer Three"
                              margin="dense"
                              name="answer_three"
                              onBlur={handleBlur}
                              onChange={onHandleTextChange}
                              required = {required}
                              type="text"
                              value={answerValues.answer_three || ''}
                              variant="outlined"
                            />
                            <ErrorMessage
                              className={classes.errorMessage}
                              component="div"
                              name="answer_three"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
    </React.Fragment>
  )
}
export default UserSecurityQuestions;

