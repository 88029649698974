import {dashboardMetricsConstants} from '../../constants/index';

const waitTimeAfterSuccessInSeconds = 60;
const initialState = {requestPending: false, nextRequest: Date.now()};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardMetricsConstants.GET_DASHBOARD_METRICS_REQUEST:
      return {...state,
        requestPending: true
      };
    case dashboardMetricsConstants.GET_DASHBOARD_METRICS_SUCCESS:
      return {...state,
        metrics: action.metrics,
        requestPending: false,
        nextRequest: Date.now() + waitTimeAfterSuccessInSeconds * 1000
      };
    case dashboardMetricsConstants.GET_DASHBOARD_METRICS_FAILURE:
      return {...state,
        requestPending: false
      };
    default:
      return state
  }
}
