import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Tooltip
} from '@mui/material';
import {PersonAdd} from '@mui/icons-material';
import {default as OtherSharedUsersTable} from '../../../../SharedUser/SharedUserList/components/SharedUsersTable/SharedUsersTable';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 500
  },
  content: {
    minHeight: '100%',
    padding: 0,
  },
  inner: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: '100%',
    minHeight: 425
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  section: {
    height: 20,
    width: 100,
    color: theme.palette.white
  },
  button: {
    color: theme.palette.white,
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light

    }
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  titleHeader: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.white
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.white,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32,
    color: theme.palette.primary.main
  },
  action: {
    margin: 0
  }
}));

/*const statusColors = {
  public: palette.publicSection,
  company: palette.companySection,
  private: palette.privateSection
};*/

const YourSharedUsersTable = props => {
  const {className, memberGuid, companyId,
    handleAddSharedUsers, ...rest} = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.titleHeader}
        classes={{
          action: classes.action
        }}
        action={
          <Tooltip
            title="Add Shared Users"
            aria-label="Add Shared Users"
          >
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleAddSharedUsers}

              startIcon={<PersonAdd/>}
            >
              Add Shared User
            </Button>
          </Tooltip>
        }
        title="Your Shared Users"
      >
      </CardHeader>
      <Divider/>
      <OtherSharedUsersTable
        memberGuid={memberGuid}
        companyId={companyId}
        sharedDocsTooltip={"Add Documents to Share"}
      />
      <Divider/>
    </Card>
  );
};

YourSharedUsersTable.propTypes = {
  className: PropTypes.string
};

export default YourSharedUsersTable;
