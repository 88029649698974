import React from 'react';

import {GridContainer} from "../../../../components/Grid";
import FilterDropdown from "../../../Document/components/panelSegments/FilterDropdown";
import GuidBasedMenuItem from "../../../Document/components/panelSegments/components/GuidBasedMenuItem";

const UserSearchPanel = props => {
  const {
    classes,
    initialCompanies,
    companySelect,
    onHandleCompanySelect,
  } = props

  return(
    <GridContainer>
      <FilterDropdown
        classes={classes}
        optionNameDisplay={"Company"}
        optionNameDisplayPlural={"Companies"}
        optionNameInternal={"companySelect"}
        optionId={"company-select"}
        includeAllCompanies={"true"}
        initialOptions={initialCompanies}
        onHandleSelect={onHandleCompanySelect}
        menuItemListMethod = { GuidBasedMenuItem }
        optionSelect={companySelect}
      />
    </GridContainer>
  )
}

export default UserSearchPanel;
