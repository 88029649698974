import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {AppBar, Toolbar} from "@mui/material";
import clsx from "clsx";
import {Link as RouterLink} from "react-router-dom";
import Logo from '../../assets/images/logoTop.png';
import PageFooter from "../../components/PageFooter/PageFooter";
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    backgroundColor: theme.palette.white,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  content: {
    height: 'calc(100% - 60)',
    backgroundColor: theme.palette.background.default,
  },
  topBarRoot: {
    boxShadow: 'none',
    height: 100
  },
  logoImage: {
    height: 120,
    width: 120,
    marginLeft: 40
  }
}));

const Minimal = props => {
  const {className, children, ...rest} = props;

  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
        {/*<Topbar />*/}
        <AppBar
          {...rest}
          className={clsx(classes.topBarRoot, className)}
        >
          <Toolbar className={classes.topBarRoot}>
            <RouterLink to="/">
              <img
                alt="Maestro"
                className={classes.logoImage}
                src={Logo}
              />
            </RouterLink>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>{children}</main>
        <PageFooter/>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
