import React, {useState} from 'react'
import SectionDetails from '../component/SectionDetails';

const ViewSection = props => {
  let { section } = props.location.state.section;

  const [values] = useState({
    id: section.id,
    name: section.name
  });

  const onHandleSubmit = (values) => {
    return true;
  }

  return(
    <SectionDetails
      initialSection = {values}
      onHandleSubmit = {onHandleSubmit}
      submitButton = "View"
      title = "View Section"
    />

  )
}

export default ViewSection
