import React, {useCallback, useEffect, useState} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@mui/styles';
import {Card, CardContent, CardHeader, Divider, IconButton, Typography} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import RefreshIcon from '@mui/icons-material/Refresh';
import PublicIcon from '@mui/icons-material/Public';
import {dashboardService} from '../../../../store/services';
import {useSelector} from 'react-redux';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {history} from "../../../../helpers/history";
import {InteractiveBarChart} from "../../../../components";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";

ChartJS.register(...registerables);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  document: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  documentIcon: {
    color: theme.palette.icon
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  },
}));

const DocumentMetrics = props => {
  const {className, metrics: {documentMetrics}, view, ...rest} = props;
  const user = useSelector(state => state.authentication.user);
  const [documents, setDocuments] = useState(documentMetrics);
  const [documentCounts, setDocumentCounts] = useState(null);
  const [maxDocument, setMaxDocument] = useState(0);
  const [data, setData] = useState(null);
  const [dataIsBuilding, setDataIsBuilding] = useState(null);
  const [dataBuilt, setDataBuilt] = useState(false);
  const [labels] = useState(['Public', 'Company', 'Private']);
  const classes = useStyles();
  const theme = useTheme();
  const isUserLikeRole = user.role === SECURITY_ROLES.USER || user.role === SECURITY_ROLES.POWER_USER;

  const options = {
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      y: {
        min: 0,
        max: maxDocument > 5 ? maxDocument : 5,
        ticks: {
          beginAtZero: true,
          count: maxDocument > 5 ? null : 5,
          stepSize: maxDocument > 5 ? null : 1
        },
      },
    },
    tooltip: {
      enabled: true,
      mode: 'point',
      intersect: false,
      usePointStyle: true,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.primary.dark,
      titleFontColor: theme.palette.white,
    },
    minBarLength: 5,
    responsive: true,
    maintainAspectRatio: false,
  };

  if (!isUserLikeRole) {
    options.plugins.legend = true
  }

  const buildData = useCallback((documents) => {
    setDataIsBuilding(true);
    let documentTotals = isUserLikeRole ? documents : documents.documentTotalCounts;
    let datasets = isUserLikeRole ? [
      buildDatasetEntry(
        null,
        documentTotals,
        [theme.palette.publicSection, theme.palette.companySection, theme.palette.privateSection],
        theme.palette.white,
        theme.palette.white,
        8)
    ] : [
      buildDatasetEntry(
        'All Documents',
        documentTotals,
        theme.palette.allDocumentsSection,
        theme.palette.red2,
        theme.palette.white,
        1),
      buildDatasetEntry(
        'Accessible Documents',
        documents.documentUserCounts,
        theme.palette.accessibleSection,
        theme.palette.red2,
        theme.palette.white,
        1)
    ];

    let data = {
      datasets,
      labels
    };
    let documentCounts = [
      {
        title: 'Public',
        value: documentTotals.publicCount,
        icon: <PublicIcon style={{color: theme.palette.publicSection}}/>,
        color: theme.palette.publicSection
      },
      {
        title: 'Company',
        value: documentTotals.companyCount,
        icon: <BusinessIcon style={{color: theme.palette.companySection}}/>,
        color: theme.palette.companySection
      },
      {
        title: 'Private',
        value: documentTotals.privateCount,
        icon: <LockIcon style={{color: theme.palette.privateSection}}/>,
        color: theme.palette.privateSection
      }];
    let maxDocuments = Math.max.apply(Math, Object.values(documentTotals));

    setMaxDocument(maxDocuments);
    setDocumentCounts(documentCounts);
    setDataIsBuilding(false);
    setData(data);
    setDataBuilt(true);
  }, [theme, setDataIsBuilding, setDataBuilt, setDocumentCounts, setData, labels, isUserLikeRole])

  function buildDatasetEntry(label, data, backgroundColor, borderColor, hoverBorderColor, borderWidth) {
    return {
      label,
      data: [data.publicCount, data.companyCount, data.privateCount],
      backgroundColor,
      borderColor,
      borderWidth,
      hoverBorderColor
    }
  }

  async function resetData() {
    setData(null);
    setDataBuilt(false);
  }

  const onHandleBarClickEvent = (x) => {
    let pathname = '/documents';
    history.push({
      pathname: pathname,
      view: view,
      initialSection: labels[x],
      startDate: "01/01/2000"
    });
  }

  const handleRefresh = event => {
    getData().then(metrics => {
      setDocuments(metrics);
      resetData().then(r => {
        buildData(documents)
      })
    });
  };

  async function getData(days) {
    switch (user.role) {
      case SECURITY_ROLES.COMPANY_ADMIN:
        return await dashboardService.getDocumentCountsMetrics(user.companyId).then(r => {
          return r.data
        });
      case SECURITY_ROLES.CLOUD_ADMIN:
        return await dashboardService.getCloudAdminDocumentCountsMetrics(user.id).then(r => {
          return r.data
        });
      default:
        return await dashboardService.getUserDocumentCountsMetrics().then(r => {
          return r.data
        });
    }
  }

  useEffect(() => {
    if (!dataBuilt && !dataIsBuilding) {
      buildData(documents)
    }
  }, [dataBuilt, dataIsBuilding, buildData, documents])

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader className={classes.titleHeader}
                  action={
                    <IconButton
                      disabled={!dataBuilt}
                      onClick={handleRefresh}
                      size="small"
                    >
                      <RefreshIcon/>
                    </IconButton>
                  }
                  title="Document Metrics"
      />
      <Divider/>
      <CardContent>
        {data && documentCounts && !dataIsBuilding ? (
          <React.Fragment>
            <div className={classes.chartContainer}>
              <InteractiveBarChart
                data={data}
                options={options}
                onHandleBarClick={onHandleBarClickEvent}
              />
            </div>
            <div className={classes.stats}>
              {documentCounts.map(document => (
                <div
                  className={classes.document}
                  key={document.title}
                >
                  <span className={classes.documentIcon}>{document.icon}</span>
                  <Typography
                    style={{color: document.color}}
                    variant="h2"
                  >
                    {document.value}
                  </Typography>

                  <Typography
                    style={{color: document.color}}
                    variant="h6"
                  >{document.title}</Typography>

                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (<LoadingSpinner/>)}
      < /CardContent>
    </Card>
  );
};

DocumentMetrics.propTypes = {
  className: PropTypes.string
};

export default DocumentMetrics;
