/*
This helper function is used to get the value of environment specific configuration variables at run time.
This is useful when you want to use different configuration variables for different environments without rebuilding the UI.
It will first look for variables in the window.maestroenv object which can be declared at runtime in the /app/maestroenv.config.js file.
If the variable is not found, it will return the hard coded value that was injected at build time.
Instead of using process.env.REACT_APP_VARIABLE_NAME, you can use maestroenv.REACT_APP_VARIABLE_NAME to access the value.
*/

export const maestroenv = new Proxy(({}), {
  get(target, name) {
    return getEnv(name);
  }
});

function getEnv(name) {
  let theEnvValue = null;
  if ( typeof window.maestroenv === 'undefined' || window.maestroenv[name] == null) {
    if (process.env[name] == null) {
      console.log('Error: Environment variable ' + name + ' not found.');
    } else {
      theEnvValue = process.env[name];
    }
  } else {
    theEnvValue = window.maestroenv[name];
  }
  return theEnvValue;
}
