import React from 'react';
import {Redirect, Switch} from 'react-router-dom';
import {SECURITY_ROLES} from 'constants/SECURITY_ROLES';
import {RouteWithLayout, SecuredRouteWithLayout} from './components';
import {Blank as BlankLayout, Main as MainLayout, Minimal as MinimalLayout} from './layouts';

import {
  Account as AccountView,
  AddCategory as CategoryAddView,
  AddSection as SectionAddView,
  AddSharedUser as AddSharedUserView,
  AddUser as AddUserView,
  ApplicationSettings as ApplicationSettingsView,
  AsyncLoadingScreen as AsyncLoadingScreenView,
  ChangePassword as PasswordView,
  ConnectedApps as ConnectedAppsView,
  ConnectedAppSettings as ConnectedAppSettingsView,
  ConnectedAppsLink as ConnectedAppLinkView,
  Dashboard as DashboardView,
  EditCategory as CategoryEditView,
  EditSection as SectionEditView,
  Error as ErrorView,
  Icons as IconsView,
  ListCategory as CategoryListView,
  ListClassification as ClassificationListView,
  ListCompany as CompanyListView,
  ListDocument as DocumentListView,
  ListFiletype as FiletypeListView,
  ListSection as SectionListView,
  Login as LoginView,
  NotFound as NotFoundView,
  DocumentNotes as DocumentNotesView,
  NotificationSettings as NotificationSettingsView,
  OAuthCallback as OAuthCallbackView,
  ProductList as ProductListView,
  RoleSelect as RoleView,
  SelectDocumentToShare as DocumentToShareView,
  SharedUserList as SharedUserListView,
  SignIn as SignInView,
  SignUp as SignUpView,
  StagedDocument as DocumentStagedView,
  StatusNote as StatusNoteView,
  Typography as TypographyView,
  UploadDocument as DocumentUploadView,
  UserList as UserListView,
  ViewCategory as CategoryDetailsView,
  ViewCompany as CompanyDetailsView,
  ViewConnectedApps as ConnectedAppDetailsView,
  ViewSection as SectionDetailsView,
  ViewSharedUser as SharedUserView,
  ViewUser as UserView
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      {/* Secured Routes */}
      <SecuredRouteWithLayout
        component={CompanyDetailsView}
        exact
        layout={MainLayout}
        path="/company/details"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={CompanyListView}
        exact
        layout={MainLayout}
        path="/company"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={CategoryListView}
        exact
        layout={MainLayout}
        path="/category"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={CategoryAddView}
        exact
        layout={MainLayout}
        path="/category/add"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={CategoryDetailsView}
        exact
        layout={MainLayout}
        path="/category/details"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={CategoryEditView}
        exact
        layout={MainLayout}
        path="/category/edit"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={ClassificationListView}
        exact
        layout={MainLayout}
        path="/classification"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={RoleView}
        exact
        layout={BlankLayout}
        path="/role"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DocumentListView}
        exact
        layout={MainLayout}
        path="/documents"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DocumentStagedView}
        exact
        layout={MainLayout}
        path="/documents/staged"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN,SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      {/*<SecuredRouteWithLayout*/}
      {/*  component={DocumentNotesView}*/}
      {/*  exact*/}
      {/*  layout={MainLayout}*/}
      {/*  path="/documents/notes"*/}
      {/*  roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN,SECURITY_ROLES.ANY_AUTHORIZED_USER]}*/}
      {/*/>*/}
      <SecuredRouteWithLayout
        component={StatusNoteView}
        exact
        layout={MainLayout}
        path="/documents/statusnote"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DocumentToShareView}
        exact
        layout={MainLayout}
        path="/documents/toshare"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DocumentUploadView}
        exact
        type={[ "document" ]}
        refresh={"false"}
        layout={MainLayout}
        path="/documents/upload/documents"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DocumentUploadView}
        exact
        type={[ "image", "drawing" ]}
        refresh={"false"}
        layout={MainLayout}
        path="/documents/upload/image"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={DocumentUploadView}
        exact
        type={[ "audio", "video" ]}
        refresh={"true"}
        layout={MainLayout}
        path="/documents/upload/video"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={FiletypeListView}
        exact
        layout={MainLayout}
        path="/filetype"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={PasswordView}
        exact
        layout={MainLayout}
        path="/password"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={SectionListView}
        exact
        layout={MainLayout}
        path="/section"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={SectionAddView}
        exact
        layout={MainLayout}
        path="/section/add"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={SectionDetailsView}
        exact
        layout={MainLayout}
        path="/section/details"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={ConnectedAppDetailsView}
        exact
        layout={MainLayout}
        path="/settings/connectedapps/details"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={ConnectedAppLinkView}
        exact
        layout={MainLayout}
        path="/connectedapps/link"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={SectionEditView}
        exact
        layout={MainLayout}
        path="/section/edit"
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        component={AddSharedUserView}
        exact
        layout={MainLayout}
        path="/sharedusers/add"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN,SECURITY_ROLES.ANY_AUTHORIZED_USER]}
      />
      <SecuredRouteWithLayout
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
        component={SharedUserListView}
        exact
        layout={MainLayout}
        path="/sharedusers/list"
      />
      <SecuredRouteWithLayout
        roles={[SECURITY_ROLES.ANY_AUTHORIZED_USER]}
        component={SharedUserView}
        exact
        layout={MainLayout}
        path="/sharedusers/view"
      />
      <SecuredRouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={AddUserView}
        exact
        layout={MainLayout}
        path="/users/add"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={UserView}
        exact
        layout={MainLayout}
        path="/users/view"
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN]}
      />
      <SecuredRouteWithLayout
        component={ConnectedAppsView}
        exact
        layout={MainLayout}
        path={"/apps"}
        roles={[SECURITY_ROLES.CLOUD_ADMIN, SECURITY_ROLES.COMPANY_ADMIN, SECURITY_ROLES.USER, SECURITY_ROLES.POWER_USER]}
      />
      {/* Non-Secured Routes*/}

      <RouteWithLayout
        component={OAuthCallbackView}
        exact
        layout={BlankLayout}
        path="/oauth2/callback"
      />
      <RouteWithLayout
        component={AsyncLoadingScreenView}
        exact
        layout={BlankLayout}
        path="/loading"
      />
      <RouteWithLayout
        component={LoginView}
        exact
        layout={BlankLayout}
        path="/login"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={NotificationSettingsView}
        exact
        layout={MainLayout}
        path="/settings/notification"
      />
      <RouteWithLayout
        component={ApplicationSettingsView}
        exact
        layout={MainLayout}
        path="/settings/application"
      />
      <RouteWithLayout
        component={ConnectedAppSettingsView}
        exact
        layout={MainLayout}
        path="/settings/connectedapp"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={ErrorView}
        exact
        layout={MinimalLayout}
        path="/error"
      />
      <Redirect to="/not-found"/>
    </Switch>
  );
};

export default Routes;
