import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import PageFooter from '../../components/PageFooter/PageFooter';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: 'calc(100% - 40)'
  },
  content: {
    height: 'calc(100% -40)'
  }
}));

const Blank = props => {
  const {children} = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <main className={classes.content}>{children}</main>
      <PageFooter/>
    </div>
  );
};

Blank.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Blank;
