import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";

const SingleLevel = ({ classes, item, customRouterLink }) => {
  return (
    <ListItem
      className={classes.button}
      component={customRouterLink}
      to={item.href}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

export default SingleLevel;
