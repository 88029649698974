import React, {useState} from 'react'
import {useDispatch} from 'react-redux';

import {sectionActions} from '../../../store/actions';
import SectionDetails from '../component/SectionDetails';

const EditSection = props => {
  let { section } = props.location.state.section;

  const [values] = useState({
    id: section.id,
    prevname: section.name,
    name: section.name
  });

  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    dispatch(sectionActions.update(values))
    return true;
  }

  return(
    <SectionDetails
      initialSection = {values}
      onHandleSubmit = {onHandleSubmit}
      submitButton = "Update"
      title = "Edit Section"
    />

  )
}

export default EditSection;
