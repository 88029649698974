import React from "react";
import MenuItem from "@mui/material/MenuItem";

const MenuItemContainer = props => {
  const {
    classes,
    optionKey,
    optionValue,
    optionDisplay
  } = props;

  return (<MenuItem
    classes={{
      root: classes.selectMenuItem,
      selected: classes.selectMenuItemSelected
    }}
    key={optionKey}
    value={optionValue}
  >
    {optionDisplay}
  </MenuItem>);
}

export default MenuItemContainer;
