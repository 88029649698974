import {GridItem} from "../../../../../components/Grid";
import DialogContentText from "@mui/material/DialogContentText";
import ProgressBar from "../../../../../components/ProgressBar/ProgressBar";
import {Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {vodService} from "../../../../../store/services";
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";

/**
 *
 * @param {{progressPromise: Promise}} props
 * @return {JSX.Element}
 * @constructor
 */
const VodProgressView = (props) => {
  const {
    progressPromise
  } = props;

  const [progressStatus, setProgressStatus] = useState(undefined)
  const [progressPending, setProgressPending] = useState(false);

  const updateStatusAfterWait = async () => {
    if (!progressPromise){
      return;
    }
    if (!progressStatus || progressStatus.finished !== true) {
      setProgressPending(true);
      const progressUrl = (await progressPromise).progress;
      const waitTime = (progressStatus ? progressStatus.next - Date.now() : 0);
      const timeoutMethod = resolve => setTimeout(resolve, waitTime);
      await Promise.resolve(new Promise(timeoutMethod));
      const progress = await vodService.getVoDUploadProgress(progressUrl);
      setProgressStatus(progress);
      setProgressPending(false);
    }
  }

  const updateStatusAfterWaitCallback = useCallback(updateStatusAfterWait, [progressStatus, progressPromise]);

  useEffect(() => {
    if (progressPending === false) {
      updateStatusAfterWaitCallback().then(/*Do Nothing*/);
    }
  }, [progressPromise, progressStatus, progressPending, updateStatusAfterWaitCallback])

  if (!progressStatus)
    return (<LoadingSpinner
      title="Beginning upload. Please be patient."
    />);

  return (<Grid>
      <GridItem>
        <DialogContentText>
          Upload Progress
        </DialogContentText>
        <ProgressBar
          currentProgress={progressStatus.uploading.progress}
          fullProgress={progressStatus.uploading.out_of}
          error={progressStatus.message}
        />
      </GridItem>
      <GridItem>
        <DialogContentText>
          Processing Progress
        </DialogContentText>
        <ProgressBar
          currentProgress={progressStatus.processing.progress}
          fullProgress={progressStatus.processing.out_of}
          error={progressStatus.message}
        />
      </GridItem>
      <GridItem>
        <DialogContentText>
          Saving Progress
        </DialogContentText>
        <ProgressBar
          currentProgress={progressStatus.saving.progress}
          fullProgress={progressStatus.saving.out_of}
          error={progressStatus.message}
        />
      </GridItem>
    </Grid>
  );
}

export default VodProgressView;
