export const classificationConstants = {
  ADDNEW_REQUEST: 'CLASSIFICATIONS_ADDNEW_REQUEST',
  ADDNEW_SUCCESS: 'CLASSIFICATIONS_ADDNEW_SUCCESS',
  ADDNEW_FAILURE: 'CLASSIFICATIONS_ADDNEW_FAILURE',

  DELETE_REQUEST: 'CLASSIFICATIONS_DELETE_REQUEST',
  DELETE_SUCCESS: 'CLASSIFICATIONS_DELETE_SUCCESS',
  DELETE_FAILURE: 'CLASSIFICATIONS_DELETE_FAILURE',

  GETALL_REQUEST: 'CLASSIFICATIONS_GETALL_REQUEST',
  GETALL_SUCCESS: 'CLASSIFICATIONS_GETALL_SUCCESS',
  GETALL_FAILURE: 'CLASSIFICATIONS_GETALL_FAILURE',

  UPDATE_REQUEST: 'CLASSIFICATIONS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CLASSIFICATIONS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CLASSIFICATIONS_UPDATE_FAILURE',

};
