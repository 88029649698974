import React, {useState} from 'react'
import * as Yup from 'yup';
import {ErrorMessage, Form, Formik} from 'formik';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid,} from '@mui/material'

import {history} from '../../../../helpers/history';

import {DarkerDisabledTextField, useStyles} from './styles';

const CategoryDetails = props => {
  const {
    title,
    initialCategory,
    onHandleSubmit,
    submitButton
  } = props

  let open = true;
  let required = false;
  let disabled = true;
  if (submitButton !== 'View'){
    required = true;
    disabled = false;
  }

  const classes = useStyles();
  const [values] = useState(initialCategory);

  return(
    <Formik
      initialValues={values}
      onReset= {() => {
        history.push('/category');
      }}
      onSubmit={(values, {setSubmitting, setValues, setErrors, setFieldError}) => {
        setSubmitting(true);
        if (onHandleSubmit(values)) {
          setSubmitting(false);
          if (submitButton === 'Add') {
            setValues(initialCategory);
          } else {
            history.push('/category');
          }
        } else {
          setErrors('Category update not successful');
          setFieldError('name', 'Category already on file');
        }
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .trim()
          .min(3)
          .when('$required', (required, requireSchema) => required
            ? requireSchema
            : requireSchema).required('Category Name is Required'),

      })}
    >
      {(props) => {
        const {
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit
        } = props;

        return (
          <Form onSubmit={handleSubmit}>
            <Dialog
              aria-labelledby="form-dialog-title"
              onClose={handleReset}
              open={open}
            >
              <DialogTitle id="form-dialog-title">{ title }</DialogTitle>
              <DialogContent>
                {/*<DialogContentText>Add Category</DialogContentText>*/}
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <DarkerDisabledTextField
                      autoFocus
                      disabled ={disabled}
                      fullWidth
                      label="Category Name"
                      margin="dense"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required = {required}
                      type="text"
                      value={values.name || ''}
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="name"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleReset}
                >
                  Cancel
                </Button>
                {disabled
                  ? null
                  : (<Button
                    onClick={handleSubmit}
                      color="primary"
                      disabled={isSubmitting}
                     >
                    {submitButton}
                  </Button>)
                }
              </DialogActions>
              {/*<pre>{JSON.stringify(props, null, 2)}</pre>*/}
            </Dialog>
          </Form>
        )}
      }

    </Formik>
  )
}

export default CategoryDetails;
