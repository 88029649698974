import React from 'react';
import {makeStyles} from '@mui/styles';
import {useSelector} from 'react-redux';
import {SECURITY_ROLES} from '../../constants/SECURITY_ROLES';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';
import CompanyAdminDashboard from './CompanyAdminDashboard';
import SharedUserDashboard from './SharedUserDashboard';
import LoadingSpinner from "../../components/Spinner/loading.spinner";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  }
}));


const Dashboard = () => {
  const classes = useStyles();
  const user = useSelector(state => state.authentication.user);

  return (
    <div className={classes.root}>

      {user && user.role ? (
        <React.Fragment>
          { user.role === SECURITY_ROLES.COMPANY_ADMIN &&

            <CompanyAdminDashboard/>
          }
          {
            user.role === SECURITY_ROLES.CLOUD_ADMIN &&
              <AdminDashboard/>
          }
          {
            user.role === SECURITY_ROLES.USER &&
            <UserDashboard/>
          }
          {
            user.role === SECURITY_ROLES.POWER_USER &&
            <UserDashboard/>
          }
          {
            user.role === SECURITY_ROLES.SHARED_USER &&
            <SharedUserDashboard/>
          }


        </React.Fragment>)
        :
        (<LoadingSpinner/>)
      }
    </div>
  );
};

export default Dashboard;
