import {settingConstants} from '../../constants/index';

const initialState = {};

export function settings(state = initialState, action) {
  switch (action.type) {
    case settingConstants.ACTIVATE_REQUEST:
      return {...state,
        requestPending: true
      };
    case settingConstants.ACTIVATE_SUCCESS:
      return {
        ...state,
        returncode: action.data,
        requestPending: false
      };
    case settingConstants.ACTIVATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case settingConstants.ADDNEW_REQUEST:
      return {...state,
        requestPending: true
      };
    case settingConstants.ADDNEW_SUCCESS:
      return {
        ...state,
        id: action.data,
        requestPending: false
      };
    case settingConstants.ADDNEW_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case settingConstants.DEACTIVATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case settingConstants.DEACTIVATE_SUCCESS:
      return {
        ...state,
        returncode: action.data,
        requestPending: false
      };
    case settingConstants.DEACTIVATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case settingConstants.GETALL_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case settingConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.settings.data,
        requestPending: false
      };
    case settingConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case settingConstants.GET_SETTING_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case settingConstants.GET_SETTING_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case settingConstants.GET_SETTING_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case settingConstants.GET_CONNECTED_APPS_LOCATION_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case settingConstants.GET_CONNECTED_APPS_LOCATION_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case settingConstants.GET_CONNECTED_APPS_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case settingConstants.UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case settingConstants.UPDATE_SUCCESS:
      return {
        ...state,
        item: action.setting.data,
        requestPending: false
      };
    case settingConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
