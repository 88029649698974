export const requiredUserProfileInputFields =
  [
    'firstName',
    'lastName',
    'email',
    'phone',
    'state',
    'zip',
    'street',
    'city'
  ];
