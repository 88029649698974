import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import MaterialTable from '@material-table/core';
import {Avatar, Typography} from '@mui/material';

// @mui/icons-material
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {companyActions} from '../../../store/actions';

import {history} from '../../../helpers/history';
import moment from 'moment';
import PageHeader from '../../../components/PageHeader/PageHeader';
import {makeStyles} from '@mui/styles';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {green} from "@mui/material/colors";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {getInitials} from "../../../helpers";
import theme from "../../../theme";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {EmptyList} from "../../../components/EmptyList";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: 0
  }
}));

const ListCompany = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies.items);
  const parentList = useSelector(state => state.companies.parents);

  const [initialRender, setInitialRender] = useState(false);
  let company = {
    id: 0,
    name: '',
    logoname: '',
    guid: '',
    parentguid: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    registercode: '',
    active: 0,
    deactivatedate: '',
    datecreated: '',
    lastname: '',
    firstname: '',
    cStreet: '',
    cCity: '',
    cState: '',
    cZipcode: '',
    cEmail: '',
    cPhone: ''
  };

  const DatetoString = (dateString) => {
    var dateObj = new Date(dateString);
    var momentObj = moment(dateObj);
    return (momentObj.format('MM/DD/YYYY'));
  }

  const getCompanyDetails = (viewType, rowData) => {
    getClickedCompany(rowData.guid);
    history.push({
      pathname: '/company/details',
      state: {
        company: company,
        companies: parentList,
        submit: viewType
      }
    });
  }

  const handleClickedEdit = (rowData) => {
    getCompanyDetails("Edit", rowData);
  }

  const handleClickedView = (rowData) => {
    getCompanyDetails("View", rowData);
  }

  const handleClickedAdd = () => {
    company = {
      id: 0,
      name: '',
      logoname: '',
      guid: '',
      parentguid: '',
      street: '',
      city: '',
      state: '',
      zipcode: '',
      registercode: '',
      active: 1,
      deactivatedate: null,
      datecreated: null,
      lastname: '',
      firstname: '',
      cStreet:'',
      cCity: '',
      cState: '',
      cZipcode:'',
      cEmail: '',
      cPhone: ''
    };
    history.push({
      pathname: '/company/details',
      state: {
        company: company,
        companies: parentList,
        submit: "Add"
      }
    });
  }

  const getClickedCompany = (id) => {
    let obj = companies.find((d) => (d.guid === id));
    company = {
      id: obj.guid,
      name: obj.name,
      guid: obj.guid,
      parentguid: obj.parent_company_guid,
      street: obj.street,
      city: obj.city,
      state: obj.state,
      zipcode: obj.zip_code,
      registercode: obj.register_code,
      logoname: obj.logo_name,
      deactivatedate: DatetoString(obj.deactivate_date),
      datecreated: DatetoString(obj.date_created),
      active: obj.active,
      firstname: obj.first_name,
      lastname: obj.last_name,
      cStreet: obj.cStreet,
      cCity: obj.cCity,
      cState: obj.cState,
      cZipcode: obj.cZip_code,
      cPhone: obj.cPhone,
      cEmail: obj.cEmail
    };
  }

  let actions = null;
    actions = [
      {
        tooltip: 'Remove All Selected Companies',
        icon: 'delete',
        onClick: (evt, data) => {
          console.log('You want to delete ' + data.length + ' rows');
        }
      },
      {
        icon: () => <AddOutlinedIcon/>,
        tooltip: 'Add Company',
        isFreeAction: true,
        onClick: (event) => handleClickedAdd()
      },
      {
        icon: () => <VisibilityOutlinedIcon/>,
        tooltip: 'View',
        onClick: (evt, rowData) => {

          handleClickedView(rowData);
        },
        position: 'row'
      },
      {
        icon: () => <EditOutlinedIcon/>,
        tooltip: 'Edit',
        onClick: (evt, rowData) => {
          handleClickedEdit(rowData)
        },
        position: 'row'
      }
    ]

  const renderCompanyIcon = useCallback((company) => {
    return (<div className={classes.nameContainer}
         style={{
           display: 'flex',
           alignItems: 'center'
         }}>
      <Avatar
        style={{
          backgroundColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
          marginRight: 20
        }}
        src={company.avatarUrl}
      >
        {getInitials(company.name)}
      </Avatar>
      <Typography
        variant="body1">{company.name}
      </Typography>
    </div>)}, [classes]);

  const renderActive = useCallback((company) => {
    return company.active ?
      (<CheckCircleIcon style={{color: green[500]}}/>) :
      (<RemoveCircleRoundedIcon color={'error'}/>);
  }, []);

  useEffect(() => {
    dispatch(companyActions.getAllCompanies());
    dispatch(companyActions.getParentCompanyList());
    setInitialRender(true);
  }, [dispatch, initialRender]);

  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <PageHeader
          subTitle={'List of All Companies'}
          title={'Company'}
        />
        {!companies ? (<LoadingSpinner/>) :
          (
            <Card className={classes.content}>
              <CardBody style={{backgroundColor: '#FFF', padding: 0}}>
                <MaterialTable
                  localization={{
                    body: {
                      emptyDataSourceMessage: (
                        <EmptyList
                          label="No Companies to Display!"
                        />
                      ),
                    },
                  }}
                  style={{padding: theme.spacing(2)}}
                  options={{
                    emptyRowsWhenPaging: false,
                    pageSize: 20,
                    selection: true,
                    searchFieldAlignment: 'left',
                    actionsColumnIndex: -1
                  }}
                  actions={actions}
                  columns={[
                    {
                      field: 'name', title: "Company", render: renderCompanyIcon
                    },
                    {
                      title: 'Parent Company',
                      field: 'parent_name'
                    },
                    {
                      title: 'Contact First Name',
                      field: 'first_name'
                    },
                    {
                      title: 'Contact Last Name',
                      field: 'last_name'
                    },
                    {
                      title: 'Register Code',
                      field: 'register_code'
                    },
                    {
                      title: 'Date Created',
                      field: 'date_created',
                      type: 'date',
                      dateSetting: {
                        format: 'MM/dd/yyyy'
                      },
                    },
                    {
                      field: 'active', title: "Active",
                      render: renderActive
                    }
                  ]}
                  data={companies}
                  title=""
                />
              </CardBody>
            </Card>
          )}
      </div>
    </div>
  )
}

export default ListCompany;
