import {requestHelper} from '../helpers/index';
import axiosInstance from '../../axios.instance';
import { maestroenv } from '../../helpers/maestroenv';

export const sectionService = {
  getAllSections
};

function getAllSections() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/section/`)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
