import React from 'react';

import moment from 'moment';
import {documentService} from '../../../store/services';
import FileListWindow from "../components/FileListWindow";
import {DOCUMENT_VIEWER_MODES} from "../../../constants/DOCUMENT_VIEWER_MODES";
import {documentActions} from "../../../store/actions";

const SelectDocumentToShare = (props) => {
  const {
    sharedUserGuid
  } = props.location;

  return (<FileListWindow
    title={'Documents to Share'}
    subtitle={'Available Documents'}
    includeSectionFilter
    includeCompanyFilter
    includeClassificationFilter
    includeCategoryFilter
    includeBeginDate
    initialSection={'All Sections'}
    initialBeginDate={new moment().subtract(90, 'days')}
    includeEndDate
    initialEndDate={new moment()}
    includeFilename
    sharedUserGuid={sharedUserGuid}
    viewer={DOCUMENT_VIEWER_MODES.SELECT}
    getDocumentFromDocumentServiceMethod={(id) => documentService.getDocumentById(id)}
    searchDocumentFromDocumentServiceMethod={(searchFields) => documentActions.searchDocuments(searchFields)}
  />);
}

export default SelectDocumentToShare;
