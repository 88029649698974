import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {Divider, Drawer} from '@mui/material';

import {Profile, SidebarNav} from './components';
import {useSelector} from 'react-redux';
import {SECURITY_ROLES} from '../../../../constants/SECURITY_ROLES';
import {adminPages} from './components/SidebarPages/adminPages';
import {memberPages} from './components/SidebarPages/memberPages';
import {nonMemberPages} from './components/SidebarPages/nonMemberPages';

const useStyles = makeStyles(theme => ({
  drawer: {
    position: "fixed",
    width: theme.overlayProportions.sidebarWidth,
    [theme.breakpoints.up('lg')]: {
      top: theme.overlayProportions.topbarHeight,
      height: `calc(100vh - ${theme.overlayProportions.topbarHeight} - ${theme.overlayProportions.footerHeight})`
    },
    display: 'flex',
    flexDirection: 'column',
    borderRightColor: '#bcbcbc'
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    position: "relative",
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;
  const { user } = useSelector(state => state.authentication);

  const classes = useStyles();

  let pages = [];

  if (user && user.role) {
    if (user.role !== SECURITY_ROLES.SHARED_USER) {
      pages = pages.concat(memberPages);
    } else {
      pages = pages.concat(nonMemberPages);
    }
    if (user.role === SECURITY_ROLES.CLOUD_ADMIN || user.role === SECURITY_ROLES.COMPANY_ADMIN) {
      pages = pages.concat(adminPages);
    }
  }

  return (
    <Drawer
      anchor="left"
      classes={{ root: classes.drawer, paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
