import 'date-fns';
import React from 'react';

// @mui/icons-material
// @mui/material components
import FilterListIcon from '@mui/icons-material/FilterList';
import {GridItem} from '../../../../components/Grid';
import Button from '../../../../components/CustomButtons/Button';
import Grid from '@mui/material/Grid';
import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import {TextField, Typography} from '@mui/material';
import {isEmpty} from 'underscore';

const CheckEmailPanel = props => {
  const {
    classes,
    emailSelect,
    userFoundMessage,
    handleFilterClick,
    handleEmailSelect
  } = props

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Invalid Email')
      .required('Email is Required')
  })

  return (
    <Formik
      enableReinitialize
      initialTouched={'email'}
      initialValues={emailSelect}
      onSubmit={(values) => {
      }}
      validateOnMount
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          touched,
          errors
        } = formikProps;

        const disabled = !isEmpty(touched) && !isEmpty(errors);

        return (
          <Grid container>
            <Grid
              item
              sm={12}
            >
              <Grid container>
                <GridItem
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    onChange={handleEmailSelect}
                    onBlur={handleEmailSelect}
                    type="email"
                    value={emailSelect.email}
                    variant="outlined"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    component="div"
                    name="email"
                  />
                </GridItem>

              </Grid>
              {(isEmpty(userFoundMessage)) ? ('') :(
                <GridItem
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <div>
                    <Typography
                      style={{color: '#FF0000', textAlign: 'bottom'}}
                      variant={'h6'}
                    >{userFoundMessage}</Typography>
                  </div>

                </GridItem>
              )}
            </Grid>
            <GridItem
              style={{width: '100%'}}
              xs={12}
            >
              <Button
                color={'facebook'}
                onClick={handleFilterClick}
                disabled={disabled}
              >
                <FilterListIcon /> Check Email
              </Button>
            </GridItem>
            {/*<DisplayFormikState {...formikProps} />*/}
          </Grid>
        )
      }}
    </Formik>
  )
}

export default CheckEmailPanel;
