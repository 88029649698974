export const categoryConstants = {
  ADDNEW_REQUEST: 'CATEGORIES_ADDNEW_REQUEST',
  ADDNEW_SUCCESS: 'CATEGORIES_ADDNEW_SUCCESS',
  ADDNEW_FAILURE: 'CATEGORIES_ADDNEW_FAILURE',

  DELETE_REQUEST: 'CATEGORIES_DELETE_REQUEST',
  DELETE_SUCCESS: 'CATEGORIES_DELETE_SUCCESS',
  DELETE_FAILURE: 'CATEGORIES_DELETE_FAILURE',

  GETALL_REQUEST: 'CATEGORIES_GETALL_REQUEST',
  GETALL_SUCCESS: 'CATEGORIES_GETALL_SUCCESS',
  GETALL_FAILURE: 'CATEGORIES_GETALL_FAILURE',

  UPDATE_REQUEST: 'CATEGORIES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CATEGORIES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CATEGORIES_UPDATE_FAILURE',

};
