import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 100,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: theme.palette.white
  },
  avatarIcon: {
    height: 100,
    width: 100,
    color: theme.palette.tertiary.main,

  },
  roleText: {
    color: theme.palette.tertiary.main,
    fontWeight: 800
  },
  locationText: {
    color: theme.palette.secondary.main
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  changePasswordButton: {
    marginRight: theme.spacing(2)
  },
}));
