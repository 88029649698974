import React, {useState} from 'react';
import {ButtonBase, Card, CardContent, Divider, Grid, Typography} from '@mui/material';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {makeStyles} from "@mui/styles";
import hexRgb from "hex-rgb";

const useStyles = makeStyles(theme => ({
    root: {
      margin: 10,
      padding: theme.spacing(3)
    },
    cardRoot: {
      margin: 20,
      display: 'flex',
      minHeight: 350,
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    cardContent: {
      flex: '1 0 auto',
      overflowWrap: "break-word"
    },
    cover: {
      width: '100%',
      maxWidth: 100,
      height: 'auto'
    },
    controls: {
      display: 'flex',
      alignItems: 'right',
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: 100,
      width: '100%',
    },
    connectedAppImage: {
      maxWidth: 70,
      width: 50,
      height: 'auto'
    },
    appTitle: {
      fontWeight: 800,
      color: theme.palette.textColor,
      paddingTop: 5,
      paddingBottom: 5
    },
    image: {
      position: 'relative',
      minWidth: 100,
      height: 'auto',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important', // Overrides inline-style
        height: 'auto',
      },
      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.5,
          filter: `saturate(var(--value, 2))`,
          webkitFilter: `saturate(var(--value, 2))`
        },
        '& $connectedAppImage': {
          width: 60
        },
        '& $imageMarked': {
          opacity: 0,
        },
        '& $imageTitle': {
          padding: 5,
          border: '5px solid currentColor',
          borderRadius: 10
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    captionText: {
      padding: 10
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0.5,
      transition: theme.transitions.create('opacity'),
    },
    iconImageDiv: {
      width: '100%',
      alignContent: 'center'
    },
    iconText: {
      minWidth: 48,
      fontSize: 48
    },
    iconImageDivReverse: {
      width: '100%',
      alignContent: 'center',
      filter: `invert(1)`,
      webkitFilter: `invert(1)`
    },
    imageTitle: {
      maxWidth: 90,
      position: 'relative',
      padding: 5,
      border: '2px solid white',
      borderRadius: 10
    },
    imageMarked: {
      height: 1,
      width: 40,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 20px)',
      transition: theme.transitions.create('opacity'),
    }
  }))
;

const ConnectedApp = props => {
    const {connectedApp} = props;

    const classes = useStyles();
    const [hover, setHover] = useState(false);
    const [initialRender, setInitialRender] = useState(true);
    const handleMouseEnter = () => {
      setHover(true);
    }
    const handleMouseLeave = () => {
      setHover(false);
    }

    if (initialRender) {
      setInitialRender(false);
    }


    return (
      <React.Fragment>
        {connectedApp &&
        <Card className={classes.cardRoot}>
          <ButtonBase
            onClick={() => window.open(connectedApp.link, '_blank')}
            focusRipple

            key={connectedApp.title}
            className={classes.image}
            focusVisibleClassName={classes.focusVisible}
            style={{
              width: 150,
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <span className={classes.imageBackdrop} style={{backgroundColor: connectedApp.bgColor}}/>

            <span className={classes.imageButton}>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                className={classes.imageTitle}
              >
                <div className={classes.iconImageDiv}>
                  <FontAwesomeIcon className={classes.iconText} icon={Icons[connectedApp.iconFile]}/>
                </div>
                <span className={classes.imageMarked}/>
              </Typography>
            </span>
          </ButtonBase>
          <div className={classes.details} style={{
            ...({borderLeft: `15px solid ${hexRgb(connectedApp.bgColor + 'cc', {format: 'css', alpha: 0.25})}`}),
            ...(hover && {borderLeft: `15px solid ${hexRgb(connectedApp.bgColor + 'cc', {format: 'css', alpha: 0.5})}`}),
          }}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h4" className={classes.appTitle}>
                {connectedApp.name.toUpperCase()}
              </Typography>
              <Divider/>
              <Typography
                className={classes.captionText}
                variant="body2"
              >
                {connectedApp.description}
              </Typography>
            </CardContent>
          </div>
        </Card>
        }

      </React.Fragment>);
  }
;

export default ConnectedApp;
