import React from 'react';
import {makeStyles} from '@mui/styles';
import {Button, Grid, Typography} from '@mui/material';
import ErrorPage from '../../assets/images/errorPage.jpg';
import {history} from '../../helpers/history';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    height: '100%',
    backgroundColor: theme.palette.white,
  },
  content: {
    height: '100%',
    marginTop: 50,
    textAlign: 'center'
  },
  image: {
    marginTop: 20,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const Error = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justifyContent="center"
        spacing={4}
      >
        <Grid
          item
          lg={6}
          xs={12}
        >
          <div className={classes.content}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h1" style={{padding:10}}>
                  An Error Has Occurred!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={() => history.push('/')} variant="contained" color="secondary">
                  Get Me Out Of Here!
                </Button>
              </Grid>
            </Grid>
            <img
              alt="Under development"
              className={classes.image}
              src={ErrorPage}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Error;
