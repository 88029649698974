import {filetypeConstants} from '../../constants/index';

const initialState = {};

export function filetypes(state = initialState, action) {
  switch (action.type) {
    case filetypeConstants.ADDNEW_REQUEST:
      return {...state,
        requestPending: true
      };
    case filetypeConstants.ADDNEW_SUCCESS:
      return {
        ...state,
        id: action.data,
        requestPending: false
      };
    case filetypeConstants.ADDNEW_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case filetypeConstants.GETALL_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case filetypeConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.data,
        requestPending: false
      };
    case filetypeConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case filetypeConstants.GET_CATEGORY_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case filetypeConstants.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case filetypeConstants.GET_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case filetypeConstants.UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case filetypeConstants.UPDATE_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case filetypeConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
