import {Tab, Tabs} from "@mui/material";
import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Close} from "@mui/icons-material";
import TabPanel from "./TabPanel";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => (
  {
    form: {}
  })
);

/**
 *
 * @param {{
 *   label: string,
 *   indicatorColor: "primary" | "secondary",
 *   scrollButtons: bool | "auto",
 *   textColor: "primary" | "secondary" | "inherit",
 *   variant: "standard" | "scrollable" | "fullWidth",
 *   defaultTabName: string,
 *   defaultTabPanel: JSX.Element,
 *   runOnTabChange: function(int),
 *   runOnTabClose: function(int)
 * }} props
 * @param {MutableRefObject<{}>} ref
 * @return {JSX.Element}
 */
function TabList(props, ref){
  const {
    label,
    indicatorColor,
    scrollButtons,
    textColor,
    variant,
    defaultTabName,
    defaultTabPanel,
    runOnTabChange=(newValue)=>{},
    runOnTabClose=(deleteId)=>{}
  } = props;

  const classes = useStyles();
  const [tabs, setTabs] = useState({list: [], value: 0});

  if (!ref){
    console.error("Please set \"ref\"");
    return (<></>)
  }

  ref.current = {
    currentTabValue: tabs.value,
    tabList: tabs.list,
    addTab: handleAddTab,
    changeTab: handleTabChange,
    closeTab: handleTabClose
  }

  function handleAddTab(tabLabel, tabPanel){
    setTabs(
      prevState => addTabToListInSet(
        prevState,
        tabLabel,
        tabPanel)
    );
  }

  function addTabToListInSet(prevState, tabLabel, tabPanel) {
    const tabId = ((prevState.list.length === 0) ?
      (1) :
      ((prevState.list[prevState.list.length - 1].id) + 1));
    const newTab = {
      key: tabId,
      id: tabId,
      value: 'tab' + tabId,
      label: tabLabel(tabId),
      panel: tabPanel(tabId)
    }
    return {
      list: [...prevState.list, newTab],
      value: tabId
    }
  }

  function handleTabChangeEvent(event, newValue){
    if (event.isDefaultPrevented()) return;
    handleTabChange(newValue)
  }

  function handleTabChange(newValue){
    setTabs(prevState => {return {list: prevState.list, value: newValue}});
    runOnTabChange(newValue);
  }

  function handleTabCloseEvent(event, deleteId) {
    event.preventDefault();
    handleTabClose(deleteId);
  }

  function handleTabClose(deleteId) {
    let tabID = parseInt(deleteId)
    let tabIDIndex = 0;

    let newTabList = tabs.list.filter((tabValue, index) => {
      if (tabValue.id === tabID) {
        tabIDIndex = index;
      }

      return tabValue.id !== tabID;
    });

    let curValue = tabs.value;
    if (curValue === tabID) {
      if (tabIDIndex === 0) {
        curValue = 0
      } else {
        curValue = tabs.list[tabIDIndex - 1].id
      }
    }

    setTabs({list: newTabList, value: curValue});
    //setInitialLoad(true);
    runOnTabClose(deleteId);
  }

  function makeTabElement(tab) {
    return (<Tab
      aria-controls={`wrapped-tabpanel-${tab.id}`}
      className={classes.form}
      icon={
        <Close
          onClick={(event) => handleTabCloseEvent(event, tab.id)}
        />
      }
      id={`wrapped-tab-${tab.id}`}
      key={tab.key}
      label={
        <div>
          {tab.label}
        </div>
      }
      value={tab.id}
      wrapped
    />)
  }

  function makeTabPanelElement(tab) {
    return (<TabPanel
      index={tab.id}
      key={tab.id}
      plain
      value={tabs.value}
    >
      {tab.panel}
    </TabPanel>)
  }

  return (<>
    <Tabs
      aria-label={label}
      indicatorColor={indicatorColor}
      onChange={(event, newV) => handleTabChangeEvent(event, newV)}
      scrollButtons={scrollButtons}
      textColor={textColor}
      value={tabs.value}
      variant={variant}
    >
      <Tab
        aria-controls={"wrapped-tabpanel-0"}
        className={classes.form}
        key={0}
        id={"wrapped-tab-0"}
        label={
          <div>
            {defaultTabName}
          </div>
        }
        value={0}
        wrapped
      />
      {(!tabs || !tabs.list) ? ('') : (tabs.list.map(makeTabElement))}
    </Tabs>
    <TabPanel
      index={0}
      plain
      value={tabs.value}>
      {defaultTabPanel}
    </TabPanel>
    {
      (!tabs || !tabs.list) ? ('') : (tabs.list.map(makeTabPanelElement))}
  </>)
}

const TabListWithRef = React.forwardRef(TabList);

TabList.propTypes = {
  label: PropTypes.string,
  indicatorColor: PropTypes.oneOf(["primary", "secondary"]),
  scrollButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["auto"])]),
  textColor: PropTypes.oneOf(["secondary", "primary", "inherit"]),
  variant: PropTypes.oneOf(["standard", "scrollable", "fullWidth"]),
  defaultTabName: PropTypes.string,
  defaultTabPanel: PropTypes.element,
  runOnTabChange: PropTypes.func,
  runOnTabClose: PropTypes.func
};

export default TabListWithRef;
