import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  container: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  cardContainer: {
    width: 400,
    minWidth: 400,
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '100%'
  },
  loginLogoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginLogoImage: {
    width: 350,
    justifyContent: 'center',
    alignItems: 'center'
  },
  formContainer: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
    },
  },
}));
