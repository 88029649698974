import {makeStyles} from "@mui/styles";
import {Switch as MuiSwitch} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const disabledOpacity = 0.25;
const useStyles = makeStyles((theme) => ({
  switchOverrides: {
    //"checked" would be overridden, "thumb" would override "switchBase" even when not checked
    "& > *.Mui-checked": {
      color: theme.palette.secondary.main,
    },
    //no option to set checked color for track naturally
    "& > *.Mui-checked + .MuiSwitch-track": {
      backgroundColor: theme.palette.gray
    },
    //no option to set disabled opacity for track naturally
    "& > *.Mui-disabled + .MuiSwitch-track": {
      opacity: disabledOpacity
    },
  },
  switchDisabled: {
    opacity: disabledOpacity
  },
  switchBase: {
    color: theme.palette.white
  },
  switchTrack: {
    backgroundColor: theme.palette.darkGray
  }
}));

/**
 *
 * @param {{
 *   checked: boolean,
 *   name: string,
 *   disabled: boolean,
 *   onHandleToggle: function
 * }} props
 * @returns {JSX.Element}
 * @constructor
 */
const Switch = props => {
  const {
    checked = true,
    name = "",
    disabled = false,
    onHandleToggle = () => {/*Do Nothing*/}
  } = props;

  const classes = useStyles();

  return (<MuiSwitch
    checked={Boolean(checked)}
    name={name}
    classes={{
      root: classes.switchOverrides,
      switchBase: classes.switchBase,
      disabled: classes.switchDisabled,
      track: classes.switchTrack
  }}
    disabled={disabled}
    onChange={onHandleToggle}
  />);
}

Switch.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onHandleToggle: PropTypes.func
}

export default Switch;
