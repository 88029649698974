import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import SharedUserInfoForm from '../components/SharedUserInfoForm';
import {
  Card,
  CardContent,
  Grid,
  LinearProgress
} from '@mui/material';
import SharedUserAdditionalInfo from '../components/SharedUserAdditionalInfo';
import {useDispatch, useSelector} from 'react-redux';
import PageHeader from '../../../components/PageHeader/PageHeader';
import Button from '../../../components/CustomButtons/Button';
import {Create, Refresh} from '@mui/icons-material';
import {generateKey} from '../../../helpers';
import {userActions} from '../../../store/actions';
import {SECURITY_ROLES} from "../../../constants/SECURITY_ROLES";
import LoadingSpinner from "../../../components/Spinner/loading.spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  createButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  resetButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  fab: {
    margin: theme.spacing(1), // You might not need this now
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3)
  }
}));

const ViewSharedUser = (props) => {
  const title = props.location.state.title;
  const subtitle = props.location.state.subTitle;
  const userInfo = props.location.state.user;
  const additionalDetails = props.location.state.additionalDetails;
  const submit = props.location.state.submit;

  const classes = useStyles();
  const dispatch = useDispatch();

  const requestPending = useSelector(state => state.users.requestPending);
  const {role} = useSelector(state => state.authentication.user);

  const [initialRender, setInitialRender] = useState(true);
  const [userFound] = useState(false);
  const [updateKey, setUpdateKey] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [hasInfoErrors, setHasInfoErrors] = useState( false);
  const [hasAdditionalErrors, setHasAdditionalErrors] = useState(false);
  const [sharedUserInfo, setSharedUserInfo] = useState(userInfo);
  const [keyInfo, setKeyInfo] = useState(additionalDetails);


  let edit = false;
  if (submit === 'Edit'){
    edit = true;
  }

  if (submit !== 'Add' && initialRender) {
    if (additionalDetails) {
      ///////////////////////
      // Additional Details
      ///////////////////////
      setKeyInfo(additionalDetails);

      //////////////////
      // User Info
      //////////////////
      setSharedUserInfo(userInfo);

      setInitialRender(false);
    }
  }

  /////////////////////
  // Shared User Info
  /////////////////////
  const onHandleSharedInfoChange = (event) => {
    if (edit && (role === SECURITY_ROLES.CLOUD_ADMIN || role === SECURITY_ROLES.COMPANY_ADMIN)){
      setSharedUserInfo(sharedUserInfo => ({ ...sharedUserInfo, [event.target.name]: event.target.value}));
      setUpdateInfo(true);
    }
  };

  ////////////////////////
  // Additional Details
  ////////////////////////
  const onHandleAdditionalDetailsChange = (event) => {
    if (edit){
      setKeyInfo(keyInfo => ({...keyInfo, [event.target.name]: event.target.value}));
      setUpdateKey(true);
    }
  };
  const onHandleSwitchChange = (event) => {
    if (edit){
      setKeyInfo(keyInfo => ({...keyInfo, [event.target.name]: (event.target.checked ? 1 : 0)}));
      setUpdateKey(true);
    }
  };

  const onHandleRadioChange = (event) => {
    const radio_value = event.target.value;
    if (edit) {
      if (event.target.value === 'does_not_expire') {
        setKeyInfo(keyInfo => ({
          ...keyInfo,
          does_not_expire: 1,
          expires_days: 0,
          key_expiry: radio_value,
          daysEnabled: true
        }));
      } else {
        setKeyInfo(keyInfo => ({
          ...keyInfo,
          does_not_expire: 0,
          expires_days: 0,
          key_expiry: radio_value,
          daysEnabled: false
        }));
      }
      setUpdateKey(true);
    }
  };

  const onHandleCreateKey = (event) => {
    if (edit){
      let key = generateKey("sharedkey", 10);
      setKeyInfo(keyInfo => ({ ...keyInfo, sharedKey: key, confirmKey: key}));
      setUpdateKey(true);
    }
  };

  const onExpireDaysChange = (event) => {
    if (edit){
      setKeyInfo(keyInfo => ({ ...keyInfo, key_expiry: "expires_on_days", expires_days: event.target.value}));
      setUpdateKey(true);
    }
  };

  const onHandleReset = () => {
    if (!userFound){
      setSharedUserInfo(userInfo);
    }

    setKeyInfo(additionalDetails);
  }

  const onHandleSubmit = (rowData) => {
    let updateList = '';

    if (updateKey) {
      updateList += "\nKeyInfo";
    }

    if (updateInfo) {
      updateList += '\nSharedUserInfo';
    }

    if (updateList){
      setUpdateKey(false);
      setUpdateInfo(false);

      let sharedUserValues = {
        keyInfo: keyInfo,
        sharedUserInfo: sharedUserInfo
      }

      // update
      dispatch(userActions.updateSharedUserDetails(sharedUserValues));
    }
  };

  return (
    <div className={classes.root}>
      <PageHeader title={title} subTitle={subtitle}/>
      <Card className={classes.content}>
        {(!sharedUserInfo || !keyInfo) ? (<LoadingSpinner/>) :
          (
        <CardContent>
          <div>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <SharedUserInfoForm
                  sharedUserInfo = { sharedUserInfo }
                  userFound={ userFound }
                  onHandleSharedInfoChange={onHandleSharedInfoChange}
                  setHasInfoErrors={setHasInfoErrors}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <SharedUserAdditionalInfo
                  additionalInfo={keyInfo}
                  setAdditionalInfo={setKeyInfo}
                  onHandleSwitchChange={onHandleSwitchChange}
                  onHandleRadioChange={onHandleRadioChange}
                  onExpireDaysChange={onExpireDaysChange}
                  onHandleAdditionalInfoChange={onHandleAdditionalDetailsChange}
                  onHandleCreateKey={onHandleCreateKey}
                  setHasAdditionalErrors={setHasAdditionalErrors}
                  view={submit}
                />
              </Grid>
            </Grid>
            {requestPending && <LinearProgress/>}

              {(!edit) ? ("") : (
            <div className={classes.fab} style={{position: 'fixed'}}>
                {(hasInfoErrors || hasAdditionalErrors) ? (
                  <Button aria-label="Create" className={classes.createButton}
                          color="primary"
                          type="submit"
                          variant="contained"
                          onClick={onHandleSubmit}
                          disabled
                  >
                    <Create/> Update
                  </Button>
                ) : (
                  <Button aria-label="Create" className={classes.createButton}
                          color="primary"
                          type="submit"
                          variant="contained"
                          onClick={onHandleSubmit}
                  >
                    <Create/> Update
                  </Button>
                )}
                <Button aria-label="Reset" className={classes.resetButton}
                 color="primary"
                 type="submit"
                 variant="contained"
                 onClick={onHandleReset}
                 disabled={requestPending}
                >
                  <Refresh/> Reset
                </Button>

            </div>
              )}
          </div>
        </CardContent>
          )}
        {/*<CardActions className={classes.content}>*/}

        {/*</CardActions>*/}
      </Card>
    </div>
  )
}

export default ViewSharedUser;
