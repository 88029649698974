import {vodConstants} from "../../constants/index";
import {vodService} from "../../services/index";
import {alertActions} from "../alerts/alert.actions";

export const vodActions = {
  uploadToVoDApi,
  getVoDUploadProgress,
  getVoDFile
};

function uploadToVoDApi(visibility, uploadFields) {
  return dispatch => {
    dispatch(request(uploadFields));

    return vodService.uploadToVoDApi(visibility, uploadFields)
      .then(
        fileListUploaded => {
          dispatch(success(fileListUploaded));
          if (fileListUploaded.progress !== undefined) {
            dispatch(alertActions.success(fileListUploaded));
          } else {
            dispatch(alertActions.warning(fileListUploaded.message));
          }
          return fileListUploaded;
        },
        error => {
          const errorStr = error.toString().includes("502") ? "Cannot currently upload video or audio" : error.response.data.error;
          dispatch(failure(errorStr));
          dispatch(alertActions.error(errorStr));
          throw error;
        }
      );
  };

  function request(videoUploadField) {
    return {type: vodConstants.UPLOAD_VOD_REQUEST, videoUploadField}
  }

  function success(videoListUpload) {
    return {type: vodConstants.UPLOAD_VOD_SUCCESS, videoListUpload}
  }

  function failure(error) {
    return {type: vodConstants.UPLOAD_VOD_FAILURE, error}
  }
}

function getVoDUploadProgress(progressUrl) {
  return dispatch => {
    dispatch(request(progressUrl));

    vodService.getVoDUploadProgress(progressUrl)
      .then(
        progressReport => {
          dispatch(success(progressReport));
          if (progressReport.finished !== undefined) {
            dispatch(alertActions.success(progressReport));
          } else {
            dispatch(alertActions.warning(progressReport.status));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(progressUrl) {
    return {type: vodConstants.RETRIEVE_VOD_PROGRESS_REQUEST, progressUrl}
  }

  function success(progressReport) {
    return {type: vodConstants.RETRIEVE_VOD_PROGRESS_SUCCESS, progressReport}
  }

  function failure(error) {
    return {type: vodConstants.RETRIEVE_VOD_PROGRESS_FAILURE, error}
  }
}

function getVoDFile(fileUrl) {
  return dispatch => {
    dispatch(request(fileUrl));

    vodService.getVoDFile(fileUrl)
      .then(
        file => {
          dispatch(success(file));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(progressUrl) {
    return {type: vodConstants.RETRIEVE_VOD_FILE_REQUEST, progressUrl}
  }

  function success(file) {
    return {type: vodConstants.RETRIEVE_VOD_FILE_SUCCESS, file}
  }

  function failure(error) {
    return {type: vodConstants.RETRIEVE_VOD_FILE_FAILURE, error}
  }
}
