import DashboardIcon from '@mui/icons-material/Dashboard';
import PageviewIcon from '@mui/icons-material/Pageview';
import React from 'react';

export const nonMemberPages = [
  {
    collapse: true,
    title: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardIcon />
  },
  {
    title: 'Documents',
    href: '/documents',
    icon: <PageviewIcon />
  }
];
