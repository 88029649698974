import axiosInstance from '../../axios.instance';
import {requestHelper} from '../helpers';
import {toastHelper} from '../../helpers/toast.helper';
import {alertConstants} from '../constants';
import { maestroenv } from '../../helpers/maestroenv';

export const dashboardService = {
  getCloudAdminDashboardMetrics,
  getCloudAdminUserRoleMetrics,
  getUserDashboardMetrics,
  getCompanyAdminDashboardMetrics,
  getCloudAdminDocumentHistoryForNumberOfDays,
  getCloudAdminIpMetrics,
  getCloudAdminDocumentCountsMetrics,
  getDocumentHistoryForNumberOfDays,
  getCloudAdminTopViewedDocumentsMetrics,
  getTopViewedDocumentsMetrics,
  getTopUserViewedDocumentsMetrics,
  getUserRoleMetrics,
  getUserDocumentCountsMetrics,
  getUserStagedDocumentCountsMetrics,
  getUserDocumentHistoryForNumberOfDays,
  getDocumentCountsMetrics,
  getStagedDocumentCountsMetrics,
  getSharedUserDashboardMetrics
};

async function getCloudAdminDashboardMetrics() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/cloudAdmin`)
    .then(response => {
      return response.data
    })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getCloudAdminUserRoleMetrics() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/cloudAdmin/userRoleMetrics`)
    .then(response => {return response.data})
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getCompanyAdminDashboardMetrics(companyId, days) {
  let daysValue = days ? `/?days=${days}` : ''
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/companyAdmin/${companyId}${daysValue}`)
    .then(response => {
      return response.data
    })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getDocumentHistoryForNumberOfDays(companyId, days) {
  let daysValue = `?days=${days}`
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/companyAdmin/documentHistory/${companyId}/${daysValue}`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getCloudAdminDocumentHistoryForNumberOfDays(days) {
  let daysValue = `?days=${days}`
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/cloudAdmin/documentHistory/${daysValue}`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getUserDocumentHistoryForNumberOfDays(userId, days) {
  let daysValue = `?days=${days}`
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/user/documentHistory/${userId}/${daysValue}`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getCloudAdminIpMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/metrics/ip`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getDocumentCountsMetrics(companyId) {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/companyAdmin/documentCounts/${companyId}`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getStagedDocumentCountsMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/companyAdmin/stagedDocumentCounts`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getUserDocumentCountsMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/user/documentCounts`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getUserStagedDocumentCountsMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/user/stagedDocumentCounts`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getCloudAdminDocumentCountsMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/cloudAdmin/documentCounts`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getUserRoleMetrics(companyId) {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/companyAdmin/userRoleMetrics/${companyId}`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getTopViewedDocumentsMetrics(companyId) {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/companyAdmin/topViewedDocumentsMetrics/${companyId}`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
async function getTopUserViewedDocumentsMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/user/topViewedDocumentsMetrics`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
async function getCloudAdminTopViewedDocumentsMetrics() {
  return await axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/cloudAdmin/topViewedDocumentsMetrics`)
    .then(response => { return response.data})
    .catch(function(error) {
      toastHelper.showToast(alertConstants.ERROR, error.toString());
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getSharedUserDashboardMetrics() {

  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/shareduser/dashboard`)
    .then(response => {
      return response.data
    })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getUserDashboardMetrics() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/dashboard/user`)
    .then(response => {
      return response.data})
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
