import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import palette from "../../theme/palette";
import {Chip} from "@mui/material";


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  }
}));


export default function EmptyList(props) {
  const {label, size="medium"} = props
  const classes = useStyles();
  return (
    <Chip
      className={classes.blankSection}
      style={{backgroundColor: palette.error.light, width: '100%'}}
      label={label}
      size={size}
    />
  )
}
EmptyList.propTypes = {
  label: PropTypes.string
};
