import React from 'react';
import {ErrorMessage, Form, Formik} from "formik";
import * as Yup from "yup";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Grid,
} from "@mui/material";
import {isEmpty} from "underscore";


const AccessKeyDialog = (props) => {
  const {
    openAccessKey,
    setOpenAccessKey,
    classes,
    keyInfo,
    onHandleSubmit,
  } = props;

  const validationSchema = Yup.object().shape({
    sharedKey: Yup.string().trim().required('Please Enter a shared key')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
        "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmKey: Yup.string().trim().required('Confirm Shared Key is Required')
      .oneOf([Yup.ref('sharedKey'), null], 'Shared Key must match'),
    expires_days: Yup.number()
      .min(1, "Number of days must be a number.")
      .nullable(true)
      .transform((v) => (v === '' || Number.isNaN(v) ? 0 : v))
  });

  const initialTouched = {
    sharedKey: true,
    confirmKey: true
  };

  return (
    <Formik
      enableReinitialize
      initialValues={keyInfo}
      onReset={(values) => {
        setOpenAccessKey(false);
      }}
      initialTouched={initialTouched}
      onSubmit={(values, {setSubmitting, setValues, setErrors, setFieldError}) => {
        setSubmitting(true);
        setOpenAccessKey(false);
        if (onHandleSubmit(values)) {
          setSubmitting(false);
        } else {
          setErrors("Shared User Access Key update not successful");
          setFieldError('name', 'Shared User Access Key failed');
        }
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formikProps) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit
        } = formikProps;

        const hasAccessKeyErrors = (!isEmpty(touched) && !isEmpty(errors))

        return (
          <Form onSubmit={handleSubmit}>
            <Dialog open={openAccessKey}
                    onClose={handleReset}
                    aria-labelledby="key-dialog"
            >
              <DialogTitle id="key-dialog-title">Access Key Change</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={3}
                  className={classes.content}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Shared Key"
                      margin="dense"
                      name="sharedKey"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sharedKey}
                      variant="outlined"
                    />
                    <ErrorMessage className={classes.errorMessage} component="div" name="sharedKey"/>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Confirm Key"
                      margin="dense"
                      name="confirmKey"
                      type="password"
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      value={values.confirmKey}
                      variant="outlined"
                    />
                    <ErrorMessage className={classes.errorMessage} component="div" name="confirmKey"/>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  aria-label="update" className={classes.updateButton}
                  type='submit'
                  onClick={handleSubmit}
                  color="primary"
                  disabled={hasAccessKeyErrors}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  aria-label="cancel" className={classes.cancelButton}
                  onClick={handleReset}
                  color="primary"
                  variant="contained">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  );
}

export default AccessKeyDialog;
