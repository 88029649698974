import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {states as States} from 'constants/states';
import useStyles from './styles';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  TextField
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {userActions} from '../../../../store/actions';
import Button from "../../../../components/CustomButtons/Button";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";

const AccountDetails = props => {
  const {className, profile, ...rest} = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const requestPending = useSelector(state => state.users.requestPending);
  const user = useSelector(state => state.authentication.user);
  const [values] = useState({
    id: profile.id,
    firstName: profile.firstName,
    lastName: profile.lastName,
    email: profile.email,
    phone: profile.phone,
    state: profile.state,
    zip: profile.zip,
    street: profile.street,
    city: profile.city,
    company: profile.company,
    companyId: profile.companyId,
    role: profile.role
  });

  return (
    <Formik
      initialValues={values}
      onSubmit={(values, {setSubmitting}) => {
        dispatch(userActions.updateProfile(values))
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .trim()
          .email('Invalid Email')
          .required('Email is Required'),
        firstName: Yup.string()
          .trim()
          .required('First Name is Required'),
        lastName: Yup.string()
          .trim()
          .required('Last Name is Required'),
        street: Yup.string()
          .trim()
          .required('Street Address is Required'),
        city: Yup.string()
          .trim()
          .required('City is Required'),
        phone: Yup.string()
          .trim()
          .required('Phone is Required'),
        zip: Yup.number()
          .min(5, 'Zip Code is Invalid')
          .required('Zip is Required'),
      })}
    >
      {(props) => {
        const {
          values,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <Card
              {...rest}
              className={clsx(classes.root, className)}
            >
              <CardHeader
                subheader="Update your profile information"
                title="Profile"
              />
              <Divider/>
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="First Name"
                      margin="dense"
                      name="firstName"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="firstName"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Last Name"
                      margin="dense"
                      name="lastName"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="lastName"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Email Address"
                      margin="dense"
                      name="email"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="email"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Phone Number"
                      margin="dense"
                      name="phone"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.phone}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="phone"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Street Address"
                      margin="dense"
                      name="street"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.street}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="street"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="City"
                      margin="dense"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.city}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="city"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      SelectProps={{native: true}}
                      fullWidth
                      label="Select State"
                      margin="dense"
                      name="state"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // eslint-disable-next-line react/jsx-sort-props
                      select
                      value={values.state}
                      variant="outlined"
                    >
                      {States.map(option => (
                        <option
                          key={option.abbreviation}
                          value={option.abbreviation}
                        >
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="state"
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="zip"
                      margin="dense"
                      name="zip"
                      disabled={user.role === SECURITY_ROLES.SHARED_USER}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.zip}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="zip"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider/>
              {user.role !== SECURITY_ROLES.SHARED_USER &&
                <CardActions>
                  {requestPending && <LinearProgress/>}
                  <Button
                    color="success"
                    disabled={requestPending}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                  >
                    Save details
                  </Button>
                  <Button
                    color="github"
                    disabled={requestPending}
                    onClick={handleReset}
                    type="submit"
                    variant="contained"
                  >
                    Reset
                  </Button>
                </CardActions>
              }
            </Card>
          </form>
        )}}
    </Formik>
  );
}

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
