import axiosInstance from '../../axios.instance';
import {requestHelper} from '../helpers';
import { maestroenv } from '../../helpers/maestroenv';

export const companyService = {
    add,
    update,
    activate,
    deactivate,
    getParentCompanyList,
    getAllCompanies,
    getAllActiveCompanies,
    getCompanyById,
    getCompanyByName,
    getConnectedApps,
    getActiveConnectedApps,
    getCompanyAppIconImages,
    getCompanyAppBackgroundImages,
    addNewConnectedApp,
    updateConnectedApp,
    updateConnectedAppLink,
    uploadAppImages
};

function activate(id) {
    return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${id}/activate`)
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

function add(company) {
    return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/companies/add`, JSON.stringify(company))
        .then(response => {
            return response.data.data
        })
        .catch(function (error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

function deactivate(id) {
    return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${id}/deactivate`)
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

function getAllCompanies() {
    return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/companies/`)
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

function getAllActiveCompanies() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/companies/active`)
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


function getParentCompanyList() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/companies/getparentlist`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getCompanyByName(name) {
    return axiosInstance.get(maestroenv.REACT_APP_API_URL + '/companies/' + name + '/getcompany')
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

function getCompanyById(id) {
    return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/company/${id}`)
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

async function getConnectedApps(companyId) {
    return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/company/${companyId}/apps`)
        .then(response => {return response.data})
        .catch(function(error) {
            return Promise.reject(requestHelper.handleErrorResponse(error));
        });
}

async function getActiveConnectedApps(companyId) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/company/${companyId}/activeapps`)
    .then(response => {return response.data})
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function updateConnectedApp(appValues) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${appValues.companyId}/connectedappupdate`, JSON.stringify(appValues))
    .then(response => {
      return response.data.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function addNewConnectedApp(appValues) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${appValues.companyId}/connectedappnew`, JSON.stringify(appValues))
    .then(response => {
      return response.data.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


async function getCompanyAppIconImages(companyId) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/company/${companyId}/appicons`)
    .then(response => {
      return response.data.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getCompanyAppBackgroundImages(companyId) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/company/${companyId}/appbackgrounds`, {'companyId': companyId})
    .then(response => {
      return response.data.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

/*function uploadConnectedAppImages (uploadFields) {
  return  axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/addappimage`, uploadFields)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}*/

async function updateConnectedAppLink(appValues) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${appValues.companyId}/connectedappupdatelink`, JSON.stringify(appValues))
    .then(response => {
      return response.data.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function uploadAppImages (uploadFields, companyId) {
  return  axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${companyId}/addappimage`, uploadFields)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function update(company) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/company/${company.companyInfo.id}/update`, JSON.stringify(company))
    .then(response => {
      return response.data.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
