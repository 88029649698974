import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@mui/styles';
import {Card, CardContent, CardHeader, Divider, IconButton, Typography} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import BusinessIcon from '@mui/icons-material/Business';
import RefreshIcon from '@mui/icons-material/Refresh';
import PublicIcon from '@mui/icons-material/Public';
import {dashboardService} from '../../../../../store/services';
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import {history} from "../../../../../helpers/history";
import {InteractiveBarChart} from "../../../../../components";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  document: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  documentIcon: {
    color: theme.palette.icon
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  }
}));

const CloudAdminDocumentMetrics = props => {
  const {className, metrics: {documentMetrics}, ...rest} = props;
  const [documents, setDocuments] = useState(documentMetrics);
  const [documentCounts, setDocumentCounts] = useState(null);
  const [data, setData] = useState(null);
  const [dataIsBuilding, setDataIsBuilding] = useState(null);
  const [dataBuilt, setDataBuilt] = useState(false);
  const labels = useState(['Public', 'Company', 'Private']);
  const classes = useStyles();
  const theme = useTheme();


  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'point',
        intersect: false,
        usePointStyle: true,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.primary.dark,
        titleFontColor: theme.palette.white,
      }
    },
    minBarLength: 5,
    responsive: true,
    maintainAspectRatio: false,
  };

  const buildData = useCallback((documents) =>  {
    setDataIsBuilding(true);
    let data = {
      datasets: [
        {
          data: [documents.publicCount, documents.companyCount, documents.privateCount],
          backgroundColor: [
            theme.palette.publicSection,
            theme.palette.companySection,
            theme.palette.privateSection
          ],
          border: 0,
          hoverOffset: 8
        },
      ],
      labels: labels
    }
    let documentCounts = [
      {
        title: 'Public',
        value: documents.publicCount,
        icon: <PublicIcon style={{color: theme.palette.publicSection}}/>,
        color: theme.palette.publicSection
      },
      {
        title: 'Company',
        value: documents.companyCount,
        icon: <BusinessIcon style={{color: theme.palette.companySection}}/>,
        color: theme.palette.companySection
      },
      {
        title: 'Private',
        value: documents.privateCount,
        icon: <LockIcon style={{color: theme.palette.privateSection}}/>,
        color: theme.palette.privateSection
      }]
    setDocumentCounts(documentCounts);
    setDataIsBuilding(false);
    setData(data);
    setDataBuilt(true);
  }, [theme, setDataBuilt, setDataIsBuilding, labels])

  async function resetData() {
    setData(null);
    setDataBuilt(false);
  }

  const onHandleBarClickEvent = (x) => {
    let pathname='/documents';
    history.push({
      pathname: pathname,
      initialSection: labels[x],
      startDate: "01/01/2000"
    });
  }

  const handleRefresh = event => {
    getData().then(metrics => {
      setDocuments(metrics);
      resetData().then(r => {
        buildData(documents)
      })
    });
  };

  async function getData(days) {
    return await dashboardService.getCloudAdminDocumentCountsMetrics().then(r => {
      return r.data
    });
  }

  useEffect(() => {
    if (!dataBuilt && !dataIsBuilding) {
      buildData(documents)
    }
  }, [dataBuilt, dataIsBuilding, buildData, documents])

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.titleHeader}
        action={
          <IconButton
            disabled={!dataBuilt}
            onClick={handleRefresh}
            size="small"
          >
            <RefreshIcon/>
          </IconButton>
        }
        title="Document Metrics"
      />
      <Divider/>
      <CardContent>
        {data && documentCounts && !dataIsBuilding ? (
          <React.Fragment>
            <div className={classes.chartContainer}>
              <InteractiveBarChart
                data={data}
                options={options}
                onHandleBarClick={onHandleBarClickEvent}
              />
            </div>
            <div className={classes.stats}>
              {documentCounts.map(document => (
                <div
                  className={classes.document}
                  key={document.title}
                >
                  <span className={classes.documentIcon}>{document.icon}</span>
                  <Typography
                    style={{color: document.color}}
                    variant="h2"
                  >
                    {document.value}
                  </Typography>

                  <Typography
                    style={{color: document.color}}
                    variant="h6"
                  >{document.title}</Typography>

                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (<LoadingSpinner title={"Loading Dashboard"}/>)}
      </CardContent>
    </Card>
  );
};

CloudAdminDocumentMetrics.propTypes = {
  className: PropTypes.string
};

export default CloudAdminDocumentMetrics;
