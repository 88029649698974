import {Collapse, List, ListItem, ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import {useSelector} from "react-redux";
import {SECURITY_ROLES} from "../../../../../../constants/SECURITY_ROLES";

const MultiLevel = ({ classes, item, customRouterLink }) => {
  const { menu: children } = item;
  const { role } = useSelector(state => state.authentication.user);
  const [isOpen, setIsOpen] = React.useState(null);
  return (
    <React.Fragment>
      <ListItem button
                onClick={() => setIsOpen(!isOpen)}
                className={classes.button}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit >
        <List component="div" >
          {children.map((child, key) => (
            (role.includes('Admin')) || ((role === SECURITY_ROLES.POWER_USER || SECURITY_ROLES.USER) && (!child.admin)) ? (
              <MenuItem
                key={key}
                className={classes.multilevel}
                component={customRouterLink}
                to={child.href}
              >
                <ListItemIcon>
                  {child.icon}
                </ListItemIcon>
                {child.title}
              </MenuItem>
            ) : ("")
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

export default MultiLevel;
