/**
 * setTimeout as a promise (.then compatible) with a method to cancel.
 * @param waitTime duration of setTimeout
 * @param proceedOnCancel cancelling causes the promise to resolve if true and reject otherwise
 * @param runOnResolve method to run when resolved, if any (has a default)
 * @returns {{cancel: (function(): void), promise: Promise<runOnResolve>, waitTime: (number)}}
 */
function cancellableTimeoutPromise(waitTime, proceedOnCancel, runOnResolve = ()=>{}){
  const returnValue = {
    cancel: () => console.warn("Can't exit process yet"),
    promise: undefined,
    waitTime: waitTime && waitTime > 0 ? waitTime : 0
  }

  returnValue.promise = new Promise((resolve, reject) => {
    const timeoutId = setTimeout(()=>resolve(runOnResolve), returnValue.waitTime);
    if (returnValue.waitTime > 0) returnValue.cancel = () => {
      clearTimeout(timeoutId);
      proceedOnCancel ?
        resolve(runOnResolve) :
        reject(new Error("Aborted"));
    };
    else returnValue.cancel = () => {};
  });

  return returnValue;
}

export default cancellableTimeoutPromise;
