import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import React from 'react';
import {makeStyles} from '@mui/styles';
import {isEmpty} from 'underscore';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import Switch from "../../../../components/Switch";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

const SharedUserAdditionalInfo = (props) => {
  const {
    additionalInfo,
    onHandleSwitchChange,
    onHandleRadioChange,
    onExpireDaysChange,
    onHandleAdditionalInfoChange,
    setHasAdditionalErrors,
    view
  } = props

  const classes = useStyles();
  const privValues = {
    download: 0,
    print: 0,
    view: 1
  };

  let validationSchema = '';
  if (view === 'Add'){
    validationSchema = Yup.object().shape({
      sharedKey: Yup.string().trim().required('Please Enter a shared key')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
          'Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
        ),
      confirmKey: Yup.string().trim().required('Confirm Shared Key is Required')
        .oneOf([Yup.ref('sharedKey'), null], 'Shared Key must match'),
      expires_days: Yup.number()
        .min(0, 'Number of days must be a number.')
        .nullable(true)
        .transform((v) => (v === '' || Number.isNaN(v) ? 0 : v))
    });
  }

  const initialTouched = {};
  Object.keys(additionalInfo).map(function (key, index) {
    return initialTouched[key] = true;
  });

  return (
    <Formik
      enableReinitialize
      innerRef={form => setHasAdditionalErrors(form && !isEmpty(form.touched) && !isEmpty(form.errors))}
      initialTouched={initialTouched}
      initialValues={additionalInfo}
      onSubmit={(values) => {
      }}
      validateOnMount
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        const {
          values,
          handleBlur
        } = formikProps;

        return (
          <form>
          {(!additionalInfo) ? (<LoadingSpinner/>) :
            (
              <div>
                <Card>
                  <CardContent>
                    {view === 'Add' ? (
                    <div className={classes.contentHeader}>
                      <Typography variant={'h5'}>Shared Key</Typography>
                      <Divider/>
                    </div>) : (
                      <div>
                        <div className={classes.contentHeader}>
                          <Typography variant={'h5'}>Expiration</Typography>
                          <Divider/>
                        </div>
                        <Grid
                          container
                          spacing={3}
                          className={classes.content}
                        >
                          {additionalInfo.keyExpiry === 'expires_on_days' ? (
                          <Grid
                            item
                            xs={12}
                          >
                            <Typography variant={'h3'}>
                              Date Expires:  {additionalInfo.expires}
                            </Typography>
                          </Grid> ) : (
                          <Grid
                            item
                            xs={12}
                          >
                            <Typography variant={'h3'}>
                              DOES NOT EXPIRE
                            </Typography>
                          </Grid> )
                          }
                        </Grid>
                      </div>

                    )}
                    {view === 'Add' &&
                    <Grid
                      container
                      spacing={3}
                      className={classes.content}
                    >
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Shared Key"
                          margin="dense"
                          name="sharedKey"
                          type="password"
                          onChange={onHandleAdditionalInfoChange}
                          onBlur={handleBlur}
                          value={additionalInfo.sharedKey}
                          variant="outlined"
                        />
                        <ErrorMessage
                          className={classes.errorMessage}
                          component="div"
                          name="sharedKey"
                        />
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Confirm Key"
                          margin="dense"
                          name="confirmKey"
                          type="password"
                          onChange={onHandleAdditionalInfoChange}
                          onBlur={handleBlur}
                          value={additionalInfo.confirmKey}
                          variant="outlined"
                        />
                        <ErrorMessage className={classes.errorMessage} component="div" name="confirmKey"/>
                      </Grid>
                      {/*<Grid*/}
                      {/*  item*/}
                      {/*  md={2}*/}
                      {/*  xs={12}*/}
                      {/*>*/}
                      {/*  <Button aria-label="generatekey"*/}
                      {/*    type="button"*/}
                      {/*    variant="text"*/}
                      {/*    onClick={onHandleCreateKey}*/}
                      {/*  >*/}
                      {/*    Generate Key*/}
                      {/*  </Button>*/}
                      {/*</Grid>*/}
                      <Grid
                        item
                        xs={12}
                      >
                        <Divider />
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                      >
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <FormLabel component="legend">Key Expiration</FormLabel>
                          <RadioGroup
                            aria-label="key_expiry"
                            name="key_expiry"
                            value={additionalInfo.key_expiry}
                            onChange={onHandleRadioChange}
                          >
                            <FormControlLabel
                              value="Does_not_expire"
                              control={<Radio />}
                              label="does_not_expire"
                            />
                            <FormControlLabel
                              value="expires_on_days"
                              control={<Radio />}
                              label={
                                <TextField
                                  name="expires_days"
                                  label="Number of days"
                                  margin="normal"
                                  onChange={onExpireDaysChange}
                                  onBlur={handleBlur}
                                  value={additionalInfo.expires_days}
                                  variant="outlined"
                                  disabled={additionalInfo.daysEnabled}
                                />
                              }
                            />
                            {
                              (values.password_expiry === 'expires_on_days') ? (
                                <ErrorMessage
                                  className={classes.errorMessage}
                                  component="div"
                                  name="expires_days"
                                />
                              ) : null
                            }
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    }
                    <div className={classes.contentHeader}>
                      <Typography variant={'h5'}>Privileges</Typography>
                      <Divider/>
                    </div>
                    <Grid
                      container
                      spacing={3}
                      className={classes.content}
                    >
                      {
                        Object.entries(privValues).map(([key, value]) => (
                          <Grid
                            item
                            key={key}
                            xs={12}
                          >
                            <FormControlLabel
                              control={<Switch
                                checked={additionalInfo[key]}
                                onHandleToggle={onHandleSwitchChange}
                                name={key}
                              />}
                              label={key.charAt(0).toUpperCase() + key.slice(1)}
                              labelPlacement="start"
                              className={classes.switch}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </CardContent>
                </Card>
                {/*<DisplayFormikState {...formikProps}/>*/}
              </div>
            )}
        </form>
      )
      }}
    </Formik>
  )
}
export default SharedUserAdditionalInfo;

