import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from './styles';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import {Link} from 'react-router-dom';

const PendingDocuments = props => {
  const { className, metrics: {pendingDocumentsCount}, ...rest } = props;

  const classes = useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item className={classes.titleBox}>
            <Typography
              className={classes.title}
              gutterBottom
              variant="body2"
            >
              DOCUMENTS PENDING APPROVAL
            </Typography>
            <Typography
              className={classes.valueText}
              variant="h3"
            >{pendingDocumentsCount}</Typography>
          </Grid>
          <Grid item>
            <Link to={{pathname: '/documents/staged',
                initialStatus: 'All Status',
                initialSection: 'All Sections',
                startDate: "01/01/2000"
              }}>
              <Avatar className={classes.avatar}>
                <DescriptionIcon className={classes.icon} />
              </Avatar>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PendingDocuments.propTypes = {
  className: PropTypes.string
};

export default PendingDocuments;
