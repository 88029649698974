import React, {useCallback, useEffect, useState} from 'react'
import CardBody from '../../../components/Card/CardBody';
import Card from '../../../components/Card/Card';
import {useDispatch, useSelector} from 'react-redux';
import {
  categoryActions,
  classificationActions,
  companyActions,
  documentActions,
  sectionActions,
  vodActions
} from '../../../store/actions';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';

import {UploadListPanel} from './components';
import PageHeader from "../../../components/PageHeader/PageHeader";
import {makeStyles} from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import VodProgressView from "./components/VodProgressDialog/VodProgressView";
import {SearchPanel} from "../components";
import {GuidBasedMenuItem} from "../components/panelSegments/components";
import UploadFilterButtons from "./components/UploadFilterButtons/UploadFilterButtons";
import {
  NameBasedMenuItemWithIdReturn
} from "../components/panelSegments/components/NameBasedMenuItem";
//import {classificationService, /*documentService, filetypeService*/} from "../../../store/services";
import {OfficeDocTypes} from "../../../constants/filetypes/OfficeDocTypes";
import {DrawingDocTypes} from "../../../constants/filetypes/DrawingDocTypes";
import {AudioVideoDocTypes} from "../../../constants/filetypes/AudioVideoDocTypes";
import UploadDocDisplayedClassifications from "../../../constants/filetypes/ValidClassificationTypesForUploadWindow";
import UploadDocTypeList from "../../../constants/filetypes/ValidDocTypesForUploadWindow";
import TabList from "../../../components/Tab/TabList";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    margin: 0,
    padding: 0,
    backgroundColor: '#FFF'
  },
  button: {
    margin: theme.spacing(2),
    marginTop: 0
  },
}));

/**
 *
 * @param props {{type: ("document" | "image" | "drawing/CAD" | "video" | "audio")[] }}
 * @return {JSX.Element}
 * @constructor
 */
const UploadDocument = props => {
  const {type} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies.items);
  const categories = useSelector(state => state.categories.items);
  const classifications = useSelector(state => state.classifications.items);
  const currentClassifications = type
    .map(typeVal => (UploadDocDisplayedClassifications[typeVal] ?? (c => {
      throw new Error(`no corresponding classification for ${typeVal}`)
    }))(classifications ?? []))
    .flat()
    .concat(UploadDocDisplayedClassifications.uncategorized(classifications ?? ["Uncategorized"]));
  const sections = useSelector(state => state.sections.items);
  const company = useSelector(state => state.authentication.user.companyId);
  const user = useSelector(state => state.authentication.user.id);
  const requestPending = useSelector(state => state.documents.requestPending || state.vod.requestPending);
  const vodRequestPending = useSelector(state => state.vod.requestPending);

  const [fileList, setFileList] = useState([]);
  const [companySelect, setCompanySelect] = useState(company);
  const [sectionSelect, setSectionSelect] = useState(1);
  const [categorySelect, setCategorySelect] = useState(13);
  const [classificationSelect, setClassificationSelect] = useState(14);
  const [checkedB] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const mimeTypeList = type.map(typeVal => UploadDocTypeList[typeVal]).flat()

  const onHandleReset = () => {
    setFileList([]);
  };

  let formData = new FormData();

  const onHandleChange = event => {
    let files = event.target.files;
    setFileList(files);
  };

  const onHandleUpload = async () => {
    formData = new FormData();
    formData.append('company', companySelect);
    formData.append('user', user);
    formData.append('section', sectionSelect);
    formData.append('category', categorySelect);
    formData.append('classification', classificationSelect);

    let filePromises = [];
    for (let i = 0; i < fileList.length; i++) {
      const contentType =
        AudioVideoDocTypes.isDocType(fileList[i]) ??
        OfficeDocTypes.isDocType(fileList[i]) ??
        DrawingDocTypes.isDocType(fileList[i])
      if (contentType) {
        uploadDocToFileConversionApi(fileList[i], contentType, filePromises);
      } else {
        formData.append('fileList[]', fileList[i]);
      }
    }
    setFileList([]);
    await Promise.all(filePromises).then(() => {/*Nothing*/});
    if (formData.getAll('fileList[]').length > 0 || formData.getAll('fileLinks[]').length > 0)
      dispatch(documentActions.uploadDocuments(formData));
  };

  function uploadDocToFileConversionApi(file, contentType, filePromises) {
    let videoFormData = new FormData();
    let fileWithNewContentType = new Blob([file], {type: contentType})
    videoFormData.append("file", fileWithNewContentType, file.name);
    let uploadPromise = Promise.resolve(dispatch(vodActions.uploadToVoDApi(sectionSelect, videoFormData))
      .then(
        avUploadResolveFunction,
        rej => new Error(rej)
      ));
    filePromises.push(uploadPromise)
    uploadTabRef.current.addTab((id) => file.name, (id) => (<VodProgressView
      progressPromise={uploadPromise}
    />));
  }

  function avUploadResolveFunction(res) {
    if (!res) {
      return new Error("Resolved as if query succeeded, but no value");
    }
    formData.append('fileLinks[]', JSON.stringify(res));
    return res;
  }

  const onHandleSectionSelect = event => {
    setSectionSelect(event.target.value);
  };

  const onHandleCompanySelect = event => {
    setCompanySelect(event.target.value);
  };

  const onHandleCategorySelect = event => {
    setCategorySelect(event.target.value);
  };

  const onHandleClassificationSelect = event => {
    setClassificationSelect(event.target.value);
  };

  // useEffect(() => {
  //   if (progressPending === false && vodRequestPending === false && vodLinks) {
  //     updateStatusAfterWaitCallback(vodLinks.progress).then(/*Do Nothing*/);
  //   }
  // }, [classificationSelect, vodLinks, vodRequestPending, progress, progressPending, updateStatusAfterWaitCallback])

  useEffect(() => {
    dispatch(companyActions.getAllActiveCompanies());
    dispatch(categoryActions.getAllCategories());
    dispatch(sectionActions.getAllSections());
    dispatch(classificationActions.getAllClassifications());
  }, [dispatch]);

  const defaultTab = (!user || !companies || !categories || !classifications || !sections || requestPending || vodRequestPending) ?
    (<LoadingSpinner/>) :
    (<React.Fragment>
        <Accordion
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
          style={{width: '100%', margin: 0, padding: 10, backgroundColor: '#3b5998'}}
        >
          <AccordionSummary
            aria-controls="search-details"
            expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}}/>}
            id="search-details"
          >
            <Typography
              style={{color: '#FFFFFF'}}
                    variant={'h3'}>Browse for {type.join(", ")}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{backgroundColor: '#FFFFFF'}}>
            <SearchPanel
              initialSections={sections}
              sectionSelect={sectionSelect}
              onHandleSectionSelect={onHandleSectionSelect}
              includeAllSections={false}
              searchSectionsByMethod={NameBasedMenuItemWithIdReturn}

              initialClassifications={currentClassifications}
              initialMimetypes={mimeTypeList}
              classificationSelect={currentClassifications.find(classification => classification.id === classificationSelect) ? classificationSelect : 14}
              onHandleClassificationSelect={onHandleClassificationSelect}
              includeAllClassifications={false}
              searchClassificationsByMethod={NameBasedMenuItemWithIdReturn}

              initialCategories={categories}
              categorySelect={categorySelect}
              onHandleCategorySelect={onHandleCategorySelect}
              includeAllCategories={false}
              searchCategoriesByMethod={NameBasedMenuItemWithIdReturn}

              initialCompanies={companies}
              companySelect={companySelect}
              onHandleCompanySelect={onHandleCompanySelect}
              includeAllCompanies={false}
              searchCompaniesByMethod={GuidBasedMenuItem}

              renderButton={false}
              customButtons={UploadFilterButtons({
                classes,
                mimeTypeList,
                fileList,
                onHandleChange,
                onHandleUpload,
                onHandleReset
              })}
            />
          </AccordionDetails>
        </Accordion>

        {!fileList ? (<LoadingSpinner/>) : (
          <UploadListPanel
            checkPin={checkedB}
            classes={classes}
            fileList={fileList}
          />
        )}
      </React.Fragment>
    );


  const uploadTabRef = React.useRef({});

  return (
    <div className={classes.root}>
      <PageHeader title={"Upload"} subTitle={type.join(", ")}/>
      <Card
        plain
        style={{margin: 0}}
      >
        <CardBody style={{backgroundColor: '#FFF', padding: 0}}>
          <TabList
            label="Progress Tabs"
            indicatorColor="secondary"
            scrollButtons="auto"
            textColor="primary"
            variant="standard"
            defaultTabName="Upload"
            defaultTabPanel={defaultTab}
            ref={uploadTabRef}
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default UploadDocument;
