import React from 'react'
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {Card, CardContent, Grid} from "@mui/material";
import MaestroLogo from "../../../assets/images/logo.png";
import useStyles from './styles';
import LoginBackground from "../../../assets/images/login-background-2.jpg";

const AsyncLoadingScreen = (props) => {
  const title = props && props.location && props.location.state ? props.location.state.title : (props.title ? props.title : 'Loading');

  const classes = useStyles();
  return (
    <Grid
      className={classes.container}
      container
      style={{backgroundImage: `url(${LoginBackground})`}}
    >
      <div className={classes.formContainer}>
      <Card
        className={classes.cardContainer}
        raised
      >
        <CardContent>
          <div className={classes.form}>
            <Grid
              className={classes.loginLogoContainer}
              container
            >
              <img
                alt="Maestro"
                className={classes.loginLogoImage}
                src={MaestroLogo}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <LoadingSpinner title={title}/>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </div>
    </Grid>
  )
}

export default AsyncLoadingScreen;
