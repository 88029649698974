import {combineReducers} from 'redux';

import {authentication} from './authentication/authentication.reducer';
import {categories} from './category/categories.reducer';
import {classifications} from './classification/classifications.reducer';
import {companies} from './company/companies.reducer';
import {dashboard} from './dashboard/dashboard.reducer';
import {documents} from './document/documents.reducer';
import {vod} from './vod/vod.reducer';
import {filetypes} from './filetype/filetypes.reducer';
import {sections} from './section/sections.reducer';
import {settings} from './setting/settings.reducer';
import {users} from './user/users.reducer';
import {alert} from './alerts/alert.reducer';
import {layout} from './layout/layout.reducer';
import {userConstants} from '../constants';

const appReducer = combineReducers({
  authentication,
  categories,
  classifications,
  dashboard,
  documents,
  filetypes,
  sections,
  settings,
  users,
  alert,
  layout,
  companies,
  vod
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.LOGOUT) {
    state = undefined
  }
  if (action.type === userConstants.LOGIN_ROLE_REQUEST) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer;
