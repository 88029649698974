import MenuItemContainer from "../MenuItemContainer";

const GuidBasedMenuItem = props => {
  const {
    classes,
    options
  } = props;

  if (options == null) return null;
  let mappedItems = options.map(option => MenuItemContainer(option ? {
    classes: classes,
    optionKey: option.guid,
    optionValue: option.guid,
    optionDisplay: option.name
  } : {classes}))
  return (mappedItems);
}

export default GuidBasedMenuItem;
