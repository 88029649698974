import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import MaterialTable from '@material-table/core';

// @mui/icons-material
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {categoryActions} from '../../../store/actions';

import {history} from '../../../helpers/history';
import PageHeader from '../../../components/PageHeader/PageHeader';
import {makeStyles} from '@mui/styles';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {EmptyList} from "../../../components/EmptyList";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ListCategory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector(state => state.categories.items);

  let category = {
    id: 0,
    name: '',
    active: false
  };

  useEffect(() => {
    dispatch(categoryActions.getAllCategories());
  }, [dispatch]);

  const handleClickedEdit = (rowData) => {
    getClickedCompany(rowData.id);
    history.push({
      pathname: '/category/edit',
      state: {
        category: {category}
      }
    });
  }

  const handleClickedView = (rowData) => {
    getClickedCompany(rowData.id);
    history.push({
      pathname: '/category/details',
      state: {
        category: {category}
      }
    });
  }

  const handleClickedAdd = () => {
    history.push('/category/add');
  }

  const getClickedCompany = (id) => {
    let obj = categories.find((d) => (d.id === id));
    category = {
      id: obj.id,
      name: obj.name,
      active:obj.active
    };

  }

  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <PageHeader
          subTitle={'List of All Categories'}
          title={'Category'}
        />
        {!categories ? (<LoadingSpinner/>) :
          (
            <MaterialTable
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <EmptyList
                      label="No Categories to Display!"
                    />
                  ),
                },
              }}
              actions={[
                {
                  icon: () => <AddOutlinedIcon />,
                  tooltip: 'Add Category',
                  isFreeAction: true,
                  onClick: (event) => handleClickedAdd()
                },
                rowData => ({
                  icon: () => <VisibilityOutlinedIcon />,
                  tooltip: 'View',
                  onClick: (event, rowData) => {
                    handleClickedView(rowData);
                  }
                }),
                (rowData) => ({
                  icon: () => <EditOutlinedIcon />,
                  tooltip: 'Edit',
                  onClick: (event, rowData) => {
                    handleClickedEdit(rowData)
                  }
                }),
              ]}
              columns={[
                {
                  title: 'Name',
                  field: 'name'
                }
              ]}
              data={categories}
              options={{
                search: true,
                searchFieldAlignment: 'left'

              }}
              title=""
            />
          )}
      </div>
    </div>
  )
}

export default ListCategory;
