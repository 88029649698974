import React, {useEffect, useState} from 'react';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './style.css';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ShareIcon from '@mui/icons-material/Share';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {makeStyles} from '@mui/styles';
import {useSelector} from 'react-redux';
import {Divider, IconButton, Paper, Typography} from '@mui/material';

import * as PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Button from '../../../../components/CustomButtons/Button';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {DOCUMENT_STATUS} from "../../../../constants/DOCUMENT_STATUS";
import StatusNote from "./components/StatusNote";
import {AddCircle, DownloadForOffline, RemoveCircle, SpeakerNotes} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import {history} from "../../../../helpers/history";
import {DOCUMENT_VIEWER_MODES} from "../../../../constants/DOCUMENT_VIEWER_MODES";
import {roles} from "../../../../constants/roles";
import VoDRenderer from "../VoDFileViewer";
import {vodService} from "../../../../store/services";
import {blue} from "@mui/material/colors";
import DocumentAttributes from "../../ListDocument/components/DocumentAttributes";
import DocumentNotes from "../../ListDocument/components/DocumentNotes";

// const useStyles = makeStyles(theme => ({
//   content: {
//     width: '100%',
//     height: '100%',
//     padding: 0,
//   },
//   documentContent: {
//     maxWidth: '100%',
//     maxHeight: '100%',
//   },
//   pageContent: {
//     overflow: 'scroll',
//     "& > .annotationLayer .textAnnotation img": {
//       maxWidth: "8px",
//       maxHeight: "8px"
//     }
//   },
//   panelContent: {
//     padding: theme.spacing(2)
//   },
//   approveButton: {
//     margin: theme.spacing(0.1),
//     backgroundColor: theme.palette.green,
//     color: '#FFF'
//   },
//   disapproveButton: {
//     backgroundColor: theme.palette.red,
//     margin: theme.spacing(0.1),
//     color: '#FFF'
//   },
//   holdButton: {
//     margin: theme.spacing(0.1),
//     backgroundColor: theme.palette.orange,
//     color: '#FFF'
//   },
//   deleteButton: {
//     margin: theme.spacing(0.1),
//     backgroundColor: theme.palette.black,
//     color: '#FFF'
//   },
//   notesButton: {
//     margin: theme.spacing(0.1),
//     backgroundColor: theme.palette.black,
//     color: '#FFF'
//   },
//   infoButton: {
//     margin: theme.spacing(0.1),
//     backgroundColor: "white",
//     color: blue
//   },
//   errorMessage: {
//     color: '#FF0000',
//     fontSize: '.75em',
//     fontWeight: 800
//   },
//   fab: {
//     margin: theme.spacing(1),
//     position: 'fixed',
//     bottom: theme.spacing(4),
//     zIndex: 9999
//   },
//   extendedIcon: {
//     marginRight: theme.spacing(1),
//   },
//   modalContent: {
//     position: 'absolute',
//     border: '2px solid white',
//     backgroundColor: 'white',
//     boxShadow: '2px solid white',
//     height: '60%',
//     width: '60%',
//     overflowY: 'scroll',
//     margin: 'auto'
//   },
//   modalHeader: {
//     padding: theme.spacing(3),
//     color: theme.palette.white,
//     backgroundColor: theme.palette.secondary.main,
//     fontWeight: 'bold'
//   },
// }));

class ElevationScroll extends React.Component {
  render() {
    return null;
  }
}

ElevationScroll.propTypes = {children: PropTypes.node};
// Create Document Component
const FileViewer = (
  props
) => {
  const {
    classes,
    file,
    approval,
    tabData,
    tabId,
    guid,
    view,
    shared,
    notesCount,
    metaData,
    displayName,
    onHandleApproved,
    onHandleRejected,
    onHandleHoldForQuestions,
    onHandleRemove,
    onHandleShare,
    onHandleRemoveShare,
    status
  } = props;

  // const classes = useStyles();

  const {role, roleDetails, privileges} = useSelector(state => {
    const roleTemp = state.authentication.user.role;
    const userPrivileges = state.authentication.user.availablePrivileges;
    const roleDetailList = roles.filter(r => r.value === roleTemp, this);
    return {
      role: roleTemp,
      privileges: userPrivileges,
      roleDetails: roleDetailList.length > 0 ? roleDetailList[0] : {}
    }
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1.5);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [prevButton, setPrevButton] = useState(true);
  const [nextButton, setNextButton] = useState(false);
  const [fileToRender, setFileToRender] = useState(null);
  const [openNote, setOpenNote] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [documentInfo, setDocumentInfo] = useState({
    notes: "",
    status: status,
    private: false,
  });

  const isAudioVideo = tabData.vod_access && tabData.vod_access.endsWith(".xml");

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  const onNoteChange = (event) => {
    setDocumentInfo(documentInfo => ({...documentInfo, [event.target.name]: event.target.value}));
  }

  const onStatusSelected = (selected) => {
    setDocumentInfo({...documentInfo, status: selected});
    setOpenNote(true);
  }

  const onHandleSwitchChange = (event) => {
    setDocumentInfo({...documentInfo, [event.target.name]: (event.target.checked ? 1 : 0)});
  }

  const onDownloadFile = (blob) => {
    const actualFileUrlPromise = !tabData.vod_access ?
      blob.then(blob => window.URL.createObjectURL(blob)) :
      vodService.getVoDFileDirectUrl(tabData.original_filename).then(url => url.toString());
    actualFileUrlPromise.then(fileURL => {
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `${tabData.display_name}${tabData.display_name.endsWith(tabData.file_type) ? "" : tabData.file_type}`;
      alink.click();
    });
  };

  const onPrevPage = (event) => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
    disableButtons(pageNumber - 1);
  };

  const onNextPage = (event) => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
    disableButtons(pageNumber + 1);
  };

  const onShowNotes = () => {
    setShowNotes(true);
  };


  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleCloseShowNotes = () => {
    setShowNotes(false);
  };

  const onShowInfo = () => {
    setOpenInfo(true);
  };

  const onPageChange = (event) => {
    let newPage = parseInt(event.target.value);

    if (newPage < 1) {
      newPage = 1;
    } else if (newPage > numPages) {
      newPage = numPages;
    } else {
      setPageNumber(newPage);
    }

    disableButtons(newPage);
  };

  const onZoomIn = (event) => {
    setPageScale(pageScale + 0.2);
  };

  const onZoomOut = (event) => {
    setPageScale(pageScale - 0.2);
  };

  const disableButtons = (page) => {
    if (page <= 1) {
      setPrevButton(true);
    } else {
      setPrevButton(false);
    }

    if (page >= numPages) {
      setNextButton(true);
    } else {
      setNextButton(false);
    }
  }

  useEffect(() => {
    if (file instanceof Promise) {
      file.then(response => {
        setFileToRender(response);
      })
    } else {
      if (file && file.data) {
        setFileToRender(file.data)
      }
    }
  }, [file])

  const zoomOutButton = <RemoveCircle
    style={{color: "red", margin: 5}}
    aria-label="Zoom Out"
    disabled={pageScale <= 0.3}
    id="zoomOut"
    onClick={onZoomOut}
    size="large"
  >
  </RemoveCircle>

  const zoomInButton = <AddCircle
    style={{color: "green", margin: 5}}
    aria-label="Zoom In"
    disabled={pageScale >= 3}
    id="zoomIn"
    onClick={onZoomIn}
    size="large"
  >
  </AddCircle>

  const downloadButton = (privileges.indexOf('Download') > -1) ? (
    <Button
      color="info"
      id="download"
      onClick={(event) => onDownloadFile(file)}
      size="small"
    >
      <DownloadForOffline/> Download
    </Button>
  ) : undefined

  const previousPageButton = <Button
    color="info"
    disabled={prevButton}
    id="prev"
    onClick={onPrevPage}
    size="small"
  >
    <NavigateBeforeIcon/> Prev page
  </Button>

  const nextPageButton = <Button
    color="primary"
    disabled={nextButton}
    id="next"
    onClick={onNextPage}
    size="small"
  >
    <NavigateNextIcon/> Next page
  </Button>

  const fileRenderer = (!fileToRender) ?
    (<LoadingSpinner/>) :
    (isAudioVideo) ?
      getAudioVideoViewer() :
      getDocumentViewer();

  function getDocumentViewer() {
    return (<Document
      className={classes.documentContent}
      file={fileToRender}
      onLoadSuccess={onDocumentLoadSuccess}
      imageResourcesPath={"annotationPDF/"}
      error={`Failed to Render "${tabData.display_name}"`}
      noData={`No File Specified, Not Even "${tabData.display_name}"`}
      loading={`Loading "${tabData.display_name}"`}
    >
      <Page
        className={classes.pageContent}
        scale={pageScale}
        pageNumber={pageNumber}
      />
    </Document>)
  }

  function getAudioVideoViewer() {
    return (<VoDRenderer
      videoName={tabData.display_name}
      videoUUID={tabData.guid}
      videoLink={tabData.vod_access}
    />);
  }

  return (
    <div className="PDFView">
      <Divider/>
      <div
        onContextMenu={(e) => e.preventDefault()}
      >
        <Paper
          className={classes.content}
          elevation={1}
        >

          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
          >
            <div className={classes.fab}>
              <Grid
                alignItems="center"
                container
                direction="row"
                justifyContent="center"
              >
                {!isAudioVideo ? (<>
                  {zoomOutButton}
                  {zoomInButton}
                  {downloadButton}
                  {previousPageButton}
                  {nextPageButton}
                </>) : (<>
                  {downloadButton}
                </>)}
                {(metaData && metaData.length > 0) ? (
                  <IconButton
                    id="info"
                    onClick={onShowInfo}
                    size="large"
                  >
                    <InfoIcon fontSize="large" sx={{color: blue[500]}}/>
                  </IconButton>
                ) : undefined}
                {(notesCount > 0) ? (
                  <IconButton
                    id="notes"
                    onClick={onShowNotes}
                    size="large"
                  >
                    <SpeakerNotes fontSize="large" sx={{color: "black"}}/>
                  </IconButton>
                ) : undefined}
                {!isAudioVideo ? (<Typography
                  style={{marginLeft: 20, marginRight: 20}}
                  variant="h4"
                >Page
                  <input
                    id="page"
                    name="page"
                    onChange={onPageChange}
                    type="text"
                    value={pageNumber}
                  />
                  <span>of {numPages}</span>
                </Typography>) : undefined}
                {openNote ? (
                  <StatusNote
                    classes={classes}
                    documentId={guid}
                    openNote={openNote}
                    setOpenNote={setOpenNote}
                    documentInfo={documentInfo}
                    ownerGuid={tabData.owner_guid}
                    section={tabData.section}
                    displayName={tabData.display_name}
                    onNoteChange={onNoteChange}
                    onHandleRejected={onHandleRejected}
                    onHandleHoldForQuestions={onHandleHoldForQuestions}
                    onHandleRemove={onHandleRemove}
                    onHandleApproved={onHandleApproved}
                    onHandleSwitchChange={onHandleSwitchChange}
                    view="pdf"
                    tabId={tabId}
                    role={role}
                  >
                  </StatusNote>) : undefined
                }

                <React.Fragment>
                  {(approval === 'true') && (view !== DOCUMENT_VIEWER_MODES.SELECT) && (status !== DOCUMENT_STATUS.REMOVED) &&
                    (roleDetails && roleDetails.isAdmin) &&
                    <div>
                      <Button
                        aria-label="Approve"
                        className={classes.approveButton}
                        onClick={(event) =>
                          onHandleApproved(guid, tabId, tabData.display_name, tabData.section, tabData.owner_guid, DOCUMENT_STATUS.APPROVED)
                        }
                        size="small"
                        style={{position: 'relative', backgroundColor: '#0aac23'}}
                        type="submit"
                        variant="text"
                      >
                        <ThumbUpIcon/> Approve
                      </Button>
                      {status !== DOCUMENT_STATUS.REJECTED &&
                        <Button
                          aria-label="Reject"
                          className={classes.disapproveButton}
                          onClick={() => onStatusSelected(DOCUMENT_STATUS.REJECTED)}
                          size="small"
                          style={{position: 'relative', backgroundColor: '#bf0913'}}
                          type="submit"
                          variant="text"
                        >
                          <ThumbDownIcon/> Reject
                        </Button>
                      }
                      {status !== DOCUMENT_STATUS.HOLD &&
                        <Button
                          aria-label="Hold"
                          className={classes.holdButton}
                          onClick={() => onStatusSelected(DOCUMENT_STATUS.HOLD)}
                          size="small"
                          style={{position: 'relative', backgroundColor: '#FFC31E'}}
                          type="submit"
                          variant="text"
                        >
                          <ContactSupportIcon/> Hold
                        </Button>
                      }
                    </div>
                  }

                  {(approval === 'false') &&
                    (view === DOCUMENT_VIEWER_MODES.SELECT) &&
                    <div>
                      {shared !== 'true' ? (
                        <Button
                          aria-label="Share"
                          className={classes.approveButton}
                          onClick={
                            () => onHandleShare(guid, tabId)
                          }
                          size="small"
                          style={{position: 'relative', backgroundColor: '#0aac23'}}
                          type="submit"
                          variant="text"
                        >
                          <ShareIcon/> Share
                        </Button>
                      ) : (
                        <Button
                          aria-label="RemoveShare"
                          className={classes.disapproveButton}
                          onClick={() => onHandleRemoveShare(guid, tabId)}
                          size="small"
                          style={{position: 'relative', backgroundColor: '#bf0913'}}
                          type="submit"
                          variant="text"
                        >
                          <CancelPresentationIcon/> Remove Share
                        </Button>
                      )}
                    </div>
                  }

                  {(view === DOCUMENT_VIEWER_MODES.STAGED) && (!status.includes(DOCUMENT_STATUS.REMOVED)) &&
                    <Button aria-label="Remove"
                            className={classes.deleteButton}
                            style={{position: 'relative'}}
                            type="submit"
                            size="small"
                            variant="text"
                            onClick={() => onStatusSelected(DOCUMENT_STATUS.REMOVED)}
                    >
                      <DeleteForeverIcon/> Remove
                    </Button>
                  }
                </React.Fragment>
              </Grid>
              {openInfo &&
                <DocumentAttributes
                  style={{
                    position: 'absolute',
                    border: '2px solid #000',
                    backgroundColor: 'white',
                    boxShadow: '2px solid black',
                    height: 80,
                    width: 200
                  }}
                  showModal={openInfo}
                  setShowModal={setOpenInfo}
                  handleCloseModal={handleCloseInfo}
                  classes={classes}
                  displayName={tabData.display_name}
                  pageTitle="Document Attributes"
                  metaData={tabData.metaData}
                  view={view}

                />
              }
              {showNotes &&
                <DocumentNotes
                  showModal={showNotes}
                  setShowModal={setShowNotes}
                  handleCloseModal={handleCloseShowNotes}
                  classes={classes}
                  displayName={tabData.display_name}
                  pageTitle={(view === DOCUMENT_VIEWER_MODES.STAGED) ? 'Notes - Staged Documents' : 'Notes - Documents'}
                  document={tabData}
                  view={view}
                />
              }
            </div>
            {fileRenderer}
          </Grid>
        </Paper>
      </div>
    </div>
  );
}

export default FileViewer;
