import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {Card, CardContent, CardHeader, Divider, Link, Tooltip} from '@mui/material';
import Button from "../../../../../components/CustomButtons/Button";
import LinkIcon from '@mui/icons-material/Link';
import {useDispatch, useSelector} from 'react-redux';
import MaterialTable from '@material-table/core';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {history} from '../../../../../helpers/history';
import {Layers} from "@mui/icons-material";
import {companyActions} from "../../../../../store/actions";
import {companyService} from "../../../../../store/services";
import CardBody from "../../../../../components/Card/CardBody";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {green} from "@mui/material/colors";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import {SearchPanel} from "../../../../Document/components";
import {GuidBasedMenuItem} from "../../../../Document/components/panelSegments/components";
import {EmptyList} from "../../../../../components/EmptyList";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 500,
      actions: {
      justifyContent: 'flex-end'
    },
  },
  content: {
    minHeight: '100%',
    padding: 0,
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  },
  title: {
    fontSize: "large"
  },
  subheader: {
    color: theme.palette.white
  },
  addButton: {
    color: theme.palette.white,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light

    }
  },
  icon: {
    padding: 5,
    minHeight: 42,
      minWidth: 42,
      color: "white",
    backgroundColor: "#2d2d2d",
    borderRadius: 5
  },
  action: {
    margin: 0
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  connectedAppImage: {
    maxWidth: 70,
    width: 50,
    height: 'auto'
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  iconImageDiv: {
    width: '100%',
    alignContent: 'center',
  },
  imageTitle: {
    maxWidth: 90,
    position: 'relative',
    padding: 5,
    border: '2px solid white',
    borderRadius: 10
  },
  imageMarked: {
    height: 1,
    width: 40,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 20px)',
    transition: theme.transitions.create('opacity'),
  }
}));

/*const statusColors = {
  public: palette.publicSection,
  company: palette.companySection,
  private: palette.privateSection
};*/

const ConnectedAppsList = props => {
  const { initial } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const companies = useSelector(state => state.companies.items);
  const [connectedApps, setConnectedApps] = useState(null);
  const [companySelect, setCompanySelect] = useState(user.companyId);
  const requestPending = useSelector(state => state.companies.requestPending);
  const [initialRender, setInitialRender] = useState(initial);

  let pathname = "";

  const handleClickedLink = (rowData) => {
    pathname='/connectedapps/link';
    history.push({
      pathname: pathname,
      state: {
        submit: "Link",
        initialConnectedApp: {
          link: rowData.link,
          id: rowData.id,
          companyId: rowData.companyId,
          name: rowData.name
        }
      }
    });
  }

  const handleClickedEdit = (rowData) => {
    pathname='/settings/connectedapps/details';
    history.push({
      pathname: pathname,
      state: {
        action: "Edit",
        initialConnectedApp: rowData,
      }
    });
  }

  const handleClickedAdd = () => {
    let data = {
      bgColor: "",
      companyId: companySelect,
      description: "",
      enabled: 1,
      iconFile: "",
      id: 0,
      link: "",
      name: ""
    }
    pathname='/settings/connectedapps/details';
    history.push({
      pathname: pathname,
      state: {
        action: "Add",
        initialConnectedApp: data,
      }
    });
  }

  const handleCompanySelect = event => {
    setCompanySelect(event.target.value);
    setConnectedApps(null);
  };

  useEffect(() => {
    if (initialRender) {
      dispatch(companyActions.getAllActiveCompanies());
      companyService.getConnectedApps(companySelect)
        .then((results) => {
          setConnectedApps(results.data.connectedApps);
        });
      setInitialRender(false);
    }
  }, [dispatch, connectedApps, initialRender, companySelect]);

  return (
    <div className={classes.root}>
      <Card className={classes.content}>
        <form>
        <CardHeader
          className={classes.titleHeader}
          classes={{
            action: classes.action,
            subheader: classes.subheader,
            title: classes.title
          }}

          subheader={'Manage Orchestrated Apps'}
          title={'Orchestrated Apps'}
          action={
            <Tooltip
              title="Add Orchestrated Apps"
              aria-label="Add Orchestrated Apps"
            >
              <Button
                variant="contained"
                color={"primary"}
                onClick={handleClickedAdd}
                startIcon={<Layers />}
              >
                Add Orchestrated Apps
              </Button>
            </Tooltip>
          }
        />
        <Divider />
        {(requestPending || !companies || !connectedApps) ? (<LoadingSpinner/>) :(
          <div className={classes.content}>
            <CardContent>
              <Grid
                container
                spacing={6}
              >
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                >
                  <h2 className={classes.cardIconTitle}>Search Filter</h2>
                </Grid>
              </Grid>
              <Grid
                container
              >
                <hr/>
              </Grid>
              <div>
                <SearchPanel
                  classes = {classes}
                  companySelect = {companySelect}
                  initialCompanies = {companies}
                  onHandleCompanySelect = {handleCompanySelect}
                  searchCompaniesByMethod = {GuidBasedMenuItem}
                  includeAllCompanies = {false}
                  renderButton = {false}
                />
              </div>
            </CardContent>
            <CardBody style={{backgroundColor: '#FFF', padding: 0}}>

              <MaterialTable
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <EmptyList
                        label="No Orchestrated Apps Setup For This Company  !"
                      />
                    ),
                  },
                }}
                actions={[
                  (rowData) => ({
                    icon: () => <EditOutlinedIcon />,
                    tooltip: 'Edit Orchestrated App',
                    onClick: (event, rowData) => {
                      handleClickedEdit(rowData)
                    }
                  }),
                  (rowData) => ({
                    icon: () => <LinkIcon />,
                    tooltip: 'Change Link to Apps',
                    onClick: (event, rowData) => {
                      handleClickedLink(rowData)
                    }
                  })
                ]}
                columns={[
                  {
                    title: 'Apps Name',
                    field: 'name'
                  },
                  {
                    title: 'Description',
                    field: 'description',
                    width:  '15%'
                  },
                  {
                    title: 'URL',
                    field: 'link',
                    render: app =>
                      (
                        <Link href={app.link} rel="noopener" color={"secondary"} variant={"subtitle2"} target={"_blank"} >{app.link}</Link>
                      )
                  },
                  {
                    title: 'Icon',
                    field: 'iconFile',
                    render: app => (
                      <FontAwesomeIcon className={classes.icon} style={{backgroundColor: app.bgColor}} icon={Icons[app.iconFile]}/>
                    )
                  },
                  {
                    field: 'enabled', title: 'Active',
                    render: app =>
                      (app.enabled ? (<CheckCircleIcon style={{color: green[500]}}/>) : (
                        <RemoveCircleRoundedIcon color={'error'}/>))
                  },
                ]}
                data={connectedApps}
                options={{
                  emptyRowsWhenPaging: false,
                  pageSize: 10,
                  search: true,
                  searchFieldAlignment: 'left',
                  actionsColumnIndex: -1
                }}
                title=""
              />
            </CardBody>
          </div>

        )}
      </form>
    </Card>
    </div>
  );
};

export default ConnectedAppsList;
