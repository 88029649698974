import * as React from 'react';
import Card from "../../../../../components/Card/Card";
import {Grid, Modal, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {DOCUMENT_STATUS} from "../../../../../constants/DOCUMENT_STATUS";
import {alertActions, documentActions} from "../../../../../store/actions";
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {green} from "@mui/material/colors";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import MaterialTable, {MTableBodyRow, MTableToolbar} from "@material-table/core";
import PageHeader from "../../../../../components/PageHeader/PageHeader";
import CardBody from "../../../../../components/Card/CardBody";
import NotesToolbar from "../NotesToolbar";
import {documentService} from "../../../../../store/services";
import DocumentAttributes from "../DocumentAttributes";
import StatusNote from "../../../components/FileViewer/components/StatusNote";
import {DOCUMENT_VIEWER_MODES} from "../../../../../constants/DOCUMENT_VIEWER_MODES";
import Select from "@mui/material/Select";
import {isEmpty, isSet} from "underscore";

const DocumentNotes = props => {
  const {
    showModal,
    handleCloseModal,
    classes,
    displayName,
    pageTitle,
    document,
    view
  } = props;

  const dispatch = useDispatch();

  const [initial, setInitial] = useState(true);
  const {companyId, id, role} = useSelector(state => state.authentication.user);

  // const notesDetails = useSelector(state => state.documents.notes);
  const [openNote, setOpenNote] = useState(false);
  const [documentNotes, setDocumentNotes] = useState(null);
  const [lastChangedBy, setLastChangedBy] = useState(null);
  const [documentInfo, setDocumentInfo] = useState({
    notes: "",
    status: DOCUMENT_STATUS.NOTES,
    private: true,
  });

  let uniqueId = 1;

  let documentFields = {
    'document_guid': '',
    'user_guid': id,
    'company_guid': companyId,
    'notes': '',
    'private': true
  }

  const onNoteChange = (event) => {
    setDocumentInfo(documentInfo => ({...documentInfo, [event.target.name]: event.target.value}));
  }

  const onHandleSwitchChange = (event) => {
    setDocumentInfo(({...documentInfo, [event.target.name]: (event.target.checked ? 1 : 0)}));
  }

  async function addNotes(documentFields) {
    return await documentService.insertDocumentNotes(documentFields)
      .then(response => {
          dispatch(alertActions.success("Successfully Added Notes!"))
          return response.data
        }
      )
  }

  // const getDateNow = (event) => {
  //   let dateNow = new Date();
  //   return ((dateNow.getMonth() + 1) + '/'  +  dateNow.getDate() + '/' + dateNow.getFullYear())
  //
  // }

  const handleAddNotes = (newData) => {
    documentFields.document_guid = document.guid;
    documentFields.user_guid = id;
    documentFields.company_guid = companyId;
    documentFields.notes = newData.notes;
    documentFields.status = DOCUMENT_STATUS.NOTES;
    if (isEmpty(newData.private)){
      documentFields.private = 1;
    } else {
      documentFields.private = Number(newData.private);
    }

    addNotes(documentFields).then(dtls => {
      setInitial(true);
      setDocumentInfo({
          notes: "",
          status: DOCUMENT_STATUS.NOTES,
          private: true
        }
      );
    });
  };

  useEffect(() => {
    if (initial) {
      documentService.getDocumentNotes(document)
        .then(state => {
          setDocumentNotes(state.data.documentNotes);
          setLastChangedBy(state.data.full_name);
          setInitial(false);
        });
    }
  // }, [dispatch, initial, document])
  }, [initial, document])

  let columns = [
    {
      title: 'Status',
      field: 'note_status',
      editable: 'never',
      initialEditValue: DOCUMENT_STATUS.NOTES
    },
    {
      title: 'Notes',
      field: 'notes',
      editComponent: props => (
        <input
          type="text"
          placeholder="Enter Notes"
          value={props.value}
          onChange={e => props.onChange(e.target.value)}
        />
      )
    },
    {
      title: 'Noted By',
      field: 'full_name',
      editable: 'never'
    },
    {
      title: 'Date Noted',
      field: 'date_created',
      type: 'date',
      dateSetting: {
        format: 'MM/dd/yyyy'
      },
      editable: 'never'
    }
  ];

  if (role.toLowerCase().includes("admin")) {
    columns.splice(3, 0,
      {
        title: "For Admin Only",
        field: "private",
        type: "numeric",
        render: note =>
          (Number(note.private) ? (<CheckCircleIcon style={{color: green[500]}}/>) : (
            <RemoveCircleRoundedIcon color={'error'}/>)),
        lookup: { 1: "true", 0: "false" },
      })
  }

  return (
    <Modal
      onClose={handleCloseModal}
      open={showModal}
      className={classes.modalContent}
    >
      {(!documentNotes || !classes) ? (<LoadingSpinner/>) : (
        <div className={classes.root}>
          <Card className={classes.content}>
            <Typography variant={"h4"} className={classes.modalHeader}>
              Notes - {displayName}
            </Typography>
            <CardBody style={{padding: 0}}>
              <Grid
                className={classes.content}
                alignItems={'center'}
                container
                justifyContent={'center'}
                // style={{padding: 0, margin: 0}}
              >
                <MaterialTable
                  style={{width: "100%", height: "100%", padding: 20, paddingBottom: 0}}
                  options={{
                    pageSize: documentNotes.length > 20 ? 20 : (documentNotes.length >= 5 ? documentNotes.length : 5),
                    searchFieldAlignment: 'left',
                    addRowPosition: 'first',
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          setDocumentNotes([...documentNotes, newData]);
                          handleAddNotes(newData);
                          resolve();
                        }, 1000)
                      }),
                  }}
                  columns={columns}
                  // components={components}
                  components={{
                    Row: props => <MTableBodyRow id={uniqueId += 1 } {...props} />
                  }}
                  data={documentNotes}
                  title=""
                />
              </Grid>
            </CardBody>
          </Card>
        </div>
      )}
    </Modal>
  );
}

export default DocumentNotes;
