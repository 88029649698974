import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import palette from 'theme/palette';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {Link} from 'react-router-dom';
import MaterialTable from "@material-table/core";
import {EmptyList} from "../../../../components/EmptyList";
import theme from "../../../../theme";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'fit-content'
  },
  content: {
    minHeight: '100%',
    padding: 0,
  },
  inner: {
    paddingTop: 20,
    paddingBottom: 20,
    minWidth: '100%',
    minHeight: 425,
    overflowX: "scroll"
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  section: {
    height: 20,
    width: 100,
    color: theme.palette.white
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  }
}));

const statusColors = {
  public: palette.publicSection,
  company: palette.companySection,
  private: palette.privateSection
};

const LatestDocuments = props => {
  const {className, metrics: {latestDocumentsData: {documents}}, ...rest} = props;

  const classes = useStyles();

  let docId = documents.length;
  const documentTableData = documents.map(document => {
    const date = moment(document.date).format("MM/DD/YYYY HH:mm:ss");
    const sectionDiv = (<div className={classes.statusContainer}>
      <Chip
        className={classes.section}
        label={document.section}
        size={'medium'}
        style={{backgroundColor: statusColors[document.section.toLowerCase()]}}
      />
    </div>);
    docId--;
    return {
      id: docId - 1,
      name: document.name,
      version: document.version,
      date: date,
      companyName: document.companyName,
      section: sectionDiv
    }
  }).reverse();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.titleHeader}
        title="Latest Documents"
      />
      <Divider/>
      <CardContent className={classes.content}>
        <MaterialTable
          columns={[
            {title: 'Name', field: 'name'},
            {title: 'Version', field: 'version'},
            {title: 'Date', field: 'date', type: 'date'},
            {title: 'Company', field: 'companyName'},
            {title: 'Section', field: 'section'}
          ]}
          data={documentTableData}
          options={{
            draggable: false,
            paging: true,
            pageSize: 5,
            emptyRowsWhenPaging: false,
            pageSizeOptions: [],
            search: false,
            sorting: false,
            toolbar: false}}

          localization={{
            body: {
              emptyDataSourceMessage: (
                <EmptyList
                  label="No documents to display!"
                />
              ),
            },
          }}
          showPaginationBottom={false}
          style={{padding: theme.spacing(2)}}
          title=""
        />
      </CardContent>
      <Divider/>
      {documents.length > 0 &&
        <CardActions className={classes.actions}>
          <Link to="/documents">
            <Button
              color="primary"
              size="small"
              variant="text"
            >
              View all <ArrowRightIcon/>
            </Button>
          </Link>
        </CardActions>
      }
    </Card>
  );
};

LatestDocuments.propTypes = {
  className: PropTypes.string
};

export default LatestDocuments;
