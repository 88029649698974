import React from 'react';
// material-ui components
import {withStyles} from "@mui/styles";

import styles from 'assets/jss/components/progressBarStyle.js'
import {DialogContent, LinearProgress, Typography} from "@mui/material";

const ProgressBar = React.memo(props => {
    const {
      currentProgress,
      fullProgress,
      error
    } = props;

    const completionAsPercent = Math.round(currentProgress / fullProgress * 1000) / 10;
    const errored = ((error !== undefined && error !== "" && completionAsPercent < 100) || Number.isNaN(completionAsPercent));

    const progressBarStyleVar = styles(completionAsPercent, errored);

    const StyledDialogContent = withStyles(progressBarStyleVar.progressBarBox)(DialogContent);
    const StyledLinearProgress = withStyles(progressBarStyleVar.progressBar)(LinearProgress);
    const StyledTypography = withStyles(progressBarStyleVar.progressBarText)(Typography);

    return (
      <StyledDialogContent>
        <StyledLinearProgress value={completionAsPercent} variant={'determinate'}/>
        <StyledTypography>{`${completionAsPercent}%`}</StyledTypography>
      </StyledDialogContent>
    );
  }
);

export default ProgressBar;
