/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {forwardRef, useState} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import { MultiLevel, SingleLevel } from "../index";
import hasChildren from "../../../../../../helpers/hasChildren";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    "&.active": {
      color: theme.palette.text.active,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.main
      }
    },
    color: theme.palette.primary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.text.active,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  multilevel:{
    paddingLeft: theme.spacing(3)
  }
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = props => {
  const { pages } = props;

  const { role } = useSelector(state => state.authentication.user);
  const [openLevel, setOpenLevel] = useState(null);
  const [initialRender, setInitialRender] = useState(true);

  const handleClick = (title) => {
    setOpenLevel(openLevel => ({...openLevel, [title]: !openLevel[title]}));
  };

  const classes = useStyles();
  const PageItem = ({ item }) => {
    if ((role.includes('Admin')) || ((!role.includes('Admin')) && (!item.admin))){
      const Component = hasChildren(item) ? MultiLevel : SingleLevel;
      return <Component classes={classes}
                        item={item}
                        openLevel={openLevel}
                        setOpenLevel={setOpenLevel}
                        handleClick={handleClick}
                        customRouterLink={CustomRouterLink}
      />;
    } else {
      return "";
    }
  };

  if (initialRender) {
    let openVariables = {}
    pages.map((page, key) => {
      if (page.menu) {
        openVariables[page.title] = false;
      }
      return page;
    });
    setOpenLevel(openVariables);
    setInitialRender(false);
  }

  return (
    pages.map((page, key) =>
      <PageItem key={key} item={page} />
    )
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
