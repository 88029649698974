import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import MaterialTable from '@material-table/core';

// @mui/icons-material
import {filetypeActions} from '../../../store/actions';

import PageHeader from '../../../components/PageHeader/PageHeader';
import {makeStyles} from '@mui/styles';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {
  classificationService,
  filetypeService
} from "../../../store/services";
import {SECURITY_ROLES} from "../../../constants/SECURITY_ROLES";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ListFiletype = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { role, companyId } = useSelector(state => state.authentication.user);
  const [classificationData, setClassificationData] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [fileTypeData, setFileTypeData] = useState(null);

  let fileType = {
    id: 0,
    extension: null,
    classification_id: 0,
    name: null,
    app_to_open: null,
    active: true,
    role:role,
    companyId: null
  };

  let columns = [
    {
      title: 'Extension',
      field: 'extension',
      editable: "never"
    },
    {
      title: 'Classification',
      field: 'classification_id',
      lookup: classificationData,
      validate: rowData => Boolean(rowData.classification_id)
    },
    {
      title: 'App To Open',
      field: 'app_to_open',
      editable: "never"
    },
    {
      title: 'Active',
      field: 'active',
      lookup: { 0: 'false', 1: 'true' },
      validate: rowData => Boolean(rowData.active),
    }
  ];

  let editable = {}
  if (role === SECURITY_ROLES.CLOUD_ADMIN) {
    columns = [
      {
        title: 'Extension',
        field: 'extension',
        validate: rowData => Boolean(rowData.extension)
      },
      {
        title: 'Media Type',
        field: 'classification_id',
        lookup: classificationData,
        validate: rowData => Boolean(rowData.classification_id)
      },
      {
        title: 'App To Open',
        field: 'app_to_open',
      },
      {
        title: 'Active',
        field: 'active',
        lookup: {0: 'false', 1: 'true'},
        validate: rowData => Boolean(rowData.active),
      }
    ];
    editable = {
      onRowAdd: newData =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            setFileTypeData([...fileTypeData, newData]);
            onHandleAdd(newData);
            resolve();
          }, 1000)
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            onHandleUpdate(newData);
            const dataUpdate = [...fileTypeData];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setFileTypeData([...dataUpdate]);
            resolve();
          }, 1000)
        }),
    }
  }

  async function getFileTypes () {
    await filetypeService.getAllFiletypes().then((results) => {
      setFileTypeData (results.data);
    });
  }

  const onHandleUpdate = (values) => {
    if (!values.extension.startsWith(".")){
      values.extension = '.' + values.extension;
    }
    getClickedFileType(values);
    dispatch(filetypeActions.update(fileType));
    getFileTypes();
  }

  const onHandleAdd = (values) => {
    if (!values.extension.startsWith(".")){
      values.extension = '.' + values.extension;
    }
    fileType = {
      id: 0,
      extension: values.extension,
      classification_id: values.classification_id,
      app_to_open: values.app_to_open,
      active: true,
      role: role,
      companyId: null
    };
    filetypeService.add(fileType)
      .then((results) => {
        getFileTypes();
      })
      .catch(function(error){
        getFileTypes();
      });

  }

  const getClickedFileType = (values) => {
    let obj = fileTypeData.find((d) => (d.filetype_id === values.filetype_id));
    fileType = {
      id: values.filetype_id,
      extension: values.extension,
      prev_extension: obj.extension,
      classification_id: values.classification_id,
      app_to_open: values.app_to_open,
      active: true,
      role: role,
      companyId: companyId
    };
  }

  useEffect(() => {
    if (initialRender){
      classificationService.getAllClassifications().then((results) => {
        if (initialRender) {
          let classification = results.data.reduce(function(acc, cur, i) {
            acc[cur.id] = cur.name;
            return acc;
          }, {});

          setClassificationData(classification);
        }
      });
      getFileTypes();
      setInitialRender(false);
    }

  }, [fileTypeData, classificationData, initialRender]);

  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <PageHeader
          subTitle={'List of All Filetypes'}
          title={'Filetype'}
        />
        {(!fileTypeData || !classificationData) ? (<LoadingSpinner/>) :
          (
            <MaterialTable
              editable={editable}
              columns={columns}
              data={fileTypeData}
              options={{
                idSynonym: "filetype_id",
                search: true,
                searchFieldAlignment: 'left',
                actionsColumnIndex: -1,
                addRowPosition: "first"

              }}
              title=""
            />
          )}
      </div>
    </div>
  )
}

export default ListFiletype;
