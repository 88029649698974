import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Card, Grid} from '@mui/material';
import {SECURITY_ROLES} from '../../../constants/SECURITY_ROLES';
import {Redirect} from 'react-router-dom';

import {DocumentMetrics, DocumentsHistory, LatestDocuments, TopDocumentsViewedMetrics, StagedDocumentMetrics} from '../components/';
import {useDispatch, useSelector} from 'react-redux';
import {CompanyMetrics, PendingDocuments, UsersMetrics} from './components/';
import CompanyUserRoleMetrics from './components/CompanyUserRoleMetrics';
import {dashboardService} from '../../../store/services';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";

const useStyles = makeStyles(theme => ({
  root: {
    //marginBottom: theme.spacing(4)
  }
}));

const CompanyAdminDashboard = () => {
  const classes = useStyles();
  const user = useSelector(state => state.authentication.user);
  const [metrics, setMetrics] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let isSubscribed = true;
    if (user) {
      dashboardService.getCompanyAdminDashboardMetrics(user.companyId)
        .then(metrics => isSubscribed ? setMetrics(metrics.data) : null);
    }
    return () => (isSubscribed = false);
  }, [user, dispatch]);

  return (
    <React.Fragment>
      {user && (user.role) && (user.role === SECURITY_ROLES.COMPANY_ADMIN) ?
        (
          <div className={classes.root}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <PendingDocuments metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Pending Documents"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <CompanyMetrics metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Company Metrics"}/></Card>)
                }

              </Grid>
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <UsersMetrics userData={metrics}/>) : (<Card><LoadingSpinner title={"Loading User Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <CompanyUserRoleMetrics metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Role Metrics"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <DocumentMetrics metrics={metrics} view={SECURITY_ROLES.COMPANY_ADMIN}/>) : (<Card><LoadingSpinner title={"Loading Document Metrics"}/></Card>)
                }

              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                sm={12}
                xl={4}
                xs={12}
              >
                {metrics ? (
                  <StagedDocumentMetrics metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Staging Document Metrics"}/></Card>)
                }

              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xl={12}
                xs={12}
              >
                {metrics ? (
                  <LatestDocuments metrics={metrics}/>) : (<Card><LoadingSpinner  title={"Loading Latest Documents"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xl={6}
                xs={12}
              >
                {metrics ? (
                  <TopDocumentsViewedMetrics metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Top Documents"}/></Card>)
                }
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                sm={12}
                xl={6}
                xs={12}
              >
                {metrics ? (
                  <DocumentsHistory metrics={metrics}/>) : (<Card><LoadingSpinner  title={"Loading Document History"}/></Card>)
                }
              </Grid>
            </Grid>
          </div>)
        :
        (<Redirect to="/login"/>)
      }
    </React.Fragment>
  );
};

export default CompanyAdminDashboard;
