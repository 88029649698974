import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import {Card, CardHeader, Divider} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import MaterialTable from '@material-table/core';
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import {SECURITY_ROLES} from "../../../../../constants/SECURITY_ROLES";
import {isNull} from "underscore";
import {settingService} from "../../../../../store/services";
import {settingActions} from "../../../../../store/actions";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 500,
      actions: {
      justifyContent: 'flex-end'
    },
  },
  content: {
    minHeight: '100%',
    padding: 0,
  },
  titleHeader: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.white
  },
  title: {
    fontSize: "large"
  },
  subheader: {
    color: theme.palette.white
  },
  button: {
    color: theme.palette.white,
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.light

    }
  },
  icon: {
    height: 32,
      width: 32,
      color: theme.palette.primary.main
  },
  action: {
    margin: 0
  }
}));

const Appsetting = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const { role, companyId } = useSelector(state => state.authentication.user);
  const [initialRender, setInitialRender] = useState(true);
  const [settingData, setSettingData] = useState(null);
  let columns = [
    {
      title: 'Short Name',
      field: 'name',
      editable: 'never'
    },
    {
      title: 'Full Name',
      field: 'display_name',
      editable: 'never'
    },
    {
      title: 'Setting Value',
      field: 'value',
      editable: 'onUpdate',
      validate: rowData => Boolean(rowData.value),
    },
    {
      title: 'Admin Only',
      field: 'admin',
      editable: 'never'
    }
  ];

  let editable = {};
  if (role === SECURITY_ROLES.CLOUD_ADMIN){
    columns = [
      {
        title: 'Short Name',
        field: 'name',
        validate: rowData => Boolean(rowData.name),
      },
      {
        title: 'Full Name',
        field: 'display_name',
        validate: rowData => Boolean(rowData.display_name),
      },
      {
        title: 'Setting Value',
        field: 'value',
        validate: rowData => Boolean(rowData.value),
      },
      {
        title: 'Admin Only',
        field: 'admin',
        lookup: { 0: 'false', 1: 'true' },
        validate: rowData => Boolean(rowData.admin),
      }
    ];
    editable = {
      onRowAdd: newData =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            setSettingData([...settingData, newData]);
            onHandleAdd(newData);
            resolve();
          }, 1000)
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...settingData];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setSettingData([...dataUpdate]);
            onHandleUpdate(newData);
            resolve();
          }, 1000)
        }),
    };
  } else {
    editable = {
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...settingData];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setSettingData([...dataUpdate]);
            onHandleUpdate(newData);
            resolve();
          }, 1000)
        }),
    };
  }

  let setting = {};

  const onHandleUpdate = (values) => {
    getClickedSetting(values);
    dispatch(settingActions.update(setting));
  }

  const onHandleAdd = (values) => {
    setting = {
      id: 0,
      csId: 0,
      name: values.name,
      display_name: values.display_name,
      value: values.value,
      admin: values.admin,
      role: role,
      companyId: null
    };
    dispatch(settingActions.add(setting));
  }

  const getClickedSetting = (values) => {
    let obj = settingData.find((d) => (d.name === values.name));
    setting = {
      id: obj.id,
      csId: obj.csId,
      name: values.name,
      display_name: values.display_name,
      value: values.value,
      admin: values.admin,
      role: role,
      companyId: companyId
    };
  }

  useEffect(() => {
    if (initialRender){
      settingService.getAllSettings().then((results) => {
        let transformedArray = results.data;
        if (role === SECURITY_ROLES.COMPANY_ADMIN){
          transformedArray = results.data.map(x => {
            if (!isNull(x.cValue)) {
              x.value = x.cValue
            }
            return (x)
          });
        }
        setSettingData(transformedArray);
      });
      setInitialRender(false);
    }
  }, [settingData, initialRender, role]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form>
        <CardHeader
          className={classes.titleHeader}
          classes={{
            action: classes.action,
            subheader: classes.subheader,
            title: classes.title
          }}
          title="Application Settings"
          subheader="Manage application settings"
        />
        <Divider />
        {(!settingData) ? (<LoadingSpinner/>) :(
            <MaterialTable
              columns={columns}
              data={settingData}
              options={{
                pageSize: settingData.length > 10 ? 10 : (settingData.length >= 5 ? settingData.length : 5),
                search: true,
                searchFieldAlignment: 'left',
                actionsColumnIndex: -1,
                addRowPosition: "first"
              }}
              editable={editable}
              title=""
            />
        )}
      </form>
    </Card>
  );
};

Appsetting.propTypes = {
  className: PropTypes.string
};

export default Appsetting;
