import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import {Sidebar, Topbar} from './components';
import {useDispatch, useSelector} from 'react-redux';
import {alertActions} from '../../store/actions';
import {toastHelper} from '../../helpers/toast.helper';
import PageFooter from '../../components/PageFooter/PageFooter';

const useStyles = makeStyles(theme => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.overlayProportions.topbarHeight
    },
  },
  shiftContent: {
    left: theme.overlayProportions.sidebarWidth,
    width: `calc(100% - ${Number.isNaN(theme.overlayProportions.sidebarWidth) ? theme.overlayProportions.sidebarWidth : `${theme.overlayProportions.sidebarWidth}px`}) !important`
  },
  content: {
    position: "absolute",
    width: "100%",
    paddingBottom: theme.overlayProportions.footerHeight,
    backgroundColor: theme.palette.background.default
  }
}));

const Main = props => {
  const {children} = props;
  const dispatch = useDispatch();
  const {type, message} = useSelector(state => state.alert);
  const classes = useStyles();
  const theme = useTheme();
  const authenticatedUser = useSelector(state => state.authentication.user);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  useEffect(() => {
    if (type) {
      toastHelper.showToast(type, message);
      dispatch(alertActions.clear());
    }
  }, [type, message, dispatch]);

  return authenticatedUser ?
    (<div
          className={classes.root}
        >
          <Topbar onSidebarOpen={handleSidebarOpen}/>
          <Sidebar
            onClose={handleSidebarClose}
            open={shouldOpenSidebar}
            variant={isDesktop ? 'persistent' : 'temporary'}
          />
          <main className={clsx({
            [classes.shiftContent]: isDesktop,
            [classes.content]: true
          })}>
            {children}
          </main>
          <PageFooter/>
        </div>) :
    (<Redirect to={{pathname: '/login', state: {from: props.location}}}/>);
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
