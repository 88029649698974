/**
 *
 * @param classifications
 * @param permittedValues {string[]}
 * @return {string[]}
 */
function filterToOneOfValues(classifications, permittedValues){
  return classifications.filter(type => permittedValues.includes(type.name))
}

/**
 *
 * @param classifications {string[]}
 * @param excludedValues {string[]}
 * @return {string[]}
 */
function filterToAllExceptValues(classifications, excludedValues){
  return classifications.filter(type => !excludedValues.includes(type.name))
}

const UploadDocDisplayedClassifications = {
  document: (classifications) => filterToAllExceptValues(classifications, ["Image", "Drawing","Drawing/CAD", "Audio", "Video", "Uncategorized"]),
  image: (classifications) => filterToOneOfValues(classifications, ["Image"]),
  drawing: (classifications) => filterToOneOfValues(classifications, ["Drawing", "Drawing/CAD"]),
  audio: (classifications) => filterToOneOfValues(classifications, ["Audio"]),
  video: (classifications) => filterToOneOfValues(classifications, ["Video"]),
  uncategorized: (classifications) => filterToOneOfValues(classifications, ["Uncategorized"])
}

export default UploadDocDisplayedClassifications;
