import {settingConstants} from '../../constants/index';
import {settingService} from '../../services/index';
import {alertActions} from '../index';
import {SECURITY_ROLES} from "../../../constants/SECURITY_ROLES";

export const settingActions = {
  add,
  update,
  getSettingByName,
  getAllSettings,
  getConnectedAppsLocation
};

function add(setting) {
  let name = setting.name;
  return dispatch => {
    dispatch(request(setting));

    settingService.add(setting)
      .then(
        setting => {
          dispatch(success(setting));
          dispatch(alertActions.success('Default Setting ' + name + ' added'));
          getAllSettings();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(setting) {
    return {type: settingConstants.ADDNEW_REQUEST, setting}
  }

  function success(setting) {
    return {type: settingConstants.ADDNEW_SUCCESS, setting}
  }

  function failure(error) {
    return {type: settingConstants.ADDNEW_FAILURE, error}
  }
}

function getAllSettings() {
  return dispatch => {
    dispatch(request());

    settingService.getAllSettings()
      .then(
        settings => dispatch(success(settings)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: settingConstants.GETALL_REQUEST}
  }

  function success(settings) {
    return {type: settingConstants.GETALL_SUCCESS, settings}
  }

  function failure(error) {
    return {type: settingConstants.GETALL_FAILURE, error}
  }
}

function getSettingByName(name, companyId=null) {
  return dispatch => {
    dispatch(request(name));

    settingService.getSettingByName(name, companyId)
      .then(
        setting => dispatch(success(setting)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request(name) {
    return {type: settingConstants.GET_SETTING_REQUEST}
  }

  function success(name) {
    return {type: settingConstants.GET_SETTING_SUCCESS, name}
  }

  function failure(error) {
    return {type: settingConstants.GET_SETTING_FAILURE, error}
  }
}


function getConnectedAppsLocation(companyId=null) {
  return dispatch => {
    dispatch(request(companyId));

    settingService.getConnectedAppsLocation(companyId)
      .then(
        setting => dispatch(success(setting)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request(companyId) {
    return {type: settingConstants.GET_CONNECTED_APPS_LOCATION_REQUEST}
  }

  function success(companyId) {
    return {type: settingConstants.GET_CONNECTED_APPS_LOCATION_SUCCESS, companyId}
  }

  function failure(error) {
    return {type: settingConstants.GET_CONNECTED_APPS_LOCATION_FAILURE, error}
  }
}


function update(setting) {
  let name = setting.name;
  let role = setting.role
  return dispatch => {
    dispatch(request(setting));

    settingService.update(setting)
      .then(
        setting => {
          dispatch(success(setting));
          if (role === SECURITY_ROLES.COMPANY_ADMIN){
            dispatch(alertActions.success('Company Setting ' + name + ' updated.'));
          } else {
            dispatch(alertActions.success('Setting ' + name + ' updated.'));
          }
          getAllSettings();
        },
        error => {
          dispatch(failure(error.toString()));
          if (role === SECURITY_ROLES.COMPANY_ADMIN){
            dispatch(alertActions.success('Failed to update company setting' + name + '.'));
          } else {
            dispatch(alertActions.success('Failed to update setting' + name + '.'));
          }
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(setting) {
    return {type: settingConstants.UPDATE_REQUEST, setting}
  }

  function success(setting) {
    return {type: settingConstants.UPDATE_SUCCESS, setting}
  }

  function failure(error) {
    return {type: settingConstants.UPDATE_FAILURE, error}
  }
}

