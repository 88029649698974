import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {AppBar, Badge, Hidden, IconButton, Toolbar, Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import {userActions} from '../../../../store/actions';
import {history} from '../../../../helpers/history';
import AppsLauncherIcon from 'assets/images/appHub.png';
import Logo from 'assets/images/logoTop.png';
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";

const useStyles = makeStyles(theme => (
  {
    root: {
      boxShadow: 'none',
      height: theme.overlayProportions.topbarHeight,
      position: "fixed"
    },
    topbar: {
      height: "100%",
      background: theme.palette.grey["900"]
    },
    flexGrow: {
      flexGrow: 1
    },
    iconButton: {
      height: "50%",
      marginLeft: theme.spacing(1)
    },
    logoImage: {
      height: "100%",
      marginLeft: "10%",
      background: theme.palette.info
    },
    topBarLogo: {
      marginLeft: "40%",
      color: theme.palette.white
    }
  }
));

const Topbar = props => {
  const {className, onSidebarOpen, ...rest} = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const {user} = useSelector(state => state.authentication);

  const [notifications] = useState([]);

  const orchestratedAppsButton = (user.role !== SECURITY_ROLES.SHARED_USER &&
    <Tooltip
      arrow
      title="Orchestrated Apps"
    >
      <IconButton
        className={classes.iconButton}
        onClick={() => history.push('/apps')}
      >
        <img alt="Apps" src={AppsLauncherIcon} style={{height: "100%"}}/>
      </IconButton>
    </Tooltip>
  )

  const notificationsButton = (<Tooltip
    arrow
    title="Notifications"
  >
    <IconButton color="inherit"
      className={classes.iconButton}>
      <Badge
        badgeContent={notifications.length}
        color="primary"
        variant="dot"
        sx={{width: "100%", height: "100%"}}
      >
        <NotificationsIcon sx={{width: "100%", height: "100%"}}/>
      </Badge>
    </IconButton>
  </Tooltip>)

  const switchRolesButton = (user.availableRoles.length > 1 &&
    <Tooltip
      arrow
      title="Switch Role"
    >
      <IconButton
        className={classes.iconButton}
        color="inherit"
        onClick={() => history.push('/role', {switchRole: true})}
      >
        <SwapHorizontalCircleIcon sx={{width: "100%", height: "100%"}}/>
      </IconButton>
    </Tooltip>
  )

  const signOutButton = (<Tooltip
    arrow
    title="Sign Out"
  >
    <IconButton
      className={classes.iconButton}
      color="inherit"
      onClick={() => dispatch(userActions.logout(user))}
    >
      <InputIcon sx={{width: "100%", height: "100%"}}/>
    </IconButton>
  </Tooltip>)

  const openMenuButton = (<Tooltip
    arrow
    title="Open Menu"
  >
    <IconButton
      className={classes.iconButton}
      color="inherit"
      onClick={onSidebarOpen}
    >
      <MenuIcon sx={{width: "100%", height: "100%"}}/>
    </IconButton>
  </Tooltip>)

  return (
    <AppBar
      {...rest}
      className={clsx(className, classes.root)}
    >
      <Toolbar className={classes.topbar}>
        <RouterLink to="/" style={{height: "100%"}}>
          <img
            alt="Maestro"
            className={classes.logoImage}
            src={Logo}
          />
        </RouterLink>
        <div className={classes.flexGrow}/>
        <Hidden lgDown>
          {orchestratedAppsButton}
          {notificationsButton}
          {switchRolesButton}
          {signOutButton}
        </Hidden>
        <Hidden lgUp>
          {openMenuButton}
          {orchestratedAppsButton}
          {switchRolesButton}
          {signOutButton}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
