import React from 'react';
import {makeStyles} from '@mui/styles';

import {SharedUsersTable} from './components';
import PageHeader from "../../../components/PageHeader/PageHeader";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const SharedUserList = (props) => {
  const {memberGuid, companyId } = props.location.state;

  const classes = useStyles();
  return (
    <div>
      {memberGuid &&
      <div className={classes.root}>
        <div className={classes.content}>
          <PageHeader title={"Shared Users"} subTitle={"List of All Shared Users"}/>
          <SharedUsersTable
            memberGuid={memberGuid}
            companyId={companyId}
            isSharedUser={false}
          />
        </div>
      </div>
      }
    </div>
  )
}

export default SharedUserList;
