import {ErrorMessage, Formik} from 'formik';
import * as Yup from 'yup';
import {history} from '../../../helpers/history';

import {ButtonGroup, Divider, Grid, TextField, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';
import {userActions} from '../../../store/actions';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import Button from "../../../components/CustomButtons/Button";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  loaderStyle: {
    marginLeft: theme.spacing(4),
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentHeaderText: {
    color: '#308ad0'
  }
}));

const RoleSelectionForm = (props) => {
  const classes = useStyles();
  const { switchRole } = props;
  const requestPending = useSelector(state => state.authentication.requestPending);
  const authenticatedUser = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();
  let selectedRole = authenticatedUser.role;
  const [values] = useState({
    selectedRole: authenticatedUser.role,
    availableRoles: authenticatedUser.availableRoles
  });
  return (
    <React.Fragment>
      { (requestPending) ? (<LoadingSpinner title={"Initializing your role as " + selectedRole }/>) : (
        <Formik
          initialValues={values}
          onSubmit={(values, {setSubmitting}) => {
            selectedRole = values.selectedRole
            dispatch(userActions.loginWithRole(values.selectedRole))
          }}
          validationSchema={Yup.object().shape({
            selectedRole: Yup.string()
              .trim()
              .required('Role is Required'),
          })}
        >
          {(props) => {
            const {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.contentHeader}>
                  <Typography
                    className={classes.contentHeaderText}
                    variant={'h3'}
                  >
                    Select Role
                  </Typography>
                  <Divider/>
                </div>

                <Grid
                  className={classes.content}
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      SelectProps={{native: true}}
                      defaultValue={values.selectedRole}
                      fullWidth
                      label="Select Role"
                      name="selectedRole"
                      onBlur={handleBlur}
                      // eslint-disable-next-line react/jsx-sort-props
                      onChange={(event) => {
                        selectedRole = event;
                        handleChange(event)
                      }}
                      select
                      variant="outlined"
                    >
                      {values.availableRoles.map(option => (
                        <option
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </TextField>
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="state"
                    />
                  </Grid>

                </Grid>
                <Divider/>
                <ButtonGroup orientation={"vertical"} fullWidth >

                  <Button
                    color="primary"
                    disabled={!values.selectedRole}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                  >
                    Continue
                  </Button>
                  <Button
                  color="github"
                  disabled={!values.selectedRole}
                  onClick={() => { switchRole ? history.push('/') :dispatch(userActions.logout(authenticatedUser))}}
                  variant="contained"
                >
                  Cancel
                </Button>
                </ButtonGroup>
              </form>
            )
          }}
        </Formik>)}

    </React.Fragment>
  )
}
export default RoleSelectionForm;

