import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {ButtonGroup, Card, Grid,} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import PageHeader from '../../../components/PageHeader/PageHeader';
import {UserAccount, UserInfo, UserPrivileges, UserRoles, UserSecurityQuestions} from '../components';
import Button from '../../../components/CustomButtons/Button';
import {userActions} from '../../../store/actions';
import {history} from '../../../helpers/history';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: theme.spacing(4)
  },
  fab: {
    margin: theme.spacing(1), // You might not need this now
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(3)
  },
}));



const ViewUser = (props) => {
  const title = props.location.state.title;
  const subtitle = props.location.state.subTitle;
  const values = props.location.state.user;
  const submit = props.location.state.submit;
  const additionalDetails = props.location.state.additionalDetails;
  const classes = useStyles();
  const dispatch = useDispatch();

  const {role, userId} = useSelector(state => {
    const roleTemp = state.authentication.user.role;
    const id = state.authentication.user.id;
    return {
      role: roleTemp,
      userId: id
    }
  });

  const [updateAccount, setUpdateAccount] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [updateRole, setUpdateRole] = useState(false);
  const [updateAnswer, setUpdateAnswer] = useState(false);
  const [accountValues, setAccountValues] = useState(null);
  const [infoValues, setInfoValues] = useState(null);
  const [privateValues, setPrivateValues] = useState(null);
  const [roleSelect, setRoleSelect] = useState('');
  const [userRoles, setUserRoles] = useState(null);
  const [userRoleList, setUserRoleList] = useState(null);
  const [roleItems, setRoleItems] = useState([]);
  const [answerValues, setAnswerValues] = useState({
    question_one: 0,
    question_two: 0,
    question_three: 0,
    answer_one: '',
    answer_two: '',
    answer_three: ''
  });
  const [hasInfoErrors, setHasInfoErrors] = useState( false);
  const [hasAnswerErrors, setHasAnswerErrors] = useState( false);
  const [hasAccountErrors, setHasAccountErrors] = useState( false);
  /*const [isTouched, setIsTouched] = useState({
    info: false,
    account: false,
    roles: false,
    answers: false
  });*/

  const [initialRender, setInitialRender] = useState(true);

  let edit = false;
  if (submit === 'Edit'){
    edit = true;
  }

  function getExpiresDate(nbrOfDays, passwordDate) {
    return (moment(passwordDate).add(nbrOfDays, 'days').format("MM/DD/YYYY"));
  }

  function getExpiry(additionalDetails) {
    let expiry = 'expires_on_login';
    if (additionalDetails.account.expires_days === ""){
      additionalDetails.account.expires_days = 0;
    }

    if (additionalDetails.account.expires_days > 0){
      expiry = 'expires_on_days';
    } else if (additionalDetails.account.does_not_expire === 1) {
      expiry = 'does_not_expire';
    }

    return expiry;
  }

  if (initialRender) {
    if (additionalDetails){
      //////////////////
      // User Account
      //////////////////
      let account = {};
      account = {
        attempt: additionalDetails.account.attempt,
        deactivate_date: additionalDetails.account.deactivate_date,
        does_not_expire: additionalDetails.account.does_not_expire,
        expires_days: additionalDetails.account.expires_days,
        expires_on_login: additionalDetails.account.expires_on_login,
        password_expiry: getExpiry(additionalDetails),
        locked: additionalDetails.account.locked,
        sharedUser: additionalDetails.account.sharedUser,
        active: additionalDetails.account.active,
        password_date: additionalDetails.account.password_date,
        expireDate: (additionalDetails.account.expires_days === 0) ?
          moment().format("MM-DD-YYYY") :
          getExpiresDate(additionalDetails.account.expires_days, additionalDetails.password_date)
      };

      setAccountValues(account);

      //////////////////
      // Expire Date Info


      //////////////////
      // User Info
      //////////////////
      setInfoValues(values);

      //////////////////
      // User Privileges
      //////////////////
      if (additionalDetails.userPrivs){
        setPrivateValues(additionalDetails.userPrivs);
      }

      //////////////////
      // User Roles
      //////////////////
      if (additionalDetails.roles) {
        let foundRoles = {};
        let initialRoleItems = [];
        let privRoleList = [];

        additionalDetails.roles.forEach(function (r) {
            initialRoleItems.push({
              'name': r.role,
              'type': 'boolean',
              'value': r.role,
              'validationType': 'boolean'
            });

          foundRoles = Object.assign(foundRoles,
            {[r.role]: (additionalDetails.userRoles.includes(r.role)) ? 1 : 0});

          if (additionalDetails.userRoles){
            if (additionalDetails.userRoles.includes(r.role)){
              privRoleList.push({
                'id': r.id,
                'role': r.role
              });
            }
          }

        });

        setUserRoles(foundRoles);
        setRoleItems(initialRoleItems);
        setUserRoleList(privRoleList);
        if (privRoleList.length > 0) {
          setRoleSelect(privRoleList[0].id);
          //setRoleSelectName(privRoleList[0].role);
        } else {
          setRoleSelect('')
        }
      }

      ////////////////////////////
      // User Security Questions
      ////////////////////////////
      if (additionalDetails.answers === null) {
        setAnswerValues({
          question_one: 0,
          question_two: 0,
          question_three: 0,
          answer_one: '',
          answer_two: '',
          answer_three: ''
        });
      } else if (additionalDetails.answers !== answerValues) {
        setAnswerValues(additionalDetails.answers);
      }
    }

    setInitialRender(false);

  }

  //////////////////
  // User Account
  //////////////////
  const onHandleSwitchChange = (event) => {
    if (edit){
      setAccountValues(accountValues => ({ ...accountValues, [event.target.name]: (event.target.checked ? 1 : 0)}));
      setUpdateAccount(true);
    }
  };

  const onHandleRadioChange = (event) => {
    if (edit){
      const pwd_value = event.target.value;
      if (event.target.value === 'does_not_expire'){
        setAccountValues(accountValues => ({
          ...accountValues,
          'does_not_expire': 1,
          'expires_days': 0,
          'expires_on_login': 0,
          'password_expiry': pwd_value
        }));
      } else if (event.target.value === 'expires_on_login') {
        setAccountValues(accountValues => ({
          ...accountValues,
          'does_not_expire': 0,
          'expires_days': 0,
          'expires_on_login': 1,
          'password_expiry': pwd_value
        }));
      } else {
        setAccountValues(accountValues => ({
          ...accountValues,
          'does_not_expire': 0,
          'expires_on_login': 0,
          'password_expiry': pwd_value
        }));
      }

      setUpdateAccount(true);
    }
  };

  const onExpireDaysChange = (event) => {
    if (edit){
      let passwordDate = new moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
      setAccountValues(accountValues => ({ ...accountValues,
        'password_expiry': 'expires_on_days',
        'expires_days': event.target.value,
        'password_date': passwordDate,
        'expireDate': getExpiresDate(event.target.value, passwordDate)}));
      setUpdateAccount(true);
    }
  };

  //////////////////
  // User Info
  //////////////////
  const onHandleInfoChange = (event) => {
    if (edit){
      setInfoValues(infoValues => ({ ...infoValues, [event.target.name]: event.target.value}));
      setUpdateInfo(true);
    }
  };


  //////////////////
  // User Roles
  //////////////////
  const onHandleRoleChange = (event) => {
    if (edit){
      setUserRoles(userRoles => ({ ...userRoles, [event.target.name]: (event.target.checked ? 1 : 0)}));
      setUpdateRole(true);
    }
  };

  ////////////////////
  // User Privileges
  ////////////////////
  const onHandlePrivilegeChange = (event) => {
    //TODO: handle privilege changes properly
    console.warn({message: "Privilege updates not supported at this time", eventReference: event});
  };

  ////////////////////////////
  // User Security Questions
  ////////////////////////////
  const onHandleTextChange = (event) => {
    if (edit){
      setAnswerValues(answerValues => ({ ...answerValues, [event.target.name]: event.target.value}));
      setUpdateAnswer(true);
    }
  };

  const onHandleSubmit = () => {
    let updateList = '';
    let infoUpdate = null;
    let accountUpdate = null;
    let roleUpdate = null;
    let answerUpdate = null;

    if (updateAccount) {
      updateList += '\nAccount';
      accountUpdate = accountValues;
    }

    if (updateInfo) {
      updateList += '\nInfo';
      infoUpdate = infoValues;
    }

    if (updateRole) {
      updateList += '\nRoles';
      if (values.id === userId){
        if (!(role in userRoles)){
          setUserRoles(userRoles => ({ ...userRoles, [role]: 1}));
        }
      }


      roleUpdate = userRoles;
    }

    if (updateAnswer) {
      updateList += '\nSecurity Answers';
      answerUpdate = answerValues;
    }

    if (updateList){
      setUpdateAccount(false);
      setUpdateAnswer(false);
      setUpdateInfo(false);
      setUpdateRole(false);

      let userDetailValues = {
        id: values.id,
        companyId: values.companyId,
        accountUpdate: accountUpdate,
        infoUpdate: infoUpdate,
        roleUpdate: roleUpdate,
        answerUpdate: answerUpdate
      }

      dispatch(userActions.updateUserDetails(userDetailValues));
      history.push('/users');
    }
  };

  return (
    <div className={classes.root}>
      <PageHeader
        subTitle={subtitle}
        title={title}
      />
      {(!additionalDetails) ? (<LoadingSpinner/>) : (
        <Card className={classes.content}>
          {(!infoValues || !accountValues || !additionalDetails['questions'] || !userRoles ||
            !roleItems || !userRoleList || !additionalDetails['privileges'] || !privateValues)
            ? (<LoadingSpinner/>) :
            (
              <div>
                <Grid
                  container
                  spacing={4}
                  paddingBottom={4}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xl={6}
                    xs={12}
                  >
                    <UserInfo
                      edit={edit}
                      infoValues={infoValues}
                      password={additionalDetails.account.password}
                      onHandleInfoChange={onHandleInfoChange}
                      setHasInfoErrors={setHasInfoErrors}
                      setInfoValues={setInfoValues}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <UserSecurityQuestions
                      allQuestions={additionalDetails.questions}
                      answerValues={answerValues}
                      edit={edit}
                      onHandleTextChange={onHandleTextChange}
                      setHasAnswerErrors={setHasAnswerErrors}
                    />
                  </Grid>
                </Grid>
                { additionalDetails.account.password.length === 0 ? null : (
                <Grid
                  container
                  spacing={4}
                >

                  <Grid
                    item
                    lg={3}
                    md={3}
                    xl={3}
                    xs={12}
                  >
                    <UserAccount
                      accountValues={accountValues}
                      userRoles={userRoles}
                      edit={edit}
                      onExpireDaysChange={onExpireDaysChange}
                      onHandleRadioChange={onHandleRadioChange}
                      onHandleSwitchChange={onHandleSwitchChange}
                      setHasAccountErrors={setHasAccountErrors}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                  >
                    <UserRoles
                      edit={edit}
                      onHandleRoleChange={onHandleRoleChange}
                      roleItems={roleItems}
                      title="Roles"
                      userRoles={userRoles}
                    />
                  </Grid>
                  {userRoleList.length === 0 ? null : (
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={6}
                      xs={12}
                    >
                      <UserPrivileges
                        company_guid = {values.companyId}
                        roleSelect={roleSelect}
                        privileges={additionalDetails.privileges}
                        title="Privileges"
                        userRoleList={userRoleList}
                        user_guid = {values.id}
                        onHandlePrivilegesChange={onHandlePrivilegeChange}
                      />
                    </Grid>
                    )}
                </Grid>
                )}
                  {(!edit) ? ('') : (
                  <ButtonGroup>
                    {(hasInfoErrors || hasAnswerErrors || hasAccountErrors) ? (
                      <Button size="large"
                        aria-label="Update"
                        className={classes.fab}
                        disabled
                        onClick={onHandleSubmit}
                        style={{position: 'fixed'}}
                        type="submit"
                        variant="text"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button size="large"
                        aria-label="Update"
                        className={classes.fab}
                        color="primary"
                        onClick={onHandleSubmit}
                        style={{position: 'fixed'}}
                        type="submit"
                        variant="text"
                      >
                        Update
                      </Button>
                    )}
                  </ButtonGroup>
                )}

              </div>
            )}
        </Card>
      ) }

    </div>
  );
};

export default ViewUser;
