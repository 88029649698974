import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from './styles';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import {Link} from 'react-router-dom';

const TotalDocuments = props => {
  const { className, metrics, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL VIEWABLE DOCUMENTS
            </Typography>
            <Typography
              variant="h3"
              className={classes.valueText}
            >{metrics.documentCount}
            </Typography>
          </Grid>
          <Grid item>
            <Link to={'/documents'}>
            <Avatar className={classes.avatar}>
              <DescriptionIcon className={classes.icon} />
            </Avatar>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalDocuments.propTypes = {
  className: PropTypes.string
};

export default TotalDocuments;
