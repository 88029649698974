import React, {useState} from 'react'
import {LinkDialog} from "../components";
import {makeStyles} from "@mui/styles";
import {companyActions} from "../../../store/actions";
import {useDispatch} from "react-redux";
import LoadingSpinner from "../../../components/Spinner/loading.spinner";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  updateButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  cancelButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  errorMessage: {
    color: "#ff0000",
    fontSize: '.75em',
    fontWeight: 800
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

const ConnectedAppsLink = props => {
  let initialConnectedApp = props.location.state.initialConnectedApp;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [open] = useState(true);
  const [initialDialogRender, setInitialDialogRender] = useState( true);
  const [hasLinkErrors, setHasLinkErrors] = useState( true);
  const [linkInfo, setLinkInfo] = React.useState(initialConnectedApp);

  const handleLinkUpdate = (data) => {

    let appValues = {};
    appValues["id"] = data.id;
    appValues["companyGuid"] = data.companyId;
    appValues["link"] = data.link;
    appValues["name"] = data.name;

    setLinkInfo(appValues);

  };

  const onHandleLinkChange = (event) => {
    setLinkInfo(linkInfo => ({...linkInfo, [event.target.name]: event.target.value}));
  };

  const onHandleSubmit = (values) => {
    dispatch(companyActions.updateConnectedAppLink(values));
    return true;
  }

  if (initialDialogRender){
    if (initialConnectedApp){
        setLinkInfo({
          id: initialConnectedApp.id,
          companyId: initialConnectedApp.companyId,
          link: initialConnectedApp.link,
          name: initialConnectedApp.name
        });
    }
    setInitialDialogRender(false);
  }

  return(
    <div>
      {(!linkInfo) ? (<LoadingSpinner/>) :
      (
        <LinkDialog
          open = {open}
          classes = {classes}
          linkInfo = { linkInfo }
          hasLinkErrors = { hasLinkErrors }
          setHasLinkErrors = { setHasLinkErrors }
          onHandleLinkChange = { onHandleLinkChange }
          handleLinkUpdate = { handleLinkUpdate }
          onHandleSubmit = { onHandleSubmit }
        >
        </LinkDialog>

      )}
    </div>
  )
}

export default ConnectedAppsLink
