import palette from 'theme/palette';

export const options = {
  maintainAspectRatio: false,
  animation: {
    duration: 200,
    delay: 0,
    loop: false
  },
  pointBorderWidth: 6,
  pointRadius: 3,
  spanGaps: true,
  pointHoverBorderWidth: 12,
  hoverBorderWidth: 12,
  hoverBorderRadius: 12,
  borderRadius: 1,
  tension: 0.3,
  plugins: {
    legend: { display: true },
    tooltip: {
      enabled: true,
      mode: 'nearest',
      usePointStyle: true,
      intersect: false,
      borderColor: palette.divider,
      backgroundColor: palette.primary.dark,
      titleColor: palette.white,
    },
  },
};
