const hasChildren = (item) => {
  const { menu: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}
export default hasChildren
