import {userConstants} from '../../constants/index';

const initialState = {
  requestPending: false,
  profile: null
};

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_USER_PROFILE_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GET_USER_PROFILE_SUCCESS:
      return {...state,
        profile: action.user,
        requestPending: false
      };
    case userConstants.GET_USER_PROFILE_FAILURE:
      return {...state,
        error: action.error,
        profile: null,
        requestPending: false
      };
    case userConstants.UPDATE_USER_PROFILE_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.UPDATE_USER_PROFILE_SUCCESS:
      return {...state,
        profile: action.user,
        requestPending: false
      };
    case userConstants.UPDATE_USER_PROFILE_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.UPDATE_USER_DETAILS_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.UPDATE_USER_DETAILS_SUCCESS:
      return {...state,
        data: action.user,
        requestPending: false
      };
    case userConstants.UPDATE_USER_DETAILS_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GETALL_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GETALL_SUCCESS:
      return {...state,
        items: action.users.data,
        requestPending: false
      };
    case userConstants.GETALL_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GETALLBYCOMPANY_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GETALLBYCOMPANY_SUCCESS:
      return {...state,
        items: action.user.data,
        requestPending: false
      };
    case userConstants.GETALLBYCOMPANY_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GET_USER_PRIVILEGES_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GET_USER_PRIVILEGES_SUCCESS:
      return {...state,
        privs: action.users.data,
        requestPending: false
      };
    case userConstants.GET_USER_PRIVILEGES_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GET_USER_ROLE_PRIVILEGES_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GET_USER_ROLE_PRIVILEGES_SUCCESS:
      return {...state,
        privs: action.users.data,
        requestPending: false
      };
    case userConstants.GET_USER_ROLE_PRIVILEGES_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GET_SHARED_USER_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GET_SHARED_USER_SUCCESS:
      return {...state,
        shareduser: action.user,
        requestPending: false
      };
    case userConstants.GET_SHARED_USER_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };

    case userConstants.SEND_EMAIL_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.SEND_EMAIL_SUCCESS:
      return {...state,
        data: action.data,
        requestPending: false
      };
    case userConstants.SEND_EMAIL_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GET_ADDITIONAL_USER_DETAILS_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GET_ADDITIONAL_USER_DETAILS_SUCCESS:
      return {...state,
        additional: action.users.data,
        requestPending: false
      };
    case userConstants.GET_ADDITIONAL_USER_DETAILS_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.GET_USER_SECURITY_QUESTIONS_REQUEST:
      return {...state,
        requestPending: true
      };
    case userConstants.GET_USER_SECURITY_QUESTIONS_SUCCESS:
      return {...state,
        items: action.user.data,
        requestPending: false
      };
    case userConstants.GET_USER_SECURITY_QUESTIONS_FAILURE:
      return {...state,
        error: action.error,
        requestPending: false
      };
    case userConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        requestPending: true,
        items: state.items.map(user =>
          user.id === action.id
            ? {...user, deleting: true}
            : user
        )
      };
    case userConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {...state,
        requestPending: false,
        items: state.items.filter(user => user.id !== action.id)
      };
    case userConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        requestPending: false,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const {deleting, ...userCopy} = user;
            // return copy of user with 'deleteError:[error]' property
            return {...userCopy, deleteError: action.error};
          }

          return user;
        })
      };
    default:
      return state
  }
}
