import {classificationConstants} from '../../constants/index';

const initialState = {};

export function classifications(state = initialState, action) {
  switch (action.type) {
    case classificationConstants.ADDNEW_REQUEST:
      return {...state,
        requestPending: true
      };
    case classificationConstants.ADDNEW_SUCCESS:
      return {
        ...state,
        id: action.classification.data,
        requestPending: false
      };
    case classificationConstants.ADDNEW_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case classificationConstants.GETALL_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case classificationConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.classifications.data,
        requestPending: false
      };
    case classificationConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case classificationConstants.GET_CLASSIFICATION_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case classificationConstants.GET_CLASSIFICATION_SUCCESS:
      return {
        ...state,
        item: action.data,
        requestPending: false
      };
    case classificationConstants.GET_CLASSIFICATION_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case classificationConstants.UPDATE_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case classificationConstants.UPDATE_SUCCESS:
      return {
        ...state,
        item: action.classification.data,
        requestPending: false
      };
    case classificationConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
