import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Card, CardContent, Grid, Tab, Tabs, TextField,} from '@mui/material';
import Button from "../../components/CustomButtons/Button";
import {alertActions, userActions} from '../../store/actions';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import useStyles from './styles';
import {toastHelper} from '../../helpers/toast.helper';
import MaestroLogo from 'assets/images/logo.png';
import LoginBackground from 'assets/images/login-background-2.jpg';
import LoadingSpinner from "../../components/Spinner/loading.spinner";
import {history} from "../../helpers/history";
import { maestroenv } from '../../helpers/maestroenv';

function Login() {
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
    accessKey: ''
  });
  const [activeTabId, setActiveTabId] = useState(0);
  const {username, password, accessKey} = inputs;
  const {requestPending} = useSelector(state => state.authentication);
  const dispatch = useDispatch();
  const {type, message} = useSelector(state => state.alert);
  const [sharedLogin, setSharedLogin] = useState(false);

  useEffect(() => {
    if (type) {
      toastHelper.showToast(type, message);
      dispatch(alertActions.clear());
    }
  }, [dispatch, type, message]);

  const oauthLogin = () => {
    dispatch(userActions.oauthLogin(encodeURI(maestroenv.REACT_APP_OAUTH_URL)));
    history.push('/loading', {title: "Contacting Login Server"});
  }

  function handleChange(e) {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if ((username)) {
      if (password && !sharedLogin) {
        dispatch(userActions.login(username, password));
      } else if (accessKey && sharedLogin) {
        dispatch(userActions.loginAsSharedUser(username, accessKey));
      }
    }
  }

  return (
    <Grid
      className={classes.container}
      container
      style={{backgroundImage: `url(${LoginBackground})`}}
    >
      <div className={classes.formContainer}>

        <Card
          className={classes.cardContainer}
          raised
        >
          <CardContent>
            <div className={classes.form}>
              <Grid
                className={classes.loginLogoContainer}
                container
              >
                <img
                  alt="Maestro"
                  className={classes.loginLogoImage}
                  src={MaestroLogo}
                />
              </Grid>
              {!requestPending  &&
                  <div>
                    <Tabs
                      centered
                      className={classes.tabs}
                      indicatorColor="secondary"
                      onChange={(e, id) => {
                        setInputs({username: '', password: '', accessKey: ''});
                        setActiveTabId(id);
                        setSharedLogin(id === 1);
                      }}
                      textColor="secondary"
                      value={activeTabId}
                    >
                      <Tab
                        classes={{root: classes.tab}}
                        label="Member"
                      />
                      <Tab
                        classes={{root: classes.tab}}
                        label="Non-Member"
                      />
                    </Tabs>
                    {activeTabId === 0 && (
                      <React.Fragment>
                        <div className={classes.formDividerContainer}>
                          <div className={classes.formDivider}/>
                        </div>
                        <Button
                          variant="contained"
                          color={"primary"}
                          size={"large"}
                          className={classes.loginButton}
                          startIcon={<VpnKeyIcon/>}
                          onClick={oauthLogin}
                        >
                          Login
                        </Button>
                      </React.Fragment>
                    )}
                    {activeTabId === 1 && (
                      <React.Fragment>
                        <div className={classes.formDividerContainer}>
                          <div className={classes.formDivider}/>
                        </div>
                        <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          color="secondary"
                          fullWidth
                          id="username"
                          margin="normal"
                          name="username"
                          onChange={handleChange}
                          placeholder="Email Address"
                          type="username"
                          value={username}
                        />
                        <TextField
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                          color="secondary"
                          fullWidth
                          id="accessKey"
                          margin="normal"
                          name="accessKey"
                          onChange={handleChange}
                          placeholder="Password"
                          type="password"
                          value={accessKey}
                        />
                        <div className={classes.formButtons}>
                          {requestPending ? (
                            <LoadingSpinner/>
                          ) : (
                            <Button
                              color="primary"
                              disabled={
                                username.length === 0 || accessKey.length === 0
                              }
                              onClick={handleSubmit}
                              size="large"
                              variant="contained"
                            >
                              Login
                            </Button>
                          )}
                          <Button
                            className={classes.forgetButton}
                            color="primary"
                            size="large"
                          >
                            Forgot Access Key?
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
              }
            </div>
          </CardContent>
        </Card>
      </div>


    </Grid>

  );
}

export default Login;
