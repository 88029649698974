import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Paper, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {green} from '@mui/material/colors';
import MaterialTable from "@material-table/core";
import theme from "../../../../../theme";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DescriptionIcon from '@mui/icons-material/Description';
import {history} from "../../../../../helpers/history";
import CardBody from "../../../../../components/Card/CardBody";
import Card from "../../../../../components/Card/Card";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, companyActions} from "../../../../../store/actions";
import moment from "moment";
import {userService} from "../../../../../store/services";
import {Schedule} from "@mui/icons-material";
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import {AccessKeyDialog, PasswordExpirationDialog} from "../../../components";
import {EmptyList} from "../../../../../components/EmptyList";
import {SECURITY_ROLES} from "../../../../../constants/SECURITY_ROLES";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%'
  },
  content: {
    marginTop: 0,
    margin: 0,
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  updateButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  cancelButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  errorMessage: {
    color: "#ff0000",
    fontSize: '.75em',
    fontWeight: 800
  },
  switch: {
    display: "flex",
    justifyContent: "space-between",
  }

}));

const SharedUsersTable = (props) => {
    const {
      memberGuid,
      companyId,
      isSharedUser = false, //sharedDocsTooltip = 'Shared Documents'
    } = props;
    const dispatch = useDispatch();

    const { role } = useSelector(state => state.authentication.user);
    const companies = useSelector(state => state.companies.items);
    const [initialRender, setInitialRender] = useState(true);
    const [users, setUsers] = useState(null);
    const [openExpiry, setOpenExpiry] = useState(false);
    const [openAccessKey, setOpenAccessKey] = useState(false);
    const [passwordInfo, setPasswordInfo] = React.useState(null);
    const [keyInfo, setKeyInfo] = React.useState(null);

    const classes = useStyles();

    let initialUserInfo = {
      firstName: '',
      lastName: '',
      email: ''
    }

    let initialAdditionalInfo = {
      memberGuid: memberGuid,
      companyGuid: companyId,
      sharedUserGuid: '',
      print: 0,
      download: 0,
      view: 1,
      started: null,
      expires: null,
      expiresDays: 1,
      doesNotExpire: 0,
      sharedKey: '',
      confirmKey: '',
      keyExpiry: 'expires_on_days',
      active: 0
    }

    /*const handleCompanySelect = event => {
      setCompanySelect(event.target.value);
    };*/

    const getSharedUsers = async (memberGuid) => {
      listSharedUsers(memberGuid).then(sharedUsers => {
        setUsers(sharedUsers);
      });
    };

    const getSharedUsersCallback = useCallback(getSharedUsers, []);

    async function updateSharedUserPasswordInfo(values) {
      return await userService.updateSharedUserPasswordInfo(values).then(response => {
          dispatch(alertActions.success("Successfully Updated Expiration for SharedUser  : " + response.data.firstName + " " + response.data.lastName));
          return response.data
        }
      )
    }

    async function updateSharedUserAccessKey(values) {
      return await userService.updateSharedUserKeyInfo(values).then(response => {
          dispatch(alertActions.success("Successfully Updated Access Key for SharedUser  : " + response.data.firstName + " " + response.data.lastName));
          return response.data
        }
      )
    }


    async function listSharedUsers(memberGuid) {
      return await userService.getAllSharedUsers(memberGuid).then(response => response.sharedUsers)
    }

    const initializeSharedUserInfo = (sharedUser) => {
      initialUserInfo.firstName = sharedUser.firstName;
      initialUserInfo.lastName = sharedUser.lastName;
      initialUserInfo.email = sharedUser.email;
      initialUserInfo.street = sharedUser.street;
      initialUserInfo.city = sharedUser.city;
      initialUserInfo.state = sharedUser.state;
      initialUserInfo.zip = sharedUser.zipCode;
      initialUserInfo.phone = sharedUser.phone;

      initialAdditionalInfo.memberGuid = sharedUser.memberGuid;
      initialAdditionalInfo.sharedUserGuid = sharedUser.guid;
      initialAdditionalInfo.companyGuid = sharedUser.companyGuid;
      initialAdditionalInfo.sharedByMember = sharedUser.sharedByMember;
      initialAdditionalInfo.active = sharedUser.active;
      initialAdditionalInfo.print = sharedUser.printPrivilege;
      initialAdditionalInfo.view = sharedUser.viewPrivilege;
      initialAdditionalInfo.download = sharedUser.downloadPrivilege;
      initialAdditionalInfo.started = sharedUser.started;
      initialAdditionalInfo.doesNotExpire = sharedUser.doesNotExpire;
      initialAdditionalInfo.expiresDays = sharedUser.expiresDays;
      let expDate = new Date(sharedUser.expires)
      if (sharedUser.doesNotExpire) {
        expDate = new Date(Date.now());
      }

      let dd = String(expDate.getDate()).padStart(2, '0');
      let mm = String(expDate.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = expDate.getFullYear();

      expDate = mm + '/' + dd + '/' + yyyy;

      initialAdditionalInfo.expires = expDate;
      initialAdditionalInfo.keyExpiry = sharedUser.doesNotExpire ? 'does_not_expire' : 'expires_on_days';
    }

    const getSharedUserDetails = (viewType, sharedUser) => {
      initializeSharedUserInfo(sharedUser);

      history.push({
        pathname: "/sharedusers/view",
        state: {
          title: "Shared User Details",
          subTitle: viewType + " Shared User",
          submit: viewType,
          user: initialUserInfo,
          additionalDetails: initialAdditionalInfo
        }
      })
    }

    const handleClickedEdit = (rowData) => {
      getSharedUserDetails("Edit", rowData);
    }

    const handlePasswordExpirationDialog = (rowData) => {
      let keyExpiry = 'expires_on_days';
      if (rowData.doesNotExpire)
        keyExpiry = 'does_not_expire';

      let expDate = new Date(rowData.expires)
      let dd = String(expDate.getDate()).padStart(2, '0');
      let mm = String(expDate.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = expDate.getFullYear();

      expDate = mm + '/' + dd + '/' + yyyy;

      setPasswordInfo({
        doesNotExpire: rowData.doesNotExpire,
        expiresDays: rowData.expiresDays,
        keyExpiry: keyExpiry,
        started: rowData.started,
        expires: expDate,

        memberGuid: rowData.memberGuid,
        companyGuid: rowData.companyGuid,
        sharedUserGuid: rowData.guid
      });

      setOpenExpiry(true);
    }

    const handleAccessKeyDialog = (rowData) => {
      initializeSharedUserInfo(rowData);
      setKeyInfo({
        sharedKey: '',
        confirmKey: '',
        memberGuid: rowData.memberGuid,
        companyGuid: rowData.companyGuid,
        sharedUserGuid: rowData.guid
      });

      setOpenAccessKey(true);
    }

    const onHandleExpirySubmit = (values) => {
      updateSharedUserPasswordInfo(values).then(sharedUser => {
        getSharedUsers(sharedUser.memberGuid);
      });
    }

    const onHandleAccessKeySubmit = (values) => {
      updateSharedUserAccessKey(values).then(sharedUser => {
        getSharedUsers(sharedUser.memberGuid);
      });
      return true;
    };

    const handleClickedView = (rowData) => {
      getSharedUserDetails("View", rowData);
    }

    const handleClickedSharedDocuments = (rowData) => {
      let pathname = `/documents${isSharedUser ? `` : '/toshare'}`;
      history.push({
        'pathname': pathname,
        'isSharedUser': isSharedUser,
        'initialSection': 'All Sections',
        'startDate': new moment().subtract(1, 'years'),
        'sharedUserGuid': rowData.guid,
        'sharedUserName': rowData.firstName + ' ' + rowData.lastName,
        'memberGuid': rowData.memberGuid
      });
    }

    const headers = [
      {
        field: 'name', title: "Name", render: user =>
          <div className={classes.nameContainer}
               style={{
                 display: 'flex',
                 alignItems: 'center'
               }}>
            <Typography
              variant="body1">{user.firstName + ' ' + user.lastName}
            </Typography>
          </div>
      },
      {field: 'email', title: "Email"},
      {
        field: 'expires',
        title: "Date Expires",
        type: 'date',
        dateSetting: {
          format: 'MM/dd/yyyy'
        },
      },
      {
        field: 'viewPrivileges', title: "View",
        render: user =>
          (user.viewPrivilege ? (<CheckCircleIcon style={{color: green[500]}}/>) : (
            <RemoveCircleRoundedIcon color={'error'}/>))
      },
      {
        field: 'printPrivileges', title: "Print",
        render: user =>
          (user.printPrivilege ? (<CheckCircleIcon style={{color: green[500]}}/>) : (
            <RemoveCircleRoundedIcon color={'error'}/>))
      },
      {
        field: 'downloadPrivileges', title: "Download",
        render: user =>
          (user.downloadPrivilege ? (<CheckCircleIcon style={{color: green[500]}}/>) : (
            <RemoveCircleRoundedIcon color={'error'}/>))
      },
      {
        field: 'accessExpired', title: "Access",
        render: user =>
          (user.accessExpired ? (<RemoveCircleRoundedIcon color={'error'}/>) : (
            <CheckCircleIcon style={{color: green[500]}}/>))
      },
    ];

    let actions = [
      {
        icon: () => <Schedule/>,
        tooltip: 'Access Expiration',
        onClick: (evt, rowData) => {
          handlePasswordExpirationDialog(rowData);
        },
        position: 'row'
      },
      {
        icon: () => <VpnKeyIcon/>,
        tooltip: 'Change Access Key',
        onClick: (evt, rowData) => {
          handleAccessKeyDialog(rowData);
        },
        position: 'row'
      },

      (rowData) => {
        let iconColor = '#FF0000';
        let toolTipMessage = 'No Shared Documents';
        if (rowData.sharedDocumentCount > 0) {
          iconColor = '#228b22';
          toolTipMessage = 'Has Shared Documents';
        }
        return (
          {
            icon: () => <DescriptionIcon style={{color: iconColor}}/>,
            tooltip: toolTipMessage,
            onClick: (evt, rowData) => {
              handleClickedSharedDocuments(rowData);
            },
            position: 'row'
          })
      },
    ];

    if (role.toLowerCase().includes("admin")){
      actions.unshift(
        {
          icon: () => <VisibilityOutlinedIcon/>,
          tooltip: 'View Shared User',
          onClick: (evt, rowData) => {
            handleClickedView(rowData);
          },
          position: 'row'
        },
        {
          icon: () => <EditOutlinedIcon/>,
          tooltip: 'Edit Shared User',
          onClick: (evt, rowData) => {
            handleClickedEdit(rowData);
          },
          position: 'row'
        })
    }

    actions.concat([
      {
        icon: () => <Schedule/>,
        tooltip: 'Access Expiration',
        onClick: (evt, rowData) => {
          handlePasswordExpirationDialog(rowData);
        },
        position: 'row'
      },
      {
        icon: () => <VpnKeyIcon/>,
        tooltip: 'Change Access Key',
        onClick: (evt, rowData) => {
          handleAccessKeyDialog(rowData);
        },
        position: 'row'
      },

      (rowData) => {
        let iconColor = '#FF0000';
        let toolTipMessage = 'No Shared Documents';
        if (rowData.sharedDocumentCount > 0) {
          iconColor = '#228b22';
          toolTipMessage = 'Has Shared Documents';
        }
        return (
          {
            icon: () => <DescriptionIcon style={{color: iconColor}}/>,
            tooltip: toolTipMessage,
            onClick: (evt, rowData) => {
              handleClickedSharedDocuments(rowData);
            },
            position: 'row'
          })
      },
    ]);

    useEffect(() => {
      if (initialRender) {
        dispatch(companyActions.getAllActiveCompanies());
        getSharedUsersCallback(props.memberGuid).then(()=>{});
        setInitialRender(false);
      }
    }, [dispatch, getSharedUsersCallback, initialRender, props.memberGuid]);

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {(!users || !companies || initialRender) ? (<LoadingSpinner/>) :
            (
              <Card className={classes.content}>
                <CardBody>
                  <MaterialTable
                    data={users}
                    options={{
                      idSynonym: "guid",
                      emptyRowsWhenPaging: false,
                      pageSize: 20,//Math.min(Math.max(users ? users.length : 0, 5), 20),
                      searchFieldAlignment: 'right',
                      actionsColumnIndex: -1
                    }}
                    localization={{
                      body: {
                        emptyDataSourceMessage: (
                          <EmptyList
                            label="No Shared Users Found!"
                          />
                        ),
                      },
                    }}
                    style={{padding: theme.spacing(2)}}
                    actions={actions}
                    columns={headers}
                    title=""
                  />
                </CardBody>
                {openExpiry ? (
                  <PasswordExpirationDialog
                    openExpiry={openExpiry}
                    setOpenExpiry={setOpenExpiry}
                    classes={classes}
                    passwordInfo={passwordInfo}
                    onHandleSubmit={onHandleExpirySubmit}
                  >
                  </PasswordExpirationDialog>) : undefined
                }
                {openAccessKey ? (
                  <AccessKeyDialog
                    openAccessKey={openAccessKey}
                    setOpenAccessKey={setOpenAccessKey}
                    classes={classes}
                    keyInfo={keyInfo}
                    onHandleSubmit={onHandleAccessKeySubmit}
                  >
                  </AccessKeyDialog>) : undefined
                }
              </Card>
            )}
        </Paper>
      </div>
    );
  }
;

export default SharedUsersTable;
