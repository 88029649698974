import React from 'react';
import {makeStyles} from '@mui/styles';
import {Card, Grid} from '@mui/material';

import {AccountDetails, AccountProfile} from './components';
import {useSelector} from 'react-redux';
import PageHeader from '../../components/PageHeader/PageHeader';
import LoadingSpinner from "../../components/Spinner/loading.spinner";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: theme.spacing(4)
  }
}));

const Account = () => {
  const classes = useStyles();
  const userProfile = useSelector(state => state.users.profile);

  return (
    <div className={classes.root}>
      <PageHeader
        subTitle={'View/Edit Account Details'}
        title={'Account Details'}
      />
      <Card className={classes.content}>
        {!userProfile ? (<LoadingSpinner/>) :
          (<Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              md={6}
              xl={4}
              xs={12}
            >
              <AccountProfile profile={userProfile}/>
            </Grid>
            <Grid
              item
              lg={8}
              md={6}
              xl={8}
              xs={12}
            >
              <AccountDetails profile={userProfile}/>
            </Grid>
          </Grid>)}
      </Card>
    </div>
  );
};

export default Account;
