import {requestHelper} from '../helpers/index';
import axiosInstance from '../../axios.instance';
import { maestroenv } from '../../helpers/maestroenv';
//import {pdfjs} from "react-pdf";

export const documentService = {
  addShareDocument,
  approveDocumentUpload,
  downloadDocument,
  holdDocumentUpload,
  rejectDocumentUpload,
  removeDocumentUpload,
  insertDocumentNotes,
  removeShareDocument,
  searchDocuments,
  searchSharedUserDocuments,
  getPDFFile,
  getDocumentById,
  getDocumentForSharedUserById,
  getStagedDocuments,
  getDocumentNotes,
  uploadDocuments
};

function approveDocumentUpload(approveFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/approve`, JSON.stringify(approveFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getDocumentById(id) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/document/${id}`,
    {
      headers: {
        'content-type': 'application/pdf'
      },
      responseType: 'blob'
    })
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

async function getDocumentForSharedUserById(id) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/shareduser/document/${id}`,
    {
      headers: {
        'content-type': 'application/pdf'
      },
      responseType: 'blob'
    })
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function createBlobFromResponse(responseData) {
  return new Blob(
    [responseData],
    {type: 'application/pdf'});
}

function getPDFFile(fileUrl, documentId) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/pdffile`, JSON.stringify({
    'fileUrl': fileUrl, 'documentId': documentId
  }), {responseType: 'blob'})
    .then(
      response => {
        return URL.createObjectURL(createBlobFromResponse(response.data))
      }
    )
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });

}

function getStagedDocuments(documentSearch) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/staged`, JSON.stringify(documentSearch))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getDocumentNotes(tabData) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/notes`, JSON.stringify(tabData))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function holdDocumentUpload(holdFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/otherstatus`, JSON.stringify(holdFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function insertDocumentNotes(documentFields) {
  let id = documentFields.document_guid;
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/document/${id}/addnotes`, JSON.stringify(documentFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function rejectDocumentUpload(rejectFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/otherstatus`, JSON.stringify(rejectFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function removeDocumentUpload(removeFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/otherstatus`, JSON.stringify(removeFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


function addShareDocument(shareFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/addsharedocument`, JSON.stringify(shareFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function removeShareDocument(removeFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/removesharedocument`, JSON.stringify(removeFields))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function searchDocuments(documentSearch) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/search`, JSON.stringify(documentSearch))
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
function searchSharedUserDocuments(documentSearch) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/shareduser/documents/search`, JSON.stringify(documentSearch))
    .then(response => {
      return response.data;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function uploadDocuments(uploadFields) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/documents/uploadfiles`, uploadFields)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function downloadDocument(document_guid) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/document/${document_guid}/download`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });

}

