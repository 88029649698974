import React, {Component} from 'react';
import {Router} from 'react-router-dom';

import {ThemeProvider} from '@mui/material/styles';
import validate from 'validate.js';
import theme from './theme';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import {store} from './store';
import {Provider} from 'react-redux';
import {history} from './helpers/history';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {CookiesProvider} from "react-cookie";
import {StyledEngineProvider} from "@mui/material";

validate.validators = {
  ...validate.validators,
  ...validators
};


export default class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <CookiesProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <Router history={history}>
                <Routes/>
                <ToastContainer
                  autoClose={5000}
                  closeOnClick
                  draggable
                  hideProgressBar={false}
                  newestOnTop={false}
                  pauseOnFocusLoss
                  pauseOnHover
                  position="bottom-right"
                  rtl={false}
                />
              </Router>
            </ThemeProvider>
          </Provider>
        </CookiesProvider>
      </StyledEngineProvider>
    );
  }
}
