import React, {useState} from 'react';
import useStyles from './styles';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  TextField
} from '@mui/material';
import Button from "../../components/CustomButtons/Button";
import {useDispatch, useSelector} from 'react-redux';
import {userActions} from '../../store/actions';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';

const ChangePassword = props => {
  const {className} = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const authenticatedUser = useSelector(state => state.authentication.user);
  const requestPending = useSelector(state => state.users.requestPending);
  const [values] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  return (
    <div className={classes.root}>
      <Formik
        initialValues={values}
        onSubmit={(values, {setSubmitting}) => {
          dispatch(userActions.changePassword(values.currentPassword, values.newPassword, authenticatedUser.id))
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string()
            .trim()
            .required('Current Password is Required'),
          newPassword: Yup.string()
            .trim()
            .min(4, 'New Password must be at least 4 characters')
            .required('This Field is Required'),
          confirmNewPassword: Yup.string()
            .trim()
            .oneOf([Yup.ref('newPassword'), null], 'Does not match New Password')
            .required('This Field is Required'),
        })}
      >
        {(props) => {
          const {
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <Card
                className={clsx(classes.root, className)}
              >
                <CardHeader
                  subheader="Change your existing password"
                  title="Change Password"
                />
                <Divider/>
                <CardContent>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Current Password"
                      margin="dense"
                      name="currentPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.currentPassword}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="currentPassword"
                    />
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="New Password"
                      margin="dense"
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.newPassword}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="newPassword"
                    />
                  </Grid>
                  <Grid
                    className={classes.gridItem}
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      margin="dense"
                      name="confirmNewPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.confirmNewPassword}
                      variant="outlined"
                    />
                    <ErrorMessage
                      className={classes.errorMessage}
                      component="div"
                      name="confirmNewPassword"
                    />
                  </Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                  {requestPending && <LinearProgress/>}
                  <Button
                    color="primary"
                    disabled={requestPending}
                    onClick={handleSubmit}
                    type="submit"
                    variant="contained"
                  >
                    Change Password
                  </Button>
                  <Button
                    color="github"
                    component={RouterLink}
                    disabled={requestPending}
                    to="/account"
                    type="submit"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </CardActions>
              </Card>

            </form>
          )
        }}
      </Formik>
    </div>
  );
}

export default ChangePassword;
