export const requestHelper = {
  handleErrorResponse
};

function handleErrorResponse(error) {
  if (error.response && error.response.data && error.response.data.error && error.response.data.error.description) {
    return error.response.data.error.description;
  } else {
    return error;
  }
}
