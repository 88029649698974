import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';
import {makeStyles} from '@mui/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItem,
  ListItemAvatar,
  Typography
} from '@mui/material';

import {options} from './chart';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import palette from '../../../../../theme/palette';
import {dashboardService} from '../../../../../store/services';
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  emptyAvatarStyle: {
    margin: 10,
    width: '100%',
    height: 50
  },
  section: {
    color: theme.palette.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  titleHeader: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.white
  },
  underline: {
    borderBottom: '2px solid white'
  },
  selectMenuMain: {
    color: theme.palette.white,
  },
  iconStyle: {
    color: theme.palette.white
  }
}));

/*const statusColors = {
  Public: palette.publicSection,
  Company: palette.companySection,
  Private: palette.privateSection
};*/

const CloudAdminDocumentsHistory = props => {
  const {className, metrics: {addedDocumentsHistoryData}, ...rest} = props;
  const [days, setDays] = useState(addedDocumentsHistoryData.numberOfDays);
  const [dates, setDates] = useState([]);
  const [dataSets, setDataSets] = useState([]);
  const [dataBuilt, setDataBuilt] = useState(false);
  const [data, setData] = useState({labels: undefined, datasets: undefined});
  const [dataIsBuilding, setDataIsBuilding] = useState(false);
  const [metrics, setMetrics] = useState(addedDocumentsHistoryData);
  const classes = useStyles();
  const buildData = useCallback((metrics) => {
    setDataIsBuilding(true);
    let documentDataMap = new Map();
    metrics.addedDocuments.forEach(document => {
      if (documentDataMap.has(document.date)) {
        let dateObject = documentDataMap.get(document.date);
        dateObject.publicCount += document.documentCountData.publicCount;
        dateObject.privateCount += document.documentCountData.privateCount;
        dateObject.companyCount += document.documentCountData.companyCount;
      } else {
        documentDataMap.set(document.date, document.documentCountData);
      }
    })

    //Uncomment line and corresponding function to use each day in range instead of just days with non-zero uploads
    //documentDataMap = documentDataMapWithAllDatesInRange(metrics.numberOfDays, documentDataMap);

    let datesList = Array.from(documentDataMap.keys());
    let publicDataCounts = [];
    let privateDataCounts = [];
    let companyDataCounts = [];

    documentDataMap.forEach(date => {
      publicDataCounts.push(date.publicCount);
      privateDataCounts.push(date.privateCount);
      companyDataCounts.push(date.companyCount);
    });

    setDates(datesList)
    let publicDataSet = {
      label: 'Public',
      backgroundColor: palette.publicSection,
      borderColor: palette.publicSection,
      data: publicDataCounts
    }
    let privateDataSet = {
      label: 'Private',
      backgroundColor: palette.privateSection,
      borderColor: palette.privateSection,
      data: privateDataCounts
    }
    let companyDataSet = {
      label: 'Company',
      backgroundColor: palette.companySection,
      borderColor: palette.companySection,
      data: companyDataCounts
    }
    setDataSets([publicDataSet, privateDataSet, companyDataSet]);
    setDataBuilt(true);
    setDataIsBuilding(false);
  }, [setDataIsBuilding, setDataBuilt, setDataSets])

  /**function documentDataMapWithAllDatesInRange(numberOfDays, documentDataMap){
    const now = Date.now();
    let dailyDataMap = new Map();
    for (let daysBeforeToday = numberOfDays - 1; daysBeforeToday >= 0; daysBeforeToday--){
      const currentDay = new Date(now - daysBeforeToday*86400000);
      const currentDayString = currentDay.toLocaleDateString("en-GB", {year: "numeric", month: "short", day: "2-digit"}).replaceAll(" ", "-");
      if (documentDataMap.has(currentDayString)) {
        dailyDataMap.set(currentDayString, documentDataMap.get(currentDayString));
      } else {
        dailyDataMap.set(currentDayString, {publicCount: 0, privateCount: 0, companyCount: 0});
      }
    }
    return dailyDataMap;
  }*/

  useEffect(() => {
    if (dataBuilt && !dataIsBuilding) {
      setData({
        labels: Array.from(dates),
        datasets: dataSets
      })
    } else if (!dataBuilt && !dataIsBuilding) {
      buildData(metrics)
    }
  }, [dataBuilt, dataIsBuilding, setData, metrics, dates, dataSets, buildData])

  async function resetData() {
    setDataBuilt(false)
    setDataIsBuilding(true)
  }

  const onHandleDaysChange = event => {
    getMetrics(event.target.value).then(metrics => {
      setDays(event.target.value);
      setMetrics(metrics);
      resetData().then(r => {buildData(metrics)})
    });
  };
  async function getMetrics(days) {
    return await dashboardService.getCloudAdminDocumentHistoryForNumberOfDays(days).then(r => { return r.data});
  }
  const availableDaysValues = [7, 30, 90];
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.titleHeader}
        action={
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
              icon: classes.iconStyle,
            }}
            className={classes.selectMenuMain}
            inputProps={{
              className:  classes.underline,
              name: 'daysSelect',
              id: 'days-select'
            }}
            onChange={onHandleDaysChange}
            value={days}
          >
            {
              availableDaysValues.map((day) => (
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  key={day}
                  value={day}
                >
                  Last {day} Days
                </MenuItem>
              ))
            }
          </Select>
        }
        title={`Documents Added in the Last ${days} Days`}
      />
      <Divider/>
      <CardContent>
        <div className={classes.chartContainer}>
          {dataBuilt && !dataIsBuilding ? (
            <React.Fragment>
              {metrics && metrics.addedDocuments.length > 0 && data.labels && data.datasets ? (
                <Line data={data} type={'line'} options={options}/>
              ):(<ListItem
                key="NoDocuments"
                style={{width: '100%'}}
              >
                <ListItemAvatar style={{width: '100%'}}>
                  <Avatar
                    className={classes.emptyAvatarStyle}
                    style={{backgroundColor: palette.error.light, width: '100%'}}
                    variant="rounded"
                  >
                    <Typography
                      align="center"
                      className={classes.section}
                      color="textPrimary"
                      component="span"
                      style={{backgroundColor: palette.error.light}}
                      variant="h5"
                    >
                      There Have Been No Documents Added Within The Past {days} Days!
                    </Typography>
                  </Avatar>
                </ListItemAvatar>
              </ListItem>)}
            </React.Fragment>
          ) : (<LoadingSpinner/>)
          }
        </div>
      </CardContent>
      <Divider/>
      <CardContent/>
    </Card>
  );
};

CloudAdminDocumentsHistory.propTypes = {
  className: PropTypes.string
};

export default CloudAdminDocumentsHistory;
