import React, {useEffect, useState} from 'react';
import {withStyles} from '@mui/styles';
import {lighten} from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {Avatar,Card, CardActions, CardContent, Divider, LinearProgress, Typography} from '@mui/material';
import useStyles from './styles';
import {requiredUserProfileInputFields} from '../../../../constants/profileFields';
import {AccountCircle} from '@mui/icons-material';
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Button from "../../../../components/CustomButtons/Button";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";
import { maestroenv } from '../../../../helpers/maestroenv';
const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('rgba(143,236,137,0.87)', 0.5),
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#07b502',
  },
})(LinearProgress);

const AccountProfile = props => {
  const {className, profile, ...rest} = props;
  const user = useSelector(state=> state.authentication.user);
  const [profileCompleteness, setProfileCompleteness] = useState(100);
  const classes = useStyles();

  //Calculate our % complete
  useEffect(() => {
    let profileLength = 0;
    let allFieldsCount = 8;
    requiredUserProfileInputFields.forEach((field) => {
      if (profile[field] !== undefined) {
        profileLength++;
      }
    });
    setProfileCompleteness(profileLength/allFieldsCount * 100)
  }, [profile]);

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h2"
            >
              {profile.firstName} {profile.lastName ? profile.lastName : ''}
            </Typography>
            <Divider/>
            <Typography
              className={classes.roleText}
              color="textSecondary"
              variant={'h4'}
            >
              {user.role}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {profile.city}, {profile.state}
            </Typography>
          </div>
          <Avatar
            className={classes.avatar}
          ><AccountCircle className={classes.avatarIcon}/></Avatar>
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">Profile Completeness: {profileCompleteness}%</Typography>
          <BorderLinearProgress
            color="secondary"
            value={profileCompleteness}
            variant="determinate"
          />
        </div>
      </CardContent>
      <Divider/>
      {user.role !== SECURITY_ROLES.SHARED_USER &&
        <CardActions>
          <Button
            className={classes.changePasswordButton}
            color="primary"
            component={RouterLink}
            onClick={() =>
              window.open(maestroenv.REACT_APP_OAUTH_ACCOUNT_MANAGE_URL, "_blank")
            }
            variant="contained"
          >
            Change Password
          </Button>
        </CardActions>
      }
    </Card>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
