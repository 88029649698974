import CloudIcon from "@mui/icons-material/Cloud";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import theme from "../../../../theme/index"

const userRoleMetricDisplayInfo = ({
  cloudAdmin: {
    title: 'Cloud Admins',
    getValue: (roleMetrics) => roleMetrics.cloudAdminRoleCount,
    iconElement: CloudIcon,
    color: theme.palette.red
  },
  companyAdmin: {
    title: 'Company Admins',
    getValue: (roleMetrics) => roleMetrics.companyAdminRoleCount,
    iconElement: SupervisorAccountIcon,
    color: theme.palette.companySection
  },
  user: {
    title: 'Standard Users',
    getValue: (roleMetrics) => roleMetrics.userRoleCount,
    iconElement: PersonIcon,
    color: theme.palette.publicSection
  },
  viewOnly: {
    title: 'View Only',
    getValue: (roleMetrics) => roleMetrics.userViewRoleCount,
    iconElement: VisibilityIcon,
    color: theme.palette.privateSection
  },
  inactive: {
    title: 'Inactive',
    getValue: (roleMetrics) => roleMetrics.inactiveUsersCount,
    iconElement: LockIcon,
    color: theme.palette.gray
  }
});

export default userRoleMetricDisplayInfo;
