import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import {userActions} from "../../../store/actions";
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  TextField,
  Typography
} from "@mui/material";
import {states as States} from "../../../constants/states";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {SECURITY_ROLES} from "../../../constants/SECURITY_ROLES";
import {useLocation} from "react-router";
import {userService} from "../../../store/services";
import Button from "../../../components/CustomButtons/Button";
import {Refresh, Create} from "@mui/icons-material";
import LoadingSpinner from "../../../components/Spinner/loading.spinner";

const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: "#FF0000",
    fontSize: '.75em',
    fontWeight: 800
  },
  createButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  resetButton: {
    margin: theme.spacing(1),
    color: '#FFF'
  },
  fab: {
    position: "fixed",
    margin: theme.spacing(1),
    bottom: theme.spacing(4),
    right: theme.spacing(3)
  },
}));

const AddUserForm = (props) => {
  const location = useLocation();
  const {companyId, role} = location.state
  const classes = useStyles();

  const requestPending = useSelector(state => state.users.requestPending);
  const dispatch = useDispatch();
  const [initialRender, setInitialRender] = useState(true);
  const [roles, setRoles] = useState([]);
  const [values] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    state: '',
    zip: '',
    street: '',
    city: '',
    company: companyId,
    role: SECURITY_ROLES.USER,
    password: '',
    confirmPassword: ''
  });

  const getRolesToMange = async (role) => {
    await userService.getValidRolesToManage({
      role: role
    }).then(dtls => {
      setRoles(dtls.data);
    });
  }

  const initialTouched = {};
  Object.keys(values).map(function (key, index) {
    return initialTouched[key] = true;
  });

  if (initialRender) {
    getRolesToMange(role);
    setInitialRender(false);
  }

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Invalid Email')
      .required('Email is Required'),
    firstName: Yup.string()
      .trim()
      .required('First Name is Required'),
    lastName: Yup.string()
      .trim()
      .required('Last Name is Required'),
    street: Yup.string()
      .trim()
      .required('Street Address is Required'),
    city: Yup.string()
      .trim()
      .required('City is Required'),
    phone: Yup.string()
      .trim()
      .required('Phone is Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    zip: Yup.number()
      .min(5, 'Zip Code is Invalid')
      .required('Zip is Required'),
    password: Yup.string().required('Password is Required')
      .min(8, 'Password must be at least 8 characters'),
    confirmPassword: Yup.string().required('Confirm Password is Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  return (
    <Formik
      enableReinitialize
      initialValues={values}
      initialTouched={initialTouched}
      onSubmit={(values) => {
        dispatch(userActions.addUser(values, companyId))
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(props) => {
        const {
          values,
          isValid,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            {(!roles) ? (<LoadingSpinner/>) : (
              <Card>
                <PageHeader title={"User"} subTitle={"Create New User"}/>
                <CardContent>
                  <div className={classes.contentHeader}>
                    <Typography variant={"h5"}>User Details</Typography>
                    <Divider/>
                  </div>
                  <Grid
                    container
                    spacing={3}
                    className={classes.content}
                  >
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="First Name"
                        margin="dense"
                        name="firstName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="firstName"/>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Last Name"
                        margin="dense"
                        name="lastName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="lastName"/>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Select Role"
                        margin="dense"
                        name="role"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{native: true}}
                        value={values.role}
                        variant="outlined"
                      >
                        <option
                          key={0}
                          value={0}
                        >

                        </option>
                        {roles.map(option => (
                          <option
                            key={option.role}
                            value={option.role}
                          >
                            {option.role}
                          </option>
                        ))}
                      </TextField>
                      <ErrorMessage className={classes.errorMessage} component="div" name="role"/>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Email Address"
                        margin="dense"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="email"/>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Phone Number"
                        margin="dense"
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.phone}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="phone"/>
                    </Grid>
                  </Grid>
                  <div className={classes.contentHeader}>
                    <Typography variant={"h5"}>Password</Typography>
                    <Divider/>
                  </div>
                  <Grid
                    container
                    spacing={3}
                    className={classes.content}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Password"
                        margin="dense"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="password"/>
                    </Grid>
                    <Grid item md={6} xs={12}/>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        margin="dense"
                        name="confirmPassword"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="confirmPassword"/>
                    </Grid>
                  </Grid>
                  <div className={classes.contentHeader}>
                    <Typography variant={"h5"}>Address Information</Typography>
                    <Divider/>
                  </div>

                  <Grid
                    container
                    spacing={3}
                    className={classes.content}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Street Address"
                        margin="dense"
                        name="street"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.street}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="street"/>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="City"
                        margin="dense"
                        name="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        value={values.city}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="city"/>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Select State"
                        margin="dense"
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{native: true}}
                        value={values.state}
                        variant="outlined"
                      >
                        {States.map(option => (
                          <option
                            key={option.abbreviation}
                            value={option.abbreviation}
                          >
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                      <ErrorMessage className={classes.errorMessage} component="div" name="state"/>
                    </Grid>
                    <Grid
                      item
                      md={2}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="zip"
                        margin="dense"
                        name="zip"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.zip}
                        variant="outlined"
                      />
                      <ErrorMessage className={classes.errorMessage} component="div" name="zip"/>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider/>
                <CardActions className={classes.content}>
                  {requestPending && <LinearProgress/>}
                  <div className={classes.fab} style={{position: 'fixed'}}>
                    <Button aria-label="Clear" className={classes.createButton}
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!isValid}
                    >
                      <Create/> Create User
                    </Button>
                    <Button aria-label="Reset" className={classes.resetButton}
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={handleReset}
                            disabled={requestPending}
                    >
                      <Refresh/> Reset
                    </Button>
                  </div>

                </CardActions>
              </Card>
            )}
          </form>
        )
      }}
    </Formik>
  )
}
export default AddUserForm;

