export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  ACTIVATE_REQUEST: 'USER_ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'USER_ACTIVATE_SUCCESS',
  ACTIVATE_FAILURE: 'USER_ACTIVATE_FAILURE',

  DEACTIVATE_REQUEST: 'USER_DEACTIVATE_REQUEST',
  DEACTIVATE_SUCCESS: 'USER_DEACTIVATE_SUCCESS',
  DEACTIVATE_FAILURE: 'USER_DEACTIVATE_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  OAUTH_LOGIN_REQUEST: 'OAUTH_LOGIN_REQUEST',
  OAUTH_LOGIN_SUCCESS: 'OAUTH_LOGIN_SUCCESS',
  OAUTH_LOGIN_FAILURE: 'OAUTH_LOGIN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',

  LOGIN_ROLE_REQUEST: 'USERS_LOGIN_ROLE_REQUEST',
  LOGIN_ROLE_SUCCESS: 'USERS_LOGIN_ROLE_SUCCESS',
  LOGIN_ROLE_FAILURE: 'USERS_LOGIN_ROLE_FAILURE',

  LOGOUT: 'USERS_LOGOUT',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILURE: 'GET_USER_PROFILE_FAILURE',

  GET_USER_SECURITY_QUESTIONS_REQUEST: 'GET_USER_SECURITY_QUESTIONS_REQUEST',
  GET_USER_SECURITY_QUESTIONS_SUCCESS: 'GET_USER_SECURITY_QUESTIONS_SUCCESS',
  GET_USER_SECURITY_QUESTIONS_FAILURE: 'GET_USER_SECURITY_QUESTIONS_FAILURE',

  GET_ADDITIONAL_USER_DETAILS_REQUEST: 'GET_ADDITIONAL_USER_DETAILS_REQUEST',
  GET_ADDITIONAL_USER_DETAILS_SUCCESS: 'GET_ADDITIONAL_USER_DETAILS_SUCCESS',
  GET_ADDITIONAL_USER_DETAILS_FAILURE: 'GET_ADDITIONAL_USER_DETAILS_FAILURE',

  GET_USER_PRIVILEGES_REQUEST: 'GET_USER_PRIVILEGES_REQUEST',
  GET_USER_PRIVILEGES_SUCCESS: 'GET_USER_PRIVILEGES_SUCCESS',
  GET_USER_PRIVILEGES_FAILURE: 'GET_USER_PRIVILEGES_FAILURE',

  GET_USER_ROLE_PRIVILEGES_REQUEST: 'GET_USER_ROLE_PRIVILEGES_REQUEST',
  GET_USER_ROLE_PRIVILEGES_SUCCESS: 'GET_USER_ROLE_PRIVILEGES_SUCCESS',
  GET_USER_ROLE_PRIVILEGES_FAILURE: 'GET_USER_ROLE_PRIVILEGES_FAILURE',

  UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILURE: 'UPDATE_USER_PROFILE_FAILURE',

  UPDATE_USER_DETAILS_REQUEST: 'UPDATE_USER_DETAILS_REQUEST',
  UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
  UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',

  UPDATE_SHAREDUSER_DETAILS_REQUEST: 'UPDATE_SHAREDUSER_DETAILS_REQUEST',
  UPDATE_SHAREDUSER_DETAILS_SUCCESS: 'UPDATE_SHAREDUSER_DETAILS_SUCCESS',
  UPDATE_SHAREDUSER_DETAILS_FAILURE: 'UPDATE_SHAREDUSER_DETAILS_FAILURE',

  UPDATE_SHAREDUSER_KEYINFO_REQUEST: 'UPDATE_SHAREDUSER_KEYINFO_REQUEST',
  UPDATE_SHAREDUSER_KEYINFO_SUCCESS: 'UPDATE_SHAREDUSER_KEYINFO_SUCCESS',
  UPDATE_SHAREDUSER_KEYINFO_FAILURE: 'UPDATE_SHAREDUSER_KEYINFO_FAILURE',

  UPDATE_SHAREDUSER_PASSWORD_REQUEST: 'UPDATE_SHAREDUSER_PASSWORD_REQUEST',
  UPDATE_SHAREDUSER_PASSWORD_SUCCESS: 'UPDATE_SHAREDUSER_PASSWORD_SUCCESS',
  UPDATE_SHAREDUSER_PASSWORD_FAILURE: 'UPDATE_SHAREDUSER_PASSWORD_FAILURE',

  CHANGE_USER_PASSWORD_REQUEST: 'CHANGE_USER_PASSWORD_REQUEST',
  CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_FAILURE: 'CHANGE_USER_PASSWORD_FAILURE',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  GETALLBYCOMPANY_REQUEST: 'USERS_GETALL_BY_COMPANY_REQUEST',
  GETALLBYCOMPANY_SUCCESS: 'USERS_GETALL_BY_COMPANY_SUCCESS',
  GETALLBYCOMPANY_FAILURE: 'USERS_GETALL_BY_COMPANY_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_REQUEST_SUCCESS: 'ADD_USER_REQUEST_SUCCESS',
  ADD_USER_REQUEST_FAILURE: 'ADD_USER_REQUEST_FAILURE',

  ADD_SHARED_USER_REQUEST: 'ADD_SHARED_USER_REQUEST',
  ADD_SHARED_USER_SUCCESS: 'ADD_SHARED_USER_SUCCESS',
  ADD_SHARED_USER_FAILURE: 'ADD_SHARED_USER_FAILURE',

  GET_SHARED_USER_REQUEST: 'GET_SHARED_USER_REQUEST',
  GET_SHARED_USER_SUCCESS: 'GET_SHARED_USER_SUCCESS',
  GET_SHARED_USER_FAILURE: 'GET_SHARED_USER_FAILURE',

  SEND_EMAIL_REQUEST: 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE: 'SEND_EMAIL_FAILURE'
};
