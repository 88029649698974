export const vodConstants = {
  UPLOAD_VOD_REQUEST: 'UPLOAD_VOD_REQUEST',
  UPLOAD_VOD_SUCCESS: 'UPLOAD_VOD_SUCCESS',
  UPLOAD_VOD_FAILURE: 'UPLOAD_VOD_FAILURE',

  RETRIEVE_VOD_PROGRESS_REQUEST: 'RETRIEVE_VOD_PROGRESS_REQUEST',
  RETRIEVE_VOD_PROGRESS_SUCCESS: 'RETRIEVE_VOD_PROGRESS_SUCCESS',
  RETRIEVE_VOD_PROGRESS_FAILURE: 'RETRIEVE_VOD_PROGRESS_FAILURE',

  RETRIEVE_VOD_FILE_REQUEST: 'RETRIEVE_VOD_FILE_REQUEST',
  RETRIEVE_VOD_FILE_SUCCESS: 'RETRIEVE_VOD_FILE_SUCCESS',
  RETRIEVE_VOD_FILE_FAILURE: 'RETRIEVE_VOD_FILE_FAILURE'
}
