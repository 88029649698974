import {
  dangerColor,
  defaultFont,
  grayColor,
  successColor,
  warningColor
} from 'assets/jss/index.js';

const height = 10;
const borderRadius = 10;

const progressBarContainerStyle = {
  root: {
    alignItems: "top",
    fontSize: `${height*0.85}vh`,
    height: `${height}vh`,
    width: "100%",
    position: "relative"
  }
}

const progressBarStyle = (completionPercent, errored) => ({
  root: {
    backgroundColor: grayColor,
    borderRadius: borderRadius,
    height: `85%`,
    position: "relative",
    zIndex: 0
  },
  barColorPrimary: {
    backgroundColor:
      errored === false ?
        completionPercent < 100 ?
          warningColor[2] :
          successColor[0] :
        dangerColor[0],
    borderRadius: borderRadius
  }
});

const progressBarTextStyle = (completionPercent) => {
  let cnv = document.createElement('canvas');
  let ctx = cnv.getContext('2d');
  ctx.font = defaultFont.fontFamily;

  let textLen = ctx.measureText(`${completionPercent}%`);
  //Sometimes numbers like 11.2% will be too long in spite of the attempt to measure, which is why the +1 is there
  let width = Math.abs(textLen.actualBoundingBoxLeft) + Math.abs(textLen.actualBoundingBoxRight) + 1;

  return ({
    root: {
      font: defaultFont,
      fontSize: "1em",
      fontWeight: "bold",
      marginRight: `min(101% - ${completionPercent}%, 101% - ${width/9.5}em)`,
      marginTop: `-0.55em`,
      textAlign: "right",
      position: "relative",
      zIndex: 0,
    }
  })
};

const progressBarStyleGroup = (completionPercent, errored) => {
  const progressBar = progressBarStyle(completionPercent, errored);
  const progressBarText = progressBarTextStyle(completionPercent);

  return ({
    progressBarBox: progressBarContainerStyle,
    progressBar: progressBar,
    progressBarText: progressBarText
  })
};

export default progressBarStyleGroup;
