import React from 'react';
import clsx from 'clsx';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import useStyles from './styles';
import {Link} from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';

const UsersMetrics = props => {
  const {className, metrics, ...rest} = props;

  const classes = useStyles();
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {metrics &&
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
              variant="body2"
            >
              TOTAL DOCUMENTS
            </Typography>
            <Typography
              className={classes.valueText}
              variant="h3"
            >{metrics.documentsCount}</Typography>
          </Grid>
          <Grid item>
            {/*<Link to={'/documents'}>*/}
            <Link to={{pathname:'/documents', initialSection: "All Sections",startDate: "01/01/2000"}}>
              <Avatar className={classes.avatar}>
                <DescriptionIcon className={classes.icon} />
              </Avatar>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
      }
    </Card>
  );
};

export default UsersMetrics;
