import React from 'react'
import {useDispatch} from 'react-redux';

import {categoryActions} from '../../../store/actions';
import SectionDetails from '../component/SectionDetails';

const initialSection = {
  name: ''
};

const AddSection = props => {
  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    dispatch(categoryActions.add(values))
    return true;
  }

  return(
    <SectionDetails
      initialSection = {initialSection}
      onHandleSubmit = {onHandleSubmit}
      submitButton = "Add"
      title = "Add New Section"
    />

  )
}

export default AddSection;
