import {colors} from '@mui/material';
import tinycolor from 'tinycolor2';

const white = '#FFFFFF';
const black = '#000000';
const primary = '#404041';
const secondary =  '#147ad5';
const spinner = '#325c9d';
const tertiary = '#4ba09a';
const warning = '#FFC260';
const success = '#3CD4A0';
const info = '#9013FE';
const publicSection = '#3aaddd';
const companySection = '#4d16b8';
const privateSection = '#e71e69';
const accessibleSection = "rgba(155,231,91,0.2)"
const allDocumentsSection = "rgba(77, 22, 184, 0.4)";
const holdStatus = '#3aaddd';
const removedStatus = '#4d16b8';
const rejectedStatus = '#e71e69';
const waitingStatus = '#25c638';
const red = '#db0000';
const red2 = "#ff6384";
const green = '#25c638';
const lightenRate = 7.5;
const darkenRate = 15;
const gray = '#b5b5b5';
const darkGray = '#4c4c4c';

const palette = {
  publicSection,
  privateSection,
  companySection,
  accessibleSection,
  allDocumentsSection,
  holdStatus,
  removedStatus,
  rejectedStatus,
  waitingStatus,
  black,
  white,
  red,
  red2,
  gray,
  darkGray,
  green,
  spinner,
  primary: {
    main: primary,
    light: tinycolor(primary)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(primary)
      .darken(darkenRate)
      .toHexString(),
  },
  secondary: {
    main: secondary,
    light: tinycolor(secondary)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(secondary)
      .darken(darkenRate)
      .toHexString(),
    contrastText: '#FFFFFF',
  },
  tertiary: {
    main: tertiary,
    light: tinycolor(tertiary)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(tertiary)
      .darken(darkenRate)
      .toHexString(),
    contrastText: '#FFFFFF',
  },
  warning: {
    main: warning,
    light: tinycolor(warning)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(warning)
      .darken(darkenRate)
      .toHexString(),
  },
  success: {
    main: success,
    light: tinycolor(success)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(success)
      .darken(darkenRate)
      .toHexString(),
  },
  info: {
    main: info,
    light: tinycolor(info)
      .lighten(lightenRate)
      .toHexString(),
    dark: tinycolor(info)
      .darken(darkenRate)
      .toHexString(),
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.common.black,
    secondary: colors.grey[600],
    link: colors.blue[600],
    disabled: '#000000',
  },
  background: {
    default: '#e0e2e3',
    paper: white
  },
  divider: colors.grey[200]
};

export default palette;
