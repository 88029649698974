import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  root: {},
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  }
}));
