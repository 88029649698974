export const filetypeConstants = {

  ADDNEW_REQUEST: 'FILETYPES_ADDNEW_REQUEST',
  ADDNEW_SUCCESS: 'FILETYPES_ADDNEW_SUCCESS',
  ADDNEW_FAILURE: 'FILETYPES_ADDNEW_FAILURE',

  DELETE_REQUEST: 'FILETYPES_DELETE_REQUEST',
  DELETE_SUCCESS: 'FILETYPES_DELETE_SUCCESS',
  DELETE_FAILURE: 'FILETYPES_DELETE_FAILURE',

  GETALL_REQUEST: 'FILETYPES_GETALL_REQUEST',
  GETALL_SUCCESS: 'FILETYPES_GETALL_SUCCESS',
  GETALL_FAILURE: 'FILETYPES_GETALL_FAILURE',

  GET_MIMETYPES_BY_ID_REQUEST: 'MIMETYPES_BY_ID_REQUEST',
  GET_MIMETYPES_BY_ID_SUCCESS: 'MIMETYPES_BY_ID_SUCCESS',
  GET_MIMETYPES_BY_ID_FAILURE: 'MIMETYPES_BY_ID_FAILURE',

  UPDATE_REQUEST: 'FILETYPES_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'FILETYPES_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'FILETYPES_UPDATE_FAILURE',

};
