import {classificationConstants} from '../../constants/index';
import {classificationService} from '../../services/index';
import {alertActions} from '../index';

export const classificationActions = {
  add,
  update,
  getAllClassifications
};

function add(classification) {
  return dispatch => {
    dispatch(request(classification));

    classificationService.add(classification)
      .then(
        classification => {
          dispatch(success(classification));
          dispatch(alertActions.success('Classification added'));
          getAllClassifications();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(classification) {
    return {type: classificationConstants.ADDNEW_REQUEST, classification}
  }

  function success(classification) {
    return {type: classificationConstants.ADDNEW_SUCCESS, classification}
  }

  function failure(error) {
    return {type: classificationConstants.ADDNEW_FAILURE, error}
  }
}

function getAllClassifications() {
  return dispatch => {
    dispatch(request());

    classificationService.getAllClassifications()
      .then(
        classifications => dispatch(success(classifications)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: classificationConstants.GETALL_REQUEST}
  }

  function success(classifications) {
    return {type: classificationConstants.GETALL_SUCCESS, classifications}
  }

  function failure(error) {
    return {type: classificationConstants.GETALL_FAILURE, error}
  }
}

function update(classification) {
  return dispatch => {
    dispatch(request(classification));

    classificationService.update(classification)
      .then(
        classification => {
          dispatch(success(classification));
          dispatch(alertActions.success('Classification updated'));
          getAllClassifications();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(classification) {
    return {type: classificationConstants.UPDATE_REQUEST, classification}
  }

  function success(classification) {
    return {type: classificationConstants.UPDATE_SUCCESS, classification}
  }

  function failure(error) {
    return {type: classificationConstants.UPDATE_FAILURE, error}
  }
}
