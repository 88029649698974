import MenuItemContainer from "../MenuItemContainer";

const NameBasedMenuItem = props => {
  const {
    classes,
    options,
    returnId = false
  } = props;

  if (options == null) return null;
  let mappedItems = options.map(option => MenuItemContainer(option ? {
    classes: classes,
    optionKey: option.name,
    optionValue: returnId ? option.id : option.name,
    optionDisplay: option.name
  } : {classes}))
  return (mappedItems);
}

const NameBasedMenuItemWithIdReturn = props => NameBasedMenuItem({
  returnId: true,
  ...props
})

export default NameBasedMenuItem;
export {NameBasedMenuItemWithIdReturn};
