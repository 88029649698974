import React, {useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Line} from 'react-chartjs-2';
import {makeStyles} from '@mui/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider
} from '@mui/material';

import {options} from './chart';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import palette from '../../../../theme/palette';
import {useSelector} from 'react-redux';
import {dashboardService} from '../../../../store/services';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import CardBody from "../../../../components/Card/CardBody";
import {EmptyList} from "../../../../components/EmptyList";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500
  },
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  emptyAvatarStyle: {
    margin: 10,
    width: '100%',
    height: 50
  },
  section: {
    color: theme.palette.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  titleHeader: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.white
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  underline: {
    borderBottom: '2px solid white'
  },
  selectMenuMain: {
    color: theme.palette.white,
  },
  iconStyle: {
    color: theme.palette.white
  }
}));

const DocumentsHistory = props => {
  const {className, metrics: {addedDocumentsHistoryData}, view, ...rest} = props;
  const [days, setDays] = useState(addedDocumentsHistoryData.numberOfDays);
  const [dates, setDates] = useState(null);
  const [dataSets, setDataSets] = useState(null);
  const user = useSelector(state => state.authentication.user);
  const [dataBuilt, setDataBuilt] = useState(false);
  const [data, setData] = useState({});
  const [dataIsBuilding, setDataIsBuilding] = useState(false);
  const [metrics, setMetrics] = useState(addedDocumentsHistoryData);
  const classes = useStyles();

  const buildData = useCallback((metrics) => {
    setDataIsBuilding(true);
    let documentDataMap = new Map();
    metrics.addedDocuments.forEach(document => {
      if (documentDataMap.has(document.date)) {
        let dateObject = documentDataMap.get(document.date);
        dateObject.publicCount += document.documentCountData.publicCount;
        dateObject.privateCount += document.documentCountData.privateCount;
        dateObject.companyCount += document.documentCountData.companyCount;
      } else {
        documentDataMap.set(document.date, document.documentCountData);
      }
    })
    setDates(documentDataMap.keys());
    let publicDataCounts = [];
    let privateDataCounts = [];
    let companyDataCounts = [];

    documentDataMap.forEach(date => {
      publicDataCounts.push(date.publicCount);
      privateDataCounts.push(date.privateCount);
      companyDataCounts.push(date.companyCount);
    });

    let publicDataSet = {
      label: 'Public',
      backgroundColor: palette.publicSection,
      borderColor: palette.publicSection,
      data: publicDataCounts
    }
    let privateDataSet = {
      label: 'Private',
      backgroundColor: palette.privateSection,
      borderColor: palette.privateSection,
      data: privateDataCounts
    }
    let companyDataSet = {
      label: 'Company',
      backgroundColor: palette.companySection,
      borderColor: palette.companySection,
      data: companyDataCounts
    }
    setDataSets([publicDataSet, privateDataSet, companyDataSet]);
    setDataBuilt(true);
    setDataIsBuilding(false);
  }, [setDataIsBuilding, setDataBuilt, setDataSets])

  useEffect(() => {
    if (dataBuilt && !dataIsBuilding) {
      setData({
        labels: Array.from(dates),
        datasets: dataSets
      })
    } else if (!dataBuilt && !dataIsBuilding) {
      buildData(metrics)
    }
  }, [dataBuilt, dataIsBuilding, metrics, buildData, setData, dataSets, dates])

  async function resetData() {
    setDataBuilt(false)
    setDataIsBuilding(true)
  }

  const onHandleDaysChange = event => {
    getMetrics(event.target.value).then(metrics => {
      setDays(event.target.value);
      setMetrics(metrics);
      resetData().then(r => {
        buildData(metrics)
      })
    });
  };

  async function getMetrics(days) {
    if (user.role === SECURITY_ROLES.USER || user.role === SECURITY_ROLES.POWER_USER){
      return await dashboardService.getUserDocumentHistoryForNumberOfDays(user.userId, days).then(r => {
        return r.data
      });
    } else {
      return await dashboardService.getDocumentHistoryForNumberOfDays(user.companyId, days).then(r => {
        return r.data
      });
    }
  }

  const availableDaysValues = [7, 30, 90];
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader className={classes.titleHeader}
                  action={
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                        icon: classes.iconStyle,
                      }}
                      className={classes.selectMenuMain}
                      inputProps={{
                        className:  classes.underline,
                        name: 'daysSelect',
                        id: 'days-select',
                      }}
                      onChange={onHandleDaysChange}
                      value={days}
                    >
                      {
                        availableDaysValues.map((day) => (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            key={day}
                            value={day}
                          >
                            Last {day} Days
                          </MenuItem>
                        ))
                      }
                    </Select>
                  }
                  title={`Documents Added in the Last ${days} Days`}
      />
      <Divider/>
      <CardBody>
        <div className={classes.chartContainer}>
          {dataBuilt && !dataIsBuilding ? (
            <React.Fragment>
              {metrics && metrics.addedDocuments.length > 0 && data.labels && data.datasets ? (
                <Line
                  data={data}
                  options={options}
                  type={"line"}
                />
              ) : (
                <EmptyList
                  label={`There Have Been No Documents Added Within The Past ${days} Days!`}
                />
              )}
            </React.Fragment>
          ) : (<LoadingSpinner/>)
          }
        </div>
      </CardBody>
      <Divider/>
      <CardContent/>
    </Card>
  );
};

DocumentsHistory.propTypes = {
  className: PropTypes.string
};

export default DocumentsHistory;
