import {ErrorMessage, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {isEmpty} from 'underscore';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import Switch from "../../../../components/Switch";


const useStyles = makeStyles((theme) => ({
  contentHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(4),
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
  }
}));

const UserAccount = (props) => {
  const {
    className,
    accountValues,
    userRoles,
    onHandleSwitchChange,
    onHandleRadioChange,
    onExpireDaysChange,
    setHasAccountErrors,
    edit,
    ...rest
  } = props

  const classes = useStyles();
  let initialTouched = {};
  if (edit){
    initialTouched = {
      expires_days: true
    }
  }

  return (
    <React.Fragment>
      {(!accountValues) ? (<LoadingSpinner/>) :
        (
          <Formik
            enableReinitialize
            innerRef={self=>setHasAccountErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
            initialTouched={initialTouched}
            initialValues={accountValues}
            onSubmit={(values, {setSubmitting}) => {
              alert('Account Update not available at this time.');
            }}
            validateOnMount
            validationSchema={Yup.object().shape({
              expires_days: Yup.number()
                .min(0, 'Number of days must be a number.')
                .nullable(true)
                .transform((v) => (v === '' || Number.isNaN(v) ? 0 : v))
            })}
          >
            {(formikProps) => {
              const {
                values,
                handleBlur,
                handleSubmit
              } = formikProps;

              return (
                <Form onSubmit={handleSubmit}>
                  <Card
                    {...rest}
                    className={clsx(classes.root, className)}
                  >
                    <CardHeader
                      title="Additional Details"
                    />
                    <Divider/>
                    <CardContent>
                      <Grid
                        className={classes.content}
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <FormControlLabel
                            className={classes.switch}
                            control={<Switch
                              checked={Boolean(accountValues.active)}
                              name="active"
                              onHandleToggle={onHandleSwitchChange}
                                     />}
                            label="Active"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <FormControlLabel
                            className={classes.switch}
                            control={<Switch
                              checked={Boolean(accountValues.locked)}
                              name="locked"
                              onHandleToggle={onHandleSwitchChange}
                                     />}
                            label="Locked"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography variant={"h5"} >
                            Date Expires: {accountValues.expireDate}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          xs={12}
                        >
                          <FormControl
                            className={classes.formControl}
                            component="fieldset"
                          >
                            <FormLabel component="legend">
                                Password Expiration:
                            </FormLabel>
                            <RadioGroup
                              aria-label="password_expiry"
                              name="password_expiry"
                              onChange={onHandleRadioChange}
                              value={accountValues.password_expiry}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Does not expire"
                                value="does_not_expire"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Expires On Login"
                                value="expires_on_login"
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label={
                                  <TextField
                                    label="Number of days"
                                    margin="normal"
                                    name="expires_days"
                                    onBlur={handleBlur}
                                    onChange={onExpireDaysChange}
                                    value={accountValues.expires_days}
                                    variant="outlined"
                                  />
                                }
                                value="expires_on_days"
                              />
                              {
                                (values.password_expiry === 'expires_on_days') ? (
                                  <ErrorMessage
                                    className={classes.errorMessage}
                                    component="div"
                                    name="expires_days"
                                  />
                                ) : null
                              }
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                      </Grid>
                    </CardContent>
                  </Card>
                </Form>
              )
            }}
          </Formik>
        )}
    </React.Fragment>
  )
}
export default UserAccount;

