import {categoryConstants} from '../../constants/index';
import {categoryService} from '../../services/index';
import {alertActions} from '../index';

export const categoryActions = {
  add,
  update,
  getAllCategories,
};

function add(category) {
  return dispatch => {
    dispatch(request(category));

    categoryService.add(category)
      .then(
        category => {
          dispatch(success(category));
          dispatch(alertActions.success('Category added'));
          getAllCategories();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(category) {
    return {type: categoryConstants.ADDNEW_REQUEST, category}
  }

  function success(category) {
    return {type: categoryConstants.ADDNEW_SUCCESS, category}
  }

  function failure(error) {
    return {type: categoryConstants.ADDNEW_FAILURE, error}
  }
}

function getAllCategories() {
  return dispatch => {
    dispatch(request());

    categoryService.getAllCategories()
      .then(
        categories => dispatch(success(categories)),
        error => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {type: categoryConstants.GETALL_REQUEST}
  }

  function success(categories) {
    return {type: categoryConstants.GETALL_SUCCESS, categories}
  }

  function failure(error) {
    return {type: categoryConstants.GETALL_FAILURE, error}
  }
}

function update(category) {
  return dispatch => {
    dispatch(request(category));

    categoryService.update(category)
      .then(
        category => {
          dispatch(success(category));
          dispatch(alertActions.success('Category updated'));
          getAllCategories();
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(category) {
    return {type: categoryConstants.UPDATE_REQUEST, category}
  }

  function success(category) {
    return {type: categoryConstants.UPDATE_SUCCESS, category}
  }

  function failure(error) {
    return {type: categoryConstants.UPDATE_FAILURE, error}
  }
}
