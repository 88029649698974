import {makeStyles} from '@mui/styles';
import {blue} from "@mui/material/colors";

export default makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  content: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  documentContent: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  pageContent: {
    overflow: 'scroll',
    "& > .annotationLayer .textAnnotation img": {
      maxWidth: "8px",
      maxHeight: "8px"
    }
  },
  panelContent: {
    padding: theme.spacing(2)
  },
  approveButton: {
    margin: theme.spacing(0.1),
    backgroundColor: theme.palette.green,
    color: '#FFF'
  },
  disapproveButton: {
    backgroundColor: theme.palette.red,
    margin: theme.spacing(0.1),
    color: '#FFF'
  },
  holdButton: {
    margin: theme.spacing(0.1),
    backgroundColor: theme.palette.orange,
    color: '#FFF'
  },
  deleteButton: {
    margin: theme.spacing(0.1),
    backgroundColor: theme.palette.black,
    color: '#FFF'
  },
  notesButton: {
    margin: theme.spacing(0.1),
    backgroundColor: theme.palette.black,
    color: '#FFF'
  },
  infoButton: {
    margin: theme.spacing(0.1),
    backgroundColor: "white",
    color: blue
  },
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  fab: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: theme.spacing(4),
    zIndex: 9999
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  modalContent: {
    border: '2px solid white',
    backgroundColor: 'white',
    boxShadow: '2px solid white',
    height: '60%',
    width: '60%',
    overflowY: 'scroll',
    margin: 'auto'
  },
  modalHeader: {
    padding: theme.spacing(3),
    color: theme.palette.white,
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  tabContent: {
    padding: 0
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },
  indicatorColor: {
    backgroundColor: theme.palette.tertiary
  },
}));
