import {sectionConstants} from '../../constants/index';

const initialState = {};

export function sections(state = initialState, action) {
  switch (action.type) {
    case sectionConstants.GETALL_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case sectionConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.sections.data,
        requestPending: false
      };
    case sectionConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
