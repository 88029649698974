import 'date-fns';
import React from 'react';

// @mui/icons-material
// @mui/material components
import Button from '../../../../../components/CustomButtons/Button';
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";

const UploadFilterButtons = props => {
  const {
    classes,
    mimeTypeList,
    fileList,
    onHandleChange,
    onHandleUpload,
    onHandleReset
  } = props

  return fileList.length <= 0 ? [<form
    encType="multipart/form-data"
    key="browse"
    method="post">
    <label htmlFor="select-file">
      <div>
        <input
          accept={mimeTypeList}
          id="select-file"
          multiple={true}
          name="select-file[]"
          onChange={onHandleChange}
          style={{display: 'none', position: 'fixed'}}
          type="file"/>
        <Button
          color="primary"
          className={classes.button}
          component="span"
          variant="contained">
          <AddIcon/> Browse
        </Button>
      </div>
    </label>
  </form>] : [(<Button
    color="success"
    className={classes.button}
    component="span"
    onClick={onHandleUpload}
    key="Upload"
    variant="contained">
    <CloudUploadIcon/> Upload
  </Button>), (<Button
    color="primary"
    className={classes.button}
    component="span"
    onClick={onHandleReset}
    key="Reset"
    variant="contained">
    <RotateLeftIcon/> Reset
  </Button>)];
}

export default UploadFilterButtons;
