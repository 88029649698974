import React from 'react';
import clsx from 'clsx';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import useStyles from './styles';
import {Link} from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import {useSelector} from "react-redux";

const SharedUsersMetrics = props => {
  const {className, metrics, ...rest} = props;

  const user = useSelector(state => state.authentication.user);
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {metrics &&
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textPrimary"
              gutterBottom
              variant="body2"
            >
              SHARED USERS
            </Typography>
            <Typography
              className={classes.valueText}
              variant="h3"
            >{metrics.sharedUsersCount}</Typography>
          </Grid>
          <Grid item>
            <Link to={{
                  pathname: "/sharedusers/list",
                  state: {
                  title: "Shared Users",
                  subTitle: "List all Shared User for Member",
                  memberGuid: user.id,
                  companyId: user.companyId
                }
            }}
              >
              <Avatar className={classes.avatar}>
                <PeopleIcon className={classes.icon}/>
              </Avatar>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
      }
    </Card>
  );
};

export default SharedUsersMetrics;
