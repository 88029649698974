import React from 'react'
import {useDispatch} from 'react-redux';

import {categoryActions} from '../../../store/actions';
import CategoryDetails from '../component/CategoryDetails';

const initialCategory = {
  name: ''
};

const AddCategory = props => {
  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    dispatch(categoryActions.add(values))
    return true;
  }

  return(
    <CategoryDetails
      initialCategory = {initialCategory}
      onHandleSubmit = {onHandleSubmit}
      submitButton = "Add"
      title = "Add New Category"
    />

  )
}

export default AddCategory;
