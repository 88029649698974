import React from 'react';
import {makeStyles} from '@mui/styles';
import {Grid} from '@mui/material';

import {ConnectedApps} from './components';
import PageHeader from "../../../components/PageHeader/PageHeader";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    marginTop: 0
  }
}));

const ConnectedAppsSettings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PageHeader title={"Connected App Settings"} subTitle={"Manage Connected App Settings"}/>
      <Card  className={classes.content}>
        <CardBody>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <ConnectedApps
                initial="true"
              />
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
};

export default ConnectedAppsSettings;
