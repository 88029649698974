import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Card, Grid} from '@mui/material';
import {Redirect} from 'react-router-dom';

import {UsersMetrics, TopUserDocumentsViewedMetrics, SharedUsersMetrics} from './components';
import {useDispatch, useSelector} from 'react-redux';
import {DocumentMetrics, DocumentsHistory, LatestDocuments, StagedDocumentMetrics} from '../components';
import YourSharedUsersTable from './components/SharedUsersTable';
import {dashboardService} from '../../../store/services';
import {history} from '../../../helpers/history';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const UserDashboard = () => {
  const classes = useStyles();
  const user = useSelector(state => state.authentication.user);
  const [metrics, setMetrics] = useState(null);
  const dispatch = useDispatch();

  const handleAddSharedUsers = event => {
    history.push("/sharedusers/add");
  };

  useEffect(() => {
    let isSubscribed = true;
    if (user) {
      dashboardService.getUserDashboardMetrics()
        .then(metrics =>  isSubscribed ? setMetrics(metrics.data) : null)
    }

    return () => (isSubscribed = false);

  }, [user, dispatch]);

  return (
    <React.Fragment>
      { user.role !== null  && (user.role.includes('User'))? (<div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={6}
            sm={6}
            xl={6}
            xs={12}
          > {metrics ? (
              <UsersMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading User Metrics"}/></Card>)
            }
          </Grid>
          <Grid
            item
            lg={6}
            xl={6}
            sm={6}
            xs={12}
          >
            {metrics ? (
              <SharedUsersMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Shared Users"}/></Card>)
            }
          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            {metrics ? (
              <DocumentMetrics metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Document Metrics"}/></Card>)
            }

          </Grid>

          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
          >
            {metrics ? (
              <StagedDocumentMetrics metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Staged Document Metrics"}/></Card>)
            }

          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xl={12}
            xs={12}
          >
            {metrics ? (
              <LatestDocuments metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Latest Documents"}/></Card>)
            }

          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xl={6}
            xs={12}
          > {metrics ? (
              <TopUserDocumentsViewedMetrics metrics={metrics}/>) : (  <Card><LoadingSpinner title={"Loading Top Viewed Documents"}/></Card>)
            }
          </Grid>

          <Grid
            item
            lg={6}
            xl={6}
            md={12}
            sm={12}
            xs={12}
          >
            {metrics ? (
              <DocumentsHistory metrics={metrics}/>) : (<Card><LoadingSpinner title={"Loading Document History"}/></Card>)
            }
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xl={12}
            xs={12}
          > {metrics ? (
            <YourSharedUsersTable
              memberGuid={user.id}
              companyId={user.companyId}
              handleAddSharedUsers={handleAddSharedUsers}
            />
            ) : (
              <Card>
                <LoadingSpinner  title={"Loading Your Shared Users"}/>
              </Card>
            )
          }

          </Grid>
        </Grid>
      </div>): (<Redirect to={'/login'}/>)}
    </React.Fragment>

  );
};

export default UserDashboard;
