import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Card, Grid} from '@mui/material';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {SECURITY_ROLES} from '../../../constants/SECURITY_ROLES';
import TotalDocuments from './components/TotalDocuments';
import SharedUserAccountDetails from './components/SharedUserAccountDetails/SharedUserAccountDetails';
import {dashboardService} from '../../../store/services';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";

const useStyles = makeStyles(theme => ({
  root: {
  }
}));

const SharedUserDashboard = (props) => {
  const classes = useStyles();
  const [ metrics, setMetrics ] = useState(null);
  const user = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let isSubscribed = true;
    if (user) {
      dashboardService.getSharedUserDashboardMetrics()
        .then(
          metrics => isSubscribed ? setMetrics(metrics.data) : null
        );
    }
    return () => (isSubscribed = false);

  }, [user, dispatch]);

  return (
    <React.Fragment>
      {user && (user.role) && (user.role === SECURITY_ROLES.SHARED_USER)?
        (
          <div className={classes.root}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                xs={12}
              >
                {metrics ? (
                  <TotalDocuments metrics={metrics}/>) : (  <Card><LoadingSpinner/></Card>)
                }
              </Grid>
              <Grid
                item
                xs={12}
              >
                {metrics ? (
                  <SharedUserAccountDetails metrics={metrics}/>) : (  <Card><LoadingSpinner/></Card>)
                }
              </Grid>
            </Grid>
          </div>)
        :
        (<Redirect to="/login"/>)
      }
    </React.Fragment>

  );
};

export default SharedUserDashboard;
