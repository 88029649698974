import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {v4 as uuid} from 'uuid';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import palette from '../../../../theme/palette';
import {Link} from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import {dashboardService} from '../../../../store/services';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text,
    fontSize: 14
  },
  section: {
    color: theme.palette.white
  },
  content: {
    padding: 0
  },
  emptyAvatarStyle: {
    margin: 10,
    width: '100%',
    height: 50
  },
  avatarStyle: {
    marginRight: 10,
    width: 100,
    height: 20
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  }
}));

const TopDocumentsViewedMetrics = props => {
  const {className, metrics: {topViewedDocuments: {documents}}, ...rest} = props;
  const [documentData, setDocumentData] = useState(documents);
  const user = useSelector(state => state.authentication.user);

  const classes = useStyles();
  const statusColors = {
    Public: palette.publicSection,
    Company: palette.companySection,
    Private: palette.privateSection
  };

  const handleRefresh = event => {
    getData().then(metrics => {
      setDocumentData(metrics.documents);
    });
  };

  async function getData() {
    return await dashboardService.getTopViewedDocumentsMetrics(user.companyId).then(r => {
      return r.data
    });
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader className={classes.titleHeader}
        action={
          <IconButton
            onClick={handleRefresh}
            size="small"
          >
            <RefreshIcon/>
          </IconButton>
        }
        subtitle={documentData.length > 0 ? `${documentData.length}  in total` : 'No Recent Documents'}
        title={documentData.length > 0 ? `Top ${documentData.length} Viewed Documents` : 'No Recent Documents'}
      />
      <Divider/>
      <CardContent className={classes.content}>
        <List>
          {documentData.length <= 0 ? (
              <ListItem
                key="NoDocuments"
                style={{width: '100%'}}
              >
                <ListItemAvatar style={{width: '100%'}}>
                  <Avatar
                    className={classes.emptyAvatarStyle}
                    style={{backgroundColor: palette.error.light, width: '100%'}}
                    variant="rounded"
                  >
                    <Typography
                      align="center"
                      className={classes.section}
                      color="textPrimary"
                      component="span"
                      style={{backgroundColor: palette.error.light, width: '100%'}}
                      variant="h5"
                    >
                      No Recent Document Activity!
                    </Typography>
                  </Avatar>
                </ListItemAvatar>
              </ListItem>
            )
            :
            (<React.Fragment>{documentData.map((document, i) => (
              <ListItem
                divider={i < documents.length - 1}
                key={uuid()}
              >
                <ListItemAvatar>
                  <Avatar
                    className={classes.avatarStyle}
                    style={{backgroundColor: statusColors[document.section]}}
                    variant="rounded"
                  >
                    <Typography
                      align="center"
                      className={classes.section}
                      color="textPrimary"
                      component="span"
                      style={{backgroundColor: statusColors[document.section]}}
                      variant="body2"
                    >
                      {document.section}
                    </Typography>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <React.Fragment>
                      <Typography
                        className={classes.title}
                        color="textPrimary"
                        component="span"
                        variant="body2"
                      >
                        {document.documentName}
                      </Typography>
                    </React.Fragment>}
                />
                <ListItemSecondaryAction>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography
                          className={classes.title}
                          color="textPrimary"
                          component="span"
                          variant="body2"
                        >
                          {document.numberOfViews}
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={'Views'}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}</React.Fragment>)}

        </List>
      </CardContent>
      <Divider/>
      {documentData.length > 0 &&
      <CardActions className={classes.actions}>
        <Link to="/documents">
          <Button
            color="primary"
            size="small"
            variant="text"
          >
            View all <ArrowRightIcon/>
          </Button>
        </Link>
      </CardActions>
      }
    </Card>
  );
};

TopDocumentsViewedMetrics.propTypes = {
  className: PropTypes.string
};

export default TopDocumentsViewedMetrics;
