import React from "react";
import {GridItem} from "../../../../../components/Grid";
import CustomInput from "../../../../../components/CustomInput/CustomInput";

const FilterTextInput = props => {
  const {
    optionNameDisplay,
    optionNameInternal,
    optionId,
    onHandleChange,
    defaultValue,
    screenRatios = {xs: 12, sm: 12, md: 2, lg: 2},
    render
  } = props;

  if (render === false || (render === undefined && onHandleChange === undefined)) return null;
  else return (
    <GridItem
      xs={screenRatios.xs}
      sm={screenRatios.sm}
      md={screenRatios.md}
      lg={screenRatios.lg}
      xl={screenRatios.xl}
    >
      <CustomInput
        labelText={optionNameDisplay}
        id={optionId}
        name={optionNameInternal}
        formControlProps={{
          fullWidth: true
        }}
        value={defaultValue}
        inputProps={{
          onChange: event => {
            onHandleChange(event);
          },
          type: 'text'
        }}
      />
    </GridItem>
  );
}

export default FilterTextInput;
