const generateKey = (typeofkey='password', keylength=10) => {
  let keyString = '';
  let random_string_length = keylength.value;
  let characters = '#$!%*abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let numbers = '0123456789';
  let specials = '#$!%*#';
  if (typeofkey === 'password'){
    characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz#$!%*#';
  }

  let max = characters.length - 1;
  for (let i = 0; i < random_string_length-2; i++) {
    keyString = keyString.concat(characters[rand(0, max)]);
  }

  keyString = keyString.concat(specials[rand(0, (specials.length - 1))])
  keyString = keyString.concat(numbers[rand(0, (numbers.length - 1))])

  return keyString;
}

function rand(min, max){
  if(min === 0){
    return Math.floor((Math.random() * max));
  }else{
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}

export default generateKey
