import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import MaterialTable from '@material-table/core';

// @mui/icons-material
import {classificationActions} from '../../../store/actions';

import PageHeader from '../../../components/PageHeader/PageHeader';
import {makeStyles} from '@mui/styles';
import LoadingSpinner from "../../../components/Spinner/loading.spinner";
import {classificationService} from "../../../store/services";
import {SECURITY_ROLES} from "../../../constants/SECURITY_ROLES";
import {EmptyList} from "../../../components/EmptyList";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const ListClassification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {role} = useSelector(state => state.authentication.user);
  const [initialRender, setInitialRender] = useState(true);
  const [mediaData, setMediaData] = useState(null);

  let editable = {};
  if (role === SECURITY_ROLES.CLOUD_ADMIN){
    editable ={
      cellStyle: {
        textAlign: "right"
      },
      onRowAdd: newData =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            setMediaData([...mediaData, newData]);
            onHandleAdd(newData);
            resolve();
          }, 1000)
        }),
      onRowUpdate: (newData, oldData) =>
        new Promise((resolve, reject) => {
          setTimeout(() => {
            const dataUpdate = [...mediaData];
            const index = oldData.tableData.id;
            dataUpdate[index] = newData;
            setMediaData([...dataUpdate]);
            onHandleUpdate(newData);
            resolve();
          }, 1000)
        }),
    };
  }


  async function getClassifications () {
    await classificationService.getAllClassifications().then((results) => {
      setMediaData(results.data)
    })
  }

  let mediaType = {};
  const onHandleUpdate = (values) => {
    getClickedMediaType(values);
    setMediaData(null);
    dispatch(classificationActions.update(mediaType));
    getClassifications();
  }

  const onHandleAdd = (values) => {
    mediaType = {
      id: 0,
      name: values.name,
      active: true
    };

    setMediaData(null);
    classificationService.add(mediaType)
      .then((results) => {
        getClassifications();
      })
        .catch(function(error){
        getClassifications();
      });
  }

  const getClickedMediaType = (values) => {
    let obj = mediaData.find((d) => (d.id === values.id));
    mediaType = {
      id: obj.id,
      name: values.name,
      active:obj.active
    };
  }


  useEffect(() => {
    if (initialRender){
      getClassifications();
      setInitialRender(false);
    }

  }, [mediaData,initialRender]);


  return (

    <div className={classes.root}>
      <div className={classes.content}>
        <PageHeader
          subTitle={'List of All Media Types'}
          title={'Media Type'}
        />
        {!mediaData ? (<LoadingSpinner/>) :
          (
            <MaterialTable
              localization={{
                body: {
                  emptyDataSourceMessage: (
                    <EmptyList
                      label="No Media Types to Display!"
                    />
                  ),
                },
              }}
              columns={[
                {
                  title: 'Media Type',
                  field: 'name',
                  validate: rowData => Boolean(rowData.name),
                  cellStyle: {
                    width: '40%',
                    maxWidth: '40%'
                  },
                  headerStyle: {
                    width: '40%',
                    maxWidth: '40%'
                  }
                },
                {
                  cellStyle: {
                    width: '55%',
                    maxWidth: '55%'
                  },
                  headerStyle: {
                    width:'55%',
                    maxWidth: '55%'
                  }
                }
              ]}
              data={mediaData}
              options={{
                emptyRowsWhenPaging: false,
                pageSize: mediaData.length > 10 ? 20 : (mediaData.length > 5 ? 10 : 5),
                search: true,
                searchFieldAlignment: 'left',
                actionsColumnIndex: -1,
                addRowPosition: "first"
              }}
              editable={editable}
              title=""
            />
          )}
      </div>
    </div>
  )
}

export default ListClassification;
