import 'date-fns';
import React from 'react';

// @mui/icons-material
// @mui/material components
import {GridItem} from '../../../../components/Grid';
import Button from '../../../../components/CustomButtons/Button';
import Grid from '@mui/material/Grid';
import FilterDropdown from '../panelSegments/FilterDropdown';
import {ArrayBasedMenuItem} from "../panelSegments/components";
import FilterDateTime from "../panelSegments/FilterDateTime";
import FilterTextInput from "../panelSegments/FilterTextInput";
import customSelectStyle from "../../../../assets/jss/customSelectStyle";
import {makeStyles} from "@mui/styles";
import NameBasedMenuItem from "../panelSegments/components/NameBasedMenuItem";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      margin: theme.spacing(2),
      marginTop: 0
    },
    ...customSelectStyle
  }
})

const SearchPanel = props => {
  const {
    role,
    initialSections,
    sectionSelect,
    onHandleSectionSelect,
    includeAllSections = true,
    searchSectionsByMethod = NameBasedMenuItem,
    initialClassifications,
    classificationSelect,
    onHandleClassificationSelect,
    includeAllClassifications = true,
    searchClassificationsByMethod = NameBasedMenuItem,
    initialCategories,
    categorySelect,
    onHandleCategorySelect,
    includeAllCategories = true,
    searchCategoriesByMethod = NameBasedMenuItem,
    initialCompanies,
    companySelect,
    onHandleCompanySelect,
    includeAllCompanies = true,
    searchCompaniesByMethod = NameBasedMenuItem,
    initialStatus,
    statusSelect,
    onHandleStatusSelect,
    includeAllStatus = true,
    searchStatusByMethod = ArrayBasedMenuItem,
    beginDate,
    onHandleBeginDateSelect,
    endDate,
    onHandleEndDateSelect,
    filename,
    onFilenameChange,
    attribute,
    onAttributeChange,
    renderButton = true,
    buttonText = '',
    buttonIcon,
    onButtonClick,
    customButtons = []

  } = props;

  const classes = useStyles();

  const allButtonElements = [];
  if (renderButton) allButtonElements.push(
    <Button
      color="primary"
      className={classes.button}
      component="span"
      variant="contained"
      onClick={onButtonClick}
    >
      {buttonIcon ? React.createElement(buttonIcon) : undefined} {buttonText}
    </Button>);
  if (customButtons) customButtons.forEach(button => allButtonElements.push(button));

  const buttonGridItems = [];
  allButtonElements.forEach((button, index) => buttonGridItems.push(<GridItem
    key={index}
    xs={12}
    md={2}
  >
    {button}
  </GridItem>));

  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Grid container>
          <FilterDropdown
            classes={classes}
            optionNameDisplay={"Company"}
            optionNameDisplayPlural={"Companies"}
            optionNameInternal={"companySelect"}
            optionId={"company-select"}
            includeAllOptionsAsOption={includeAllCompanies}
            initialOptions={initialCompanies}
            onHandleSelect={onHandleCompanySelect}
            optionSelect={companySelect}
            menuItemListMethod={searchCompaniesByMethod}
          />
          <FilterDropdown
            classes={classes}
            optionNameDisplay={"Section"}
            optionNameDisplayPlural={"Sections"}
            optionNameInternal={"sectionSelect"}
            optionId={"section-select"}
            includeAllOptionsAsOption={includeAllSections}
            initialOptions={initialSections}
            onHandleSelect={onHandleSectionSelect}
            optionSelect={sectionSelect}
            menuItemListMethod={searchSectionsByMethod}
          />
          <FilterDropdown
            classes={classes}
            optionNameDisplay={"Media Type"}
            optionNameDisplayPlural={"Media Types"}
            optionNameInternal={"classificationSelect"}
            optionId={"classification-select"}
            includeAllOptionsAsOption={includeAllClassifications}
            initialOptions={initialClassifications}
            onHandleSelect={onHandleClassificationSelect}
            optionSelect={classificationSelect}
            menuItemListMethod={searchClassificationsByMethod}
          />
          <FilterDropdown
            classes={classes}
            optionNameDisplay={"Category"}
            optionNameDisplayPlural={"Categories"}
            optionNameInternal={"categorySelect"}
            optionId={"category-select"}
            includeAllOptionsAsOption={includeAllCategories}
            initialOptions={initialCategories}
            onHandleSelect={onHandleCategorySelect}
            optionSelect={categorySelect}
            menuItemListMethod={searchCategoriesByMethod}
          />
          <FilterDropdown
            classes={classes}
            optionNameDisplay={"Status"}
            optionNameDisplayPlural={"Status"}
            optionNameInternal={"statusSelect"}
            optionId={"status-select"}
            includeAllOptionsAsOption={includeAllStatus}
            initialOptions={initialStatus}
            onHandleSelect={onHandleStatusSelect}
            optionSelect={statusSelect}
            menuItemListMethod={searchStatusByMethod}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Grid container>
          <FilterDateTime
            classes={classes}
            optionNameDisplay={"Begin File Date"}
            optionNameInternal={"begin date"}
            optionId={"begin-date"}
            onHandleSelect={onHandleBeginDateSelect}
            defaultValue={beginDate}
          />
          <FilterDateTime
            classes={classes}
            optionNameDisplay={"End File Date"}
            optionNameInternal={"end date"}
            optionId={"end-date"}
            onHandleSelect={onHandleEndDateSelect}
            defaultValue={endDate}
          />
          <FilterTextInput
            classes={classes}
            optionNameDisplay={"Filename"}
            optionNameInternal={"file_name"}
            optionId={"file_name"}
            onHandleChange={onFilenameChange}
            defaultValue={filename}
          />
          {role !== 'SharedUser' &&
            <FilterTextInput
              classes={classes}
              optionNameDisplay={'Attributes key:value; key:value ....'}
              optionNameInternal={"attributes"}
              optionId={"attributes"}
              onHandleChange={onAttributeChange}
              defaultValue={attribute}
            />
          }
        </Grid>
      </Grid>
      <Grid container>
        {buttonGridItems}
      </Grid>
    </Grid>
  )
}

export default SearchPanel;
