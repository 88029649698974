import {documentConstants} from '../../constants';

const initialState = {
  requestPending: false,
  items: null
};

export function documents(state = initialState, action) {
  switch (action.type) {
    case documentConstants.APPROVE_DOCUMENT_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.APPROVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.APPROVE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.SEARCH_ALL_REQUEST:
      return {
        ...state,
        items: null,
        requestPending: true
      };
    case documentConstants.SEARCH_ALL_SUCCESS:
      return {
        ...state,
        items: action.documents.data,
        requestPending: false
      };
    case documentConstants.SEARCH_ALL_FAILURE:
      return {
        ...state,
        items: null,
        error: action.error,
        requestPending: false
      };
    case documentConstants.STAGED_DOCUMENT_REQUEST:
      return {
        ...state,
        items: null,
        requestPending: true
      };
    case documentConstants.STAGED_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents.data,
        requestPending: false
      };
    case documentConstants.STAGED_DOCUMENT_FAILURE:
      return {
        ...state,
        items: null,
        error: action.error,
        requestPending: false
      };
    case documentConstants.UPLOAD_FILES_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.UPLOAD_FILES_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.REJECT_DOCUMENT_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.REJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.REJECT_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.ADD_SHARE_DOCUMENT_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.ADD_SHARE_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.ADD_SHARE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.REMOVE_SHARE_DOCUMENT_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.REMOVE_SHARE_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.REMOVE_SHARE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.REMOVE_DOCUMENT_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.REMOVE_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.REMOVE_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.HOLD_DOCUMENT_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.HOLD_DOCUMENT_SUCCESS:
      return {
        ...state,
        items: action.documents,
        requestPending: false
      };
    case documentConstants.HOLD_DOCUMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };

    case documentConstants.GET_PDF_REQUEST:
      return {
        ...state,
        requestPending: true
      };
    case documentConstants.GET_PDF_SUCCESS:
      return {
        ...state,
        item: action.documents,
        requestPending: false
      };
    case documentConstants.GET_PDF_FAILURE:
      return {
        ...state,
        error: action.error,
        requestPending: false
      };
    case documentConstants.DOCUMENT_NOTES_REQUEST:
      return {
        ...state,
        notes: null,
        requestPending: true
      };
    case documentConstants.DOCUMENT_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.notes.data,
        requestPending: false
      };
    case documentConstants.DOCUMENT_NOTES_FAILURE:
      return {
        ...state,
        notes: null,
        error: action.error,
        requestPending: false
      };
    case documentConstants.ADD_DOCUMENT_NOTES_REQUEST:
      return {
        ...state,
        value: null,
        requestPending: true
      };
    case documentConstants.ADD_DOCUMENT_NOTES_SUCCESS:
      return {
        ...state,
        value: action.notes.data,
        requestPending: false
      };
    case documentConstants.ADD_DOCUMENT_NOTES_FAILURE:
      return {
        ...state,
        value: null,
        error: action.error,
        requestPending: false
      };
    default:
      return state
  }
}
