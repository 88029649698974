import React, {useCallback, useEffect, useState} from 'react';
import {Chart as ChartJS, registerables} from 'chart.js';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@mui/styles';
import {Card, CardContent, CardHeader, Divider, IconButton, Typography} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PanToolIcon from '@mui/icons-material/PanTool';
import {dashboardService} from '../../../../store/services';
import {useSelector} from 'react-redux';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {InteractiveBarChart} from "../../../../components";
import {history} from "../../../../helpers/history";
import {SECURITY_ROLES} from "../../../../constants/SECURITY_ROLES";

ChartJS.register(...registerables);

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  chartContainer: {
    position: 'relative',
    height: '300px'
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  document: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  documentIcon: {
    color: theme.palette.icon
  },
  titleHeader: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.white
  },
}));

const StagedDocumentMetrics = props => {
  const {className, metrics: {stagingDocumentMetrics}, view, ...rest} = props;
  const user = useSelector(state => state.authentication.user);
  const [documents, setDocuments] = useState(stagingDocumentMetrics);
  const [documentCounts, setDocumentCounts] = useState(null);
  const [maxDocument, setMaxDocument] = useState(0);
  const [data, setData] = useState(null);
  const [dataIsBuilding, setDataIsBuilding] = useState(null);
  const [dataBuilt, setDataBuilt] = useState(false);
  const [xlabels] = useState(['Waiting', 'Hold', 'Removed', 'Rejected']);
  const classes = useStyles();
  const theme = useTheme();

  const options = {
    plugins: {
      legend: {
        display: true
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        min: 0,
        max: maxDocument > 5 ? maxDocument : 5,
        ticks: {
          beginAtZero: true,
          count: maxDocument > 5 ? null : 5,
          stepSize: maxDocument > 5 ? null : 1
        },
      },
    },
    interaction:{
      mode: 'index'
    },
    minBarLength: 5,
    responsive: true,
    maintainAspectRatio: false,
  };

  const buildData = useCallback((documents) => {
    setDataIsBuilding(true);
    const arbitraryStackKey = 'stack1';

    let data = {
      datasets: [
        {
          label: 'Company',
          stack: arbitraryStackKey,
          data: [documents['Company'].Waiting, documents['Company'].Hold, documents['Company'].Removed, documents['Company'].Rejected],
          backgroundColor: [
            theme.palette.companySection,
          ],
          borderWidth: 3,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white
        },
        {
          stack: arbitraryStackKey,
          label: 'Public',
          data: [documents['Public'].Waiting, documents['Public'].Hold, documents['Public'].Removed, documents['Public'].Rejected],
          backgroundColor: [
            theme.palette.publicSection,
          ],
          borderWidth: 3,
          borderColor: theme.palette.white,
          hoverBorderColor: theme.palette.white
        }
      ],
      labels: xlabels
    }

    let documentCounts = [
      {
        title: 'Waiting',
        value: documents['total'].Waiting,
        icon: <PendingActionsIcon style={{color: theme.palette.waitingStatus}}/>,
        color: theme.palette.waitingStatus
      },
      {
        title: 'Hold',
        value: documents['total'].Hold,
        icon: <PanToolIcon style={{color: theme.palette.holdStatus}}/>,
        color: theme.palette.holdStatus
      },
      {
        title: 'Removed',
        value: documents['total'].Removed,
        icon: <DeleteForeverIcon style={{color: theme.palette.removedStatus}}/>,
        color: theme.palette.removedStatus
      },
      {
        title: 'Rejected',
        value: documents['total'].Rejected,
        icon: <ThumbDownIcon style={{color: theme.palette.rejectedStatus}}/>,
        color: theme.palette.rejectedStatus
      }]

    let maxDocuments = Math.max.apply(Math, documentCounts.map(function(v) {
      return v.value; }));

    setMaxDocument(maxDocuments);
    setDocumentCounts(documentCounts);
    setDataIsBuilding(false);
    setData(data);
    setDataBuilt(true);
  }, [theme, setDataIsBuilding, setDataBuilt, setDocumentCounts, setData, xlabels])

  async function resetData() {
    setData(null);
    setDataBuilt(false);
  }

  const onHandleBarClickEvent = (x, y) => {
    let pathname='/documents/staged';
    history.push({
      pathname: pathname,
      initialStatus: xlabels[x].toUpperCase(),
      initialSection: data.datasets[y].label,
      startDate: "01/01/2000"
    });
  }

  const handleRefresh = event => {
    getData().then(metrics => {
      setDocuments(metrics);
      resetData().then(r=>{
        buildData(documents)
      })
    });
  };

  async function getData(days) {
    if (user.role === SECURITY_ROLES.USER || user.role === SECURITY_ROLES.POWER_USER) {
      return await dashboardService.getUserStagedDocumentCountsMetrics().then(r => {
        return r.data
      });
    } else {
      return await dashboardService.getStagedDocumentCountsMetrics().then(r => {
        return r.data
      });
    }
  }

  useEffect(() => {
    if (!dataBuilt && !dataIsBuilding) {
      buildData(documents)
    }
  }, [dataBuilt, dataIsBuilding, buildData, documents])

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader className={classes.titleHeader}
        action={
          <IconButton
            disabled={!dataBuilt}
            onClick={handleRefresh}
            size="small"
          >
            <RefreshIcon/>
          </IconButton>
        }
        title="Staged Document Metrics"
      />
      <Divider/>
      <CardContent>
        {data && documentCounts && !dataIsBuilding ? (
          <React.Fragment>
            <div className={classes.chartContainer}>
              <InteractiveBarChart
                data={data}
                options={options}
                onHandleBarClick={onHandleBarClickEvent}
              />
            </div>
            <div className={classes.stats}>
              {documentCounts.map(document => (
                <div
                  className={classes.document}
                  key={document.title}
                >
                  <span className={classes.documentIcon}>{document.icon}</span>
                  <Typography
                    style={{color: document.color}}
                    variant="h2"
                  >
                    {document.value}
                  </Typography>

                  <Typography
                    style={{color: document.color}}
                    variant="h6"
                  >{document.title}</Typography>

                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (<LoadingSpinner/>)}
      < /CardContent>
    </Card>
  );
};

StagedDocumentMetrics.propTypes = {
  className: PropTypes.string
};

export default StagedDocumentMetrics;
