import React from "react";
import {GridItem} from "../../../../../components/Grid";
import {DatePicker} from "@mui/x-date-pickers/DatePicker"
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {TextField} from "@mui/material";

const FilterDateTime = props => {
  const {
    classes,
    optionNameDisplay,
    optionNameInternal,
    optionId,
    onHandleSelect,
    defaultValue,
    screenRatios = {xs: 12, sm: 12, md: 2, lg: 2},
    format = "MM/dd/yyyy",
    render
  } = props;

  if (render === false || (render === undefined && onHandleSelect === undefined)) return null;
  else return (
    <GridItem
      xs={screenRatios.xs}
      sm={screenRatios.sm}
      md={screenRatios.md}
      lg={screenRatios.lg}
      xl={screenRatios.xl}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          KeyboardButtonProps={{
            'aria-label': optionNameInternal
          }}
          inputFormat={format}
          id={optionId}
          label={optionNameDisplay}
          margin="normal"
          onChange={onHandleSelect}
          value={defaultValue}
          InputProps={{className: classes.select}}
          renderInput={(props) => <TextField
            variant="standard"
            className={classes.selectFormControl}
            InputLabelProps={{
              className: classes.selectLabel,
              htmlFor: optionId
            }}
            {...props}/>}
        />
      </LocalizationProvider>
    </GridItem>
  );
}

export default FilterDateTime;
