import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {Card, CardHeader, Divider, IconButton} from '@mui/material';
import {dashboardService} from '../../../../../store/services';
import RefreshIcon from '@mui/icons-material/Refresh';
import MaterialTable from '@material-table/core';
import LoadingSpinner from "../../../../../components/Spinner/loading.spinner";
import {createHmac} from 'crypto-browserify';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  chartContainer: {
    position: 'relative'
  },
  emptyAvatarStyle: {
    margin: 10,
    width: '100%',
    height: 50
  },
  section: {
    color: theme.palette.white
  },
  actions: {
    justifyContent: 'flex-end'
  },
  titleHeader: {
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.white
  },
}));

/*const statusColors = {
  public: palette.publicSection,
  company: palette.companySection,
  private: palette.privateSection
};*/

const CloudAdminIpMetrics = props => {
  const {className, metrics: {ipMetrics}, ...rest} = props;
  const [data, setData] = useState(null);
  const [metrics, setMetrics] = useState(ipMetrics);
  const classes = useStyles();
  useEffect(() => {
    if (!data) {
      getMetrics().then(data => { buildData(data)});
    }
  }, [data])

  const onHandleRefresh = event => {
    getMetrics(event.target.value).then(metricsData => {
      setMetrics(metrics);
      buildData(metricsData);
    });
  };

  const buildData = (metricsData) => {
    let dataElements = [];
    Object.entries(metricsData.companyData).forEach((e, i) => {
      e[1].data.forEach(obj => {
        let tempData = {user: obj.user, ip: obj.ip, company: e[0]};
        return dataElements.push({...tempData, id: createHmac("SHA256", tempData).digest().toString("hex")});
      })
    })
    setData(dataElements);
  }
  async function getMetrics() {
    return await dashboardService.getCloudAdminIpMetrics().then(r => {
      return r.data
    });
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {metrics && metrics.companyData ? (
        <React.Fragment>
          <CardHeader
            className={classes.titleHeader}
            action={
              <IconButton
                onClick={onHandleRefresh}
                size="small"
              >
                <RefreshIcon/>
              </IconButton>
            }
            title="Access By IP Address"
          />
          <Divider/>
          {data &&
              <MaterialTable
                columns={[
                  {
                    title: 'User',
                    field: 'user'
                  },
                  {
                    title: 'IP Address',
                    field: 'ip'
                  },
                  {
                    title: 'Company',
                    field: 'company'
                  },
                ]}
                data={data}
                options={{
                  search: true,
                  searchFieldAlignment: 'left'

                }}
                title=""
              />
          }
          <Divider/>
        </React.Fragment>)
        : (<LoadingSpinner/>)
      }
    </Card>
  );
};

CloudAdminIpMetrics.propTypes = {
  className: PropTypes.string
};

export default CloudAdminIpMetrics;
