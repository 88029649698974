import {requestHelper} from '../helpers/index';
import axiosInstance from '../../axios.instance';
import { maestroenv } from '../../helpers/maestroenv';

export const categoryService = {
  add,
  update,
  getAllCategories
};

function add(category) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/categories/add`, JSON.stringify(category))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getAllCategories() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/categories/`)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function update(category) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/categories/update`, JSON.stringify(category))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
