import React from 'react';
import {ErrorMessage, Form, Formik} from "formik";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormControlLabel,
  FormLabel,
  Grid, Radio, RadioGroup,
  Typography
} from "@mui/material";
import {isEmpty} from "underscore";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import {GridItem} from "../../../../components/Grid";
import moment from "moment";

const PasswordExpirationDialog = (props) => {
  const {
    openExpiry,
    setOpenExpiry,
    classes,
    passwordInfo,
    onHandleSubmit
  } = props;

  const initialTouched = {
    expiresDays: true
  };

  return (
    <Formik
      enableReinitialize
      initialValues={passwordInfo}
      onReset={(values) => {
        setOpenExpiry(false);
      }}
      initialTouched={initialTouched}
      onSubmit={(values, {setSubmitting, setValues, setErrors, setFieldError}) => {
        setSubmitting(true);
        setOpenExpiry(false);
        let now = moment();

        if (values.keyExpiry === 'does_not_expire') {
          values.doesNotExpire = 1;
          values.expiresDays = 0;
          values.expires = new moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");

        } else {
          values.doesNotExpire = 0;
          values.started = now.format("YYYY-MM-DD HH:mm:ss");
          values.expires = now.add(values.expiresDays, 'd').format("YYYY-MM-DD HH:mm:ss");
        }

        if (onHandleSubmit(values)) {
          setSubmitting(false);
        } else {
          setErrors("Shared User Expiration update not successful");
          setFieldError('name', 'Shared User Expiration failed');
        }
      }}
    >
      {(formikProps) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleReset,
          handleSubmit
        } = formikProps;

        const hasExpirationErrors = !isEmpty(touched) && !isEmpty(errors);

        return (
          <Form onSubmit={handleSubmit}>
            <Dialog open={openExpiry}
                    onClose={handleReset}
                    aria-labelledby="key-dialog"
            >
              <DialogTitle id="key-dialog-title">Password Expiration</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={3}
                  className={classes.content}
                >
                  <GridItem
                    xs={12}
                  >
                    <Typography variant={'h3'}>
                      Date Expires: {passwordInfo.expires}
                    </Typography>
                  </GridItem>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider/>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Key Expiration</FormLabel>
                      <RadioGroup aria-label="key_expiry"
                                  name="keyExpiry"
                                  value={values.keyExpiry}
                                  onChange={handleChange}
                      >
                        <FormControlLabel
                          value="does_not_expire"
                          control={<Radio/>}
                          label="Does not expire"
                        />
                        <FormControlLabel
                          value="expires_on_days"
                          control={<Radio/>}
                          label={
                            <TextField
                              name="expiresDays"
                              label="Number of days"
                              margin="normal"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.expiresDays}
                              variant="outlined"
                              disabled={values.keyExpiry === 'does_not_expire'}
                            />
                          }
                        />
                        {
                          (values.passwordExpiry === "expires_on_days") ? (
                            <ErrorMessage className={classes.errorMessage} component="div" name="expiresDays"/>
                          ) : null
                        }
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  aria-label="update" className={classes.updateButton}
                  type='submit'
                  onClick={handleSubmit}
                  color="primary"
                  disabled={hasExpirationErrors}
                  variant="contained"
                >
                  Update
                </Button>
                <Button
                  aria-label="cancel" className={classes.cancelButton}
                  onClick={handleReset}
                  color="primary"
                  variant="contained">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  );
}

export default PasswordExpirationDialog;
