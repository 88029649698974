import React, {useCallback, useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {alertActions, documentActions} from '../../../../store/actions';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography
} from '@mui/material';

import {DocumentDetails, FileViewer, SearchPanel} from '../../components';
import Card from '../../../../components/Card/CardBody';
import CardBody from '../../../../components/Card/CardBody';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import {documentService, vodService} from "../../../../store/services";
import {DOCUMENT_STATUS} from "../../../../constants/DOCUMENT_STATUS";
import {DOCUMENT_VIEWER_MODES} from "../../../../constants/DOCUMENT_VIEWER_MODES";
import FilterListIcon from "@mui/icons-material/FilterList";
import useStyles from './styles';
import TabList from "../../../../components/Tab/TabList";

const FileListWindow = (props) => {
  const {
    title = 'Documents', subtitle = 'Available Documents',
    initialSection = 'All Sections', includeSectionFilter = false,
    initialCompany = 'All Companies', includeCompanyFilter = false,
    initialClassification = 'All Media Types', includeClassificationFilter = false,
    initialCategory = 'All Categories', includeCategoryFilter = false,
    initialStatus = DOCUMENT_STATUS.APPROVED, includeStatusFilter = false,
    initialBeginDate, includeBeginDate = false,
    initialEndDate, includeEndDate = false,
    initialFilename = '', includeFilename = false,
    initialAttribute = '', includeAttribute = false,
    viewer = DOCUMENT_VIEWER_MODES.LIST,
    sharedUser = false,
    sharedUserGuid = '',
    userGuid = '',
    sharedUserRole = false,
    getDocumentFromDocumentServiceMethod = (id) => documentService.getDocumentById(id),
    searchDocumentFromDocumentServiceMethod = (searchFields) => documentActions.searchDocuments(searchFields)
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const filteredDocs = useSelector(state => state.documents.items);
  const {
    documents,
    categories,
    classifications,
    sections,
    companies
  } = filteredDocs ? filteredDocs : {};
  const {
    id,
    role,
    company,
    companyId
  } = useSelector(state => state.authentication.user);

  const [classificationSelect, setClassificationSelect] = useState(initialClassification);
  const [categorySelect, setCategorySelect] = useState(initialCategory);
  const [sectionSelect, setSectionSelect] = useState(initialSection);
  const [companySelect, setCompanySelect] = useState(initialCompany);
  const [statusSelect, setStatusSelect] = useState(initialStatus);
  const [beginDate, setBeginDate] = useState(initialBeginDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [filename, setFilename] = useState(initialFilename);
  const [attribute, setAttribute] = useState(initialAttribute);

  const [statusList] = useState([
    DOCUMENT_STATUS.REJECTED,
    DOCUMENT_STATUS.REMOVED,
    DOCUMENT_STATUS.HOLD,
    DOCUMENT_STATUS.WAITING
  ]);

  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [searchFields] = useState({
    company_guid: companyId,
    company_selection: companySelect,
    user_guid: sharedUser ? userGuid : id,
    section: sectionSelect,
    classification: classificationSelect,
    category: categorySelect,
    status: statusSelect,
    start_date: beginDate,
    end_date: endDate,
    file_name: filename,
    shared_user_guid: sharedUserRole ? id : sharedUserGuid,
    role: role
  });

  let documentFields = {
    'document_guid': '',
    'user_guid': id,
    'company_guid': companyId,
    'notes': '',
  }

  const handleApproved = (documentId, tabId, displayName, section, owner_guid, status) => {
    fillDocumentFields(documentId, tabId, {
      'notes': null,
      'private': 0,
    }, displayName, section, owner_guid, status);
    approveDocument(documentFields).then(dtls => {
      searchDocWithFilters();
    });
    tabListRef.current.closeTab(tabId);
  };

  const handleBeginDateChange = date => {
    setBeginDate(date);
  };

  const handleCategorySelect = event => {
    setCategorySelect(event.target.value);
  };

  const handleClassificationSelect = event => {
    setClassificationSelect(event.target.value);
  };

  const handleClickedView = rowData => {
    let index = files.findIndex(x => x.id === rowData.guid);
    if (index === -1) {
      let document = getDocument(rowData.display_name, rowData.guid).then(document => {
        setFiles(prevState => [...prevState, {id: rowData.guid, data: document}]);
        return document;
      });
      loadTab(rowData, document, rowData.status)
    } else {
      loadTab(rowData, files[index], rowData.status)
    }
  }

  const handleCompanySelect = event => {
    setCompanySelect(event.target.value);
  };

  const handleEndDateChange = date => {
    setEndDate(date);
  };

  const handleFilenameChange = event => {
    setFilename(event.target.value);
  };

  const handleAttributeChange = event => {
    setAttribute(event.target.value);
  };

  const handleFilterClick = event => {
    searchDocWithFilters();

    setFilename('');
    setAttribute('');
  };

  const handleHoldForQuestions = (documentId, tabId, values, displayName, section, ownerGuid) => {
    fillDocumentFields(documentId, tabId, values, displayName, section, ownerGuid, DOCUMENT_STATUS.HOLD);

    holdDocument(documentFields).then(dtls => {
      searchDocWithFilters();
    });
    tabListRef.current.closeTab(tabId);
  };

  const handleRejected = (documentId, tabId, values, displayName, section, ownerGuid) => {
    fillDocumentFields(documentId, tabId, values, displayName, section, ownerGuid, DOCUMENT_STATUS.REJECTED);

    rejectDocument(documentFields).then(dtls => {
      searchDocWithFilters();
    });
    tabListRef.current.closeTab(tabId);
  };

  const handleRemove = (documentId, tabId, values, displayName, section, ownerGuid) => {
    fillDocumentFields(documentId, tabId, values, displayName, section, ownerGuid, DOCUMENT_STATUS.REMOVED);

    removeDocument(documentFields).then(dtls => {
      searchDocWithFilters();
    });

    tabListRef.current.closeTab(tabId);
  };

  const handleRemoveShare = (documentId, tabId) => {
    documentFields.document_guid = documentId;
    documentFields.user_guid = id;
    documentFields.shared_user_guid = sharedUserGuid;
    removeShareToDocument(documentFields).then(dtls => {
      searchDocWithFilters();
    });
    tabListRef.current.closeTab(tabId);
  };

  const handleSectionSelect = event => {
    setSectionSelect(event.target.value);
  };

  const handleShare = (documentId, tabId) => {
    documentFields.document_guid = documentId;
    documentFields.user_guid = id;
    documentFields.shared_user_guid = sharedUserGuid;
    addShareToDocument(documentFields).then(dtls => {
      searchDocWithFilters();
    });

    tabListRef.current.closeTab(tabId);
  };

  const handleStatusSelect = event => {
    setStatusSelect(event.target.value);
  };

  async function approveDocument(documentFields) {
    return await documentService.approveDocumentUpload(documentFields)
      .then(response => {
          statusAlertMessage(documentFields.status);
          return response.data
        }
      )
  }

  /*async function addDocumentNotes(documentFields) {
    return await documentService.insertDocumentNotes(documentFields)
      .then(response => {
          return response.data
        }
      )
  }*/

  function fillDocumentFields(documentId, tabId, values, displayName, section, owner_guid, status) {
    documentFields.document_guid = documentId;
    documentFields.owner_guid = owner_guid;
    documentFields.section = section;
    documentFields.displayName = displayName;
    documentFields.user_guid = id;
    documentFields.company_guid = companyId;
    documentFields.notes = values.notes;
    documentFields.private = values.private;
    documentFields.status = status;
  }

  async function getDocument(displayName, documentGuid) {
    return await getDocumentFromDocumentServiceMethod(documentGuid)
      .then(document => {
        //Not including document.type.split(";")[0] will cause issues in Safari, where the type returned would be "text/plain;charset=utf-8" instead of "text/plain"
        if (document.type.split(";")[0] !== "text/plain")
          return document;
        else
          return getDocumentFromVodServiceMethod(document);
      });
  }

  async function getDocumentFromVodServiceMethod(document) {
    let url = await document.text()
    return await vodService.getVoDFile(url);
  }

  /*async function getDocumentNotes(documentGuid) {
    return await documentService.getDocumentById(documentGuid).then(document => {
      return document;
    });
  }*/

  async function holdDocument(documentFields) {
    return await documentService.holdDocumentUpload(documentFields)
      .then(response => {
          statusAlertMessage(documentFields.status);
          return response.data
        }
      )
  }

  function loadNewTab(tabData, document, status) {
    tabListRef.current.addTab(
      (tabId)=>(tabData.display_name) ? (tabData.display_name) : ('PANEL ' + tabId),
      (tabId)=>(<FileViewer
        classes={classes}
        approval={(viewer === 'staged').toString()}
        file={document}
        tabData={tabData}
        guid={tabData.guid}
        company_guid={tabData.company_guid}
        displayName={tabData.display_name}
        notesCount={tabData.notesCount}
        metaData={tabData.metaData}
        onHandleApproved={handleApproved}
        onHandleHoldForQuestions={handleHoldForQuestions}
        onHandleRejected={handleRejected}
        onHandleRemove={handleRemove}
        onHandleShare={handleShare}
        onHandleRemoveShare={handleRemoveShare}
        shared={tabData.shared}
        status={status}
        tabId={tabId}
        view={viewer}
      />)
    );
  }

  function loadTab(tabData, documentData, status) {
    let labelFound = tabListRef.current.tabList.filter(tabValue => {
      if (tabData.display_name === tabValue.label) {
        tabListRef.current.changeTab(tabValue.id);
        return 1;
      } else {
        return 0;
      }
    });
    if (labelFound.length === 0) {
      loadNewTab(tabData, documentData, status);
    }
  }

  async function rejectDocument(documentFields) {
    return await documentService.rejectDocumentUpload(documentFields)
      .then(response => {
          statusAlertMessage(documentFields.status);
          return response.data
        }
      )
  }

  async function removeDocument(documentFields) {
    return await documentService.removeDocumentUpload(documentFields)
      .then(response => {
          statusAlertMessage(documentFields.status);
          return response.data
        }
      )
  }

  function searchDocWithFilters() {
    setLoading(true);
    const currentSearchFields = {
      ...searchFields,
      company_selection: companySelect,
      section: sectionSelect,
      classification: classificationSelect,
      category: categorySelect,
      status: statusSelect,
      start_date: beginDate,
      end_date: endDate,
      file_name: filename,
      attribute: attribute
    }

    dispatch(searchDocumentFromDocumentServiceMethod(currentSearchFields));
    setLoading(false);
  }

  const searchDocWithFiltersCallback = useCallback(searchDocWithFilters,
    [dispatch, searchFields, sectionSelect, companySelect, categorySelect, classificationSelect, statusSelect, beginDate, endDate, filename, attribute, searchDocumentFromDocumentServiceMethod]);

  function statusAlertMessage(status) {
    dispatch(alertActions.success("Status of document was changed to " + status));
  }

  async function removeShareToDocument(documentFields) {
    return await documentService.removeShareDocument(documentFields)
      .then(response => {
          return response.data
        }
      )
  }

  async function addShareToDocument(documentFields) {
    return await documentService.addShareDocument(documentFields)
      .then(response => {
          return response.data
        }
      )
  }

  const filterCard = (!filteredDocs || !documents || initialLoad) ?
    (<LoadingSpinner/>) :
    (<Card
      plain
      style={{margin: 0}}
    >
      <CardBody style={{padding: 0}}>
        <Grid
          alignItems={'center'}
          container
          justifyContent={'center'}
          style={{padding: 0, margin: 0}}
        >
          <Accordion
            expanded={expanded}
            onChange={() => {
              setExpanded(!expanded);
            }}
            style={{width: '100%', margin: 0, padding: 10, backgroundColor: '#3b5998'}}
          >
            <AccordionSummary
              aria-controls="search-details"
              expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}}/>}
              id="search-details"
            >
              <Typography
                style={{color: '#FFFFFF'}}
                variant={'h3'}
              >Document Filters</Typography>
            </AccordionSummary>
            <AccordionDetails style={{backgroundColor: '#FFFFFF'}}>
              <SearchPanel
                classes={classes}
                title="Search Filter"
                company={company}
                role={role}

                initialSections={sections}
                initialCompanies={companies}
                initialCategories={categories}
                initialClassifications={classifications}
                initialStatus={statusList}

                sectionSelect={sectionSelect}
                companySelect={companySelect}
                categorySelect={categorySelect}
                classificationSelect={classificationSelect}
                statusSelect={statusSelect}

                beginDate={beginDate}
                filename={filename}
                endDate={endDate}
                attribute={attribute}

                onHandleSectionSelect={includeSectionFilter ? handleSectionSelect : undefined}
                onHandleCompanySelect={includeCompanyFilter ? handleCompanySelect : undefined}
                onHandleCategorySelect={includeCategoryFilter ? handleCategorySelect : undefined}
                onHandleClassificationSelect={includeClassificationFilter ? handleClassificationSelect : undefined}
                onHandleStatusSelect={includeStatusFilter ? handleStatusSelect : undefined}
                onHandleBeginDateSelect={includeBeginDate ? handleBeginDateChange : undefined}
                onHandleEndDateSelect={includeEndDate ? handleEndDateChange : undefined}
                onFilenameChange={includeFilename ? handleFilenameChange : undefined}
                onAttributeChange={includeAttribute ? handleAttributeChange : undefined}

                buttonText="Filter"
                buttonIcon={FilterListIcon}
                onButtonClick={handleFilterClick}
              />
            </AccordionDetails>
          </Accordion>
          {isLoading ? (<LoadingSpinner/>) : (
            <DocumentDetails
              classes={classes}
              companySelect={companySelect}
              initialDocuments={documents}
              onView={handleClickedView}
              isSharedUser={sharedUser}
              role={role}
              viewer={viewer}
            />
          )}
        </Grid>
      </CardBody>
    </Card>);

  const tabListRef = React.useRef({})

  const tabList = (<TabList
    label="Documents Tabs"
    indicatorColor="secondary"
    scrollButtons="auto"
    textColor="primary"
    variant="scrollable"
    defaultTabName="Documents"
    defaultTabPanel={filterCard}
    ref={tabListRef}
  />)

  useEffect(() => {
    if (initialLoad && companySelect) {
      searchDocWithFiltersCallback();
      setInitialLoad(false);
    }
  }, [dispatch, companySelect, filteredDocs, initialLoad, searchDocWithFiltersCallback]);

  return (
    <div className={classes.root}>
      <PageHeader title={title} subTitle={subtitle}/>
      <Card className={classes.content}>
        <CardBody style={{backgroundColor: '#FFF', padding: 0}}>
          <Paper
            elevation={1}
            style={{padding: 10, margin: 0}}
          >
            {tabList}
          </Paper>
        </CardBody>
      </Card>
    </div>
  )
}

export default FileListWindow;
