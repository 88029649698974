import {requestHelper} from '../helpers/index';
import axiosInstance from '../../axios.instance';
import { maestroenv } from '../../helpers/maestroenv';

export const filetypeService = {
  add,
  update,
  getAllFiletypes,
  getMimeTypeByClassification
};

function add(filetype) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/filetypes/add`, JSON.stringify(filetype))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


function getAllFiletypes() {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/filetypes/`)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function update(filetype) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/filetypes/update`, JSON.stringify(filetype))
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


function getMimeTypeByClassification(name) {
  return axiosInstance.get(`${maestroenv.REACT_APP_API_URL}/filetypes/mimetypes/{name}`)
    .then(response => { return response.data })
    .catch(function(error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

