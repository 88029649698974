import React, {useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Avatar, Typography} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';

import useStyles from './styles';
import {userActions} from '../../../../../../store/actions';
import LoadingSpinner from "../../../../../../components/Spinner/loading.spinner";
import {SECURITY_ROLES} from "../../../../../../constants/SECURITY_ROLES";

const Profile = props => {
  const {className, ...rest} = props;
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);
  const profile = useSelector(state => state.users.profile);
  const requestPending = useSelector(state => state.users.requestPending);
  const classes = useStyles();

  useEffect(() => {
    if (user && !requestPending && !profile && user.role !== SECURITY_ROLES.SHARED_USER) {
      dispatch(userActions.getUserProfile(user.id));
    } else if (user && !requestPending && !profile && user.role === SECURITY_ROLES.SHARED_USER) {
      dispatch(userActions.getSharedUserProfile(user.id));
    }
  }, [dispatch, user, profile, requestPending]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {profile ? (
        <React.Fragment>
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            to="/account"
          >
            <AccountCircle className={classes.avatarIcon}/>
          </Avatar>
          <Typography
            className={classes.name}
            variant="h4"
          >
            {profile.firstName} {profile.lastName ? profile.lastName : ''}
          </Typography>
          {profile.company &&
          <Typography variant="body1">{user.company}</Typography>
          }
          {user.role &&
          <Typography
            className={classes.roleText}
            variant="body1"
          >{user.role}</Typography>
          }
        </React.Fragment>
      ) : (
        <LoadingSpinner/>
      )
      }
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
