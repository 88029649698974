import axios from 'axios';
import {userService} from "./store/services";
import {history} from "./helpers/history";
import { maestroenv } from './helpers/maestroenv';

const axiosInstance = axios.create({
  baseURL: maestroenv.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json'
  },
  responseType: 'json'
});

const badJsonValues = [
  undefined,
  null,
  "undefined",
  "null",
  {}
]

function createAxiosResponseInterceptor(axiosInstance) {
  axiosInstance.interceptors.request.use(requestFulfilledInterceptor);
  axiosInstance.interceptors.response.use(responseFulfilledInterceptor, responseRejectedInterceptor)
}

function requestFulfilledInterceptor(config) {
  //const token = JSON.parse(localStorage.getItem('token'));
  config.headers.Authorization = getAuthHeader();//token ? `Bearer ${token}` : '';
  return config;
}

function responseFulfilledInterceptor(response) {
  return response;
}

function responseRejectedInterceptor(error) {
  const originalRequest = error.config;
  switch (error.response ? error.response.status : 0){
    case 401:
      if (!originalRequest._retry && originalRequest.url !== '/auth/refresh_token') {
        originalRequest._retry = true;
        return userService.refreshToken()
          .then(({data}) => {
            originalRequest.headers.Authorization = getAuthHeader();
            return axiosInstance(originalRequest);
          });
      }
      break;
    case 403:
      if (originalRequest.url === '/auth/refresh_token') {
        localStorage.removeItem('token');
        history.push('/login');
      }
      break;
    default:
      break;
  }

  return Promise.reject(error);
}

function getAuthHeader() {
  const authHeader = localStorage.getItem('token');
  return !badJsonValues.includes(authHeader) ?
    `Bearer ${JSON.parse(authHeader)}` :
    '';
}

/*function logAuthHeader(token){
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = Buffer.from(base64, 'base64').toString('utf8');
  console.log(JSON.parse(jsonPayload));
}*/

createAxiosResponseInterceptor(axiosInstance);

export default axiosInstance;
export {getAuthHeader};
