import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  root: {
  },
  card: {
    backgroundColor: theme.palette.publicSection
  },
  invitedByCard: {
    backgroundColor: theme.palette.companySection
  },
  grantedDateCard: {
    backgroundColor: theme.palette.green
  },
  expiresDateCard: {
    backgroundColor: theme.palette.red
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    color: theme.palette.white
  },
  valueText: {
    color: theme.palette.white
  },
  subText : {
    color: theme.palette.primaryTextColor,
    paddingBottom: 10
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.white,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32,
    color: theme.palette.primary.main
  }
}));
