import {makeStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {TextField} from '@mui/material';

export const useStyles = makeStyles(() => ({
  root: {},
  errorMessage: {
    color: '#FF0000',
    fontSize: '.75em',
    fontWeight: 800
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  deactivated: {
    color: 'red',
    fontWeight: 'bold'
  }
}));


export const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.9)'
    }
  }
})(TextField);
