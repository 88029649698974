import React, {useEffect} from 'react';
import {userActions} from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import AsyncLoadingScreen from "../AsyncLoading";

function OAuthCallback() {
  const dispatch = useDispatch();

  const user = useSelector(state => state.authentication.user);
  const requestPending = useSelector(state => state.authentication.requestPending);

  useEffect(() => {
    if (!requestPending && !user) {
      dispatch(userActions.getOAuthUser());
    }
  }, [requestPending, user, dispatch]);

  return (
   <AsyncLoadingScreen title={"Logging In"}/>

  );
}

export default OAuthCallback;
