import React, {useState} from 'react'
import MaterialTable from '@material-table/core';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import {EmptyList} from "../../../../components/EmptyList";
import {Info} from "@mui/icons-material";
import {isEmpty} from "underscore";
import {blue} from "@mui/material/colors";
import DocumentNotes from "../../ListDocument/components/DocumentNotes";
import {DOCUMENT_VIEWER_MODES} from "../../../../constants/DOCUMENT_VIEWER_MODES";

const DocumentDetails = props => {
  const {
    classes,
    initialDocuments,
    companySelect,
    onView,
    viewer,
    role
  } = props

  const [showNotes, setShowNotes] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  let columns = [
    {
      title: 'File Name',
      field: 'display_name',
      cellStyle: {
        width: '30%',
        maxWidth: '30%'
      }
    }
  ];

  if (companySelect.toLowerCase() === "all companies"){
    columns.push({
      title: 'Company Name',
      field: 'name',
      cellStyle: {
        width: 150,
        maxWidth: 150
      }
      ,
      filtering: false,
    })
  }

  columns.push(
    {
      title: 'File type',
      field: 'file_type',
      searchable: false,
      width: 300,
      hidden: true,
    },
    {
      title: 'Media Type',
      field: 'classification'
    },
    {
      title: 'Section',
      field: 'section'
    },
    {
      title: 'Category',
      field: 'category'
    },
    {
      type: 'numeric',
      title: 'Version',
      field: 'version'
    },
    {
      type: 'numeric',
      title: 'Size',
      field: 'size',
      hidden: true,
    },
    {
      type: 'date',
      title: 'File Date',
      field: 'upload_date',
      align: 'right',
    }
  );

  if (viewer === "staged"){
    columns.push(
      {
        type: 'string',
        title: "Status",
        field: "status"
      },
      {
        type: 'string',
        title: "Last Name",
        field: "last_name"
      });
  }

  if (role === "SharedUser") {
    columns.push(
      {
        type: 'string',
        title: "Shared by",
        field: "sharedByMembers"
      }
    );
  } else {
    columns.push (
      {
        title: 'Attributes',
        field: 'metaData',
        cellStyle: {
          width: '30%',
          maxWidth: '30%'
        },
        hidden:true,
      }
    )
  }


  const notesClick = (event, rowData) => {
    event.preventDefault();
    setDocumentData(rowData);
    setShowNotes(true);

  };

  const handleCloseShowNotes = () => {
    setShowNotes(false);
  };


  function rowClick(event, rowData) {
    event.preventDefault();
    onView(rowData);
  }

  return(
    <div style={{width: "100%", height: "100%", padding: 0}}>
      <MaterialTable
        localization={{
          body: {
            emptyDataSourceMessage: (
              <EmptyList
                label="No Documents to Display!"
              />
            ),
          },
        }}
        title=""
        options={{
          idSynonym: "guid",
          emptyRowsWhenPaging: false,
          pageSize: initialDocuments.length > 10 ? 20 : (initialDocuments.length > 5 ? 10 : 5),
          columnsButton: true,
          search: true,
          searchFieldAlignment: "left",
          actionsColumnIndex: -1,
          rowStyle: {
            height: '5px'
          }
        }}
        columns={columns}
        data={initialDocuments}
        onRowClick={rowClick}
        actions={[
          (rowData) => {
            let iconColor = '#000000';
            if (typeof (rowData.shared) !== 'undefined') {
              iconColor = '#228b22';
              if (rowData.shared === 'true') {
                iconColor = '#FF0000'
              }
            }
            return (
              {
                icon: () => <VisibilityOutlinedIcon style={{color: iconColor}}/>,
                tooltip: 'View',
                onClick: rowClick
              })
          },
          (rowData) => {
            if (rowData.notesCount > 0){
              return (
                {
                  icon: () => <SpeakerNotesIcon/>,
                  tooltip: 'Has Document Notes',
                  onClick: notesClick
                })
            }
          },
          (rowData) => {
            if (!isEmpty(rowData.metaData)){
              return (
                {
                  icon: () => <Info sx={{ color: blue[500] }}/>,
                  tooltip: 'Has Additional Info'
                })
            }
          }
        ]}
      />
      {showNotes &&
        <DocumentNotes
          // style={{
          //   position: 'absolute',
          //   border: '2px solid #000',
          //   backgroundColor: 'white',
          //   boxShadow: '2px solid black',
          //   height: 80,
          //   width: 200
          // }}
          showModal={showNotes}
          setShowModal={setShowNotes}
          handleCloseModal={handleCloseShowNotes}
          classes={classes}
          displayName={documentData.display_name}
          pageTitle={(viewer === DOCUMENT_VIEWER_MODES.STAGED) ? 'Notes - Staged Documents' : 'Notes - Documents'}
          document={documentData}
          view={viewer}
        />
      }
    </div>
  )
}

export default DocumentDetails;
