import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {IconButton, Typography} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
  },
  headerBox: {
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
    padding: theme.spacing(2),

  },
  backIcon: {
    color: theme.palette.white,
    marginRight: theme.spacing(1)
  },
  titleText: {
    color: theme.palette.white
  },
  subTitleText: {
    color: theme.palette.white
  }
}));


export default function PageHeader(props) {
  const history = useHistory();
  const classes = useStyles();
  const {title, subTitle} = props;
  return (
    <div className={classes.headerBox}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="Back"
          className={classes.backIcon}
          onClick={history.goBack}
        >
          <ChevronLeftIcon/>
        </IconButton>
        <div>
          <Typography
            className={classes.titleText}
            component="h4"
            variant="h4"
          >
            {title.toUpperCase()}
          </Typography>
          <Typography
            className={classes.subTitleText}
            color="textSecondary"
            variant="subtitle2"
          >
            {subTitle}
          </Typography>
        </div>
      </div>
    </div>
  )
}
PageHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
