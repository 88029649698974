import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from './styles';
import {Avatar, Card, CardContent, Grid, Typography} from '@mui/material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';

const SharedUserAccountDetails = props => {
  const {className, metrics, ...rest} = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <Card
            {...rest}
            className={clsx(classes.card, className)}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    ACCOUNT EXPIRES IN DAYS
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    variant="h3"
                  >{metrics.doesNotExpire ?
                      <AllInclusiveIcon/> : metrics.expiresDays}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <AccessTimeIcon className={classes.icon}/>
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <Card
            {...rest}
            className={clsx(classes.invitedByCard, className)}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    INVITED BY
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    variant="h3"
                  >{metrics.sharedBy}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <PersonIcon className={classes.icon}/>
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <Card
            {...rest}
            className={clsx(classes.grantedDateCard, className)}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    DATE ACCESS GRANTED
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    variant="h3"
                  >{moment(metrics.dateStarted).format('MM/DD/YYYY')}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <TodayIcon className={classes.icon}/>
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={6}
          md={6}
          sm={12}
          xl={6}
          xs={12}
        >
          <Card
            {...rest}
            className={clsx(classes.expiresDateCard, className)}
          >
            <CardContent>
              <Grid
                container
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    DATE ACCESS EXPIRES
                  </Typography>
                  <Typography
                    className={classes.valueText}
                    variant="h3"
                  >{metrics.doesNotExpire ? 'N/A' : moment(metrics.dateExpires).format('MM/DD/YYYY')}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatar}>
                    <TodayIcon className={classes.icon}/>
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </div>
  );
};

SharedUserAccountDetails.propTypes = {
  className: PropTypes.string
};

export default SharedUserAccountDetails;
