import {documentConstants} from '../../constants/index';
import {documentService} from '../../services/index';
import {alertActions} from '../index';

export const documentActions = {
  approveDocumentUpload,
  getStagedDocuments,
  getDocumentNotes,
  getPDFFile,
  holdDocumentUpload,
  rejectDocumentUpload,
  removeDocumentUpload,
  addShareDocument,
  removeShareDocument,
  searchDocuments,
  searchSharedUserDocuments,
  insertDocumentNotes,
  uploadDocuments
};

function approveDocumentUpload(approveFields) {
  return dispatch => {
    dispatch(request(approveFields));

    documentService.approveDocumentUpload(approveFields)
      .then(
        updateValue => {
          dispatch(success(updateValue))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(approveFields) {
    return {type: documentConstants.APPROVE_DOCUMENT_REQUEST, approveFields}
  }

  function success(updateValue) {
    return {type: documentConstants.APPROVE_DOCUMENT_SUCCESS, updateValue}
  }

  function failure(error) {
    return {type: documentConstants.APPROVE_DOCUMENT_FAILURE, error}
  }
}

function getStagedDocuments(documentSearch) {
  return dispatch => {
    dispatch(request());

    documentService.getStagedDocuments(documentSearch)
      .then(
        documents => {
          dispatch(success(documents))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(documentSearch) {
    return {type: documentConstants.STAGED_DOCUMENT_REQUEST, documentSearch}
  }

  function success(documents) {
    return {type: documentConstants.STAGED_DOCUMENT_SUCCESS, documents}
  }

  function failure(error) {
    return {type: documentConstants.STAGED_DOCUMENT_FAILURE, error}
  }
}


function insertDocumentNotes(documentSearch) {
  return dispatch => {
    dispatch(request());

    documentService.insertDocumentNotes(documentSearch)
      .then(
        updateNotes => {
          dispatch(success(updateNotes))
          dispatch(alertActions.success("Successfully added notes."));
          getDocumentNotes(documentSearch.tabData);
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(documentSearch) {
    return {type: documentConstants.ADD_DOCUMENT_NOTES_REQUEST, documentSearch}
  }

  function success(notes) {
    return {type: documentConstants.ADD_DOCUMENT_NOTES_SUCCESS, notes}
  }

  function failure(error) {
    return {type: documentConstants.ADD_DOCUMENT_NOTES_FAILURE, error}
  }
}

function getDocumentNotes(tabData) {
  return dispatch => {
    dispatch(request());

    documentService.getDocumentNotes(tabData)
      .then(
        notes => {
          dispatch(success(notes))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(tabData) {
    return {type: documentConstants.DOCUMENT_NOTES_REQUEST, tabData}
  }

  function success(notes) {
    return {type: documentConstants.DOCUMENT_NOTES_SUCCESS, notes}
  }

  function failure(error) {
    return {type: documentConstants.DOCUMENT_NOTES_FAILURE, error}
  }
}


function getPDFFile(fileUrl, documentId) {
  return dispatch => {
    dispatch(request(fileUrl));

    documentService.getPDFFile(fileUrl, documentId)
      .then(
        documents => {
          dispatch(success(documents));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(fileUrl) {
    return {type: documentConstants.GET_PDF_REQUEST, fileUrl}
  }

  function success(documents) {
    return {type: documentConstants.GET_PDF_SUCCESS, documents}
  }

  function failure(error) {
    return {type: documentConstants.GET_PDF_FAILURE, error}
  }
}

function rejectDocumentUpload(rejectFields) {
  return dispatch => {
    dispatch(request(rejectFields));

    documentService.rejectDocumentUpload(rejectFields)
      .then(
        updateValue => {
          dispatch(success(updateValue))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(rejectFields) {
    return {type: documentConstants.REJECT_DOCUMENT_REQUEST, rejectFields}
  }

  function success(updateValue) {
    return {type: documentConstants.REJECT_DOCUMENT_SUCCESS, updateValue}
  }

  function failure(error) {
    return {type: documentConstants.REJECT_DOCUMENT_FAILURE, error}
  }
}


function holdDocumentUpload(holdFields) {
  return dispatch => {
    dispatch(request(holdFields));

    documentService.holdDocumentUpload(holdFields)
      .then(
        updateValue => {
          dispatch(success(updateValue))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(holdFields) {
    return {type: documentConstants.HOLD_DOCUMENT_REQUEST, holdFields}
  }

  function success(updateValue) {
    return {type: documentConstants.HOLD_DOCUMENT_SUCCESS, updateValue}
  }

  function failure(error) {
    return {type: documentConstants.HOLD_DOCUMENT_FAILURE, error}
  }
}


function removeDocumentUpload(removeFields) {
  return dispatch => {
    dispatch(request(removeFields));

    documentService.removeDocumentUpload(removeFields)
      .then(
        updateValue => {
          dispatch(success(updateValue))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(removeFields) {
    return {type: documentConstants.REMOVE_DOCUMENT_REQUEST, removeFields}
  }

  function success(updateValue) {
    return {type: documentConstants.REMOVE_DOCUMENT_SUCCESS, updateValue}
  }

  function failure(error) {
    return {type: documentConstants.REMOVE_DOCUMENT_FAILURE, error}
  }
}

function addShareDocument(shareFields) {
  return dispatch => {
    dispatch(request(shareFields));

    documentService.addShareDocument(shareFields)
      .then(
        updateValue => {
          dispatch(success(updateValue))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(shareFields) {
    return {type: documentConstants.ADD_SHARE_DOCUMENT_REQUEST, shareFields}
  }

  function success(updateValue) {
    return {type: documentConstants.ADD_SHARE_DOCUMENT_SUCCESS, updateValue}
  }

  function failure(error) {
    return {type: documentConstants.ADD_SHARE_DOCUMENT_FAILURE, error}
  }
}

function removeShareDocument(removeFields) {
  return dispatch => {
    dispatch(request(removeFields));

    documentService.removeShareDocument(removeFields)
      .then(
        updateValue => {
          dispatch(success(updateValue))
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(removeFields) {
    return {type: documentConstants.REMOVE_SHARE_DOCUMENT_REQUEST, removeFields}
  }

  function success(updateValue) {
    return {type: documentConstants.REMOVE_SHARE_DOCUMENT_SUCCESS, updateValue}
  }

  function failure(error) {
    return {type: documentConstants.REMOVE_SHARE_DOCUMENT_FAILURE, error}
  }
}

function searchDocuments(documentSearch) {
  return dispatch => {
    dispatch(request(documentSearch));

    documentService.searchDocuments(documentSearch)
      .then(
        documents =>{
          dispatch(success(documents));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(documentSearch) {
    return {type: documentConstants.SEARCH_ALL_REQUEST, documentSearch}
  }

  function success(documents) {
    return {type: documentConstants.SEARCH_ALL_SUCCESS, documents}
  }

  function failure(error) {
    return {type: documentConstants.SEARCH_ALL_FAILURE, error}
  }
}

function searchSharedUserDocuments(documentSearch) {
  return dispatch => {
    dispatch(request(documentSearch));

    documentService.searchSharedUserDocuments(documentSearch)
      .then(
        documents =>{
          dispatch(success(documents));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(documentSearch) {
    return {type: documentConstants.SEARCH_ALL_REQUEST, documentSearch}
  }

  function success(documents) {
    return {type: documentConstants.SEARCH_ALL_SUCCESS, documents}
  }

  function failure(error) {
    return {type: documentConstants.SEARCH_ALL_FAILURE, error}
  }
}

function uploadDocuments(uploadFields) {
  return dispatch => {
    dispatch(request(uploadFields));

    documentService.uploadDocuments(uploadFields)
      .then(
        fileListUploaded => {
          dispatch(success(fileListUploaded));
          if (fileListUploaded.statusCode === 200) {
            if ((fileListUploaded.data.failedUploads).length === 0) {
              dispatch(alertActions.success(fileListUploaded.data.message));
            } else {
              dispatch(alertActions.warning(fileListUploaded.data.message));
            }
          } else {
            dispatch(alertActions.warning(fileListUploaded.data.message));
          }
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(uploadFields) {
    return {type: documentConstants.UPLOAD_FILES_REQUEST, uploadFields}
  }

  function success(fileListUpload) {
    return {type: documentConstants.UPLOAD_FILES_SUCCESS, fileListUpload}
  }

  function failure(error) {
    return {type: documentConstants.UPLOAD_FILES_FAILURE, error}
  }
}
