import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import {Button} from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  addUserButton: {
    marginRight: theme.spacing(1)
  },
  importButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  exportButton: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const SharedUsersToolbar = props => {
  const { sharedUsersLength, onHandleSendEmail } = props;

  const classes = useStyles();

  return (
      <div className={classes.row}>
        {sharedUsersLength > 0 &&
          <Button
            className={classes.addUserButton}
            onClick={onHandleSendEmail}
            color="secondary"
            variant="contained"
          >
            Send Email
          </Button>
        }
        {/*<Button className={classes.importButton}>Import</Button>*/}
        {/*<Button className={classes.exportButton}>Export</Button>*/}

      </div>
  );
};

SharedUsersToolbar.propTypes = {
  className: PropTypes.string
};

export default SharedUsersToolbar;
