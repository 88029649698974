import palette from "../../../../theme/palette";

export const options = {
  maintainAspectRatio: false,
  animation: true,
  pointBorderWidth: 6,
  spanGaps: true,
  pointHoverBorderWidth: 12,
  hoverBorderWidth: 12,
  hoverBorderRadius: 12,
  borderRadius: 1,
  tension: 0.3,
  plugins: {
    legend: { display: true },
    fill: false,
    tooltip: {
      enabled: true,
      mode: 'index',
      usePointStyle: true,
      borderColor: palette.divider,
      backgroundColor: palette.primary.dark,
      titleFontColor: palette.white,
    },
  },
};
