import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {SECURITY_ROLES} from '../../constants/SECURITY_ROLES';
import {userActions} from "../../store/actions";

const SecuredRouteWithLayout = props => {
  const {layout: Layout, component: Component, roles, ...rest} = props;
  const authenticatedUser = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  if (!authenticatedUser && !token) {
    // not logged in so redirect to login page with the return url
    return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
  } else {
    // check if route is restricted by role
    if (!authenticatedUser && token) {
      dispatch(userActions.refreshUserToken());
      if (authenticatedUser && roles && roles.indexOf(authenticatedUser.role) === -1 && roles.indexOf(SECURITY_ROLES.ANY_AUTHORIZED_USER) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{pathname: '/'}}/>
      }
    }
  }

  return (
    <Route
      {...rest}
      render={props => {
        // authorised so return component
        return (<Route
          {...rest}
          render={matchProps => (
            <Layout>
              <Component { ...rest} />
            </Layout>
          )}
                />)
      }}
    />
  );
};

SecuredRouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string
};

export default SecuredRouteWithLayout;
