export const documentConstants = {
  APPROVE_DOCUMENT_REQUEST: 'APPROVE_DOCUMENT_REQUEST',
  APPROVE_DOCUMENT_SUCCESS: 'APPROVE_DOCUMENT_SUCCESS',
  APPROVE_DOCUMENT_FAILURE: 'APPROVE_DOCUMENT_FAILURE',

  HOLD_DOCUMENT_REQUEST: 'HOLD_DOCUMENT_REQUEST',
  HOLD_DOCUMENT_SUCCESS: 'HOLD_DOCUMENT_SUCCESS',
  HOLD_DOCUMENT_FAILURE: 'HOLD_DOCUMENT_FAILURE',

  REJECT_DOCUMENT_REQUEST: 'REJECT_DOCUMENT_REQUEST',
  REJECT_DOCUMENT_SUCCESS: 'REJECT_DOCUMENT_SUCCESS',
  REJECT_DOCUMENT_FAILURE: 'REJECT_DOCUMENT_FAILURE',

  REMOVE_DOCUMENT_REQUEST: 'REMOVE_DOCUMENT_REQUEST',
  REMOVE_DOCUMENT_SUCCESS: 'REMOVE_DOCUMENT_SUCCESS',
  REMOVE_DOCUMENT_FAILURE: 'REMOVE_DOCUMENT_FAILURE',

  REMOVE_SHARE_DOCUMENT_REQUEST: 'REMOVE_SHARE_DOCUMENT_REQUEST',
  REMOVE_SHARE_DOCUMENT_SUCCESS: 'REMOVE_SHARE_DOCUMENT_SUCCESS',
  REMOVE_SHARE_DOCUMENT_FAILURE: 'REMOVE_SHARE_DOCUMENT_FAILURE',

  ADD_SHARE_DOCUMENT_REQUEST: 'ADD_SHARE_DOCUMENT_REQUEST',
  ADD_SHARE_DOCUMENT_SUCCESS: 'ADD_SHARE_DOCUMENT_SUCCESS',
  ADD_SHARE_DOCUMENT_FAILURE: 'ADD_SHARE_DOCUMENT_FAILURE',

  GET_DOCUMENT_REQUEST: 'GET_DOCUMENT_REQUEST',
  GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_FAILURE: 'GET_DOCUMENT_FAILURE',

  GET_PDF_REQUEST: 'GET_PDF_REQUEST',
  GET_PDF_SUCCESS: 'GET_PDF_SUCCESS',
  GET_PDF_FAILURE: 'GET_PDF_FAILURE',

  SEARCH_ALL_REQUEST: 'SEARCH_ALL_REQUEST',
  SEARCH_ALL_SUCCESS: 'SEARCH_ALL_SUCCESS',
  SEARCH_ALL_FAILURE: 'SEARCH_ALL_FAILURE',

  UPLOAD_FILES_REQUEST: 'UPLOAD_FILES_REQUEST',
  UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILURE: 'UPLOAD_FILES_FAILURE',

  STAGED_DOCUMENT_REQUEST: 'STAGED_DOCUMENT_REQUEST',
  STAGED_DOCUMENT_SUCCESS: 'STAGED_DOCUMENT_SUCCESS',
  STAGED_DOCUMENT_FAILURE: 'STAGED_DOCUMENT_FAILURE',

  DOCUMENT_NOTES_REQUEST: 'DOCUMENT_NOTES_REQUEST',
  DOCUMENT_NOTES_SUCCESS: 'DOCUMENT_NOTES_SUCCESS',
  DOCUMENT_NOTES_FAILURE: 'DOCUMENT_NOTES_FAILURE',

  ADD_DOCUMENT_NOTES_REQUEST: 'ADD_DOCUMENT_NOTES_REQUEST',
  ADD_DOCUMENT_NOTES_SUCCESS: 'ADD_DOCUMENT_NOTES_SUCCESS',
  ADD_DOCUMENT_NOTES_FAILURE: 'ADD_DOCUMENT_NOTES_FAILURE',
};
