import {dashboardMetricsConstants} from '../../constants/index';
import {dashboardService} from '../../services/index';
import storeActionWithDelayAndCancel from "../../../helpers/storeActionWithDelayAndCancel";

export const dashboardActions = {
  getCloudAdminDashboardMetrics,
  getCompanyAdminDashboardMetrics,
  getSharedUserDashboardMetrics,
  getUserDashboardMetrics
};

function getCloudAdminDashboardMetrics(waitTime) {
  return storeActionWithDelayAndCancel(
    dashboardService.getCloudAdminDashboardMetrics,
    waitTime,
    request,
    (metrics) => success(metrics),
    (message) => failure(message)
  );

  function request(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_REQUEST}
  }

  function success(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_SUCCESS, metrics}
  }

  function failure(error) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_FAILURE, error}
  }

}

function getCompanyAdminDashboardMetrics(companyId, days, waitTime) {
  return storeActionWithDelayAndCancel(
    () => dashboardService.getCompanyAdminDashboardMetrics(companyId, days),
    waitTime,
    request,
    (metrics) => success(metrics),
    (message) => failure(message)
  );

  function request(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_REQUEST}
  }

  function success(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_SUCCESS, metrics}
  }

  function failure(error) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_FAILURE, error}
  }
}

function getSharedUserDashboardMetrics(waitTime) {
  return storeActionWithDelayAndCancel(
    dashboardService.getSharedUserDashboardMetrics,
    waitTime,
    request,
    (metrics) => success(metrics),
    (message) => failure(message)
  );

  function request(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_REQUEST}
  }

  function success(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_SUCCESS, metrics}
  }

  function failure(error) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_FAILURE, error}
  }
}

function getUserDashboardMetrics(waitTime) {
  return storeActionWithDelayAndCancel(
    dashboardService.getUserDashboardMetrics,
    waitTime,
    request,
    (metrics) => success(metrics),
    (message) => failure(message)
  );

  function request(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_REQUEST}
  }

  function success(metrics) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_SUCCESS, metrics}
  }

  function failure(error) {
    return {type: dashboardMetricsConstants.GET_DASHBOARD_METRICS_FAILURE, error}
  }
}
