import React from 'react'
import {states as States} from 'constants/states';
import * as Yup from 'yup';
import {ErrorMessage, Formik} from 'formik';

import {
  CardContent, CardHeader,
  Divider,
  Grid,
  TextField
} from '@mui/material'

import Card from "../../../../components/Card/Card";
import {isEmpty} from "underscore";
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";


const ContactInfo = props => {
  const {
    classes,
    contactInfo,
    onHandleContactChange,
    setHasContactErrors,
    submitButton
  } = props

  let required = submitButton !== 'View';

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .trim()
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('First Name is Required'),
    lastname: Yup.string()
      .trim()
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('Last Name is Required'),
    cStreet: Yup.string()
      .trim(),
    cCity: Yup.string()
      .trim(),
    cState: Yup.string()
      .trim(),
    cPhone: Yup.string()
      .trim()
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('Contact\'s Phone is Required'),
    cEmail: Yup.string()
      .trim()
      .when('$required', (required, requireSchema) => required
        ? requireSchema
        : requireSchema).required('Contact\'s Email is Required'),
  });


  const initialTouched = {
    "firstname": true,
    "lastname": true,
    "cPhone": true,
    "cEmail": true
  };

  return(
    <Formik
      enableReinitialize
      innerRef={self=>setHasContactErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
      initialTouched={initialTouched}
      initialValues={contactInfo}
      onSubmit={() => {
      }}
      validateOnMount
      validateOnChange
      validateOnBlur
      validationSchema={validationSchema}
    >
      {(props) => {
        const {
          handleBlur
        } = props;

        return (
          <form>
            {(!contactInfo) ? (<LoadingSpinner/>) :  (
              <div className={classes.root}>
                <Card className={classes.content}>
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Contact Details"
                    />
                    <Divider/>
                    <CardContent>

                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact First Name"
                            margin="dense"
                            name="firstname"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            required = {required}
                            type="text"
                            value={contactInfo.firstname || ''}
                          />
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="firstname"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact Last Name"
                            margin="dense"
                            name="lastname"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            required = {required}
                            type="text"
                            value={contactInfo.lastname || ''}
                          />
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="lastname"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact Street Address"
                            margin="dense"
                            name="cStreet"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            type="text"
                            value={contactInfo.cStreet || ''}
                          />
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="cStreet"
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact City"
                            margin="dense"
                            name="cCity"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            type="text"
                            value={contactInfo.cCity || ''}
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                        >
                          <TextField
                            SelectProps={{native: true}}
                            variant="outlined"
                            fullWidth
                            label="Contact State"
                            margin="dense"
                            name="cState"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            select
                            // eslint-disable-next-line react/jsx-sort-props
                            type="text"
                            value={contactInfo.cState || ''}
                          >
                            <option
                            key=""
                            value=""
                            />
                          {States.map(option => (
                            <option
                            key={option.abbreviation}
                            value={option.abbreviation}
                            >
                            {option.name}
                              </option>
                              ))}
                          </TextField>
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="cState"
                          />
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact Zip"
                            margin="dense"
                            name="cZipcode"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            type="text"
                            value={contactInfo.cZipcode || ''}
                          />
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="cZipcode"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >
                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact Phone"
                            margin="dense"
                            name="cPhone"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            required = {required}
                            type="text"
                            value={contactInfo.cPhone || ''}
                          />
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="cPhone"
                          />
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={12}
                        >

                          <TextField
                            variant="outlined"
                            fullWidth
                            label="Contact Email"
                            margin="dense"
                            name="cEmail"
                            onBlur={handleBlur}
                            onChange={onHandleContactChange}
                            required = {required}
                            type="text"
                            value={contactInfo.cEmail || ''}
                          />
                          <ErrorMessage
                            className={classes.errorMessage}
                            component="div"
                            name="cEmail"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Card>
              </div>
            )}
          </form>
        )
      }
    }
    </Formik>
  )
}

export default ContactInfo;
