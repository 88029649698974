import React, {useState} from 'react'
import {useDispatch} from 'react-redux';

import {categoryActions} from '../../../store/actions';
import CategoryDetails from '../component/CategoryDetails';

const EditCategory = props => {
  let { category } = props.location.state.category;

  const [values] = useState({
    id: category.id,
    prevname: category.name,
    name: category.name
  });

  const dispatch = useDispatch();

  const onHandleSubmit = (values) => {
    dispatch(categoryActions.update(values))
    return true;
  }

  return(
    <CategoryDetails
      initialCategory = {values}
      onHandleSubmit = {onHandleSubmit}
      submitButton = "Update"
      title = "Edit Category"
    />

  )
}

export default EditCategory;
