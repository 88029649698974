import {makeStyles} from '@mui/styles';

export default makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.publicSection,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    color: theme.palette.white
  },
  valueText: {
    color: theme.palette.white
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.white,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32,
    color: theme.palette.primary.main
  }
}));
