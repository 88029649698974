import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Card from "../../../../../../components/Card/Card";
import {
  CardContent,
  CardHeader,
  Divider, FormControlLabel,
  Grid, Switch,
  TextField
} from "@mui/material";
import * as Yup from "yup";
import {ErrorMessage, Formik} from "formik";
import {useState} from "react";
import {isEmpty} from "underscore";
import {DOCUMENT_STATUS} from "../../../../../../constants/DOCUMENT_STATUS";


const StatusNote = props => {
  const {
    classes,
    documentId,
    openNote,
    setOpenNote,
    documentInfo,
    section,
    displayName,
    ownerGuid,
    onNoteChange,
    onHandleRejected,
    onHandleHoldForQuestions,
    onHandleRemove,
    onHandleSwitchChange,
    onHandleAddNotes,
    view,
    tabId,
    role
  } = props;

  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState('sm');
  const [hasErrors, setHasErrors] = useState(false);


  let required = true;
  if (view === "pdf"){
    if (documentInfo.status === DOCUMENT_STATUS.APPROVED){
      required = false;
    }
  }

  const onHandleClose = () => {
    setOpenNote(false);
  };

  let validationSchema = "";
  if (view === "notes"){
    validationSchema = Yup.object().shape({
      notes: Yup.string()
        .when('$hasNotes', (hasNotes, minSchema) => hasNotes
          ? minSchema
          : minSchema).min(10, 'Notes are OPTIONAL.  If FILLED, notes must contain at least 10 characters.'),
        });
  } else {
    validationSchema = Yup.object().shape({
      notes: Yup.string()
        .trim()
        .min(10, 'Notes must contain at least 10 characters')
        .when('$required', (required, requireSchema) => required
          ? requireSchema
          : requireSchema).required('Notes are required and must be at least 10 characters.'),
    });
  }

  const initialTouched = {
    "notes": true
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialTouched={initialTouched}
        initialValues={documentInfo}
        innerRef={self=>setHasErrors(self && !isEmpty(self.touched) && !isEmpty(self.errors))}
        onSubmit={(values) => {
          setOpenNote(false);
          if (view === 'pdf') {
            switch (documentInfo.status) {
              case DOCUMENT_STATUS.HOLD:
                onHandleHoldForQuestions(documentId, tabId, values, displayName, section, ownerGuid);
                break;
              case DOCUMENT_STATUS.REJECTED:
                onHandleRejected(documentId, tabId, values, displayName, section, ownerGuid);
                break;
              case DOCUMENT_STATUS.REMOVED:
                onHandleRemove(documentId, tabId, values, displayName, section, ownerGuid);
                break;
              default:
                alert("We have an issue.  Status not found in StatusNote View.");
                break;
            }
          } else {
            onHandleAddNotes(documentId, tabId, values);
          }
        }}

        validateOnMount
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          const {
            values,
            handleBlur,
            handleSubmit
          } = formikProps;

          return (
            <Dialog
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openNote}
              onClose={onHandleClose}
            >
              {(view === 'pdf') ? (
                <DialogTitle>Enter notes for status: {documentInfo.status}</DialogTitle>
              ) : (
                <DialogTitle>Additional Notes</DialogTitle>
              )}
                <DialogContent>
                <DialogContentText>
                     Notes entered will be used for tracking purposes.
                </DialogContentText>
                <form>
                  <div className={classes.root}>
                    <Card className={classes.content}>
                      <Grid
                        item
                        md={12}
                      >
                        <CardHeader
                          title="Enter Notes: "
                        />
                        <Divider/>
                        <CardContent>
                          <Grid
                            container
                            spacing={3}
                          >

                            {(role.toLowerCase().includes('admin') === 'staged') ? (
                            <Grid
                              item
                              xs={12}
                            >
                              <FormControlLabel
                                className={classes.switch}
                                control={
                                  <Switch
                                    checked={Boolean(values.private)}
                                    name="private"
                                    onChange={onHandleSwitchChange}
                                  />
                                }
                                label="Private"
                                labelPlacement="start"
                              />
                            </Grid>
                              ) : undefined}
                            <Grid
                              item
                              md={12}
                            >
                              <TextField
                                autoFocus
                                fullWidth
                                multiline
                                name="notes"
                                rows={6}
                                placeholder="Enter notes for status"
                                variant="outlined"
                                required = {required}
                                type="text"
                                value={values.notes}
                                onBlur={handleBlur}
                                onChange={onNoteChange}
                              />
                              <ErrorMessage
                                className={classes.errorMessage}
                                component="div"
                                name="notes"
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Grid>
                    </Card>
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                  <Button aria-label="Create" className={classes.createButton}
                          color="primary"
                          type="submit"
                          variant="contained"
                          required={required}
                          disabled={(hasErrors)}
                          onClick={handleSubmit}
                  >
                    Submit
                  </Button>

              </DialogActions>
            </Dialog>
          )}
        }
      </Formik>
    </React.Fragment>
  );
}

export default StatusNote;
