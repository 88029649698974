import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import {green} from '@mui/material/colors';
import {getInitials} from 'helpers';
import MaterialTable, {MTableToolbar} from '@material-table/core';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {history} from '../../../../helpers/history';
import CardBody from '../../../../components/Card/CardBody';
import {UsersSearchPanel} from '../../components';
import Card from '../../../../components/Card/Card';
import {useDispatch, useSelector} from 'react-redux';
import {alertActions, companyActions, userActions} from '../../../../store/actions';
import {userService} from '../../../../store/services';
import LoadingSpinner from "../../../../components/Spinner/loading.spinner";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import {LockOpen} from "@mui/icons-material";
import {EmptyList} from "../../../../components/EmptyList";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   padding: theme.spacing(3)
  // },
  // content: {
  //   marginTop: 0
  // },
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  content: {
    marginTop: 0,
    margin: 0,
  },
  paper: {
    width: '100%'
  },
  blankSection: {
    height: 50,
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.white
  },

}));

const UsersTable = () => {
  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies.items);
  const  { /*company,*/ companyId, /*id,*/ role } = useSelector(state => state.authentication.user);
  const users = useSelector(state => state.users.items)
  const [companySelect, setCompanySelect] = useState(companyId);

  const [isLoading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const classes = useStyles();

  let user = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    guid: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    active:0,
    companyId: '',
    companyName: '',
    phone: '',
    locked: 0,
    role: '',
    sharedUser: 0
  };

  const handleCompanySelect = event => {
    setLoading(true);
    setCompanySelect(event.target.value);
    setLoading(false);
  };

  /*const statusColors = {
    public: palette.publicSection,
    company: palette.companySection,
    private: palette.privateSection
  };*/

  const getAdditionalDetails = async (viewType) => {
    await userService.getAdditionalUserDetails({
      company_guid: user['companyId'],
      user_guid: user['id'],
      role: role
    }).then(dtls => {
      history.push({
        pathname: '/users/view',
        state: {
          title: 'User Details',
          subTitle: viewType + ' User',
          submit: viewType,
          user: user,
          additionalDetails: dtls.data
        }
      })
    });
  }

  const handleClickedEdit = async (rowData) => {
    getClickedUser(rowData);
    getAdditionalDetails('Edit')
    dispatch(userActions.getAllUsersOfCompany(companySelect))
  }

  const handleClickActivate = async (user_guid) => {
    userService.activateUserById(user_guid).then(user => {
      dispatch(userActions.getAllUsersOfCompany(companySelect));
      dispatch(alertActions.success("Successfully activated  : " + user.data.firstName + " " + user.data.lastName));
    });
  }

  const handleClickDeactivate = (user_guid) => {
    userService.deactivateUserById(user_guid).then(user => {
      dispatch(userActions.getAllUsersOfCompany(companySelect));
      dispatch(alertActions.success("Successfully deactivated  : " + user.data.firstName + " " + user.data.lastName));
    });
  }

  const handleClickLock = async (user_guid) => {
    userService.lockUserById(user_guid).then(user => {
      dispatch(userActions.getAllUsersOfCompany(companySelect));
      dispatch(alertActions.success("Successfully locked  : " + user.data.firstName + " " + user.data.lastName));
    });
  }

  const handleClickUnlock = (user_guid) => {
    userService.unlockUserById(user_guid).then(user => {
      dispatch(userActions.getAllUsersOfCompany(companySelect));
      dispatch(alertActions.success("Successfully unlocked  : " + user.data.firstName + " " + user.data.lastName));

    });
  }

  const handleClickedView = (rowData) => {
    getClickedUser(rowData);
    getAdditionalDetails('View')
  }

  const handleListSharedUsers = (rowData) => {
    history.push({
      pathname: "/sharedusers/list",
      state: {
        title: "Shared Users",
        subTitle: "List all Shared User for Member",
        memberGuid: rowData.guid,
        companyId: rowData.companyId
      }
    })
  }

  const getClickedUser = (rowData) => {
    let obj = users.find(
      element => element.guid === rowData.guid && element.companyId === rowData.companyId
    );

    user = {
      id: obj.guid,
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      guid: obj.guid,
      street:obj.street,
      city: obj.city,
      state: obj.state,
      zipcode:obj.zipCode,
      active:obj.active,
      companyId: obj.companyId,
      companyName: obj.companyName,
      phone: obj.phone,
      locked: obj.locked,
      sharedUser: obj.sharedUser,
    };
  }

  const headers = [
    {
      field: 'name', title: 'Name', render: user =>
        <div
          className={classes.nameContainer}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Avatar
            src={user.avatarUrl}
            style={{
              backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16),
              marginRight: 20
            }}
          >
            {getInitials(user.firstName + ' ' + user.lastName)}
          </Avatar>
          <Typography
            variant="body1"
          >{user.firstName + ' ' + user.lastName}
          </Typography>
        </div>
    },
    {field: 'email', title: 'Email'},
    {field: 'companyName', title: 'Company'},
    {field: 'phone', title: 'Phone'},
    {
      field: 'sharedUser', title: 'Is a Shared User',
      render: user =>
        (user.sharedUser ? (
          <Button disabled
            title="Is a shared user"
            aria-label="sharedUser"
            aria-readonly="true"
          >
            <CheckCircleIcon
              style={{color: green[500]}}
            />
          </Button>

        ) : (

          <Button disabled
            title="Not a Shared User"
            aria-label="memberUserOnly"
            aria-readonly="true"
          >
            <RemoveCircleRoundedIcon
              color={'error'}
            />
          </Button>
        ))
    },
    {
      field: 'active', title: 'Active',
      render: user =>
        (user.active ? (
            <Button
              title="Deactivate User "
              value={user.guid}
              onClick={()=>handleClickDeactivate(user.guid)}
              aria-label="deactivateUser"
            >
              <PersonIcon
                style={{color: green[500]}}
              />
            </Button>

          ) : (

            <Button
              title="Activate User"
              onClick={()=>handleClickActivate(user.guid)}
              aria-label="activateUser"
            >
              <PersonIcon
                color={'error'}
              />
            </Button>
        ))
    },
    {
      field: 'locked', title: 'Locked',
      render: user =>
        (user.locked ? (
          <Button
            title="Unlock User"
            onClick={()=>handleClickUnlock(user.guid)}
            aria-label="lockedUser"
          >
            <LockIcon
              color={'error'}
            />
          </Button>

        ) : (

          <Button
            title="Lock User"
            onClick={()=>handleClickLock(user.guid)}
            aria-label="lockedUser"
          >
            <LockOpen
              style={{color: green[500]}}
            />
          </Button>
        ))
    },
  ];

  useEffect(() => {
    dispatch(companyActions.getAllActiveCompanies());
    dispatch(userActions.getAllUsersOfCompany(companySelect));
  }, [dispatch, companySelect]);

  return (
    <div className={classes.root}>
        {(!users || !companies) ? (<LoadingSpinner/>) :
          (
            // <Card
            //   plain
            //   style={{margin: 0}}
            // >
            //   <CardBody style={{padding: 0}}>
            <Card className={classes.content}>
              <CardBody style={{backgroundColor: '#FFF', padding: 0}}>
                <Grid
                  alignItems={'center'}
                  container
                  justifyContent={'center'}
                  style={{padding: 0, margin: 0}}
                >
                  <Accordion
                    expanded={expanded}
                    onChange={() => {
                      setExpanded(!expanded);
                    }}
                    style={{width: '100%', margin: 0, padding: 10, backgroundColor: '#3b5998'}}
                  >
                    <AccordionSummary
                      aria-controls="search-details"
                      expandIcon={<ExpandMoreIcon style={{color: '#FFFFFF'}}/>}
                      id="search-details"
                    >
                      <Typography
                        style={{color: '#FFFFFF'}}
                        variant={'h3'}
                      >User Filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{backgroundColor: '#FFFFFF'}}>
                        <UsersSearchPanel
                          classes = {classes}
                          companySelect = {companySelect}
                          initialCompanies = {companies}
                          onHandleCompanySelect = {handleCompanySelect}
                        />
                    </AccordionDetails>
                  </Accordion>
                  {isLoading ? (<LoadingSpinner/>) : (
                    <MaterialTable
                      localization={{
                        body: {
                          emptyDataSourceMessage: (
                            <EmptyList
                              label="No Users to Display!"
                            />
                          ),
                        },
                      }}
                      style={{width: "100%", height: "100%", padding: 20, paddingBottom: 0}}
                      title=""
                      options={{
                        idSynonym: "guid",
                        emptyRowsWhenPaging: false,
                        pageSize: 20,
                        searchFieldAlignment: 'right',
                        actionsColumnIndex: -1
                      }}
                      columns={headers}
                      data={users}
                      actions={[
                        {
                          icon: () => <VisibilityOutlinedIcon />,
                          tooltip: 'View',
                          onClick: (evt, rowData) => {

                            handleClickedView(rowData);
                          },
                          position: 'row'
                        },
                        {
                          icon: () => <EditOutlinedIcon />,
                          tooltip: 'Edit',
                          onClick: (evt, rowData) => {
                            handleClickedEdit(rowData)
                          },
                          position: 'row'
                        }
                        ,
                        (rowData) => {
                          let iconColor = '#FF0000';
                          let toolTipMessage = 'Has Shared Users';
                          if (rowData.sharedUserCount > 0) {
                            iconColor = '#228b22';
                            toolTipMessage = 'Has Shared Users';
                          } else {
                            iconColor = '#FF0000';
                            toolTipMessage = 'No Shared Users';
                          }
                          return (
                            {
                              icon: () => <PeopleIcon style={{color: iconColor}}/>,
                              tooltip: toolTipMessage,
                              onClick: (event, rowData) => {
                                handleListSharedUsers(rowData);
                              },
                              position: 'row'
                            })
                        },
                      ]}
                      components={{
                        Toolbar: props => (
                          <div>
                            <MTableToolbar {...props} />
                          </div>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </CardBody>
            </Card>
          )}
    </div>
  )
}
;

export default UsersTable;
