import axiosInstance from '../../axios.instance';
import {history} from '../../helpers/history';
import {requestHelper} from '../helpers';
import { maestroenv } from '../../helpers/maestroenv';

export const userService = {
  activateUserById,
  checkSharedUserByEmail,
  deactivateUserById,
  login,
  oauthLogin,
  oauthLogout,
  getOAuthUser,
  lockUserById,
  loginWithRole,
  loginAsSharedUser,
  logout,
  register,
  getUserProfile,
  getSharedUserProfile,
  changePassword,
  getAll,
  getAdditionalUserDetails,
  getAllSharedUsers,
  getAllUsersOfCompany,
  getUserPrivilegesOfCompany,
  getUserRolePrivilegesOfCompany,
  getUserQuestionsOfId,
  getValidRolesToManage,
  getById,
  updateUserDetails,
  refreshToken,
  sendEmailToUser,
  addUser,
  addSharedUser,
  unlockUserById,
  updateSharedUserDetails,
  updateSharedUserKeyInfo,
  updateSharedUserPasswordInfo
};

function login(email, password) {
  return axiosInstance.post('/auth/login', {email, password})
    .then(response => {
      localStorage.setItem('token', JSON.stringify(response.data.data))
      return response.data;
    }).catch(function (error) {
      localStorage.removeItem('token');
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function oauthLogin(url) {
  return axiosInstance.get(url)
    .then(
      response => {
        if (!response.data && response.request.responseURL) {
          window.location.href = response.request.responseURL;
        } else {
          window.location.href=url;
        }
      })
    .catch(
      e => {
        if (e.toString().includes('401')) {
          return Promise.reject(requestHelper.handleErrorResponse({message: "Unauthorized", oauth: true}));
        }
      });
}

function oauthLogout(user) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/auth/userLogout`, JSON.stringify(user))
    .then(response => {
      axiosInstance.get(maestroenv.REACT_APP_OAUTH_LOGOUT_URL)
        .then(
          response => {
            window.location.href=maestroenv.REACT_APP_OAUTH_OPENID_END_SESSION_URL;
            return response.data;
          })
        .catch(
          e => {
            if (e.toString().includes('401')) {
              return Promise.reject(requestHelper.handleErrorResponse({message: "Unauthorized", oauth: true}));
            }
          });
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });

}

function getOAuthUser() {
  return axiosInstance.get('/auth/user')
    .then(response => {
      localStorage.setItem('token', JSON.stringify(response.data.data))
      return response.data;
    }).catch(function (error) {
      localStorage.removeItem('token');
      history.push('/');
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function loginWithRole(role) {
  return axiosInstance.post('/auth/loginWithRole', {role})
    .then(response => {
      localStorage.setItem('token', JSON.stringify(response.data.data))
      return response.data;
    }).catch(function (error) {
      localStorage.removeItem('token');
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function loginAsSharedUser(email, accessKey) {
  return axiosInstance.post('/auth/sharedLogin', {email, accessKey})
    .then(response => {
      localStorage.setItem('token', JSON.stringify(response.data.data))
      return response.data;
    }).catch(function (error) {
      console.log(error)
      localStorage.removeItem('token');
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function refreshToken() {
  return axiosInstance.get('/auth/refresh_token', {
    withCredentials: false
  }).then(({data}) => {
    localStorage.setItem('token', JSON.stringify(data.data));
    return data;
  }).catch(function (error) {
    return Promise.reject(requestHelper.handleErrorResponse(error));
  });
}

function logout() {
  return axiosInstance.get(maestroenv.REACT_APP_OAUTH_SIGN_OUT_URL)
    .then(response => {
      return response;
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getAll() {
  return axiosInstance.get('/users/')
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getAllUsersOfCompany(companyId) {
  return axiosInstance.get(`/users/bycompany/${companyId}`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getUserQuestionsOfId(userId) {
  return axiosInstance.get(`/user/${userId}/questions/`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getUserPrivilegesOfCompany(userSearch) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/users/privileges`, JSON.stringify(userSearch))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getUserRolePrivilegesOfCompany(userSearch) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/users/roleprivileges`, JSON.stringify(userSearch))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getAdditionalUserDetails(userSearch) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/users/additionaldetails`, JSON.stringify(userSearch))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getValidRolesToManage(role) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/users/rolestomanage`, JSON.stringify(role))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getUserProfile(userId) {
  return axiosInstance.get(`/user/${userId}/profile/`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}
function getSharedUserProfile(userId) {
  return axiosInstance.get(`/shareduser/${userId}/profile/`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function addUser(userData) {
  return axiosInstance.post('/users/add', JSON.stringify(userData))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function checkSharedUserByEmail(email) {
  return axiosInstance.get(`/user/${email}/email`)
    .then(response => {
      return response.data.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function changePassword(currentPassword, newPassword, userId) {
  return axiosInstance.post('/users/changepwd', JSON.stringify({
    currentPassword: currentPassword,
    newPassword: newPassword,
    id: userId
  }))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getById(id) {
  return axiosInstance.get(`/user/${id}`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function addSharedUser(userData) {
  return axiosInstance.post(`/user/${userData.additionalInfo.member_guid}/addshareduser`, JSON.stringify(userData))
    .then(response => {
      return response.data.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function getAllSharedUsers(memberGuid) {
  return axiosInstance.post(`/user/${memberGuid}/sharedusers`, JSON.stringify(memberGuid))
    .then(response => {
      return response.data.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}


function register(user) {
  return axiosInstance.post('/users/register', JSON.stringify(user))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function sendEmailToUser(emailDetails) {
  return axiosInstance.post(`${maestroenv.REACT_APP_API_URL}/users/sendemail`, {"emailDetails": emailDetails})
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function updateUserDetails(userData) {
  return axiosInstance.post('/users/updatedetails', JSON.stringify(userData))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function updateSharedUserDetails(userData) {
  return axiosInstance.post(`/user/${userData.keyInfo.memberGuid}/updateshareduser`, JSON.stringify(userData))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function updateSharedUserKeyInfo(userData) {
  return axiosInstance.post(`/user/${userData.memberGuid}/updateshareduserkey`, JSON.stringify(userData))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function updateSharedUserPasswordInfo(userData) {
  return axiosInstance.post(`/user/${userData.memberGuid}/updateshareduserexpiration`, JSON.stringify(userData))
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function deactivateUserById(id) {
  return axiosInstance.post(`/user/${id}/deactivate`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function activateUserById(id) {
  return axiosInstance.post(`/user/${id}/activate`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function lockUserById(id) {
  return axiosInstance.post(`/user/${id}/lock`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

function unlockUserById(id) {
  return axiosInstance.post(`/user/${id}/unlock`)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      return Promise.reject(requestHelper.handleErrorResponse(error));
    });
}

