import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {GridItem} from "../../../../../components/Grid";
import NameBasedMenuItem from "../components/NameBasedMenuItem";

const FilterDropdown = props => {
  const {
    classes,
    optionNameDisplay,
    optionNameDisplayPlural,
    optionNameInternal,
    optionId,
    includeAllOptionsAsOption,
    initialOptions,
    onHandleSelect,
    optionSelect,
    menuItemListMethod = NameBasedMenuItem,
    screenRatios={md: 2, lg: 2},
    render
  } = props;

  if (render === false || (render === undefined && onHandleSelect === undefined)) return null;
  else return (
    <GridItem
      xs={screenRatios.xs}
      sm={screenRatios.sm}
      md={screenRatios.md}
      lg={screenRatios.lg}
      xl={screenRatios.xl}
    >
      <FormControl
        className={classes.selectFormControl}
        fullWidth
      >
        <InputLabel
          className={classes.selectLabel}
          htmlFor={optionId}
        >
          Choose {optionNameDisplay}
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          inputProps={{
            name: optionNameInternal,
            id: optionId
          }}
          onChange={onHandleSelect}
          value={optionSelect}
          variant={'standard'}
          defaultValue=''
        >
          <MenuItem
            classes={{
              root: classes.selectMenuItem
            }}
            disabled
          >
            Choose {optionNameDisplay}
          </MenuItem>
          { includeAllOptionsAsOption ? <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={`All ${optionNameDisplayPlural}`}
          >
            All {optionNameDisplayPlural}
          </MenuItem> : undefined }
          {
            menuItemListMethod({classes: classes, options: initialOptions})
          }
        </Select>
      </FormControl>
    </GridItem>
  );
}

export default FilterDropdown;
