import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import Button from "../../../../../components/CustomButtons/Button";
import {SpeakerNotes} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    display: 'flex',
    alignItems: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  spacer: {
    flexGrow: 1
  },
  addNoteButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const NotesToolbar = props => {
  const {
    setOpenNote
  } = props;

  const classes = useStyles();

  const handleAddNote = event => {
    setOpenNote(true);
  };

  return (
    <div className={classes.row}>
      <Button
        className={classes.addNoteButton}
        onClick={handleAddNote}
        color="success"
        variant="contained"
        startIcon={<SpeakerNotes fontSize={"large"}/>}
      >
        Add notes
      </Button>
    </div>
  );
};

NotesToolbar.propTypes = {
  className: PropTypes.string
};

export default NotesToolbar;
